<button
  type="button"
  class="btn btn-color text-white rounded-pill d-block ms-auto product-sans-light"
  data-bs-toggle="modal"
  data-bs-target="#addPermissionModal"
>
  <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
  <span>Add New Permission</span>
</button>
<div class="table-responsive fluid-section bg-white permissions w-100">
  <table class="table table-hover align-middle text-center">
    <thead class="position-sticky top-0 bg-white z-9">
      <tr class="product-sans-medium">
        <th
          scope="col"
          class="text-md-start pe-0 ps-md-5 col-1 col-md-2 border-dark border-top-0"
        >
          Name
        </th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">
          Description
        </th>
        <th
          scope="col"
          class="col-1 col-md-2 position-relative border-dark border-top-0"
        >
          Edit
          <div class="w-100 h-100 bg-white position-absolute top-0"></div>
        </th>
      </tr>
    </thead>
    <tbody class="product-sans-light">

      <tr
      *ngFor="
        let row of permissions
      "
      >
        <td class="text-md-start ps-md-5 col-1 col-md-2 py-3"> {{ row.name }} </td>
        <td class="col-1 col-md-2"> {{ row.description }} </td>
        <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">

          <a (click)="edit(row._id)">
            <img
              class="px-1 px-md-2 cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#editPermissionModal"            
              src="./assets/img/edit-icon.svg"
              alt="edit icon"
            />
          </a>
          <img
            class="ps-1 ps-md-2 cursor-pointer"
            src="./assets/img/delete-icon.svg"
            data-bs-toggle="modal"
            data-bs-target="#deletePermissionModal"            
            alt="delete icon"
            (click)="getRowIdToDelete(row._id)"
          />

        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- ADD NEW PERMISSION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="addPermissionModal"
  tabindex="-1"
  aria-labelledby="addPermissionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addPermissionModalLabel"
        >
          Add New Permission
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <form
        role="form"
        [formGroup]="permissionForm"
        role="form"
        #formDir="ngForm"
        id="add-perm-form"
        novalidate
        >
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="name"
              class="form-label text-nowrap col-2 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
              >Name</label
            >
            <input
            formControlName="name"
            [ngClass]="{
              'is-invalid': formDir.submitted && name.invalid
            }"
              type="text"
              class="form-control rounded-pill"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div class="mb-2 d-flex align-items-center justify-content-between">
            <label
              for="description"
              class="form-label text-wrap col-2 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
              >Description</label
            >
            <textarea
            formControlName="description"
            [ngClass]="{
              'is-invalid': formDir.submitted && description.invalid
            }"
              class="form-control rounded-4"
              placeholder="Description"
              id="description"
            ></textarea>
          </div>
          <div class="modal-footer">
            <input
              class="btn btn-primary btn-color rounded-pill col-2"
              value="Save"
              type="submit"
              data-bs-dismiss="modal"
              (click)="AddNewPermission()"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- EDIT PERMISSION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="editPermissionModal"
  tabindex="-1"
  aria-labelledby="editPermissionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="editPermissionModalLabel"
        >
          edit Permission
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <form
        role="form"
        [formGroup]="permissionForm"
        role="form"
        #formDir="ngForm"
        id="add-perm-form"
        novalidate
        >
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="name"
              class="form-label text-nowrap col-2 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
              >Name</label
            >
            <input
            formControlName="name"
            [ngClass]="{
              'is-invalid': formDir.submitted && name.invalid
            }"
              type="text"
              class="form-control rounded-pill"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div class="mb-2 d-flex align-items-center justify-content-between">
            <label
              for="description"
              class="form-label text-wrap col-2 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
              >Description</label
            >
            <textarea
            formControlName="description"
            [ngClass]="{
              'is-invalid': formDir.submitted && description.invalid
            }"
              class="form-control rounded-4"
              placeholder="Description"
              id="description"
            ></textarea>
          </div>
          <div class="modal-footer">
            <input
              class="btn btn-primary btn-color rounded-pill col-2"
              value="Save"
              type="submit"
              data-bs-dismiss="modal"
              (click)="updatePermission()"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- DELETE CONFIRMATION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="deletePermissionModal"
  tabindex="-1"
  aria-labelledby="deletePermissionModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="deletePermissionModalLabel"
        >
          Delete Confirmation
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h3 text-center">Are you sure you want to delete?</h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-toggle="modal"
          data-bs-target="#rejectionReasonModal"
          (click)="deleteConfirmation()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
