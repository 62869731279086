import { Unicode } from './../shared/Endpoint';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuModel } from 'src/models/mongo/menu.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Injectable()
export class AuthGuard  {
  url = '';

  constructor(
    private router: Router,
    private localStorage: LocalStorageService
  ) {}

  //canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // // logged in so return true
    if (this.localStorage.isAuthenticated()) {
      if (this.hasRequiredPermission(state.url)) {
        // if (location.href.indexOf("returnUrl") >= 0) {
        //   this.actvRoute.queryParams.subscribe(params => {
        //     var url = params['returnUrl'];
        //     this.router.navigate([url]);
        //     return false;
        //   });
        // }
        return true;
      } else {
        var stg_mnus = this.localStorage.get(Unicode.MENUS);
        if (stg_mnus) {
          var menus = JSON.parse(stg_mnus);
          if (menus && menus.length > 0) {
            this.router.navigate([menus[0].link]);
          } else this.router.navigate(['/login']);
        } else this.router.navigate(['/login']);
        return true;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  private hasRequiredPermission(path: string): boolean {
    let menus = <MenuModel[]>JSON.parse(this.localStorage.get(Unicode.MENUS));
    if (menus != null && menus.length > 0) {
      if (
        menus.find((menu) => menu.link.includes(path)) ||
        path.split('/').length > 2
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
