<div
  class="modal fade"
  bsModal
  #gwsGcpOppModal="bs-modal"
  tabindex="-1"
  id="gwsGcpModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <button
        type="button"
        class="btn-close p-3 ms-auto"
        (click)="gwsGcpOppModal.hide()"
      ></button>
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center">
          Google Workspace or Google Cloud Platform?
        </h1>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-evenly py-3 px-5">
          <a
            (click)="save(true)"
            class="text-black text-decoration-none pe-5 cursor-pointer"
            data-bs-dismiss="modal"
            data-bs-target="#gwsGcpModal"
          >
            <img
              src="./assets/img/google-workspace-icon.png"
              class="w-100"
              alt="google workspace icon"
            />
          </a>
          <a
            (click)="save(false)"
            data-bs-dismiss="modal"
            data-bs-target="#gwsGcpModal"
            class="text-black text-decoration-none border-start border-secondary ps-5 cursor-pointer"
          >
            <img
              src="./assets/img/google-cloud-icon.png"
              class="w-100"
              alt="google cloud icon"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>