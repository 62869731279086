import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposalDetailsRoutingModule } from './proposal-details-routing.module';
import { ProposalDetailsComponent } from './proposal-details.component';
import { ProposalGoogleDocCardModule } from 'src/shared-components/proposal-google-doc-card/proposal-google-doc-card.module';

@NgModule({
  declarations: [ProposalDetailsComponent],
  imports: [
    CommonModule,
    ProposalGoogleDocCardModule,
    ProposalDetailsRoutingModule,
  ],
})
export class ProposalDetailsModule {}
