import { NgModule } from '@angular/core';
import { PocComponent } from './poc.component';
import { POCRoutingModule } from './poc.routing.module';

@NgModule({
  declarations: [PocComponent],
  imports: [POCRoutingModule],
  exports: [PocComponent],
})
export class PocModule {}
