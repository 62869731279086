<div
  class="modal fade"
  bsModal
  #vrmDecisionModal="bs-modal"
  tabindex="-1"
  id="vrmDecisionModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">VRM Decision</h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="vrmDecisionModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="d-flex align-items-center justify-content-between">
          {{ user_name }} has requested to create a POC without sending a DAF
          Request to Google. Do you agree?
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="takeDecision(true)"
          class="btn btn-primary btn-color rounded-pill col-lg-5 col-md-6 col-6 btn_vrm_app"
          data-bs-dismiss="modal"
        >
          Rejecte, DAF required before POC
        </button>
        <button
          type="button"
          (click)="takeDecision(false)"
          class="btn btn-primary btn-color rounded-pill col-lg-5 col-md-6 col-6 btn_vrm_rej"
          data-bs-dismiss="modal"
        >
          Approve, continue without DAF
        </button>
      </div>
    </div>
  </div>
</div>
