import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgbDropdown,
  NgbDropdownConfig,
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from 'src/auth/auth.guard';
import { LoaderInterceptor } from 'src/servcies/loader-interceptor.service';
import { LoaderService } from 'src/servcies/loader.service';
import { ConfirmDialogComponent } from 'src/shared-components/confirm-dialog/confirm-dialog.component';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { ConfirmDialogService } from './../servcies/confirm-dialog.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyLoaderComponent } from './my-loader/my-loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubscriptionService } from 'src/servcies/api/subscription.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomErrorHandlerService } from 'src/servcies/custom-error-handler.service';
import { MessageService } from '../servcies/message.service';
@NgModule({
  declarations: [AppComponent, ConfirmDialogComponent, MyLoaderComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgbPopoverModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgbDropdownModule,
  ],
  providers: [
    AuthGuard,
    HttpClient,
    ConfirmDialogService,
    DatePipe,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    SubscriptionService,
    MessageService,
    NgbDropdownConfig,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(config: NgbDropdownConfig) {
    // Set default z-index for all dropdowns
  }
}
