import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RejectionPopupComponent } from './rejection-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [RejectionPopupComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
  ],
  exports: [RejectionPopupComponent],
})
export class RejectionPopupModule {}
