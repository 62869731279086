import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingAccountsComponent } from './billing-accounts.component';
import { BillingAccountListModule } from './billing-account-list/billing-account-list.module';
import { BillingAccountDetailsModule } from './billing-account-details/billing-account-details.module';

const routes: Routes = [
  {
    path: '',
    component: BillingAccountsComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', loadChildren: () => BillingAccountListModule },
      { path: 'details', loadChildren: () => BillingAccountDetailsModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingAccountsRoutingModule {}
