<section class="box-shadow bg-white rounded-5 p-2 p-md-4 mx-0 h-95">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 p-sm-3 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
        (click)="goBack()"
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Reports
    </h1>
  </div>
  <ul class="d-flex flex-row pe-3 pe-lg-0 ps-0 small-text gap-1" id="report_tabs">
    <li
      class="col text-center p-1 p-sm-2 report-status cursor-pointer rounded-start list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="orders-report"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Orders</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 report-status cursor-pointer rounded-end list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="customers-report"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Customers</a
      >
    </li>
  </ul>

  <router-outlet></router-outlet>
</section>
