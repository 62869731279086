import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DealModel } from 'src/models/mongo/deal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-all-deals',
  templateUrl: './all-deals.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./all-deals.component.scss'],
})
export class AllDealsComponent implements OnInit {
  @HostBinding('class') class = '';
  orders: DealModel[] = [];
  plans = [];
  static googleProducts = [];
  currentUser: UserModel;
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  customerSearch: string;
  fromSelectedDate;
  toSelectedDate;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static deals = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  types = ['ALL', 'GWS', 'GCP'];

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private location: Location
  ) {
    AllDealsComponent._http = http;
    AllDealsComponent.gcp = false;
    var current = new Date();
    var beforeOneMonth = current.setMonth(current.getMonth() - 1);
    this.fromSelectedDate = new Date(beforeOneMonth).toISOString().slice(0, 10);
    this.toSelectedDate = new Date().toISOString().slice(0, 10);
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    AllDealsComponent.pagination = 1;
    this.getDeals(null);
  }

  async getDeals(pagination) {
    if (pagination == null) pagination = AllDealsComponent.pagination;
    var prs = new HttpParams()
      .set(Unicode.PAGE, (pagination - 1) * AllDealsComponent.pageSize + '')
      .set(Unicode.LIMIT, AllDealsComponent.pageSize + '')
      .set(Unicode.TYPE, this.selectedFilterType);
    prs = prs.set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.fromSelectedDate))
      prs = prs.set(Unicode.FROM_DATE, this.fromSelectedDate);
    if (!Utilities.isNullOrEmpty(this.toSelectedDate))
      prs = prs.set(Unicode.TO_DATE, this.toSelectedDate);
    await AllDealsComponent._http
      .get<any>(Endpoint.API_URL + '' + Endpoint.DEAL_LIST, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          AllDealsComponent.total = <number>resp['total'];
          AllDealsComponent.deals = resp.deals ?? [];
        }
      });
  }

  renderPage(event: number) {
    AllDealsComponent.pagination = event;
    this.getDeals(null);
  }

  search() {
    AllDealsComponent.pagination = 1;
    this.getDeals(null);
  }
  selectDate() {
    AllDealsComponent.pagination = 1;
    this.getDeals(null);
  }
  makeOrder(id) {
    var deal: DealModel = AllDealsComponent.deals.find((x) => x.id == id);
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(deal.domain)
    );
    this.router.navigate(['/orders/add-order'], {
      state: {
        customer_api_id: deal.domain.customer_api_id,
        domain_rfid: deal.domain._id,
        domain: deal.domain.domain,
        organisation: deal.domain.organisation_name,
        skus: JSON.stringify(deal.sku),
        isGWS: !deal.is_gcp,
        source: deal.source,
        google_rep: deal.google_rep,
        opportunity_nbr: deal.opportunity_nbr,
        deal_type: deal.type,
        customer: JSON.stringify(deal.customer_details),
      },
      skipLocationChange: false,
    });
  }

  viewDeal(edit: boolean, id) {
    var deal: DealModel = AllDealsComponent.deals.find((x) => x.id == id);
    if (deal.is_gcp) {
      this.router.navigate(['deals/add-deal-gcp'], {
        state: {
          edit: edit,
          dealId: id,
          isGWS: !deal.is_gcp,
          organisation: deal.customer_organisation,
          domain: deal.customer_domain,
          customer_api_id: deal.customer_api_id,
          domain_rfid: deal.domain_rfid,
          contact_person_name: deal.customer_contact_person_name,
          contact_person_email: deal.customer_email,
          country_name: deal.country_name,
        },
        skipLocationChange: false,
      });
    } else {
      this.router.navigate(['deals/add-deal'], {
        state: {
          edit: edit,
          dealId: id,
          isGWS: !deal.is_gcp,
          organisation: deal.customer_organisation,
          domain: deal.customer_domain,
          customer_api_id: deal.customer_api_id,
          domain_rfid: deal.domain_rfid,
          contact_person_name: deal.customer_contact_person_name,
          contact_person_email: deal.customer_email,
          country_name: deal.country_name,
        },
        skipLocationChange: false,
      });
    }
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    AllDealsComponent.pagination = 1;
    AllDealsComponent.gcp = !AllDealsComponent.gcp;
    this.getDeals(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      AllDealsComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getDeals(null);
    }
  }

  get GetData() {
    return AllDealsComponent.deals;
  }
  get GetTotalCount() {
    return AllDealsComponent.total;
  }
  get GetPagination() {
    return AllDealsComponent.pagination;
  }
  get GetPageSize() {
    return AllDealsComponent.pageSize;
  }
  goBack(): void {
    this.location.back();
  }

  deleteDeal(id: string) {
    if (confirm('Are you sure to delete this Deal?')) {
      this.http
        .put(Endpoint.API_URL + '' + Endpoint.DELETE_DEAL + id, {})
        .subscribe((response: any) => {
          this.getDeals(null);
          alert(response.msg);
        });
    }
  }
}
