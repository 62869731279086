import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProposalListModule } from './proposal-list/proposal-list.module';
import { ProposalDetailsModule } from './proposal-details/proposal-details.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'proposal-list',
    pathMatch: 'full',
  },
  { path: 'proposal-list', loadChildren: () => ProposalListModule },
  { path: 'proposal-details', loadChildren: () => ProposalDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProposalRoutingModule {}
