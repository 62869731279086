<div>
    <div id="add_head_perm">
      <div class="p-0 m-0">
        <button
          (click)="AddNewPermission()"
          class="btn_blue_bg float-right m-0"
          data-toggle="tooltip"
          title="add new permission"
        >
          <img class="ic_plus_white p-0" src="../../assets/+.png" />
        </button>
      </div>
    </div>
    <div id="perms">
      <ul>
        <li class="row col-12">
          <span class="col-2 cust_title">Name</span>
          <span class="col-5 cust_title">Description</span>
          <span class="col-2"></span>
          <span class="col-2"></span>
        </li>
        <li class="row col-12" *ngFor="let perm of permissions">
          <span class="col-2">{{ perm.name }}</span>
          <span class="col-5">{{ perm.description }}</span>
          <button class="col-2 btn_edit_perm" (click)="editPermission(perm)">
            Edit
          </button>
          <button class="col-2 btn_rmv_perm" (click)="deletePermission(perm.id)">
            Delete
          </button>
        </li>
      </ul>
    </div>
  </div>
  
  <!--- NEW PERMISSION POPUP --->
  <div
    class="modal fade"
    bsModal
    #AddPermModal="bs-modal"
    tabindex="-1"
    [config]="{ backdrop: 'static', keyboard: false }"
  >
    <div class="modal-dialog">
      <div class="modal-content p-10">
        <div class="modal-header">
          <label class="font-weight-bold text-uppercase"
            >Add new permission</label
          >
          <div (click)="AddPermModal.hide()">
            <span class="fa fa-close sp_popup_close"></span>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <form
              (ngSubmit)="addPermission()"
              id="add-perm-form"
              [formGroup]="AddPermForm"
              role="form"
              #formDir="ngForm"
              novalidate
              class="ng-untouched ng-pristine ng-invalid row"
            >
              <div class="col-12 m-t-15 input-group">
                <span class="border-lable-flt col-12 p-0">
                  <input
                    type="text"
                    name="name"
                    placeholder=" "
                    class="form-control"
                    formControlName="name"
                    [ngClass]="{
                      'is-invalid': formDir.submitted && name.invalid
                    }"
                    required
                    autofocus
                  />
                  <label for="label-name"
                    >Name<span class="spn_required"> *</span></label
                  >
                </span>
                <div
                  class="invalid-feedback"
                  *ngIf="formDir.submitted && name.invalid"
                >
                  <p *ngIf="name.errors.required">Name is required</p>
                </div>
              </div>
              <div class="col-12 m-t-15 input-group">
                <span class="border-lable-flt col-12 p-0">
                  <textarea
                    type="text"
                    name="description"
                    placeholder=" "
                    class="form-control"
                    formControlName="description"
                  ></textarea>
                  <label for="label-name">Description</label>
                </span>
              </div>
  
              <div class="col-12 div_btns float-right p-0 text-right">
                <button class="btn_blue_bg">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-background"></div>
  