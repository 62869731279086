import { SettingsService } from 'src/servcies/backend-services/settings.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CurrencyModel } from 'src/models/mongo/currency.model';

@Component({
  selector: 'settings-currency',
  templateUrl: './currency.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  currencies: CurrencyModel[] = [];
  // currencies: any[] = [];
  pageTitle: string = '';
  @ViewChild('AddModal')
  AddModal: ModalDirective;
  currencyForm: UntypedFormGroup;
  newCurrencyModel: CurrencyModel = new CurrencyModel();
  currencyId: string;

  currency: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService
  ) {
    this.currencyForm = fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.getAllCurrencies();
  }

  AddNewCurrency() {
    var currencyData = this.currencyForm.value;
    this.settingsService
      .createCurrency(currencyData)
      .subscribe((response: any) => {
        console.log('Currency created successfully:', response);
        // push it to the currencyData
        // this.currencies.push(currencyData);
        this.currencies.push(response); // Add the newly created currency to the array
      });
  }

  updateCurrency(): void {
    const updatedCurrency = this.currencyForm.value;

    this.settingsService
      .updateCurrency(this.currencyId, updatedCurrency)
      .subscribe(
        (response) => {
          console.log('Currency updated successfully:', response);
        },
        (error) => {
          console.log('Error updating currency:', error);
        }
      );
  }

  async getAllCurrencies(): Promise<void> {
    await this.settingsService.getAllCurrencies().subscribe((response: any) => {
      this.currencies = response.data;
    });
  }

  deleteConfirmation() {
    this.settingsService
      .deleteCurrency(this.currencyId)
      .subscribe((response: any) => {
        console.log('Currency successfully deleted');
      });
  }

  getRowIdToDelete(rowId) {
    this.currencyId = rowId;
  }

  edit(rowId) {
    this.currencyId = rowId;

    this.settingsService
      .getCurrencyById(this.currencyId)
      .subscribe((response: any) => {
        this.currencyForm.patchValue(response.data);
      });
  }

  get name() {
    return this.currencyForm.get('name');
  }
  get code() {
    return this.currencyForm.get('code');
  }
}
