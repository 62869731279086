import { SalesComponent } from './sales.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaleDetailsModule } from './sale-details/sale-details.module';
import { SalesModule } from './sales.module';
import { SalesListModule } from './sales-list/sales-list.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'user-list',
    pathMatch: 'full',
  },
  {
    path: 'user-list',
    loadChildren: () => SalesListModule,
  },
  { path: 'user-details', loadChildren: () => SaleDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesRoutingModule {}
