import { DatePipe } from '@angular/common';
import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { OrderRenewalListComponent } from './order-renewal-list/order-renewal-list.component';
import { FlexCustomersComponent } from './flex-customers/flex-customers.component';
import { LocalStorageService } from '../../../../../src/servcies/localstorage-service';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-order-list',
  templateUrl: './order-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit {
  @HostBinding('class') class = ' ';
  fromSelectedDate;
  toSelectedDate;
  cancelled_order_date_from: string;
  cancelled_order_date_to: string;

  constructor(
    private localStorage: LocalStorageService,
    private location: Location
  ) {
    var current = new Date();
    if (
      !Utilities.isNullOrEmpty(
        this.localStorage.get('cancelled_order_date_from')
      )
    ) {
      this.fromSelectedDate = this.localStorage.get(
        'cancelled_order_date_from'
      );
    } else {
      var beforeOneMonth = current.setMonth(current.getMonth() - 1);
      this.fromSelectedDate = new Date(beforeOneMonth)
        .toISOString()
        .slice(0, 10);
    }
    if (
      !Utilities.isNullOrEmpty(this.localStorage.get('cancelled_order_date_to'))
    ) {
      this.toSelectedDate = this.localStorage.get('cancelled_order_date_to');
    } else {
      this.toSelectedDate = new Date().toISOString().slice(0, 10);
    }
  }

  async ngOnInit(): Promise<void> {}
  ngOnDestroy() {
    this.localStorage.remove('cancelled_order_date_from');
    this.localStorage.remove('cancelled_order_date_to');
  }
  async search(event) {
    const val = event.target.value.toLowerCase().trim();
    if (document.getElementById(Unicode.ORDERS_ALL_PAGE_ID)) {
      AllOrdersComponent.getOrders(1);
    } else if (document.getElementById(Unicode.ORDERS_RENEWAL_PAGE_ID)) {
      OrderRenewalListComponent.renewalOrders = [];
      await OrderRenewalListComponent.getRenewalOrdersAPIStatic(
        OrderRenewalListComponent._http
      );
    } else if (document.getElementById(Unicode.ORDERS_FLEX_PAGE_ID)) {
      FlexCustomersComponent.tempFlexCustomers = [];
      await FlexCustomersComponent.getFlexCUstomers();
    }
  }
  async selectDate() {
    if (document.getElementById(Unicode.ORDERS_ALL_PAGE_ID)) {
      AllOrdersComponent.getOrders(1);
    } else if (document.getElementById(Unicode.ORDERS_RENEWAL_PAGE_ID)) {
      OrderRenewalListComponent.renewalOrders = [];
      await OrderRenewalListComponent.getRenewalOrdersAPIStatic(
        OrderRenewalListComponent._http
      );
    } else if (document.getElementById(Unicode.ORDERS_FLEX_PAGE_ID)) {
      FlexCustomersComponent.tempFlexCustomers = [];
      await FlexCustomersComponent.getFlexCUstomers();
    }
  }
  clearfilters() {
    var current = new Date();
    var beforeOneMonth = current.setMonth(current.getMonth() - 1);
    this.fromSelectedDate = new Date(beforeOneMonth).toISOString().slice(0, 10);

    this.toSelectedDate = new Date().toISOString().slice(0, 10);
    this.cancelled_order_date_to = '';
    this.cancelled_order_date_from = '';
    this.localStorage.remove('cancelled_order_date_from');
    this.localStorage.remove('cancelled_order_date_to');
    const order_date_from: HTMLInputElement = <HTMLInputElement>(
      document.getElementById('order-date-from')
    );

    const order_date_to: HTMLInputElement = <HTMLInputElement>(
      document.getElementById('order-date-to')
    );

    order_date_from.value = this.fromSelectedDate;
    order_date_to.value = this.toSelectedDate;

    AllOrdersComponent.getOrders(null);
  }
  goBack(): void {
    this.location.back();
  }
}
