import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CustomerEmailValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control?.parent) return null;
    var domainName = control?.parent?.controls['domain']?.value;
    var emailDomain = control.value.split('@');
    emailDomain = emailDomain?.length > 0 ? emailDomain[1] : '';
    return emailDomain == domainName
      ? { ValidationName: { value: control.value } }
      : null;
  };
}
