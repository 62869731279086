import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddDealGCPComponent } from './add-deal-gcp.component';

const routes: Routes = [{ path: '', component: AddDealGCPComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddDealGCPRoutingModule {}
