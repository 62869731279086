import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PocDetailsRoutingModule } from './poc-details-routing.module';
import { POCGoogleDocCardModule } from 'src/shared-components/poc-google-doc-card/poc-google-doc-card.module';
import { PocDetailsComponent } from './poc-details.component';

@NgModule({
  declarations: [PocDetailsComponent],
  imports: [CommonModule, POCGoogleDocCardModule, PocDetailsRoutingModule],
})
export class PocDetailsModule {}
