import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexCustomersRoutingModule } from './flex-customers-routing.module';
import { FlexCustomersComponent } from './flex-customers.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [FlexCustomersComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    NgbModule,
    FormsModule,ReactiveFormsModule,
    ModalModule.forRoot(),
    FlexCustomersRoutingModule
  ]
})
export class FlexCustomersModule { }
