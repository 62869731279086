import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'lib-engineer-recommendations',
  template: ` <router-outlet></router-outlet> `,
  styles: [],
})
export class EngineerRecommendationsComponent {
  @HostBinding('class') class = ' ';
}
