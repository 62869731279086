import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { DomainModel } from 'src/models/mongo/domain.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { CustomerRequirementsService } from '../../customer-requirements.service';

@Component({
  selector: 'customer-requirements-details',
  templateUrl: './customer-requirements-details.component.html',
  styleUrls: ['./customer-requirements-details.component.scss'],
})
export class CustomerRequirementsDetailsComponent
  implements OnInit, AfterViewInit
{
  currentUser: UserModel;
  model: CustomerRequirementsModel = new CustomerRequirementsModel();
  title: string = 'Create Customer Requirements';
  googleDocData: any;
  currentDomain: DomainModel = new DomainModel();
  loginUsersData: any;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private customerRequirementsService: CustomerRequirementsService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state?.crModel) this.model = state.crModel;
      else if (routeParams['Id']) {
        //EMAIL LINK CASE
        await this.http
          .get(
            Endpoint.API_URL +
              Endpoint.GET_CUSTOMER_REQUIREMENT_BY_ID +
              routeParams['Id']
          )
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.model = resp;
            }
          });
      }
      this.model = Object.assign(new CustomerRequirementsModel(), this.model);
      if (this.model?._id) {
        this.title = 'Customer Requirements';
        this.googleDocData = {
          module: 'Customer Requirements',
          customer_domain: this.model.domain.domain,
          customer_org: this.model.domain.organisation_name,
          googleDocUrl: this.model.googleDocUrl,
          createdUser: {
            name: this.model.user.created_user_name,
            role: this.model.user.created_user_role,
            date: this.model.created_at,
            text: 'Created Customer Requirements',
            img: this.model.user.created_user_img,
          },
          approval_phases: this.model?.approval_phases,
        };
      } else if (this.model) {
        this.googleDocData = {
          module: 'Customer Requirements',
          customer_domain: null,
          customer_org: null,
        };
      }
      this.googleDocData.googleDocUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/document/d/' +
            this.model.google_doc_id +
            '/edit?usp=sharing&embed=true'
        );
    });
  }

  ngOnInit(): void {}

  selectCustomer(dm: DomainModel) {
    this.currentDomain = dm;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.currentDomain)
    );
  }

  createCustReq(cr: CustomerRequirementsModel) {
    this.router.navigate(['customer-requirements']);
  }

  approveCustReq(cr: CustomerRequirementsModel) {
    this.router.navigate(['customer-requirements']);
  }

  sendDraftCustReqForApproval(cr: CustomerRequirementsModel) {
    this.router.navigate(['customer-requirements']);
  }

  async sendCustReqToCustomer(custInfo) {
    var that = this;
    await this.customerRequirementsService
      .sendCustomerRequirementsToCust(
        this.model,
        custInfo,
        this.currentUser,
        this.model.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.router.navigate(['customer-requirements']);
        }
      });
  }

  cancel() {
    this.router.navigate(['customer-requirements']);
  }
}
