import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CustomerInfoPopupModule } from '../customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from '../rejection-popup/rejection-popup.module';
import { POCGoogleDocCardComponent } from './poc-google-doc-card.component';
import { AddBudgetFormModule } from 'projects/gcp/src/lib/add-budget-form/add-budget-form.module';
import { DafRequestDecisionPopupModule } from '../daf-request-decision-popup/daf-request-decision-popup.module';
import { LinkProjectPopupModule } from '../link-project-popup/link-project-popup.module';
import { NewProjectModalModule } from '../new-project-modal/new-project-modal.module';
import { PocVrmApprovalPopupModule } from '../poc-vrm-approval-popup/poc-vrm-approval-popup.module';

@NgModule({
  declarations: [POCGoogleDocCardComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    NgSelectModule,
    FormsModule,
    PocVrmApprovalPopupModule,
    DafRequestDecisionPopupModule,
    CustomerInfoPopupModule,
    RejectionPopupModule,
    NewProjectModalModule,
    LinkProjectPopupModule,
    AddBudgetFormModule,
  ],
  exports: [POCGoogleDocCardComponent],
})
export class POCGoogleDocCardModule {}
