import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerEntitlementsComponent } from './customer-entitlements.component';
import { CustomerEntitlementsRoutingModule } from './customer-entitlements-routing.module';
import { EntitlementService } from 'src/servcies/api/entitlement.service';

@NgModule({
  declarations: [CustomerEntitlementsComponent],
  imports: [CommonModule, CustomerEntitlementsRoutingModule],
  providers:[EntitlementService]
})
export class CustomerEntitlementsModule {}
