import { Utilities } from '../shared/utilities';
import { Injectable } from '@angular/core';
import { Endpoint, Unicode } from '../shared/Endpoint';
import { UserModel } from 'src/models/mongo/user.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public save(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public get(key: string) {
    return localStorage.getItem(key);
  }

  public logout() {
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    return !Utilities.isNullOrEmpty(this.getLoggedinUser());
  }
  public registerUser(userJsonString: string) {
    this.save(Unicode.CURRENT_USR, userJsonString);
  }

  public getLoggedinUser() {
    return this.get(Unicode.CURRENT_USR);
  }

  public getLoggedinUserModel(): UserModel {
    var obj = new UserModel();
    var json = this.get(Unicode.CURRENT_USR);
    if (json) obj = JSON.parse(json);
    var user = new UserModel(obj);
    return user;
  }

  public clear() {
    localStorage.clear();
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }
  public resetDataOnly() {
    var user = this.getLoggedinUser();
    this.clear();
    this.registerUser(user);
  }

  public getUserInfo(): UserModel {
    var usersInfojson = this.get(Unicode.USERS_INFO);
    return usersInfojson ? JSON.parse(usersInfojson) : {};
  }
}
