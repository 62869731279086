import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerDealsRoutingModule } from './customer-deals-routing.module';
import { CustomerDealsComponent } from './customer-deals.component';


@NgModule({
  declarations: [CustomerDealsComponent],
  imports: [
    CommonModule,
    CustomerDealsRoutingModule
  ]
})
export class CustomerDealsModule { }
