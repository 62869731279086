import { CustomerModel } from 'src/models/mongo/customer.model';
import { BaseModel } from '../mongo/base.model';
import { DomainModel } from './domain.model';
import { Utilities } from 'src/shared/utilities';
import { TeamModel } from './team';
import { Group } from 'src/shared/Endpoint';
import { UserModel } from './user.model';

export class OrderModel extends BaseModel {
  _id: string;
  id: string;
  created_by_rfid: string;
  domain_rfid: string;
  action: string;
  trial_expiry_date;
  order_status: string;
  po_id: string;
  order_sale_invoice_nbr: string;
  description: string = null;
  transfer_to_direct: boolean = false;
  opportunity_nbr: string = null;
  opportunity_source: string = null;
  opportunity_status: string = null;
  google_rep: string = null;
  checked: boolean = false;
  verified: boolean;
  draft: boolean;
  opportunity_rfid: string;
  created_at: Date;
  project_number: string;
  project_name: string;
  billing_name: string;
  place_as: boolean;

  reject_date: string;
  exported: boolean = false;
  is_withdraw: boolean = false;
  billing_method: string;
  rejecter_email: string = ''; //to be deleted
  reject_reason: string;
  execution_note: string = '';
  invoice_file_rfid: string;
  currency_rfid: string;
  gcp_credits_coupon: string;
  used: boolean;
  approval_phases = [];
  sku: any = [];
  sku_names: any = [];
  support: OrderSupportModel;
  sale_name: string;
  sale_email: string;
  is_gcp: boolean;
  customer_details: CustomerModel;
  company_section: string;
  team_rfid: string;
  //
  created_date_string: string;
  user: any;
  domain: DomainModel;
  customer_api_id: string;
  customer_auth_token: string;
  is_customer_transfer: boolean;
  customer_domain: string;
  customer_organisation: string;
  skuName: string;
  skuNames: [];
  license_count: number;
  paramsData: any = {};
  projectNumber: any;
  link_project_billing: any;
  managerApprovalPart: any;
  gpmApprovalPart: any;
  financePart: any;
  team: TeamModel;

  constructor() {
    super();
  }

  public initialiseApprovalPhases(teams: TeamModel[], currentUser: UserModel) {
    var managerTeam = this.team?.manager;
    var isTeamManager = teams.find((x) => x.manager?.id === currentUser._id);
    if (isTeamManager) {
      this.approval_phases = [
        {
          role: Group.GPM,
        },
      ];
    } else {
      this.approval_phases = [
        {
          email: managerTeam?.email,
          id: managerTeam?.id,
          name: managerTeam?.user_name,
          img: managerTeam?.image_url,
          role: Group.Sale_Manager,
        },
      ];
    }
  }
}

export class OrderSupportModel {
  available: boolean;
  price: number;
  start_date: Date;
  end_date: Date;
}
