import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'rejection-popup',
  templateUrl: './rejection-popup.component.html',
  styleUrls: ['./rejection-popup.component.scss'],
})
export class RejectionPopupComponent implements OnInit {
  @ViewChild('rejectionModal') rejectionModal: ModalDirective;
  @Output() rejectListener = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  show() {
    this.rejectionModal.show();
  }
  hide() {
    this.rejectionModal.hide();
  }

  reject(reason: string) {
    this.rejectListener.emit(reason);
  }
}
