import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SetBillingPopupRoutingModule } from './set-billing-popup-routing.module';
import { SetBillingPopupComponent } from './set-billing-popup.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [SetBillingPopupComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FormsModule,
    NgbModule,
  ],
  exports: [SetBillingPopupComponent],
})
export class SetBillingPopupModule {}
