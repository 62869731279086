import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyRoutingModule } from './currency-routing.module';
import { CurrencyComponent } from './currency.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [CurrencyComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, NgbModule,
    ModalModule.forRoot(),
    CurrencyRoutingModule
  ]
})
export class CurrencyModule { }
