import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Endpoint } from 'src/shared/Endpoint';
import { LocalStorageService } from './../../servcies/localstorage-service';
import { Unicode } from './../../shared/Endpoint';
import { Utilities } from './../../shared/utilities';
import { UserModel } from 'src/models/mongo/user.model';
declare var google: any;

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  auth2: any;
  returnUrl = '';
  show = false;
  errorLoginMsg: string = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params['returnUrl'];
    });
  }

  ngAfterViewInit(): void {
    try {
      let handleCredentialResponse = async (response: any) => {
        const data = { idToken: response.credential, oauth: 'v3' };
        var responsePayload: any = atob(response.credential.split('.')[1]);
        responsePayload = JSON.parse(responsePayload);
        this.zone.run(async () => {
          await this.afterLoginProcess(
            responsePayload.email,
            responsePayload.picture
          );
        });
      };

      let id = 'google-client-script';
      let script = document.getElementById(id);
      if (script === null) {
        let crscript = document.createElement('script');
        crscript.setAttribute('src', 'https://accounts.google.com/gsi/client');
        crscript.setAttribute('id', id);
        crscript.setAttribute('async', '');
        document.body.appendChild(crscript);
        crscript.onload = () => {
          google.accounts.id.initialize({
            client_id: Endpoint.CLIENT_ID,
            callback: handleCredentialResponse,
          });
          renderButton();
        };
      } else {
        google.accounts.id.initialize({
          client_id: Endpoint.CLIENT_ID,
          callback: handleCredentialResponse,
        });
        renderButton();
      }
    } catch (e) {
      this.showAlertMessage('User Cannot Login');
    }
    function renderButton() {
      google.accounts.id.renderButton(document.getElementById('customBtn'), {
        theme: 'filled_blue',
        size: 'large',
        type: 'button',
        text: 'Sign in with Google',
        locale: 'english',
      });
      google.accounts.id.prompt();
    }
  }

  async afterLoginProcess(email: string, imageUrl: string) {
    let that = this;
    var prs = new HttpParams()
      .set(Unicode.EMAIL, email)
      .set(Unicode.IMAGE_URL, imageUrl); //TO BE UPDATED
    await that.http
      .get<any>(Endpoint.API_URL + '' + Endpoint.LOGIN, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp && !resp.success) {
          this.showAlertMessage(resp.message);
          this.localStorage.logout();
        } else if (resp && resp.success) {
          var user: UserModel = Object.assign(new UserModel(), resp.user);
          user.img = imageUrl;
          UserModel.setUserInfo(user);
          this.localStorage.registerUser(JSON.stringify(user));
          this.localStorage.remove(Unicode.GOOGLE_PRODUCTS); //TO RE_RETREIVE IT
          this.localStorage.save(
            Unicode.GLOBAL_DATA_LIST,
            JSON.stringify(resp.global ?? [])
          );
          this.localStorage.save(
            Unicode.USERS_INFO,
            JSON.stringify(resp.users_info ?? {})
          );
          this.localStorage.save(
            Unicode.MENUS,
            JSON.stringify(resp.users_info?.menus ?? [])
          );
          var menus = resp.users_info?.menus ?? [];
          this.zone.run(() => {
            if (!Utilities.isNullOrEmpty(this.returnUrl)) {
              var url = decodeURIComponent(this.returnUrl);
              this.router.navigateByUrl(url);
            } else if (menus.length > 0) this.router.navigate([menus[0].link]);
          });
        }
      })
      .catch((err) => {
        this.showAlertMessage(err.statusText);
      });
  }

  showAlertMessage(msg: any) {
    this.show = true;
    setTimeout(() => (this.show = false), 3000);
    this.errorLoginMsg = msg;
  }
}
