<button
  type="button"
  class="btn btn-color text-white rounded-pill d-block ms-auto product-sans-light"
  data-bs-toggle="modal"
  data-bs-target="#addExchangeModal"
>
  <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
  <span>Add New Exchange Rate</span>
</button>
<div class="table-responsive fluid-section bg-white exchange-rate w-100">
  <table class="table table-hover align-middle text-center">
    <thead class="position-sticky top-0 bg-white">
      <tr class="product-sans-medium">
        <th scope="col" class="text-md-start pe-0 ps-md-5 col-1 col-md-2 border-dark border-top-0">
          Currency from
        </th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">Currency to</th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">Rate</th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">Inverse Rate</th>
        <th scope="col" class="col-1 col-md-2 position-relative border-dark border-top-0">
          Edit
          <div class="w-100 h-100 bg-white position-absolute top-0"></div>
        </th>
      </tr>
    </thead>
    <tbody class="product-sans-light">

      <tr
      *ngFor="
        let row of exchanges
      "
      >
        <td class="text-md-start ps-md-5 col-1 col-md-2 py-3"> {{ row.currency_from_id }} </td>
        <td class="col-1 col-md-2"> {{ row.currency_to_id }} </td>
        <td class="col-1 col-md-2"> {{ row.rate }} </td>
        <td class="col-1 col-md-2"> {{ row.reverse_rate }} </td>
        <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">

          <a (click)="edit(row._id)">
            <img
              class="px-1 px-md-2 cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#editExchangeModal"            
              src="./assets/img/edit-icon.svg"
              alt="edit icon"
            />
          </a>
          <img
            class="ps-1 ps-md-2 cursor-pointer"
            src="./assets/img/delete-icon.svg"
            alt="delete icon"
            data-bs-toggle="modal"
            data-bs-target="#deleteExchange"
            (click)="getRowIdToDelete(row._id)"
          />


        </td>
      </tr>

    </tbody>
  </table>
</div>
<!-- ADD NEW EXCHANGE RATE MODAL -->
<div
  class="modal fade"
  role="alert"
  id="addExchangeModal"
  tabindex="-1"
  aria-labelledby="addExchangeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addExchangeModalLabel"
        >
          Add New Exchange Rate
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
      role="form"
      [formGroup]="exchForm"
      role="form"
      #formDir="ngForm"
      id="add-exchange-form"
      novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="currency-from"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Currency from</label
              >
              <ng-select
              bindLabel="currency.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="currencyFromRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && currencyFromRfid.invalid
              }"
              placeholder="Currency from"
              aria-label="Select Currency from"
              >

                <ng-option [value]="currency._id" *ngFor="let currency of currencies">
                  {{ currency.name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="currency-to"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Currency to</label
              >
              <ng-select
              bindLabel="currency.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="currencyToRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && currencyToRfid.invalid
              }"
              placeholder="Currency to"
              aria-label="Select Currency to"
              >

                <ng-option [value]="currency._id" *ngFor="let currency of currencies">
                  {{ currency.name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="rate"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Rate</label
              >
              <input
              formControlName="rate"
              [ngClass]="{
                'is-invalid': formDir.submitted && rate.invalid
              }"

                type="text"
                class="form-control rounded-pill"
                id="rate"
                placeholder="Rate"
                required
              />
          </div>
          <div class="d-flex align-items-center justify-content-between">
              <label
                for="inverse-rate"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Inverse Rate</label
              >
              <input
              formControlName="inverseRate"
              [ngClass]="{
                'is-invalid': formDir.submitted && inverseRate.invalid
              }"

                type="text"
                class="form-control rounded-pill"
                id="inverse-rate"
                placeholder="Inverse Rate"
                required
              />
          </div>
        </div>
        <div class="modal-footer">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          value="Save"
          type="submit"
          data-bs-dismiss="modal"
          (click)="addNew()"
      />
        </div>
      </form>
    </div>
  </div>
</div>
<!-- DELETE CONFIRMATION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="deleteExchange"
  tabindex="-1"
  aria-labelledby="deleteExchangeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="deleteExchangeLabel"
        >
          Delete Confirmation
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h3 text-center">Are you sure you want to delete?</h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-toggle="modal"
          data-bs-target="#rejectionReasonModal"
          (click)="deleteConfirmation()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- EDIT EXCHANGE RATE MODAL -->
<div
  class="modal fade"
  role="alert"
  id="editExchangeModal"
  tabindex="-1"
  aria-labelledby="editExchangeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="editExchangeModalLabel"
        >
          edit Exchange Rate
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
      role="form"
      [formGroup]="exchForm"
      role="form"
      #formDir="ngForm"
      id="edit-exchange-form"
      novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="currency-from"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Currency from</label
              >
              <ng-select
              bindLabel="currency.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="currencyFromRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && currencyFromRfid.invalid
              }"
              placeholder="Currency from"
              aria-label="Select Currency from"
              >

                <ng-option [value]="currency._id" *ngFor="let currency of currencies">
                  {{ currency.name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="currency-to"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Currency to</label
              >
              <ng-select
              bindLabel="currency.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="currencyToRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && currencyToRfid.invalid
              }"
              placeholder="Currency to"
              aria-label="Select Currency to"
              >

                <ng-option [value]="currency._id" *ngFor="let currency of currencies">
                  {{ currency.name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="rate"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Rate</label
              >
              <input
              formControlName="rate"
              [ngClass]="{
                'is-invalid': formDir.submitted && rate.invalid
              }"

                type="text"
                class="form-control rounded-pill"
                id="rate"
                placeholder="Rate"
                required
              />
          </div>
          <div class="d-flex align-items-center justify-content-between">
              <label
                for="inverse-rate"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Inverse Rate</label
              >
              <input
              formControlName="inverseRate"
              [ngClass]="{
                'is-invalid': formDir.submitted && inverseRate.invalid
              }"

                type="text"
                class="form-control rounded-pill"
                id="inverse-rate"
                placeholder="Inverse Rate"
                required
              />
          </div>
        </div>
        <div class="modal-footer">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          value="Save"
          type="submit"
          data-bs-dismiss="modal"
          (click)="updateExchangeRate()"
      />
        </div>
      </form>
    </div>
  </div>
</div>
