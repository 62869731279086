import { Component, HostBinding } from '@angular/core';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from 'src/shared/utilities';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrls: ['./proposal-list.component.scss'],
})
export class ProposalListComponent {
  @HostBinding('class') class = ' ';
  opportunities: OpportunityModel[] = [];
  plans = [];
  currentUser: UserModel;
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  customerSearch: string;
  fromSelectedDate;
  toSelectedDate;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static opportunities = [];
  static proposals = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  types = ['ALL', 'GWS', 'GCP'];
  customerRfId;
  initial_userIds = [];
  add: boolean = true;
  assignsales = [];
  selectedUserId;
  selectedSales: string;
  proposalForm: UntypedFormGroup;
  domainRfId;

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    //
    ProposalListComponent._httpRequestService = httpRequestService;
    ProposalListComponent._http = http;
    ProposalListComponent.gcp = false;
    var current = new Date();
    var beforeOneMonth = current.setMonth(current.getMonth() - 1);
    this.fromSelectedDate = new Date(beforeOneMonth).toISOString().slice(0, 10);
    this.toSelectedDate = new Date().toISOString().slice(0, 10);
  }

  async ngOnInit(): Promise<void> {
    this.assignsales = await Utilities.usersByGroup(
      Group.ENGINEER,
      this.http,
      this.localStorage
    );
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    ProposalListComponent.pagination = 1;
    this.getproposals(null);
  }

  async getproposals(pagination) {
    if (pagination == null) pagination = ProposalListComponent.pagination;
    var prs = new HttpParams()
      .set(Unicode.PAGE, (pagination - 1) * ProposalListComponent.pageSize + '')
      .set(Unicode.LIMIT, ProposalListComponent.pageSize + '')
      .set(Unicode.PROPOSAL_TYPE, this.selectedFilterType)
      .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.fromSelectedDate))
      prs = prs.set(Unicode.FROM_DATE, this.fromSelectedDate);
    if (!Utilities.isNullOrEmpty(this.toSelectedDate))
      prs = prs.set(Unicode.TO_DATE, this.toSelectedDate);
    if (!Utilities.isNullOrEmpty(this.selectedSales))
      prs = prs.set(Unicode.SALES_MANAGER, this.selectedSales);
    await ProposalListComponent._http
      .get<any>(Endpoint.API_URL + '' + Endpoint.GET_ALL_PROPOSALS, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          ProposalListComponent.total = <number>resp['total'];
          ProposalListComponent.proposals = resp.proposals ?? [];
        }
      });
  }

  renderPage(event: number) {
    ProposalListComponent.pagination = event;
    this.getproposals(null);
  }

  search() {
    ProposalListComponent.pagination = 1;
    this.getproposals(null);
  }
  selectDate() {
    ProposalListComponent.pagination = 1;
    this.getproposals(null);
  }
  selectSale() {
    ProposalListComponent.pagination = 1;
    this.getproposals(null);
  }
  selectStatus() {
    ProposalListComponent.pagination = 1;
    this.getproposals(null);
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    ProposalListComponent.pagination = 1;
    ProposalListComponent.gcp = !ProposalListComponent.gcp;
    this.getproposals(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      ProposalListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getproposals(null);
    }
  }

  get GetData() {
    return ProposalListComponent.proposals;
  }
  get GetTotalCount() {
    return ProposalListComponent.total;
  }
  get GetPagination() {
    return ProposalListComponent.pagination;
  }
  get GetPageSize() {
    return ProposalListComponent.pageSize;
  }
  async assignSales(selectedUser: any) {
    if (
      !this.add &&
      !Utilities.isNullOrEmpty(this.customerRfId) &&
      !Utilities.isNullOrEmpty(this.proposalForm.value.user_ids)
    ) {
      var add_ids = this.proposalForm.value.user_ids.filter(
        (x) => this.initial_userIds.indexOf(x) < 0
      );
      var delete_ids = this.initial_userIds.filter(
        (x) => this.proposalForm.value.user_ids.indexOf(x) < 0
      );
      await this.http
        .put(Endpoint.API_URL + '' + Endpoint.ASSIGN_USER_CUSTOMER, {
          user_ids_attach: add_ids ?? [],
          user_ids_detach: delete_ids ?? [],
          domain_rfid: this.domainRfId,
        })
        .toPromise()
        .then(async (resp: any) => {
          this.initial_userIds = this.proposalForm.value.user_ids;
          alert(resp?.msg);
          // Fetch opportunities based on the selected user
          const opportunities = await this.http
            .get(
              Endpoint.API_URL +
                Endpoint.ASSIGN_USER_CUSTOMER +
                '/' +
                this.selectedUserId
            )
            .toPromise();
        });
    }
  }
  refreshPage() {
    this.getproposals(null);
  }
  goBack(): void {
    this.location.back();
  }
  viewProposal(proposal) {
    this.router.navigate(['proposal/proposal-details'], {
      state: { proposal: proposal },
    });
  }
  deleteProposal(id: string) {
    if (confirm('Are you sure to delete this proposal doc?')) {
      this.http
        .put(Endpoint.API_URL + '' + Endpoint.DELETE_PROPOSAL + id, {})
        .subscribe((response: any) => {
          this.getproposals(null);
          alert(response.msg);
        });
    }
  }
}
