import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EngineerRecommendationsDetailsRoutingModule } from './engineer-recommendations-details-routing.module';
import { EngineerRecommendationsDetailsComponent } from './engineer-recommendations-details.component';
import { EngineeringRecommendationsGoogleDocCardModule } from 'src/shared-components/engineering_recommendations-google-doc-card/engineering_recommendations-google-doc-card.module';

@NgModule({
  declarations: [EngineerRecommendationsDetailsComponent],
  imports: [
    CommonModule,
    EngineerRecommendationsDetailsRoutingModule,
    EngineeringRecommendationsGoogleDocCardModule,
  ],
})
export class EngineerRecommendationsDetailsModule {}
