<!-- *** BILLING ACCOUNT CREATED SUCCESSFULLY MODAL *** -->
<div
  class="modal fade"
  bsModal
  #accountCreated="bs-modal"
  tabindex="-1"
  id="accountCreated"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Billing Account has been successfully created
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="accountCreated.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <div class="col-12 text-center">
          <img
            src="./assets/account-created-icon.svg"
            alt="account created icon"
          />
        </div>
        <div ngbAccordion class="mt-4">
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-bottom">
                Billing Information
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template class="p-3 px-4">
                  <div
                    class="d-flex justify-content-between border-bottom align-items-center pb-2 smaller-text"
                  >
                    <div class="d-flex flex-column">
                      <span class="product-sans-medium">Customer</span>
                      <span>{{ createdBillingData?.domain || "N/A" }}</span>
                    </div>
                    <!-- <button
                      class="btn btn-primary small-text btn-color rounded-pill p-2 smaller-text align-self-end product-sans-light"
                    >
                      View Customer
                    </button> -->
                  </div>
                  <div
                    class="col-12 d-flex justify-content-between mt-2 border-bottom pb-2 smaller-text"
                  >
                    <div class="d-flex gap-3 align-items-center">
                      <img
                        src="./assets/google-cloud-icon.svg"
                        alt="google cloud icon"
                        width="50px"
                      />
                      <span>{{
                        createdBillingData?.selectedSku || "N/A"
                      }}</span>
                    </div>
                    <div class="d-flex gap-2 flex-column">
                      <span>Cost and Commitment (USD)</span>
                      <span class="product-sans-medium">{{
                        createdBillingData?.selectedPaymentPlan || "N/A"
                      }}</span>
                      <span class="product-sans-medium"
                        >No Commitment, per usage</span
                      >
                    </div>
                  </div>
                  <!-- <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium"
                        >Main Billing Account</span
                      >
                      <span>{{
                        createdBillingData?.mainBillingAccount?.name || "N/A"
                      }}</span>
                      <span
                        >ID:
                        {{
                          createdBillingData?.mainBillingAccount?.id || "N/A"
                        }}</span
                      >
                    </div>
                  </div> -->
                  <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium">Payment Plan</span>
                      <span>{{
                        createdBillingData?.selectedPaymentPlan || "N/A"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium">Account Name</span>
                      <span>{{
                        createdBillingData?.billingAccountName || "N/A"
                      }}</span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end">
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Close"
            type="button"
            (click)="accountCreated.hide()"
          />
        </div>
      </div>
    </div>
  </div>
</div>