import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpporunityProposalPhaseComponent } from './opporunity-proposal-phase.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomerInfoPopupModule } from 'src/shared-components/customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from 'src/shared-components/rejection-popup/rejection-popup.module';
import { FormsModule } from '@angular/forms';
import { ProposalGoogleDocCardModule } from 'src/shared-components/proposal-google-doc-card/proposal-google-doc-card.module';
import {OpportunitySolutionBuildingPhaseModule} from '../opportunity-solution-building-phase/opportunity-solution-building-phase.module';

@NgModule({
  declarations: [OpporunityProposalPhaseComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    FormsModule,
    CustomerInfoPopupModule,
    RejectionPopupModule,
    ProposalGoogleDocCardModule,
    OpportunitySolutionBuildingPhaseModule
  ],
  exports: [OpporunityProposalPhaseComponent],
})
export class OpporunityProposalPhaseModule {}
