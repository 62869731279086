import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInfoPopupComponent } from './customer-info-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [CustomerInfoPopupComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
  ],
  exports: [CustomerInfoPopupComponent],
})
export class CustomerInfoPopupModule {}
