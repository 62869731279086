import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewProjectModalRoutingModule } from './new-project-modal-routing.module';
import { NewProjectModalComponent } from './new-project-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConverterPipe } from 'src/servcies/pip/converter-pip';
import { LinkProjectPopupModule } from '../link-project-popup/link-project-popup.module';

@NgModule({
  declarations: [NewProjectModalComponent, ConverterPipe],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FormsModule,
    NgbModule,
    LinkProjectPopupModule,
  ],
  exports: [NewProjectModalComponent],
  providers: [],
})
export class NewProjectModalModule {}
