import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { GroupsRoutingModule } from './groups-routing.module';
import { GroupsComponent } from './groups.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [GroupsComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, NgbModule,
    GroupsRoutingModule,
    NgSelectModule,
  ]
})
export class GroupsModule { }
