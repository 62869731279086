import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerServicesRoutingModule } from './customer-services-routing.module';
import { CustomerServicesComponent } from './customer-services.component';


@NgModule({
  declarations: [CustomerServicesComponent],
  imports: [
    CommonModule,
    CustomerServicesRoutingModule
  ]
})
export class CustomerServicesModule { }
