import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LinkProjectPopupRoutingModule } from './link-project-popup-routing.module';
import { LinkProjectPopupComponent } from './link-project-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddBillingAccountModule } from '../add-billing-account/add-billing-account.module';
import { SetBillingPopupModule } from '../set-billing-popup/set-billing-popup.module';
import { AddBudgetFormModule } from 'projects/gcp/src/lib/add-budget-form/add-budget-form.module';

@NgModule({
  declarations: [LinkProjectPopupComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FormsModule,
    NgbModule,
    AddBillingAccountModule,
    SetBillingPopupModule,
    AddBudgetFormModule,
  ],
  exports: [LinkProjectPopupComponent],
})
export class LinkProjectPopupModule {}
