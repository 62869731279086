import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { SettingsService } from 'src/servcies/backend-services/settings.service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';

@Component({
  selector: 'settings-groups',
  templateUrl: './groups.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  groupForm: UntypedFormGroup;
  permissions: any = [];
  groups: any[] = [];
  groupId: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService
  ) {
    this.groupForm = fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      main: [false, Validators.required],
      permission: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    var resp: any = await this.http
      .get(Endpoint.API_URL + Endpoint.GET_PERMISSIONS)
      .toPromise();
    if (resp) {
      this.permissions = resp.permissions;
    }

    this.getAllGroups();
  }

  async getAllGroups(): Promise<void> {
    await this.settingsService.getAllGroups().subscribe((response: any) => {
      this.groups = response.groups;
      console.log('Groups:', this.groups);
    });
  }

  openMemberPage() {
    this.router.navigate(['settings/groups/group-members']);
  }

  addNew() {
    var groupData = this.groupForm.value;
    this.settingsService.createGroup(groupData).subscribe((response: any) => {
      console.log('Group created successfully:', response);
      groupData._id = response?.id;
      this.groups.push(groupData);
      //this.permissions.push(response.permission); // Add the newly created group to the array //todo ask FALHA
    });
  }

  deleteConfirmation() {
    this.settingsService.deleteGroup(this.groupId).subscribe(
      (response: any) => {
        console.log('Group successfully deleted');
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Group not found');
      }
    );
  }

  updateGroup(): void {
    const updatedGroup = this.groupForm.value;
    this.settingsService.updateGroup(this.groupId, updatedGroup).subscribe(
      (response) => {
        console.log('Group updated successfully:', response);
        var grp = this.groups.find((x) => x._id == this.groupId);
        grp.email = updatedGroup.email;
        grp.name = updatedGroup.name;
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error updating group:', error);
      }
    );
  }

  getRowIdToDelete(rowId) {
    this.groupId = rowId;
  }

  edit(rowId) {
    this.groupId = rowId;
    this.settingsService.getGroupById(this.groupId).subscribe(
      (response: any) => {
        // patch the group
        this.groupForm.patchValue(response.data);
      },
      (error) => {
        if (error.status === 401) {
          this.localStorage.logout();
          alert('Unauthorized Access');
          this.router.navigate(['./login']);
        }
        console.log('Error fetching group:', error);
      }
    );
  }

  get name() {
    return this.groupForm.get('name');
  }
  get email() {
    return this.groupForm.get('email');
  }
  get main() {
    return this.groupForm.get('main');
  }
  get permission() {
    return this.groupForm.get('permission');
  }
}
