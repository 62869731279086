import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OrdersComponent } from './orders.component';
import { OrdersRoutingModule } from './orders.routing.module';
import {  NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [OrdersComponent],
  imports: [
    CommonModule,NgSelectModule,
    FormsModule,ReactiveFormsModule,
    NgxDatatableModule,
    NgbModule,
    ModalModule.forRoot(),
    OrdersRoutingModule
  ],
  exports: [OrdersComponent]
})
export class OrdersModule { }
