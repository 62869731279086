import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectListModule } from './project-list/project-list.module';
import { BillingAccountsModule } from './billing-accounts/billing-accounts.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'project-list',
    pathMatch: 'full',
  },
  {
    path: 'project-list',
    loadChildren: () => ProjectListModule,
  },
  {
    path: 'billing-account',
    loadChildren: () => BillingAccountsModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GCPRoutingModule {}
