import { BaseModel } from '../mongo/base.model';

export class GCPProjectModel extends BaseModel {
  _id: string;
  project_id: string;
  name: string;
  number: string;
  parent: string;
  deleted: boolean;
  created_at: Date;
  created_by_rfid: string;
  poc_rfid: string;
  order_rfid: string;
  opportunity_rfid: string;
  createTime: Date;
  start_date: Date;
  //CUSTOM
  created_date_string: string;
  created_user_email: string;
  created_user_name: string;
  manager_user_id: string;
  manager_user_email: string;
  manager_user_name: string;
  consumed_time: string;
  billingAccountName: string;
  billingAccountId: string;
  billing_account_id: string;
  organizationName: string;
  folderName: string;
  mainBillingAccount;

  constructor() {
    super();
  }
}
