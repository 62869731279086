<div
  class="modal fade"
  bsModal
  #createProjectModal="bs-modal"
  tabindex="-1"
  id="createProjectModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-between border-0">
        <h1 class="modal-title fs-5 product-sans text-start col-11">
          New Project
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="createProjectModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div
          class="mb-2 d-flex align-items-center justify-content-between form-floating"
        >
          <input
            type="text"
            class="rounded-pill form-control"
            id="nv_project_name"
            [(ngModel)]="newProjectModel.name"
            placeholder="Project Name"
            required
          />
          <label
            for="nv_project_name"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Project Name</label
          >
        </div>
        <div
          class="mb-1 d-flex align-items-center justify-content-between form-floating position-relative"
        >
          <input
            type="text"
            class="rounded-pill form-control"
            id="project_id"
            [(ngModel)]="newProjectModel.project_id"
            placeholder="Project ID"
            required
          />
          <label
            for="project_id"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Project ID</label
          >
        </div>
        <p class="text-secondary mb-2 small">It cannot be changed later.</p>
        <div class="form-floating row">
          <div class="col-6">
            <input
              type="text"
              class="rounded-pill form-control"
              id="organization_name"
              [(ngModel)]="newProjectModel.organizationName"
              placeholder="Organization"
            />
          </div>
          <div class="col-6">
            <input
              type="text"
              class="rounded-pill form-control"
              id="folder_name"
              [(ngModel)]="newProjectModel.folderName"
              placeholder="Folder"
            />
          </div>
        </div>
        <p class="text-secondary mb-2 small mt-1 mb-1">
          Parent organization or folder.
        </p>
        <div
          class="gap-2 align-items-center justify-content-between"
          id="org_structure"
        >
          <ng-template #filterTemplate let-items>
            <ul>
              <li *ngFor="let item of items">
                <span
                  class="cursor-pointer caret"
                  [ngClass]="
                    item.expand ? 'fa fa-caret-down' : 'fa fa-caret-right'
                  "
                  (click)="expand(item)"
                ></span>
                <input
                  type="checkbox"
                  [(ngModel)]="item.checked"
                  (change)="clickElt($event, item)"
                />
                <label class="folder_name">{{ item.displayName }}</label>
                <ng-container
                  *ngTemplateOutlet="
                    filterTemplate;
                    context: { $implicit: item.childs }
                  "
                ></ng-container>
              </li>
            </ul>
          </ng-template>
          <ng-container
            *ngTemplateOutlet="filterTemplate; context: { $implicit: folders }"
          ></ng-container>
        </div>

        <div class="d-flex gap-2 justify-content-end">
          <input
            class="btn btn-color-outline small-text rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Cancel"
            type="button"
            (click)="createProjectModal.hide()"
          />
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Create"
            type="button"
            data-bs-dismiss="modal"
            (click)="projectCreatedStep()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="projectCreatedModal"
  bsModal
  #projectCreatedModal="bs-modal"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-between border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Project Created Successfully!!!
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="projectCreatedModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-12 text-center">
          <img
            src="./assets/project-created-icon.svg"
            alt="project created icon"
          />
        </div>
        <p class="mt-2">
          <span class="product-sans-medium">{{ newProjectModel.name }}</span>
          has been created.
        </p>
        <div class="d-flex gap-2 justify-content-end">
          <input
            class="btn btn-color-outline small-text rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Close"
            type="button"
            (click)="projectCreatedModal.hide()"
          />
          <!-- <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Link with a Billing Account"
            type="button"
            (click)="setBillingAccountStep()"
          /> -->
        </div>
      </div>
    </div>
  </div>
</div>

<link-project-popup
  [projectRfId]="newProjectModel._id"
  [projectName]="newProjectModel.name"
  (linkProj)="linkingDone($event)"
  #linkProjectModal
></link-project-popup>
