import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingAccountDetailsRoutingModule } from './billing-account-details-routing.module';
import { BillingAccountDetailsComponent } from './billing-account-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    BillingAccountDetailsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    BillingAccountDetailsRoutingModule
  ]
})
export class BillingAccountDetailsModule { }
