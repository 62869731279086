<div id="orders-flex">
  <div class="d-flex justify-content-between align-items-center py-1">
    <p class="text-primary mb-0">
      The clients that have renewals during this month
    </p>
    <button
      (click)="exportFlexList()"
      class="btn btn-primary small-text btn-color rounded-pill col-2 col-xl-1 p-1 p-xl-2 fs-6 align-self-end"
      type="button"
    >
      Export
    </button>
  </div>

  <div class="collapsible fluid-section bg-white mt-2 mt-md-0 small-text w-100">
    <div
      class="row col-12 border-bottom border-dark product-sans-medium text-center align-items-center"
    >
      <div
        class="p-1 p-md-2 text-md-start ms-md-1 ps-md-5 col-4 col-lg-4 col-md-3"
      >
        Domain
      </div>
      <div class="col-3 p-1 p-md-2">Sales Person</div>
      <div class="col p-1 p-md-2">Note</div>
      <div class="col col-1 p-1 p-md-2 position-relative">
        Edit
        <div class="w-100 h-100 bg-white position-absolute top-0"></div>
      </div>
    </div>

    <div
      class="row col-12 border-bottom text-center align-items-center py-3"
      *ngFor="let data of GetFlex; let i = index"
    >
      <div
        class="col p-1 p-md-2 text-md-start ms-md-1 ps-md-5 col-4 col-lg-4 col-md-3"
      >
        {{ data.customerDomain }}
      </div>
      <div class="col-3 p-1 p-md-2">
        <ng-select
          *ngIf="data.sales && data.sales.length > 0"
          appendTo="body"
          id="assigned-salesperson"
          [searchable]="true"
          [clearable]="false"
          [(ngModel)]="data.sales[0].first_name"
          class="col-12 rounded-pill smaller-text pe-4"
          aria-label="Assigned Salesperson"
        >
          <ng-option [value]="sale._id" *ngFor="let sale of data.sales">
            {{ sale.first_name }} {{ sale.last_name }}
          </ng-option>
        </ng-select>
      </div>
      <!-- <div class="col p-1 p-md-2">{{ data.flexNotes }}</div> -->
      <div class="col p-1 p-md-2">
        <input
          placeholder="Flex Notes"
          class="flex_input rounded-pill"
          [(ngModel)]="data.flexNotes"
          (blur)="valuechange(i, data.customerDomain, $event)"
        />
      </div>
      <div class="col col-1 p-1 p-md-2 text-end">
        <div
          role="button"
          (click)="expandInter(i)"
          aria-label="expand-icon"
          class="ms-auto me-5 cursor-pointer expand-icon"
        ></div>
      </div>
      <div class="collapse my-3" id="sku-collapse-{{ i }}">
        <div class="table-responsive card card-body rounded-4">
          <table class="table table-borderless mb-0">
            <thead
              class="product-sans-medium position-sticky top-0 bg-white z-9"
            >
              <tr class="border-bottom">
                <th scope="col" class="text-start border-top-0">SKU</th>
                <th scope="col" class="border-top-0">Plan</th>
                <th scope="col" class="border-top-0"># of Licenses</th>
                <th scope="col" class="border-top-0">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subdata of data.child; let j = index">
                <td
                  class="text-start col-4 pt-4"
                  [ngClass]="{
                    color_red: !subdata.status.toLowerCase().includes('active'),
                    color_green: subdata.status.toLowerCase().includes('active')
                  }"
                >
                  {{ subdata.skuName }}
                </td>
                <td class="pt-4">{{ subdata.plan?.planName }}</td>
                <td class="pt-4">
                  {{ subdata.seats?.licensedNumberOfSeats
                  }}/{{ subdata.licenses }}
                </td>
                <td
                  class="pt-4"
                  *ngIf="
                    subdata.plan &&
                      subdata.plan.commitmentInterval &&
                      subdata.plan.commitmentInterval.endTime;
                    else flex_date_input
                  "
                >
                  {{
                    subdata.plan.commitmentInterval.endTime
                      | date : "dd-MMM-yyyy"
                  }}
                </td>
                <ng-template #flex_date_input>
                  <td class="pt-4">
                    <input
                      class="flex_input rounded-pill"
                      type="date"
                      placeholder="Expiry Date"
                      [(ngModel)]="subdata.expiryDate"
                      (ngModelChange)="
                        selectExpiryDate(
                          $event,
                          subdata.plan.planName,
                          subdata.skuId,
                          subdata.customerDomain
                        )
                      "
                    />
                  </td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button
      *ngIf="GetFlex && GetFlex.length > 0 && GetCurrentUser.canViewAll"
      style="vertical-align: bottom; position: fixed; right: 20px; bottom: 10px"
      class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 fs-6 align-self-end"
      (click)="saveFlexChanges()"
    >
      Save Changes
    </button>
  </div>
</div>
