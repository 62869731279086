import { AddOrderModule } from './add-order/add-order.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderListModule } from './order-list/order-list.module';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderViewModule } from './order-view/order-view.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'order-list',
    pathMatch: 'full',
  },
  { path: 'order-list', loadChildren: () => OrderListModule },
  { path: 'add-order', loadChildren: () => AddOrderModule },
  { path: 'order-view', loadChildren: () => OrderViewModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdersRoutingModule {}
