import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunityListRoutingModule } from './opportunity-list-routing.module';
import { OpportunityListComponent } from './opportunity-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
  declarations: [
    OpportunityListComponent
  ],
    imports: [
        CommonModule,
        OpportunityListRoutingModule,
        NgxPaginationModule,
        FormsModule,
        NgSelectModule,
        NgbDropdownModule
    ]
})
export class OpportunityListModule { }
