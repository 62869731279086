import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunityPocPhaseComponent } from './opportunity-poc-phase.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DafRequestDecisionPopupModule } from 'src/shared-components/daf-request-decision-popup/daf-request-decision-popup.module';
import { CustomerInfoPopupModule } from 'src/shared-components/customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from 'src/shared-components/rejection-popup/rejection-popup.module';
import { NewProjectModalModule } from 'src/shared-components/new-project-modal/new-project-modal.module';
import { LinkProjectPopupModule } from 'src/shared-components/link-project-popup/link-project-popup.module';
import { AddBudgetFormModule } from 'projects/gcp/src/lib/add-budget-form/add-budget-form.module';
import { FormsModule } from '@angular/forms';

import { POCGoogleDocCardModule } from 'src/shared-components/poc-google-doc-card/poc-google-doc-card.module';
import { PocVrmApprovalPopupModule } from 'src/shared-components/poc-vrm-approval-popup/poc-vrm-approval-popup.module';
import {OpportunitySolutionBuildingPhaseModule} from '../opportunity-solution-building-phase/opportunity-solution-building-phase.module';

@NgModule({
  declarations: [OpportunityPocPhaseComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    FormsModule,
    PocVrmApprovalPopupModule,
    DafRequestDecisionPopupModule,
    CustomerInfoPopupModule,
    RejectionPopupModule,
    NewProjectModalModule,
    LinkProjectPopupModule,
    POCGoogleDocCardModule,
    AddBudgetFormModule,
    OpportunitySolutionBuildingPhaseModule
  ],
  exports: [OpportunityPocPhaseComponent],
})
export class OpportunityPocPhaseModule {}
