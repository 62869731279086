<div
  class="modal fade"
  bsModal
  #statusModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="statusModalLabel"
        >
          {{ statusInfo.title }} this Subscription
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="statusModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h5 text-center">
          Are you sure you want to {{ statusInfo.title }} this Subscription? An
          Approval request will be send to the CEO to approve/reject it.
        </h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="updateStatus()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="statusModal.hide()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
