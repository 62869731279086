import { NgModule } from '@angular/core';
import { SowComponent } from './sow.component';
import { CommonModule } from '@angular/common';
import { SowRoutingModule } from './sow.routing.module';

@NgModule({
  declarations: [SowComponent],
  imports: [CommonModule, SowRoutingModule],
  exports: [SowComponent],
})
export class SowModule {}
