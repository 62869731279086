<div class="bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="goBack()"
          alt="back icon" /></a
      >Orders
    </h1>
  </div>
  <div class="d-flex flex-wrap justify-content-between align-items-start pt-3">
    <div
      class="d-flex flex-wrap align-items-center col-12 col-lg-6 mt-3 mt-sm-0 p-0"
    >
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="fromSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (from)"
          class="form-control rounded-pill py-0"
          id="order-date-from"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="toSelectedDate"
          (change)="selectDate()"
          placeholder="Date (to)"
          class="form-control rounded-pill py-0"
          id="order-date-to"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
      <div class="form-floating my-2 my-md-0 col-6 me-2 col-md-2 mx-md-2 p-0 ">
        <button class="clear-filters-button px-3 py-2 mr-3 mb-3" (click)="clearfilters()">Clear</button>

      </div>

    </div>
    <div
      class="position-relative col-6 col-sm-6 col-md-4 col-lg-2 me-2 mb-2 mb-md-0 p-0 small-text"
    >
      <img
        class="position-absolute pt-2 px-2 end-0"
        src="./assets/img/search-blue-icon.svg"
        alt="search icon"
      />
      <input
        id="txt-order-global-search"
        type="search"
        (keyup.enter)="search($event)"
        class="form-control rounded-pill h-36"
        placeholder="Search by Domain"
      />
    </div>
  </div>

  <ul
    class="nav nav-tabs d-flex flex-row pe-3 pe-lg-0 small-text border-0 h-fit-content mb-2"
    id="order_tabs"
  >
    <li
      [routerLinkActive]="['active-status']"
      routerLink="all-orders/true"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-item col-3 text-center order-status cursor-pointer rounded-start"
    >
      <a class="nav-link border-0 product-sans-medium">All</a>
    </li>
    <li
      [routerLinkActive]="['active-status']"
      routerLink="pending-orders/false"
      [routerLinkActiveOptions]="{ exact: true }"
      class="nav-item col-3 text-center order-status cursor-pointer"
    >
      <a class="nav-link border-0 product-sans-medium">Pending</a>
    </li>
    <li
      routerLink="order-renewal-list"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="['active-status']"
      class="nav-item col-3 text-center order-status cursor-pointer"
    >
      <a
        routerLink="order-renewal-list"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['active-status']"
        class="nav-link border-0 product-sans-medium"
        >Renewals</a
      >
    </li>
    <li
      routerLink="flex-customers"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="['active-status']"
      class="nav-item col-3 text-center order-status cursor-pointer rounded-end"
    >
      <a class="nav-link border-0 product-sans-medium">Flex Customers</a>
    </li>
  </ul>

  <div class="all-table">
    <router-outlet></router-outlet>
  </div>
</div>
