<div
  class="modal fade"
  bsModal
  #infoModal="bs-modal"
  tabindex="-1"
  id="infoModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content popup_shadow">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Customer Info
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="infoModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark mt-4 d-grid">
        <input
          #custName
          placeholder="Customer Name"
          [(ngModel)]="customer_name"
          class="rounded-4 deal_description_area p-2"
        />
<!--        <input-->
<!--          #custEmail-->
<!--          placeholder="Customer Email"-->
<!--          [(ngModel)]="customer_email"-->
<!--          class="rounded-4 deal_description_area mt-2 p-2"-->
<!--        />-->

        <mat-form-field class="rounded-4 deal_description_area mt-2 p-2">
          <mat-label>Customer Emails</mat-label>
          <mat-chip-grid #chipGrid aria-label="Enter Emails">

            <mat-chip-row
              *ngFor="let email of emails,let i =index"
              (removed)="remove(email,i)"
              [editable]="true"
              (edited)="edit(email, $event,i)"
              [aria-description]="'press enter to edit ' + email"
            >
            {{email}}
            <button matChipRemove [attr.aria-label]="'remove ' + email">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>

            <input
              type="email"
              placeholder="New Email..."
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-grid>
        </mat-form-field>
        <textarea
          *ngIf="withScopes == true"
          placeholder="Scopes"
          [(ngModel)]="scopes"
          rows="6"
          class="rounded-4 deal_description_area mt-2 p-2"
        ></textarea>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
          (click)="send(custName.value)"

        >
          Send
        </button>
      </div>
    </div>
  </div>
</div>
