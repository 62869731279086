<div
  class="modal fade"
  bsModal
  #linkProjectModal="bs-modal"
  tabindex="-1"
  id="linkProjectModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-between border-0">
        <h1 class="modal-title fs-5 product-sans text-start col-11">
          Link Project to Billing
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="linkProjectModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="mt-2">
          You can choose whether to link the project to an existing billing or
          link it to a new one
        </div>
        <div class="d-flex gap-2 justify-content-end">
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 col-lg-3 col-md-3 col-4 col-sm-5 align-self-end product-sans-light"
            value="Link to existing billing"
            type="button"
            (click)="linkProjectPopup(true)"
          />
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 col-lg-3 col-md-3 col-4 col-sm-5 align-self-end product-sans-light"
            value="Link it to new billing"
            type="button"
            (click)="linkProjectPopup(false)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- *** CREATE BILLING MODAL *** -->
<div
  class="modal fade"
  bsModal
  #creatBillingModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center">
          Create Billing Account
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="creatBillingModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <add-billing-account
          *ngIf="createBilling == true"
          [createdBillingData]="createdBillingData"
          [fullAction]="true"
          [pocID]="pocID"
          [opportunityID]="oppID"
          (additionalBillingAccountData)="billingAccountCreated($event)"
        ></add-billing-account>
      </div>
    </div>
  </div>
</div>

<!-- *** BILLING ACCOUNT CREATED SUCCESSFULLY MODAL *** -->
<div
  class="modal fade"
  bsModal
  #billingAccCreatedModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Billing Account has been successfully created
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="billingAccCreatedModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <div class="col-12 text-center">
          <img
            src="./assets/account-created-icon.svg"
            alt="account created icon"
          />
        </div>
        <div ngbAccordion class="mt-4">
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-bottom">
                Order Information
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template class="p-3 px-4">
                  <div
                    class="d-flex justify-content-between border-bottom align-items-center pb-2 smaller-text"
                  >
                    <div class="d-flex flex-column">
                      <span class="product-sans-medium">Customer</span>
                      <span>{{ createdBillingData?.domain || "N/A" }}</span>
                    </div>
                    <!-- <button
                      class="btn btn-primary small-text btn-color rounded-pill p-2 smaller-text align-self-end product-sans-light"
                    >
                      View Customer
                    </button> -->
                  </div>
                  <div
                    class="col-12 d-flex justify-content-between mt-2 border-bottom pb-2 smaller-text"
                  >
                    <div class="d-flex gap-3 align-items-center">
                      <img
                        src="./assets/google-cloud-icon.svg"
                        alt="google cloud icon"
                        width="50px"
                      />
                      <span>{{
                        createdBillingData?.selectedSku || "N/A"
                      }}</span>
                    </div>
                    <div class="d-flex gap-2 flex-column">
                      <span>Cost and Commitment (USD)</span>
                      <span class="product-sans-medium">{{
                        createdBillingData?.selectedPaymentPlan || "N/A"
                      }}</span>
                      <span class="product-sans-medium"
                        >No Commitment, per usage</span
                      >
                    </div>
                  </div>
                  <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium"
                        >Main Billing Account</span
                      >
                      <span>{{
                        createdBillingData?.mainBillingAccount?.name || "N/A"
                      }}</span>
                      <span *ngIf="createdBillingData?.mainBillingAccount?.id"
                        >ID:
                        {{
                          createdBillingData?.mainBillingAccount?.id || "N/A"
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium">Payment Plan</span>
                      <span>{{
                        createdBillingData?.selectedPaymentPlan || "N/A"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 mt-2 border-bottom pb-2 smaller-text">
                    <div class="d-flex gap-2 flex-column">
                      <span class="product-sans-medium">Account Name</span>
                      <span>{{
                        createdBillingData?.billingAccountName || "N/A"
                      }}</span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="text-end">
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Link to the Project"
            type="button"
            (click)="linkToProject()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- *** PROJECT LINKED SUCCESSFULLY MODAL *** -->
<div
  class="modal fade"
  bsModal
  #successfulyLinkedModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Project has been successfully linked to Billing
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="closeSuccessLinkingPopup()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <div class="col-12 text-center">
          <img
            src="./assets/account-created-icon.svg"
            alt="account created icon"
          />
        </div>
        <div ngbAccordion class="mt-4">
          <div ngbAccordionItem>
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton class="border-bottom p-2">
                Link Information
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody class="p-0">
                <ng-template class="p-3 px-4">
                  <div
                    class="d-flex flex-column border-bottom p-15"
                    *ngIf="createdBillingData?.domain"
                  >
                    <span class="product-sans-medium">Customer</span>
                    <span>{{ createdBillingData?.domain || "N/A" }}</span>
                  </div>
                  <div class="d-flex flex-column border-bottom p-15">
                    <span class="product-sans-medium">Project</span>
                    <span>{{ createdBillingData?.projectName }}</span>
                  </div>
                  <div class="d-flex flex-column p-15">
                    <span class="product-sans-medium">Billing Account</span>
                    <span>{{ createdBillingData?.billingAccountName }}</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-end" *ngIf="pocID || oppID">
        <input
          (click)="createBudgetPopup()"
          class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
          value="Create Budget"
          type="button"
        />
      </div>
    </div>
  </div>
</div>

<set-billing-popup
  *ngIf="projectName"
  [projectName]="projectName"
  (linkAction)="linkToExistingBilling($event)"
  #setBillingModal
></set-billing-popup>

<!-- CREATION BUDGET POPUP -->
<div
  class="modal fade"
  bsModal
  #budgetCreModal="bs-modal"
  tabindex="-1"
  id="budgetCreModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">
          Create Budget
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="budgetCreModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <add-budget-form
          *ngIf="billingRfId != null"
          [billingRfId]="billingRfId"
          (createBudgetEmitter)="createBudget($event)"
        ></add-budget-form>
      </div>
    </div>
  </div>
</div>
