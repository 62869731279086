import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, Group } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class CustomerRequirementsService {
  constructor(private http: HttpClient) {}

  async sendCustomerRequirementsToCust(
    customer_requirements,
    custInfo,
    currentUser,
    opporunityID
  ): Promise<any> {
    var that = this;
    //
    var phases = customer_requirements.approval_phases;
    var custPhase = phases.find(
      (x) => x.role?.toLowerCase() == Group.CUSTOMER.toLowerCase()
    );
    if (custPhase) {
      custPhase.name = currentUser.user_name;
      custPhase.email = currentUser.email;
      custPhase.date = new Date();
      custPhase.id = currentUser._id;
      custPhase.customer_email = custInfo.email;
      custPhase.customer_name = custInfo.name;
    } else {
      phases.push({
        name: currentUser.user_name,
        email: currentUser.email,
        date: new Date(),
        id: currentUser._id,
        customer_email: custInfo.email,
        customer_name: custInfo.name,
      });
    }
    customer_requirements.approval_phases = phases;
    customer_requirements.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    await this.http
      .put(
        Endpoint.API_URL +
          Endpoint.SEND_CUST_REQ_TO_CUSTOMER +
          customer_requirements._id,
        {
          approval_phases: customer_requirements.approval_phases,
          opportunity_rfid: opporunityID,
          dependency_on: !Utilities.isNullOrEmpty(opporunityID)
            ? 'Customer'
            : null,
          customer_emails: custInfo?.email,
        }
      )
      .toPromise()
      .then((resp: any) => {
        if (resp.approval_phases) {
          alert(resp.message);
          customer_requirements.approval_phases = resp.approval_phases;
          if (customer_requirements?.approval_phases) {
            customer_requirements.managerApprovalPart =
              customer_requirements.approval_phases
                .slice()
                .reverse()
                .find(
                  (x) =>
                    x.role &&
                    x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
                );
            customer_requirements.sendingToCustomerPart =
              customer_requirements.approval_phases
                .slice()
                .reverse()
                .find(
                  (x) =>
                    x.role &&
                    x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
                );
          }
        }
      });
    return customer_requirements;
  }
}
