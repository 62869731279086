import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GcpService } from 'projects/gcp/src/public-api';

@Component({
  selector: 'set-billing-popup',
  templateUrl: './set-billing-popup.component.html',
  styleUrls: ['./set-billing-popup.component.scss'],
})
export class SetBillingPopupComponent implements OnInit {
  @ViewChild('setBillingAccountModal') setBillingAccountModal: ModalDirective;
  @Output() linkAction = new EventEmitter<any>();
  billingAccountList = [];
  @Input() projectName: string;
  billingAccountSelected;

  constructor(private http: HttpClient, private gcpService: GcpService) {}

  async ngOnInit(): Promise<void> {
    await this.gcpService
      .getListOfBillingAccount(false, null, null, null, null)
      .then((resp: any) => {
        this.billingAccountList = resp.data ?? [];
      });
  }

  show() {
    this.setBillingAccountModal.show();
  }
  hide() {
    this.setBillingAccountModal.hide();
  }
  linkBillingAccount() {
    this.linkAction.emit(this.billingAccountSelected);
  }
}
