import {
  Endpoint,
  OrderAction,
  OrderStatus,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostBinding,
  ViewEncapsulation,
  NgZone,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderModel } from 'src/models/mongo/order.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';
import { Location } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lib-view-order',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
})
export class OrderViewComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = '';
  model: OrderModel = new OrderModel();
  afterCreationUserEmail;
  afterExecutionUserEmail;
  currentUser: UserModel;
  orderPhasesInfo;
  gpmGroupEmail = [];
  executors = [];
  orderStatus: string;
  orderAction: string;
  isExecuted: boolean = false;
  editPage: boolean;
  selectOthers: boolean = false;
  isNewCustomer: boolean = false;
  date_from: string;
  date_to: string;
  odooIntegrationEnabled: boolean = false;
  @ViewChild('odooComparisonModal') odooComparisonModal: ModalDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private location: Location
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
  }
  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(async (routeParams) => {
      const params = history.state;
      this.editPage = Utilities.isTrue(params['edit']);
      this.date_from = params['date_from'];
      this.date_to = params['date_to'];

      //ORDER DETAILS/VIEW PAGE
      if (params && params['order_model'])
        this.model = JSON.parse(params['order_model']);
      else if (
        routeParams &&
        routeParams['fromEmail'] &&
        !Utilities.isNullOrEmpty(routeParams['Id'])
      ) {
        // FROM EMAIL URL
        this.editPage = true;
        var _id = routeParams['Id'];
        await this.http
          .get<any>(Endpoint.API_URL + Endpoint.GET_ORDER_BY_ID + _id)
          .toPromise()
          .then((resp) => {
            if (resp?.order) {
              this.model = resp.order;
            }
          });
      }
      if (this.model) {
        this.isNewCustomer =
          this.model.action.toLowerCase() ==
          OrderAction.NEW_CUSTOMER.toLowerCase();
        this.orderStatus = this.model.order_status;
        this.orderAction = this.model.action?.split('_').join(' ') + ':';
        if (this.model.sku && this.model.sku.length == 1) {
          var sku1 = this.model.sku[0];
          if (!Utilities.isNullOrEmpty(sku1.old_sku_name))
            this.orderAction += ' old sku=' + sku1.old_sku_name + '.';
          if (!Utilities.isNullOrEmpty(sku1.old_plan_name))
            this.orderAction += ' old plan=' + sku1.old_plan_name + '.';
          if (sku1.old_license != undefined)
            this.orderAction += ' old license=' + sku1.old_license + '.';
          if (
            this.model.action?.toLowerCase() ==
              OrderAction.UPDATE_RNEWAL_SETTNGS &&
            !Utilities.isNullOrEmpty(sku1.renewel_setting_type)
          )
            this.orderAction += ' to ' + sku1.renewel_setting_type + '.';
        }
        this.isExecuted =
          this.orderStatus.toLowerCase() == OrderStatus.Executed.toLowerCase();
      }

      var usersInfojson = this.localStorage.get(Unicode.USERS_INFO);
      var usersInfo = usersInfojson ? JSON.parse(usersInfojson) : {};
      this.orderPhasesInfo = await Utilities.orderApprovalPhases(
        this.localStorage,
        this.model,
        usersInfo
      );
    });
  }

  cancelOrder() {
    this.localStorage.save('cancelled_order_date_from', this.date_from);
    this.localStorage.save('cancelled_order_date_to', this.date_to);
    this.location.back();
  }
}
