import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRequirementsListRoutingModule } from './customer-requirements-list-routing.module';
import { CustomerRequirementsListComponent } from './customer-requirements-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    CustomerRequirementsListComponent
  ],
  imports: [
    CommonModule,
    CustomerRequirementsListRoutingModule,
    NgxPaginationModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule,
  ],
})
export class CustomerRequirementsListModule { }
