import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferableSubscriptionsRoutingModule } from './transferable-subscriptions-routing.module';
import { TransferableSubscriptionsComponent } from './transferable-subscriptions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
  declarations: [TransferableSubscriptionsComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    ModalModule.forRoot(),
    TransferableSubscriptionsRoutingModule
  ]
})
export class TransferableSubscriptionsModule { }
