import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderViewRoutingModule } from './order-view-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OrderViewComponent } from './order-view.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderViewFormModule } from 'src/shared-components/order-view-form/order-view-form.module';

@NgModule({
  declarations: [OrderViewComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    OrderViewFormModule,
    OrderViewRoutingModule,
  ],
  exports: [OrderViewComponent],
})
export class OrderViewModule {}
