<main
  class="container-fluid px-2 w-100 d-flex flex-column justify-content-between"
>
  <section
    class="container-fluid box-shadow bg-white rounded-5 p-1 px-md-4 pb-2 fluid-section h-100 w-100"
  >
    <h1 class="p-1 ps-2 h4 d-flex align-items-center mb-0 mt-3">
      <a (click)="cancel()">
        <img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon"
        />
      </a>
      {{ title }}
    </h1>
    <div class="row">
      <h2 class="h5 mt-3 mb-3 col-10">Customer details</h2>
      <div
        class="col-2 customer_id_div justify-content-between row"
        *ngIf="currentCustomer.local_id"
      >
        <label id="customer_id_div_static" class="col-6">Customer ID</label
        ><label id="customer_id_div_value" class="col-6"
          >#{{ currentCustomer.local_id }}</label
        >
      </div>
    </div>

    <label
      for="domain"
      class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
      >Domain(s)</label
    >
    <div class="mb-2 d-flex align-items-center gap-5">
      <div
        class="d-inline-flex domain-disabled rounded-pill"
        *ngFor="let domain of domains; let i = index"
      >
        <input
          placeholder="Domain"
          [(ngModel)]="domains[i]"
          type="url"
          [ngClass]="{'col-2 form-control rounded-pill  cursor-pointer  disabled-domain':true,'d-none':!formenabled,
          }"
          id="domain"
          [readonly]="disabledinput"
          (click)="SwitchForm(i)"
        />
        <img
          *ngIf="!currentCustomer.isExecuted"
          class="px-1 px-md-2 cursor-pointer"
          src="./assets/img/edit-icon.svg"
          alt="edit icon"
          (click)="editDomain(i)"
        />
      </div>
      <label
        *ngIf="!billingAccountCase && isCreationOrTransfer"
        (click)="subDomainPopupShow()"
        [ngClass]="{
          'form-label text-wrap col-3 me-3 mb-0 blue-text px-5': true,
          'd-none': !formenabled
        }"
      >
        +Add a sub domain
      </label>
      <button
        *ngIf="!formenabled"
        class="btn btn-primary small-text btn-color rounded-pill col-2 col-xl-3 p-1 p-xl-2 mt-3 fs-6 align-self-end"
        type="button"
        (click)="mainDomainPopupShow()"
      >
        +Add domain
      </button>
    </div>
    <mat-slide-toggle
      *ngFor="let form of domainforms; let i = index"
      [ngClass]="{ 'd-none': i != active_tab || i == 0 }"
      (change)="ToggleSwitch($event, i)"
      [checked]="form.get('is_inherited')?.value == 'inherited'"
    >
      Inherit Data
    </mat-slide-toggle>

    <form
      *ngFor="let form of domainforms; let i = index"
      class="d-flex flex-column flex-xl-row justify-content-xl-between col-12 flex-nowrap needs-validation gap-3"
      [ngClass]="{ 'd-none': i != active_tab }"
      [formGroup]="domainforms[i]"
      role="form"
      #formDir="ngForm"
      id="customer-create-form"
      novalidate
    >
      <div class="col-12 col-xl-6">
        <!-- <div
          *ngIf="!isCreationOrTransfer"
          class="mb-2 d-flex align-items-center justify-content-between"
        >
          <label
            for="transfer-token"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Transfer Token</label
          >
          <input
            formControlName="auth_token"
            [ngClass]="{
              'is-invalid': FormsSubmitted && domainforms[i].get('auth_token').invalid,
               'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="transfer-token"
            placeholder="Transfer Token"
          />
        </div> -->
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="company-name"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Company Name</label
          >
          <input
            formControlName="organisation_name"
            [ngClass]="{
              'is-invalid': FormsSubmitted && domainforms[i].get('organisation_name').invalid,
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            type="text"
            class="form-control rounded-pill"
            id="company-name"
            placeholder="Organisation Name"
          />
        </div>

        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="country"
            class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
            >Country</label
          >
          <ng-select
            bindLabel="country.name"
            appendTo="body"
            [searchable]="true"
            class="col form-select p-0 rounded-pill"
            [clearable]="true"
            formControlName="country"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('country').invalid,
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            placeholder="Country"
          >

          >
            <ng-option [value]="country.iso2" *ngFor="let country of countries">
              {{ country.name }}
            </ng-option>
          </ng-select>

        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="state"
            class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
            >State</label
          >
          <input
            formControlName="state"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('state').invalid,
               'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="state"
            placeholder="State"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="city"
            class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
            >City</label
          >
          <input
            formControlName="city"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('city').invalid,
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="city"
            placeholder="City"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="street"
            class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
            >Street</label
          >
          <input
            formControlName="street"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('street').invalid,
               'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="street"
            placeholder="Street"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="zip-code"
            class="form-label text-nowrap col-3 me-3 mb-0 d-none d-md-inline"
            >Zip/Postal Code</label
          >
          <input
            formControlName="postal_code"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('postal_code').invalid,
               'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="zip-code"
            placeholder="Zip/Postal Code"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="contact-email"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Alternate Email</label
          >
          <input
            formControlName="alternate_email"
            [ngClass]="{
              'is-invalid': (FormsSubmitted && domainforms[i].get('alternate_email').invalid),
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            type="email"
            class="form-control rounded-pill"
            id="alternate-email"
            placeholder="Alternate Email"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="phone-number"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >Phone</label
          >
          <ngx-intl-tel-input [cssClass]="'form-control rounded-pill phone-selector'"

          formControlName="phone"

           [ngClass]="{

              'is-invalid': FormsSubmitted && domainforms[i].get('phone').invalid,
              'phone_disabled':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Lebanon"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="false"
             [inputId]="'phone-number'"

            >

          </ngx-intl-tel-input>

        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="customer-sector"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Customer Sector</label
          >
          <ng-select
            id="customer-sector"
            bindLabel="sector"
            appendTo="body"
            [searchable]="true"
            class="col form-select p-0 rounded-pill"
            [clearable]="true"
            formControlName="customer_sector"
            [ngClass]="{
              'is-invalid':  FormsSubmitted && domainforms[i].get('customer_sector').invalid,
               'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited' ,

            }"
            placeholder="Customer Sector"
          >
            <ng-option [value]="sector" *ngFor="let sector of customerSector">
              {{ sector }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="industry"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Industry</label
          >

          <ng-select
            id="industry"
            aria-label="Select Industry"
            bindLabel="industry"
            appendTo="body"
            [searchable]="true"
            class="col form-select p-0 rounded-pill"
            [clearable]="true"
            formControlName="industry"
            [ngClass]="{
              'is-invalid': FormsSubmitted && domainforms[i].get('industry').invalid,
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited' ,

            }"
            placeholder="Industry"
          >
            <ng-option [value]="industry" *ngFor="let industry of industries">
              {{ industry }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="contact-first-name"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Contact Person First Name</label
          >
          <input
            formControlName="contact_person_first_name"
            [ngClass]="{
              'is-invalid':
                FormsSubmitted && domainforms[i].get('contact_person_first_name').invalid,
                'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',

            }"
            type="text"
            class="form-control rounded-pill"
            id="contact-first-name"
            placeholder="Contact Person First Name"
          />
        </div>
        <div class="mb-2 d-flex align-items-center justify-content-between">
          <label
            for="contact-last-name"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Contact Person Last Name</label
          >
          <input
            formControlName="contact_person_last_name"
            [ngClass]="{
              'is-invalid':
                FormsSubmitted && domainforms[i].get('contact_person_last_name').invalid,
                'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            type="text"
            class="form-control rounded-pill"
            id="contact-last-name"
            placeholder="Contact Person Last Name"
          />
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <label
            for="contact-email"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Contact Person Email</label
          >
          <input
            formControlName="contact_person_email"
            [ngClass]="{
              'is-invalid': (FormsSubmitted && domainforms[i].get('contact_person_email').invalid),
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            type="email"
            class="form-control rounded-pill"
            id="contact-email"
            placeholder="Contact Person Email"
          />
        </div>
        <div class="blue_1 f-14 ms-xl-4">
          {{
            add
              ? "after executing we will send the credentials automatically by email
          to the customer,using this account"
              : ""
          }}
        </div>
        <div
          class="mb-2 mt-2 d-flex align-items-center justify-content-between"
        >
          <label
            for="employees-number"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Number of Employees</label
          >
          <input
            formControlName="employees_nbr"
            [ngClass]="{
              'is-invalid': FormsSubmitted && domainforms[i].get('employees_nbr').invalid,
              'my_disabled_input':!formenabled || domainforms[i].get('is_inherited')?.value=='inherited',
            }"
            type="number"
            class="form-control rounded-pill"
            id="employees-number"
            placeholder="0"
          />
        </div>
        <div
          class="mb-2 d-flex align-items-center justify-content-between"
          *ngIf="currentUser.canAssign == true"
        >
          <label
            for="assigned-salesperson"
            class="form-label text-wrap col-3 ms-xl-4 me-3 me-xl-1 mb-0 d-none d-md-inline"
            >Assigned Salesperson</label
          >
          <ng-select
            [multiple]="true"
            bindLabel="user.first_name"
            appendTo="body"
            [searchable]="true"
            class="col form-select p-0 rounded-pill"
            (change)="assignSales()"
            [clearable]="true"
            formControlName="user_ids"
            placeholder="users"
            [ngClass]="{
              my_disabled_input:
                !formenabled ||
                domainforms[i].get('is_inherited')?.value == 'inherited'
            }"
          >
            <ng-option [value]="user._id" *ngFor="let user of users">
              {{ user.first_name }} {{ user.last_name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>
    <div
      class="mb-2 d-flex justify-content-end"
      *ngIf="!currentCustomer.isExecuted"
    >
      <input
        [disabled]="
          currentUser.isViewerOnly ||
          (!currentUser.isSales &&
            !currentUser.isOperations &&
            !currentUser.isAdmin &&
            !currentUser.isFinanceTeam)
        "
        class="btn btn-primary small-text btn-color rounded-pill col-2 col-xl-3 p-1 p-xl-2 mt-3 fs-6 align-self-end"
        value="Save"
        type="submit"
        (click)="firstStepSave()"
      />
    </div>
  </section>
</main>

<!-- *** CREATE BILLING MODAL *** -->
<div
  class="modal fade"
  bsModal
  #creatBillingModal="bs-modal"
  tabindex="-1"
  id="creatBillingModal_id"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center">
          Create Billing Account
        </h1>
        <button
          type="button"
          class="btn-close"
          (click)="creatBillingModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <add-billing-account
          *ngIf="createdBillingData?.channelName"
          [createdBillingData]="createdBillingData"
          [creatBillingModal]="creatBillingModal"
          (additionalBillingAccountData)="onUpdatedBillingAccountData($event)"
        ></add-billing-account>
      </div>
    </div>
  </div>
</div>

<billing-account-created-popup
  #billingAccountCreated
  [createdBillingData]="createdBillingData"
></billing-account-created-popup>

<!-- *** SAVE MODAL ***  -->
<div
  class="modal fade"
  bsModal
  #nextStepModal="bs-modal"
  tabindex="-1"
  id="nextStepModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div
        class="modal-header justify-content-center border-0 btn-color btn-color"
      >
        <span class="modal-title product-sans text-center text-white">
          Save Successful
        </span>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="nextStepModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h2 class="fs-5 text-center my-2">What do you want to do next?</h2>
        <div class="d-flex justify-content-evenly py-3 px-5">
          <button
            (click)="secondStepSave(true, false, false)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text"
          >
            Go To Dashboard
          </button>
          <button
            (click)="secondStepSave(false, true, false)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text"
          >
            Go To Customers List
          </button>
          <button
            (click)="secondStepSave(false, false, true)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text"
          >
            Create a New Oppotunity
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- *** GWS OPP OPTION ***  -->
<div
  class="modal fade"
  bsModal
  #orderDealModal="bs-modal"
  tabindex="-1"
  id="orderDealModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div
        class="modal-header justify-content-center border-0 btn-color btn-color"
      >
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="orderDealModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h2 class="fs-5 text-center my-2">What do you want to do next?</h2>
        <div class="d-flex justify-content-evenly py-3 px-5">
          <button
            (click)="GWSTakeAction(true)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text col-4"
          >
            Create Order
          </button>
          <button
            *ngIf="
              !currentUser.isViewerOnly &&
              (currentUser.isSales ||
                currentUser.isOperations ||
                currentUser.isAdmin)
            "
            (click)="GWSTakeAction(false)"
            class="btn btn-primary d-none d-md-inline rounded-pill btn-color smaller-text col-4"
          >
            Create Deal
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--- SECOND POPUP -->
<gws-gcp-popup
  #gwsGcpOppModal
  (saveOption)="goToOppunityPage($event)"
></gws-gcp-popup>

<!-- Add Domain  POPUP -->
<div
  class="modal fade"
  bsModal
  #AddDomainModal="bs-modal"
  tabindex="-1"
  id="AddDomainModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="AddDomainModalLabel"
        >
          Domain Name
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="AddDomainModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <input
            type="email"
            class="form-control rounded-pill"
            id="domain"
            [(ngModel)]="maindomain"
            [ngClass]="{ 'is-invalid': domainExists }"
            placeholder="Domain"
          />
          <div
            id="popover-domain"
            class="popover-domain d-flex align-items-center position-absolute p-3 w-75"
            [ngStyle]="{
              visibility: this.isValidDomain() ? 'visible' : 'hidden'
            }"
          >
            <p class="mb-0">
              {{ customerExistenceMessage }}
            </p>
            <!-- <button
              (click)="transferCust()"
              class="btn btn-primary product-sans-light smaller-text btn-color rounded-pill mx-2 text-nowrap h-100 lh-lg"
            >
              Create Transfer Customer
            </button> -->
            <button
              (click)="closeVerificationDomainPopup()"
              class="btn btn-primary product-sans-light smaller-text btn-color rounded-pill mx-2 text-nowrap h-100 lh-lg"
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          class="mt-2"
          *ngIf="!this.billingAccountCase && this.isCreationOrTransfer"
        >
          <span class="me-1">GCP</span>
          <mat-slide-toggle
            class="gws_switch"
            (change)="gwsSwitchChange($event)"
            [(ngModel)]="isGWSCustomer"
          >
            GWS
          </mat-slide-toggle>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="enableform()"
          [disabled]="Maindomainempty()"
        >
          {{ domainEditingText }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  bsModal
  #AddSubDomainModal="bs-modal"
  tabindex="-1"
  id="AddSubDomainModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="AddSubDomainModalLabel"
        >
          Domain Name
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="HideSubdomainModal()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <input
            type="email"
            class="form-control rounded-pill"
            id="subdomain"
            [(ngModel)]="newsubdomain"
            placeholder="Subdomain"
          />
        </div>
        <label
          *ngIf="!domainEditing"
          class="form-label text-wrap col-3 me-3 mb-0 mt-3 d-md-inline"
        >
          Do you want to inherit the main domain data or add new data for this
          sub domain?
        </label>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="!domainEditing"
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
          (click)="NewForm('new')"
          [disabled]="emptySubdomain()"
        >
          Add new Data
        </button>

        <button
          *ngIf="!domainEditing"
          type="button"
          [disabled]="emptySubdomain()"
          class="btn btn-primary btn-color rounded-pill col-4"
          (click)="NewForm('inherited')"
        >
          Inherit Main Domain Data
        </button>

        <button
          *ngIf="domainEditing"
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
          (click)="NewForm('new')"
          [disabled]="emptySubdomain()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
