<div class="deals px-4 my-2 fluid-section">
  <div class="row border-bottom product-sans-medium border-opacity-50 border-dark position-sticky top-0 bg-white py-3">
    <p class="col-2 mb-0 ps-0 text-center">Domain</p>
    <p class="col-1 mb-0 ps-0">GWS/GCP</p>
    <p class="col-1 mb-0 ps-0">Deal Type</p>
    <p class="col-1 mb-0 ps-0">DR#</p>
    <p class="col-2 mb-0 ps-0">Opportunity#</p>
    <p class="col-1 mb-0 ps-0">Opportunity Owner</p>
    <p class="col-2 mb-0 ps-0">Estimated Close Date</p>
    <p class="col-2 mb-0 ps-0 position-relative">
      Edit
      <span class="w-100 h-100 bg-white position-absolute top-0 start-0"></span>
    </p>
  </div>
  <div class="row pt-2 border-bottom border-opacity-10 border-dark" *ngFor="let deal of deals">
    <p class="col-2 mb-0 ps-0 py-3 text-center">{{ deal.customer_domain }}</p>
    <p class="col-1 mb-0 ps-0 py-3">{{ deal.source }}</p>
    <p class="col-1 mb-0 ps-0 py-3">{{ deal.type }}</p>
    <p class="col-1 mb-0 ps-0 py-3">{{ deal.dr_nbr }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ deal.opportunity_nbr }}</p>
    <p class="col-1 mb-0 ps-0 py-3">{{ deal.opportunity_owner }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ deal.estimated_close_date_string }}</p>
    <p class="col-2 mb-0 ps-0 py-2 text-end text-nowrap">
      <img
      class="px-1 px-md-2 cursor-pointer"
      src="./assets/img/view-icon.svg"
      (click)="viewDeal(false, deal.id)"
      alt="view icon"
      />
        <img class="px-1 px-md-2 cursor-pointer" src="./assets/img/edit-icon.svg" alt="edit icon" />
      <button class="btn btn-primary d-none d-md-inline product-sans-light rounded-pill col-7 btn-color smaller-text">
        Make the Order
      </button>
    </p>
  </div>
</div>
