<div class="container-fluid page_content_main">
  <div class="bg-white box-shadow mobile-section row rounded-5 p-0 m-0">
    <div class="col-12">
      <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 mt-4 ms-4">
        <a>
          <img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            (click)="cancel()"
            alt="back icon"
          />
        </a>
        {{ title }}
      </h1>
    </div>

    <div class="col-12 ps-4 ps-md-5">
      <sow-google-doc-card
        *ngIf="googleDocData"
        [cardData]="googleDocData"
        (selectDomainEmitter)="selectCustomer($event)"
        [sow]="SOW"
        (createEmitter)="createSOW($event)"
        (draftEmitter)="sendDraftSOWForApproval($event)"
        (approveEmitter)="approveSOW($event)"
        (custEmitter)="sendSOWToCust($event)"
      ></sow-google-doc-card>
    </div>
  </div>
</div>
