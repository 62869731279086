import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CustomerInfoPopupModule } from '../customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from '../rejection-popup/rejection-popup.module';
import { EngineeringRecommendationsGoogleDocCardComponent } from './engineering_recommendations-google-doc-card.component';

@NgModule({
  declarations: [EngineeringRecommendationsGoogleDocCardComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    NgSelectModule,
    FormsModule,
    CustomerInfoPopupModule,
    RejectionPopupModule
  ],
  exports: [EngineeringRecommendationsGoogleDocCardComponent],
})
export class EngineeringRecommendationsGoogleDocCardModule {}
