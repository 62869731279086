import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'lib-customer-requirements',
  template: ` <router-outlet></router-outlet> `,
  styles: [],
})
export class CustomerRequirementsComponent {
  @HostBinding('class') class = ' ';
}
