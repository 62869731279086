import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SowListRoutingModule } from './sow-list-routing.module';
import { SowListComponent } from './sow-list.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SowListComponent],
    imports: [
        CommonModule,
        SowListRoutingModule,
        NgxPaginationModule,
        FormsModule,
        NgSelectModule,
        NgbDropdownModule,
    ],
})
export class SowListModule {}
