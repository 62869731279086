import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunityOrderPhaseComponent } from './opportunity-order-phase.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomerInfoPopupModule } from 'src/shared-components/customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from 'src/shared-components/rejection-popup/rejection-popup.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { NewProjectModalModule } from 'src/shared-components/new-project-modal/new-project-modal.module';
import { LinkProjectPopupModule } from 'src/shared-components/link-project-popup/link-project-popup.module';
import { AddBudgetFormModule } from 'projects/gcp/src/lib/add-budget-form/add-budget-form.module';
import { AddOrderFormModule } from 'src/shared-components/add-order-form/add-order-form.module';
import { OrderViewModule } from 'projects/orders/src/lib/order-view/order-view.module';
import { OrderViewFormModule } from 'src/shared-components/order-view-form/order-view-form.module';
import {OpportunitySolutionBuildingPhaseModule} from '../opportunity-solution-building-phase/opportunity-solution-building-phase.module';

@NgModule({
  declarations: [OpportunityOrderPhaseComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FormsModule,
    CustomerInfoPopupModule,
    NewProjectModalModule,
    LinkProjectPopupModule,
    AddBudgetFormModule,
    RejectionPopupModule,
    AddOrderFormModule,
    OrderViewFormModule,
    OpportunitySolutionBuildingPhaseModule,
  ],
  exports: [OpportunityOrderPhaseComponent],
})
export class OpportunityOrderPhaseModule {}
