import { BaseModel } from './base.model';
import { Unicode } from 'src/shared/Endpoint';

export class SubscriptionModel extends BaseModel {
  customerId: string;
  subscriptionId: string;
  skuId: string;
  creationTime: string;
  kind: string;
  seats: Seats = new Seats();
  renewalSettings: RenewalSettings = new RenewalSettings();
  purchaseOrderId: string;
  status: string;
  resourceUiUrl: string;
  billingMethod: string;
  suspensionReasons: [];
  customerDomain: string;
  dealCode: string;
  skuName: string;
  plan: Plan;
  trialSettings: {
    isInTrial: boolean;
    trialEndTime: Date;
  };
  transferInfo: {
    transferabilityExpirationTime: string;
    minimumTransferableSeats: number;
    currentLegacySkuId: string;
  };
  //MY
  productName: string;
  seatsNbr: number;
  planObj;
  isplanChanged: boolean = false;
  isProductChanged: boolean;
  newPlanId;
  newSkuId;
  oldSkuId;
  oldPlanId: number;
  txtStatusEvent: string;
  canActivate: boolean = true;
  oldLicences: number;
  additionalLicences: number;
  opportunityNbr: string;
  opportunitySource: string;
  opportunityStatus: string;
  googleRep: string;
  sellingPrice;
  costPrice;
  newRenewelType: string;
  withGoogleOffer;
  googleOfferPercentage: number;
  withCustomerOffer;
  customerOfferPercentage: number;
  description: string;
  displayedPlanName: string;
  licensesSuffixText: string;
  isFlexible: boolean;
  isRenewalOrder: boolean;
  currencyRfId: string;
  customerEmailForSending: string;
  invoiceNbr: string;
  newPlan: any;
  orderId: number;
  invoice_file_rfid: number;
  is_withdraw: number;
  upgrade: boolean;
  products = [];
  startDate;
  endDate;

  constructor() {
    super();
  }

  public isFlexibleOrTrialPlan(): boolean {
    return (
      this.plan.planName == Unicode.FLEXIBLE_PLAN ||
      this.plan.planName == Unicode.TRIAL_PLAN
    );
  }
}

export class Seats {
  numberOfSeats?: number;
  maximumNumberOfSeats?: number;
  licensedNumberOfSeats?: number;
  kind?: string;
}
export class RenewalSettings {
  renewalType: string;
  kind: string;
  constructor() {}
}

export class Plan {
  planName: string;
  isCommitmentPlan: boolean;
  commitmentInterval: {
    startTime: string;
    endTime: string;
  };
}
