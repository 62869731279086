import { CustomerGcpProjectsModule } from './customer-gcp-projects/customer-gcp-projects.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerServicesComponent } from './customer-services.component';
import { CustomerSubscriptionsModule } from './customer-subscriptions/customer-subscriptions.module';
import { CustomerOrdersModule } from './customer-orders/customer-orders.module';
import { CustomerDealsModule } from './customer-deals/customer-deals.module';
import { CustomerEntitlementsModule } from './customer-entitlements/customer-entitlements.module';

const routes: Routes = [
  {
    path: '',
    component: CustomerServicesComponent,
    children: [
      {
        path: '',
        redirectTo: 'subscriptions',
        pathMatch: 'full',
      },
      {
        path: 'subscriptions',
        loadChildren: () => CustomerSubscriptionsModule,
      },
      { path: 'orders', loadChildren: () => CustomerOrdersModule },
      { path: 'deals', loadChildren: () => CustomerDealsModule },
      { path: 'gcp-projects', loadChildren: () => CustomerGcpProjectsModule },
      { path: 'entitlements', loadChildren: () => CustomerEntitlementsModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerServicesRoutingModule {}
