<div
  class="table-responsive fluid-section bg-white mt-2 mt-md-0 w-100 max-h-70"
  id="orders-all"
>
  <!--  <input-->
  <!--    class="form-check-input"-->
  <!--    type="checkbox"-->
  <!--    role="switch"-->
  <!--    id="orders-switch-input"-->
  <!--    hidden-->
  <!--  />-->
  <!--  <div-->
  <!--    class="orders-switch cursor-pointer"-->
  <!--    (click)="switchType($event)"-->
  <!--    id="orders-switch"-->
  <!--  ></div>-->
  <div class="d-flex justify-content-end">
    <pagination-controls
      [responsive]="true"
      previousLabel=""
      nextLabel=""
      (pageChange)="renderPage($event)"
    ></pagination-controls>
  </div>
  <div class="table-responsive fluid-section w-100">
    <table class="table table-hover align-middle text-center small-text mt-3">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-0"
          >
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-0">
            Order Date
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-0">SKU</th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-0">Status</th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-0">
            <div
              class="filter-header"
              (click)="toggleFilterOptions('order_status')"
            >
              Order Type
              <span
                class="filter-arrow"
                [class.filter-arrow-up]="isFilterOptionsOpen('order_status')"
              ></span>
            </div>
            <div
              class="filter-options-container"
              *ngIf="isFilterOptionsOpen('order_status')"
            >
              <select
                class="filter-select"
                [(ngModel)]="selectedFilterOption"
                (change)="applyFilter()"
              >
                <option value="ALL">ALL</option>
                <option value="GCP">GCP</option>
                <option value="GWS">GWS</option>
              </select>
            </div>
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-0">Action</th>
          <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 border-0 position-relative"
          >
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr
          *ngFor="
            let order of GetData
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  }
          "
        >
          <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
            {{ order.domain?.domain }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ order.created_at | date : "dd-MMM-yyyy" }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ order.skuName }}</td>
          <td class="p-1 p-md-2 col-1 col-md-2 text-uppercase">
            {{ order.order_status }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2 text-uppercase">
            <div *ngIf="order.is_gcp">GCP</div>
            <div *ngIf="!order.is_gcp">GWS</div>
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ order.action }}</td>
          <td class="p-1 p-md-2 text-nowrap col-1 col-md-3 col-lg-2 p-0 p-md-2">
            <img
              class="px-1 px-md-2 mx-3 cursor-pointer"
              src="./assets/img/view-icon.svg"
              (click)="viewOrder(true, order._id)"
              alt="view icon"
            />
          </td>
          <td>
            <div
              *ngIf="
                getCurrentUser._id == order.created_by_rfid &&
                'executed' !== order.order_status?.toLowerCase()
              "
              (click)="deleteOrder(order._id)"
            >
              <img
                class="px-1 px-md-2 cursor-pointer"
                src="./assets/img/delete-icon.svg"
                alt="delete icon"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
