import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lib-customer-gcp-projects',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-gcp-projects.component.html',
  styleUrls: ['./customer-gcp-projects.component.scss']
})
export class CustomerGcpProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
