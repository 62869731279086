import { LocalStorageService } from './../localstorage-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable()
export class SubscriptionService {
  private cache$: Map<string, Observable<Array<any>>> = new Map<
    string,
    Observable<Array<any>>
  >();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  async subscriptions(customerId: string, headers) {}

  async requestSubscriptions(
    customerId: string,
    params = null,
    withoutLoader: string = '0'
  ) {
    // return this.http
    //   .get<SubscriptionResponse>(url, { headers: headers })
    //   .pipe(map((response) => response.value));
    const url = 'customerId=' + customerId;
    var callApiRequest: boolean = true;
    if (!this.cache$ || this.cache$.size == 0) callApiRequest = true;
    else {
      if (!this.cache$.has(url)) callApiRequest = true;
      else {
        var dataRefreshTime = this.localStorageService.get(
          Unicode.REFRESH_TIME
        );
        if (Utilities.isNullOrEmpty(dataRefreshTime)) callApiRequest = true;
        else {
          var diffHours =
            new Date().getTime() - Number.parseInt(dataRefreshTime);
          diffHours = diffHours / 1000 / 60 / 60;
          callApiRequest = diffHours >= 1; // REFRESH EVERY 1 HOUR
        }
      }
    }
    if (callApiRequest) {
      //data
      await Utilities.getSubscriptionsByCustomer(
        this.http,
        customerId,
        null,
        withoutLoader
      )
        .then((resp: any) => {
          if (resp && resp.subscriptions) {
            this.localStorageService.save(
              Unicode.REFRESH_TIME,
              new Date().getTime() + ''
            );
            this.cache$.set(url, resp.subscriptions);
          }
        })
        .catch((error) => {
          if (error && error.status === 401) {
            this.requestSubscriptions(customerId, params);
          }
        });
    }
    return this.cache$.get(url);
  }
}

export interface SubscriptionResponse {
  type: string;
  value: Array<any>;
}
