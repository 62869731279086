import { LayoutsModule } from './layouts/layouts.module';
import { LoginModule } from './login/login.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/auth/auth.guard';
import { HealthyModule } from './healthy/healthy.module';

const routes: Routes = [
  { path: '', loadChildren: () => LayoutsModule, canActivate: [AuthGuard] }, //,canActivate: [AuthGuard]
  { path: 'login', loadChildren: () => LoginModule },
  { path: 'layouts', loadChildren: () => LayoutsModule },
  { path: 'health-check', loadChildren: () => HealthyModule },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
