import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingAccountModel } from 'src/models/mongo/billing-account';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode } from 'src/shared/Endpoint';
import { GcpService } from '../../gcp.service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';

@Component({
  selector: 'lib-billing-account-details',
  templateUrl: './billing-account-details.component.html',
  styleUrls: ['./billing-account-details.component.scss'],
})
export class BillingAccountDetailsComponent implements OnInit {
  @HostBinding('class') class = ' ';
  currentUser: UserModel;
  billing: BillingAccountModel = new BillingAccountModel();
  budgets = [];
  projects = [];
  initialData;
  updatedAlerts = [];

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private gcpService: GcpService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
  }
  async ngOnInit(): Promise<void> {
    this.billing = history.state.billing;
    if (this.billing?.id) {
      this.gcpService
        .BillingInfo(this.billing.id)
        .then((resp: any) => {
          this.projects = resp?.projects;
          this.budgets = resp?.budgets;
          this.initialData = { projects: this.projects, budgets: this.budgets };
        });
    }
  }

  addBudget() {
    this.budgets.unshift({
      name: null,
      amount: null,
      alerts: [{ percentage: null, amount: null }],
      new: true,
    });
  }

  addAlertRow(budget) {
    budget.alerts.push({ percentage: null, amount: null, new: true });
  }

  deleteAlert(index, budget) {
    budget.alerts.splice(index, 1);
    this.updatedAlerts.push({
      id: budget.id,
      alerts: budget.alerts.map((x) => x.percentage / 100),
    });
  }

  updateAlertPercentage(budget, alert, e) {
    var value = e.target.value;
    if (value && value > 0 && budget.amount) {
      var newAmount = ((budget.amount * value) / 100).toFixed(2);
      var previousAmount = alert.amount;
      alert.amount = newAmount;
      if (newAmount !== previousAmount) {
        var exist = this.updatedAlerts.find((x) => x.id == budget.id);
        if (exist) exist.alerts = budget.alerts.map((x) => x.percentage / 100);
        else
          this.updatedAlerts.push({
            id: budget.id,
            alerts: budget.alerts.map((x) => x.percentage / 100),
          });
      }
    }
  }
  updateAlertAmount(budget, alert, e) {
    var value = e.target.value;
    if (value && value > 0 && budget.amount) {
      var newPerc = (value * 100) / budget.amount;
      var previousPerc = alert.percentage;
      alert.percentage = newPerc.toFixed(2);
      if (newPerc !== previousPerc) {
        var exist = this.updatedAlerts.find((x) => x.id == budget.id);
        if (exist) exist.alerts = budget.alerts.map((x) => x.percentage / 100);
        else
          this.updatedAlerts.push({
            id: budget.id,
            alerts: budget.alerts.map((x) => x.percentage / 100),
          });
      }
    }
  }

  saveBudgetsChanges() {
    var newAlerts = [];
    var newBudgets = JSON.parse(
      JSON.stringify(this.budgets.filter((x) => x.new == true))
    );
    newBudgets.forEach((x) =>
      x.alerts.forEach((y) => (y.percentage = y.percentage / 100))
    );
    this.budgets.forEach((budget) => {
      if (
        budget.new !== true &&
        (budget.alerts.filter((x) => x.new == true).length > 0 ||
          this.updatedAlerts.find((x) => x.id == budget.id))
      ) {
        newAlerts.push({
          id: budget.id,
          alerts: budget.alerts
            .filter((x) => x.percentage != 0)
            .map((x) => x.percentage / 100),
        });
      }
    });
    var changes = {
      new_budgets: newBudgets,
      update_alerts: newAlerts,
    };
    this.gcpService
      .updateBillingInfo({
        id: this.billing.id,
        new_budgets: newBudgets,
        updated_alerts: newAlerts,
      })
      .then((resp: any) => {
        if (resp?.message) alert(resp.message);
        else {
          newBudgets = [];
          newAlerts = [];
          resp?.new_budgets ??
            [].forEach((x) => {
              this.budgets.find((b) => b.name == x.name).id = x.id;
            });
          alert('Updated');
        }
      });
  }

  unlinkProject(project) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Do you want to disable billing on this project?',
      null,'confirm',
      async function () {
        that.gcpService
          .unlinkProject({
            id: that.billing.id,
            pId: project.id,
          })
          .then((resp: any) => {
            if (resp?.success) {
              alert('Unlinked');
            }
          });
      },
      async function () {}
    );
  }

  back() {
    this.router.navigate(['gcp/billing-account/list']);
  }
}
