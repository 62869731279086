<div
  *ngIf="message"
  class="modal"
  tabindex="-1"
  role="dialog"
  #msgConfModal
  style="display: block !important"
>
  <div class="modal-dialog modal-md custom-alert" id="conf_dialo_modal">
    <div class="modal-header btn-color pb-0" *ngIf="message?.title">
      <h1 class="modal-title fs-5 product-sans-light">
        {{ message.title }}
      </h1>
    </div>
    <div class="modal-content">
      <div *ngIf="message?.type == 'confirm'" class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center confirm-message">{{ message.text }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="me-2" (click)="message.yesFn()">
                <button class="btn btn-yes">Yes</button>
              </a>
              <a (click)="message.noFn()">
                <button class="btn btn-no">No</button>
              </a>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="message?.type == 'info'" class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center confirm-message">{{ message.text }}</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 text-end">
            <button class="btn btn-yes" (click)="hideModal()">Done</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
