import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerRequirementsListModule } from './customer-requirements-list/customer-requirements-list.module';
import { CustomerRequirementsDetailsModule } from './customer-requirements-list/customer-requirements-details/customer-requirements-details.module';
import { CustomerRequirementsComponent } from './customer-requirements.component';

const routes: Routes = [
  {
    path: '',
    component: CustomerRequirementsComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', loadChildren: () => CustomerRequirementsListModule },
      {
        path: 'details',
        loadChildren: () => CustomerRequirementsDetailsModule,
     },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRequirementsRoutingModule {}
