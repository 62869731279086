import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerModel } from 'src/models/mongo/customer.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { CustomerInfoPopupComponent } from '../customer-info-popup/customer-info-popup.component';
import { RejectionPopupComponent } from '../rejection-popup/rejection-popup.component';
import { ProposalModel } from 'src/models/mongo/proposal.model';
import { DomainModel } from '../../models/mongo/domain.model';
import { OpportunityService } from 'projects/opportunity/src/public-api';

@Component({
  selector: 'proposal-google-doc-card',
  templateUrl: './proposal-google-doc-card.component.html',
  styleUrls: ['./proposal-google-doc-card.component.scss'],
})
export class ProposalGoogleDocCardComponent implements OnInit, AfterViewInit {
  currentUser: UserModel;
  domainSelected;
  customerSelected;
  @Input() cardData: any = {
    module: '',
    customer_domain: null,
    customer_org: null,
    googleDocUrl: '',
    createdUser: {},
    approval_phases: [],
  };
  @Input() proposal: ProposalModel = new ProposalModel();
  @Input() opporunityID: string;
  searchResultCustomers = [];
  @Output() selectCustomerEmitter = new EventEmitter<CustomerModel>();
  @Output() selectDomainEmitter = new EventEmitter<DomainModel>();
  add: boolean = false;
  approve: boolean = false;
  send: boolean = false;
  withoutAction: boolean = false;
  showSendToApprove: boolean = true;
  loginUsersData: any;
  currentCustomer: DomainModel;
  isGWS: boolean;
  @Output() createEmitter = new EventEmitter<any>();
  @Output() approveEmitter = new EventEmitter<any>();
  @Output() editEmitter = new EventEmitter<any>();
  @Output() draftEmitter = new EventEmitter<any>();
  @Output() custEmitter = new EventEmitter<any>();
  @ViewChild('rejectProposalPopupModal')
  rejectProposalPopupModal: RejectionPopupComponent;
  canSendToCustomer: boolean = false;
  @ViewChild('propcustomerInfoPopup')
  propCustomerInfoPopup: CustomerInfoPopupComponent;
  customerInfoAdded: any;
  saveText: string = 'Save & Send for Approval';

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private oppService: OpportunityService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    if (this.currentUser.isAdmin) this.saveText = 'Save';

    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
    var cstjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (!Utilities.isNullOrEmpty(cstjson))
      this.currentCustomer = JSON.parse(cstjson);

    effect(() => {
      this.customerInfoAdded =
        this.oppService.opportunity().customer_info_added;
    });
  }

  ngAfterViewInit(): void {
    if (this.proposal?._id) {
      this.add = false;
      this.showSendToApprove =
        this.proposal.draft &&
        this.currentUser._id == this.proposal.created_by_rfid;
      if (!this.proposal.draft) {
        if (!this.proposal.managerApprovalPart) this.setProposalPhasesData();
        this.approve = this.proposal.canApprove(this.currentUser);
        this.canSendToCustomer = this.proposal.canSendToCustomer(
          this.currentUser
        );
      }
      this.cardData = {
        module: 'proposal',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        googleDocUrl: this.proposal.googleDocUrl,
        domain_rfid: this.cardData.domain_rfid,
        createdUser: {
          name: this.proposal.user_proposal.created_user_name,
          role: this.proposal.user_proposal.created_user_role,
          date: this.proposal.created_at,
          text: 'Created Proposal',
          img: this.proposal.user_proposal.created_user_img,
        },
        approval_phases: this.proposal?.approval_phases,
      };
      this.setProposalPhasesData();
    } else if (this.proposal) {
      this.add = true;
      this.cardData = {
        module: 'proposal',
        customer_domain: this.cardData.customer_domain,
        customer_org: this.cardData.customer_org,
        googleDocUrl: this.proposal.googleDocUrl,
        domain_rfid: this.cardData.domain_rfid,
        start_date: this.cardData.startDate,
      };
    }
    this.cardData.googleDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/document/d/' +
        this.proposal.google_doc_id +
        '/edit?usp=sharing&embed=true'
    );
  }

  ngOnInit(): void {}

  async onSearchCustomerEnter(e) {
    var value = e.term ?? e.target.value;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultCustomers = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.API_URL + '' + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.domains && resp.domains.length > 0) {
            this.searchResultCustomers = resp.domains ?? [];
          }
        });
    }
  }

  async selectCustomer() {
    if (this.domainSelected) {
      var domain = this.domainSelected;
      this.cardData.customer_domain = domain.domain;
      this.cardData.customer_org = domain.organisation_name;
      this.cardData.domain_rfid = domain._id;
      this.selectCustomerEmitter.emit(domain);
      var new_doc_name =
        this.datePipe.transform(new Date(), 'YYYY-MM-dd') +
        ' Proposal - ' +
        domain.organisation_name;

      var params = new HttpParams()
        .set(Unicode.CUSTOMER_NAME, domain.organisation_name)
        .set(Unicode.FILE_ID, this.proposal.google_doc_id)
        .set(Unicode.NEW_FILE_NAME, new_doc_name);
      await this.http
        .get(Endpoint.API_URL + '' + Endpoint.RENAME_DOCUMENT, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.success == true) {
            console.log('updated successfully');
          }
        });
    }
  }

  createProposal(draft: boolean = false) {
    if (Utilities.isNullOrEmpty(this.cardData.domain_rfid)) {
      alert('No Customer Selected');
      return;
    }
    var receivers = [];
    var params = new HttpParams();
    if (!draft) {
      if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
        receivers.push(this.currentUser.manager_email);
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    }
    var proposal = new ProposalModel();
    proposal.created_by_rfid = this.currentUser._id;
    proposal.domain_rfid = this.cardData.domain_rfid;
    proposal.opportunity_rfid = this.opporunityID;
    proposal.is_gcp = !this.isGWS;
    proposal.draft = draft;
    proposal.google_doc_id = this.proposal.google_doc_id;
    proposal.approval_phases = [];
    proposal.start_date = this.cardData.start_date;
    var oppDependencyOn = null;
    if (!Utilities.isNullOrEmpty(this.currentUser.manager_id)) {
      proposal.approval_phases = [
        {
          role: Group.Engineer_Manager,
          name: this.currentUser.manager_name,
          email: this.currentUser.manager_email,
          id: this.currentUser.manager_id,
          img: this.currentUser.imageUrl,
        },
      ];
      oppDependencyOn = Group.ENGINEER;
    } else {
      proposal.approval_phases = [{ role: Group.CUSTOMER }];
      oppDependencyOn = Group.ENGINEER;
    }
    if (!Utilities.isNullOrEmpty(this.opporunityID))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .post(Endpoint.API_URL + Endpoint.ADD_PROPOSAL, proposal, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.proposal_rfid) {
          this.proposal = proposal;
          this.proposal.consumed_time = resp.consumed_time;
          this.proposal.draft = draft;
          this.proposal.user_proposal = {
            created_user_name: this.currentUser.user_name,
          };
          this.proposal.created_at = new Date();
          this.proposal.created_date_string = Utilities.toStringDateFormat(
            this.datePipe,
            new Date(),
            'dd MMM,yyyy-hh:mma'
          );
          this.add = false;
          this.setProposalPhasesData();
          alert(resp.message);
          this.createEmitter.emit(this.proposal);
        } else {
          alert(resp.message);
        }
      });
  }

  approveproposal(reason: string = null) {
    var oppDependencyOn = null;
    var mainGrpName =
      this.currentUser.groups.find((x) => x.main == true)?.name ?? '';
    var approvalPhases = this.proposal.approval_phases ?? [];
    var role = approvalPhases.find(
      (x) =>
        x.role && x.role.toLowerCase().indexOf(mainGrpName.toLowerCase()) >= 0
    );
    if (role && Utilities.isNullOrEmpty(role.date)) {
      role.name = this.currentUser.user_name;
      role.email = this.currentUser.email;
      role.img = this.currentUser.imageUrl;
      role.id = this.currentUser._id;
      role.date = new Date();
      role.approved = Utilities.isNullOrEmpty(reason);
      if (!Utilities.isNullOrEmpty(reason)) role.rejection_reason = reason;
    } else {
      var row: any = {
        role: Group.Engineer_Manager,
        name: this.currentUser.getFullName(),
        email: this.currentUser.email,
        img: this.currentUser.imageUrl,
        id: this.currentUser._id,
        date: new Date(),
        approved: Utilities.isNullOrEmpty(reason),
      };
      if (!Utilities.isNullOrEmpty(reason)) row.rejection_reason = reason;
      approvalPhases.push(row);
    }
    if (Utilities.isNullOrEmpty(reason)) {
      oppDependencyOn = 'PreSales';
      approvalPhases.push({ role: Group.CUSTOMER });
    }
    this.proposal.approval_phases = approvalPhases;
    var receivers = [this.proposal.user_proposal?.created_user_email];
    receivers.push(
      ...this.proposal.approval_phases
        ?.filter(
          (x) =>
            x.email &&
            x.email.toLowerCase() !== this.currentUser.email.toLowerCase()
        )
        .map((x) => x.email)
    );
    this.proposal.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    var body = {
      receivers: JSON.stringify(receivers),
      approval_phases: this.proposal.approval_phases,
      proposal_action: !Utilities.isNullOrEmpty(reason) ? 'reject' : 'approve',
      opportunity_rfid: this.proposal.opportunity_rfid,
    };
    var params = new HttpParams();
    if (!Utilities.isNullOrEmpty(this.proposal.opportunity_rfid))
      params = params.set(Unicode.DEPENDENCY_ON, oppDependencyOn);
    this.http
      .put(
        Endpoint.API_URL + Endpoint.UPDATE_PROPOSAL + this.proposal._id,
        body,
        { params: params }
      )
      .toPromise()
      .then((resp: any) => {
        if (resp?.approval_phases) {
          this.proposal.approval_phases = resp.approval_phases;
          this.setProposalPhasesData();
          alert(resp.message);
          this.approveEmitter.emit(this.proposal);
        } else {
          alert(resp.message);
        }
      });
  }

  rejectproposalPopup() {
    this.rejectProposalPopupModal.show();
  }
  rejectproposal(reason) {
    if (!Utilities.isNullOrEmpty(reason)) {
      this.rejectProposalPopupModal.hide();
      this.approveproposal(reason);
    } else alert('Add Rejection Reason please');
  }

  sendDraftproposalForApproval() {
    var receivers = [];
    if (!Utilities.isNullOrEmpty(this.currentUser.manager_id))
      receivers.push(this.currentUser.manager_email);
    var body = {
      receivers: JSON.stringify(receivers),
      proposal_rfid: this.proposal._id,
    };
    this.http
      .put(Endpoint.API_URL + Endpoint.SEND_PROPOSAL_FOR_APPROVAL, body)
      .toPromise()
      .then((resp: any) => {
        if (resp?.proposal_rfid) {
          this.proposal.draft = false;
          this.setProposalPhasesData();
          alert(resp.message);
          this.draftEmitter.emit(this.proposal);
        } else {
          alert(resp.message);
        }
      });
  }

  sendproposalToCustPopup() {
    this.propCustomerInfoPopup.show();
  }
  sendproposalToCustomer(customerInfo) {
    this.custEmitter.emit(customerInfo);
    this.propCustomerInfoPopup.hide();
  }

  setProposalPhasesData() {
    if (this.proposal?.approval_phases) {
      this.proposal.managerApprovalPart = this.proposal
        .getSortedApprovalPhasesByDateDesc()
        .slice()
        .reverse()
        .find(
          (x) =>
            x.role &&
            x.role.toLowerCase() == Group.Engineer_Manager.toLowerCase()
        );
      this.proposal.sendingToCustomerPart = this.proposal
        .getSortedApprovalPhasesByDateDesc()
        .slice()
        .reverse()
        .find(
          (x) => x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
        );
      if (
        this.proposal.sendingToCustomerPart &&
        !this.proposal.sendingToCustomerPart.date
      )
        this.proposal.sendingToCustomerPart.current = true;
      if (
        this.proposal.managerApprovalPart &&
        !this.proposal.managerApprovalPart.date
      )
        this.proposal.managerApprovalPart.current = true;

      this.cardData.managerApprovalPart = this.proposal.managerApprovalPart;
      this.cardData.sendingToCustomerPart = this.proposal.sendingToCustomerPart;
    }
  }
}
