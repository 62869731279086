import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { POCModel } from 'src/models/mongo/poc.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DomainModel } from '../../../../../src/models/mongo/domain.model';
import { PocService } from '../poc.service';

@Component({
  selector: 'poc-details',
  templateUrl: './poc-details.component.html',
  styleUrls: ['./poc-details.component.scss'],
})
export class PocDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = '';
  currentUser: UserModel;
  poc: POCModel = new POCModel();
  title: string = 'Create Proof Of Concept';
  googleDocData: any;
  currentDomain: DomainModel = new DomainModel();
  loginUsersData: any;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private pocService: PocService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state?.poc) this.poc = state.poc;
      else if (routeParams['Id']) {
        //EMAIL LINK CASE
        await this.http
          .get(Endpoint.API_URL + Endpoint.GET_POC_BY_ID + routeParams['Id'])
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.poc = resp;
            }
          });
      }
      this.poc = Object.assign(new POCModel(), this.poc);
      if (this.poc?._id) {
        this.title = 'Proof Of Concept';
        this.googleDocData = {
          module: 'poc',
          customer_domain: this.poc.domain.domain,
          customer_org: this.poc.domain.organisation_name,
          googleDocUrl: this.poc.googleDocUrl,
          createdUser: {
            name: this.poc.user_poc.created_user_name,
            role: this.poc.user_poc.created_user_role,
            date: this.poc.created_at,
            text: 'Created POC',
            img: this.poc.user_poc.created_user_img,
          },
          approval_phases: this.poc?.approval_phases,
        };
      } else if (this.poc) {
        this.googleDocData = {
          module: 'poc',
          customer_domain: null,
          customer_org: null,
        };
      }
      this.googleDocData.googleDocUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/document/d/' +
            this.poc.google_doc_id +
            '/edit?usp=sharing&embed=true'
        );
    });
  }

  ngOnInit(): void {}

  selectCustomer(dm: DomainModel) {
    this.currentDomain = dm;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.currentDomain)
    );
  }

  createPOC(poc: POCModel) {
    this.router.navigate(['poc']);
  }

  approvePOC(poc: POCModel) {
    this.router.navigate(['poc']);
  }

  sendDraftPOCForApproval(poc: POCModel) {
    this.router.navigate(['poc']);
  }

  async sendPOCToCust(customerInfo) {
    var that = this;
    await this.pocService
      .sendPOCToCustpmer(
        this.poc,
        customerInfo,
        this.currentUser,
        this.poc.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.router.navigate(['poc']);
        }
      });
  }

  cancel() {
    this.router.navigate(['poc']);
  }
}
