import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, signal } from '@angular/core';
import { CustomerRequirementsModel } from 'src/models/mongo/customer_requirements.model';
import { EngineerRecommendationsModel } from 'src/models/mongo/engineer_recommendations.model';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';
import { POCModel } from 'src/models/mongo/poc.model';
import { ProposalModel } from 'src/models/mongo/proposal.model';
import { SOWModel } from 'src/models/mongo/scope_of_work.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Group } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class OpportunityService {
  opportunity = signal<OpportunityModel>(new OpportunityModel());
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private ngZone: NgZone
  ) {}

  async getOpportunityById(id) {
    var resp: OpportunityModel = await this.http
      .get<OpportunityModel>(
        Endpoint.API_URL + Endpoint.GET_OPPORTUNITY_BY_ID + '/' + id
      )
      .toPromise();
    var opp = Object.assign(new OpportunityModel(), resp);
    if (opp.solution_building?.customer_requirements) {
      opp.solution_building.customer_requirements.domain = opp.domain;
      opp.solution_building.customer_requirements = Object.assign(
        new CustomerRequirementsModel(),
        opp.solution_building.customer_requirements
      );
    }
    if (opp.solution_building?.engineering_recommendations) {
      opp.solution_building.engineering_recommendations.domain = opp.domain;
      opp.solution_building.engineering_recommendations = Object.assign(
        new EngineerRecommendationsModel(),
        opp.solution_building.engineering_recommendations
      );
    }
    if (opp.solution_building?.sow) {
      opp.solution_building.sow.domain = opp.domain;
      opp.solution_building.sow = Object.assign(
        new SOWModel(),
        opp.solution_building.sow
      );
    }
    if (opp.poc_phase?.poc) {
      opp.poc_phase.poc.domain = opp.domain;
      opp.poc_phase.poc = Object.assign(new POCModel(), opp.poc_phase.poc);
    }
    if (opp.proposal) {
      opp.proposal.domain = opp.domain;
      opp.proposal = Object.assign(new ProposalModel(), opp.proposal);
    }
    if (!Utilities.isNullOrEmpty(opp?.domain?.contact_person_first_name)) {
      opp.domain.contact_person_name = `${
        opp.domain.contact_person_first_name ?? ''
      } ${opp.domain.contact_person_last_name ?? ''}`;
    }
    this.opportunity.set(opp);
    return opp;
  }

  setSolutionPhaseDetails(opportunity: OpportunityModel) {
    // CUST REQ & ENG RECOm
    if (
      opportunity?.solution_building?.customer_requirements?.approval_phases
    ) {
      opportunity.solution_building.customer_requirements.managerApprovalPart =
        opportunity.solution_building.customer_requirements.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(
              x.role,
              Group.Engineer_Manager
            )
          );
      opportunity.solution_building.customer_requirements.sendingToCustomerPart =
        opportunity.solution_building.customer_requirements.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
          );
    }
    if (
      opportunity?.solution_building?.engineering_recommendations
        ?.approval_phases
    ) {
      opportunity.solution_building.engineering_recommendations.managerApprovalPart =
        opportunity.solution_building.engineering_recommendations.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(
              x.role,
              Group.Engineer_Manager
            )
          );
      opportunity.solution_building.engineering_recommendations.sendingToCustomerPart =
        opportunity.solution_building.engineering_recommendations.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
          );
    }
    //
    if (opportunity.solution_building?.sow?.approval_phases) {
      opportunity.solution_building.sow.managerApprovalPart =
        opportunity.solution_building.sow.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(
              x.role,
              Group.Engineer_Manager
            )
          );
      opportunity.solution_building.sow.saleAmountPart =
        opportunity.solution_building.sow.sale_amount;
      opportunity.solution_building.sow.googleApprovalPart =
        opportunity.solution_building.sow.approval_phases
          .slice()
          .reverse()
          .find((x) => Utilities.areStringsEqualIgnoringCase(x.role, 'google'));
    }
  }

  setPOCPhaseDetails(opportunity: OpportunityModel) {
    if (opportunity?.poc_phase.poc?.approval_phases) {
      opportunity.poc_phase.poc.managerApprovalPart =
        opportunity.poc_phase.poc.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(
              x.role,
              Group.Engineer_Manager
            )
          );
      opportunity.poc_phase.poc.sendingToCustomerPart =
        opportunity.poc_phase.poc.approval_phases
          .slice()
          .reverse()
          .find((x) =>
            Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
          );
    }
  }

  setProposalPhaseDetails(opportunity: OpportunityModel) {
    if (opportunity?.proposal) {
      if (opportunity.proposal.approval_phases) {
        opportunity.proposal.managerApprovalPart =
          opportunity.proposal.approval_phases
            .slice()
            .reverse()
            .find((x) =>
              Utilities.areStringsEqualIgnoringCase(
                x.role,
                Group.Engineer_Manager
              )
            );
        opportunity.proposal.sendingToCustomerPart =
          opportunity.proposal.approval_phases
            .slice()
            .reverse()
            .find((x) =>
              Utilities.areStringsEqualIgnoringCase(x.role, Group.CUSTOMER)
            );
      }
    }
  }

  disablePhases(opportunity: OpportunityModel) {
    var currentUser: UserModel =
      this.localStorageService.getLoggedinUserModel();
    opportunity.disabled = {
      deal: false,
      solution_building:
        !opportunity.deal?.google_approved_deal ||
        (currentUser.isEngineer &&
          !currentUser.is_manager &&
          !opportunity.picked_user_ids?.includes(currentUser._id)),
      poc:
        !opportunity.deal?.google_approved_deal ||
        (currentUser.isEngineer &&
          !currentUser.is_manager &&
          !opportunity.picked_user_ids?.includes(currentUser._id)),
      proposal:
        !opportunity.deal?.google_approved_deal ||
        (currentUser.isEngineer &&
          !currentUser.is_manager &&
          !opportunity.picked_user_ids?.includes(currentUser._id)),
      order:
        !opportunity.proposal?.sendingToCustomerPart?.approval_date ||
        (currentUser.isEngineer &&
          !currentUser.is_manager &&
          !opportunity.picked_user_ids?.includes(currentUser._id)),
    };
  }

  opportunityCreationResponseMapping(
    opportunity: OpportunityModel,
    model: any,
    resp: any
  ) {
    if (resp) {
      var currentUser: UserModel =
        this.localStorageService.getLoggedinUserModel();
      opportunity._id = resp.opportunity_rfid;
      model.id = resp.deal_rfid;
      model.deal_time = resp.consumed_time;
      opportunity.deal.id = resp.deal_rfid;
      model.created_at = new Date();
      model.created_date_string = Utilities.toStringDateFormat(
        this.datePipe,
        new Date(),
        'dd MMM,yyyy-hh:mma'
      );
      model.consumed_time = resp.consumed_time;
      model.user_deal = {
        created_user_name: currentUser.getFullName(),
        manager_user_email: currentUser.manager_email,
        manager_user_name: currentUser.manager_name,
      };
      if (Utilities.isNullOrEmpty(currentUser.manager_email)) {
        model.show_gpm_phase = true;
      } else {
        model.managerApprovalPart = {
          name: currentUser.manager_name,
        };
      }
      opportunity.deal = model;
      if (opportunity.solution_building)
        opportunity.solution_building.is_psf = opportunity.deal?.psf?.available;
      this.ngZone.run(() => {
        opportunity.disabled = {
          deal: false,
          solution_building: false,
          poc: false,
          proposal: false,
          order: false,
        };
      });
      opportunity.dependency_on = Group.GPM;
    }
  }
}
