import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  HostBinding,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserRoleDataModel } from './../../../models/mongo/user.role.data';

import { DatePipe } from '@angular/common';
import { Page } from 'src/models/page';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { Endpoint, Group, OrderStatus, Unicode } from 'src/shared/Endpoint';

import Chart from 'chart.js/auto';
import { CustomerModel } from '../../../models/mongo/customer.model';
import { OrderModel } from '../../../models/mongo/order.model';
import { UserModel } from '../../../models/mongo/user.model';
import { Utilities } from '../../../shared/utilities';
import { LogModel } from '../../../models/mongo/activity_log.model';
import { DomainModel } from 'src/models/mongo/domain.model';

@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  chartLabels = ['Created Orders'];

  statuschartLabels = [
    OrderStatus.Executed,
    OrderStatus.Pending,
    OrderStatus.Rejected,
  ];

  public barchart: any;

  totalCustomers: number = 0;
  totalOrders: number = 0;
  totalUsers: number = 0;
  totalPending: number = 0;
  totalExecuted: number;
  pending_orders: OrderModel[];
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  order_in_activty: OrderModel;
  customer_in_activty: CustomerModel;
  totalRejected: number = 0;
  fromSelectedDate: NgbDateStruct;
  toSelectedDate: NgbDateStruct;
  currentUser: UserModel;
  currentUserRoleData: UserRoleDataModel;
  totalOrdersChart2: string = '';

  pageToken: string = '';
  totalLicenses: number = 0;

  static subdataStatic = [];
  static httpRequestService;
  static page = new Page();
  pageLimit = 5;
  summaryCustomerSearch: string = '';
  orders: OrderModel[];
  users: UserModel[];
  all_users: UserModel[];
  all_customers: DomainModel[];
  fab1: HTMLElement;
  innerFabs: Element;
  logs: LogModel[];
  customers: DomainModel[];
  total_orders: number;
  total_users: number;
  total_customers: number;
  statuschartData: number[] = [];
  countrieschartData: number[] = [];
  countrieschartLabels = [];
  ordersdatechartData = [];
  orderdatechartLabels = [];
  ordercountdate: number[] = [];
  startDate: string;
  endDate: string;
  beginningDate: string;
  status: string;
  onlyExecutedOrders: boolean = false;
  isAdmin: boolean = false;
  fabToggled: boolean = false;
  section1Opened: boolean = false;
  section1Closed: boolean = false;
  section1Mid: boolean = true;
  section2Opened: boolean = false;
  section2Closed: boolean = false;
  section2Mid: boolean = true;
  imgSrcDoubleArrowRight: string = './assets/img/double_arrow_right_icon.svg';
  imgSrcDoubleArrowLeft: string = './assets/img/double_arrow_left_icon.svg';
  imgSrcArrowRight: string = './assets/img/arrow_right_icon.svg';
  imgSrcArrowLeft: string = './assets/img/arrow_left_icon.svg';
  orderPieChart;
  resizeObserver;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router,
    private zone: NgZone,
    private httpRequestService: HttpRequestService
  ) {}

  @HostBinding('class') class =
    'px-2 d-flex flex-column flex-xl-row mobile-section';

  async ngOnInit(): Promise<void> {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    if (this.currentUser)
      this.isAdmin =
        this.currentUser.groups.find(
          (x) => x.name && x.name.toLowerCase() == Group.Admin
        ) != undefined;
    this.getLogs();
    this.getnewOrders();
    this.getnewUsers();
    this.getnewCustomers();
    this.getordersStatus();
    this.getordersCountries();
    this.getPendingOrders();

    this.getordersDate();

    this.fab1 = document.getElementById('fab1');
    this.innerFabs = document.getElementsByClassName('inner-fabs')[0];
    //LISTENER ON SECTIONS RESIZE
    this.resizeObserver = new ResizeObserver((entries) => {
      this.resizeCharts();
    });
    this.resizeObserver.observe(document.getElementById('main_section2'));
  }
  resizeCharts() {
    this.drawpieChart();
    this.drawbarChart();
  }

  async AddNewSale() {
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(new UserModel()) },
    });
  }

  onMouseEnter() {
    if (this.fab1.classList.contains('fab-toggled')) {
      this.innerFabs.classList.remove('show');
      this.fab1.classList.remove('fab-toggled');
      (document.querySelector('#fabIcon').children[0] as HTMLImageElement).src =
        './assets/img/fab-icon.svg';
    } else {
      this.innerFabs.classList.add('show');
      this.fab1.classList.add('fab-toggled');
      if (this.fab1.classList.contains('fab-toggled')) {
        (
          document.querySelector('.fab-toggled').children[0]
            .children[0] as HTMLImageElement
        ).src = './assets/img/fab-blue-icon.svg';
      } else {
        (
          document.querySelector('#fabIcon').children[0] as HTMLImageElement
        ).src = './assets/img/fab-icon.svg';
      }
    }
  }

  async getnewOrders() {
    var params = new HttpParams().set(
      Unicode.VIEW_ALL,
      this.currentUser.canViewAll
    );
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.DASHBOARD_ORDERS_FILE_NAME,
        { params: params }
      )
      .toPromise();
    if (resp?.orders) {
      this.orders = resp.orders;
      this.total_orders = resp.total;
    }
  }

  async getnewUsers() {
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.DASHBOARD_SALES_FILE_NAME,
        {}
      )
      .toPromise();
    if (resp && resp.users) {
      this.all_users = resp.users ?? [];

      this.users = resp.users.map((user) => ({
        _id: user._id,
        user_name: user.user_name,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        country: user.country,
        country_iso2: user.country_iso2,
        phone: user.phone,
        group_ids: user.group_ids,
        group_names: user.group_names,
        permission_ids: user.permission_ids,
        permission_codes: user.permission_codes,
        manager_id: user.manager_id,
        image_url: user.image_url,
      }));
      this.total_users = resp.total_users;
    }
  }
  async getnewCustomers() {
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.DASHBOARD_CUSTOMERS_FILE_NAME,
        {}
      )
      .toPromise();
    if (resp && resp.customers) {
      this.all_customers = resp.customers ?? [];
      this.customers = resp.customers;
      this.total_customers = resp.total_customers;
    }
  }

  async getordersStatus() {
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.DASHBOARD_ORDERS_STATUS_FILE_NAME,
        {}
      )
      .toPromise();
    if (resp && resp.total_orders) {
      this.statuschartData[0] = resp.orders_EXECUTED;
      this.statuschartData[1] = resp.orders_PENDING;
      this.statuschartData[2] = resp.orders_REJECTED;

      this.drawpieChart();
    }
  }

  async getordersCountries() {
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + Endpoint.DASHBOARD_ORDERS_COUNTRIES_FILE_NAME
      )
      .toPromise();
    this.countrieschartLabels = Object.keys(resp);
    this.countrieschartData = Object.values(resp);
    this.drawcountriesChart();
  }

  async getordersDate(
    startDate?: string,
    endDate?: string,
    beginningDate?: string
  ) {
    var prs = new HttpParams();
    if (!Utilities.isNullOrEmpty(beginningDate)) {
      prs = prs.set(Unicode.FROM_DATE, beginningDate);
    }
    if (!Utilities.isNullOrEmpty(startDate)) {
      prs = prs.set(Unicode.FROM_DATE, startDate);
    }
    if (!Utilities.isNullOrEmpty(endDate)) {
      prs = prs.set(Unicode.TO_DATE, endDate);
    }
    var resp = await this.http
      .get<any>(
        Endpoint.API_URL + Endpoint.DASHBOARD_ORDERS_DATE_FILE_NAME,
        {
          params: prs,
        }
      )
      .toPromise();
    if (startDate && endDate) {
      // Generate chart labels based on selected date range
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diff = Math.floor(
        (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
      );
      this.orderdatechartLabels = [];
      this.ordercountdate = [];
      if (this.barchart) this.barchart.destroy();
      for (let i = 0; i <= diff; i++) {
        const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        this.orderdatechartLabels.push(`${year}-${month}-${day}`);
      }
    } else if (beginningDate) {
      const start = new Date(beginningDate);
      const today = new Date();
      const diff = Math.floor(
        (today.getTime() - start.getTime()) / (1000 * 3600 * 24)
      );
      this.orderdatechartLabels = [];
      this.ordercountdate = [];
      if (this.barchart) this.barchart.destroy();
      for (let i = 0; i <= diff; i++) {
        const date = new Date(start.getTime() + i * 24 * 60 * 60 * 1000);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        this.orderdatechartLabels.push(`${year}-${month}-${day}`);
      }
    } else {
      this.orderdatechartLabels = [];
      this.ordercountdate = [];
      // Generate default chart labels for the last 7 days
      const today = new Date();
      const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

      for (let i = 0; i < 7; i++) {
        const date = new Date(sevenDaysAgo.getTime() + i * 24 * 60 * 60 * 1000);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        this.orderdatechartLabels.push(`${year}-${month}-${day}`);
      }
    }
    // Map date labels array to get corresponding order count
    if (this.onlyExecutedOrders == true) {
      this.ordercountdate = this.orderdatechartLabels.map((date) => {
        const countData = resp[date as string];
        if (countData) {
          return countData.executed || 0;
        } else {
          return 0;
        }
      });
    } else {
      this.ordercountdate = this.orderdatechartLabels.map((date) => {
        const countData = resp[date as string];
        if (countData) {
          return countData.all || 0;
        } else {
          return 0;
        }
      });
    }

    // Output order counts array
    this.drawbarChart();
  }

  drawpieChart() {
    this.zone.run(() => {
      const pieChart = document.getElementById('PieChart') as HTMLCanvasElement;
      if (pieChart) {
        var context = pieChart.getContext('2d');
        let chartStatus = Chart.getChart('PieChart');
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        context.beginPath();
        this.orderPieChart = new Chart(pieChart, {
          type: 'doughnut',
          data: {
            labels: this.statuschartLabels,

            datasets: [
              {
                data: this.statuschartData,
                backgroundColor: ['#195CC0', '#003C6B', '#FFAA00'],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: 0,
            },
            plugins: {
              legend: {
                position: 'left',
                labels: {
                  boxWidth: 40,
                  usePointStyle: true,
                  font: {
                    family: 'Product Sans',
                    size: 13,
                  },
                  padding: 30,
                },
              },
            },
          },
        });
        this.orderPieChart.update();
        this.orderPieChart.resize();
      }
    });
  }

  drawcountriesChart() {
    const countriesPie = document.getElementById(
      'countriesPie'
    ) as HTMLCanvasElement;

    function handleHover(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach(
        (color, index, colors) => {
          colors[index] =
            index === item.index || color.length === 9 ? color : color + '4D';
        }
      );
      legend.chart.update();
    }

    function handleLeave(evt, item, legend) {
      legend.chart.data.datasets[0].backgroundColor.forEach(
        (color, index, colors) => {
          colors[index] = color.length === 9 ? color.slice(0, -2) : color;
        }
      );
      legend.chart.update();
    }

    new Chart(countriesPie, {
      type: 'doughnut',
      data: {
        labels: this.countrieschartLabels,
        datasets: [
          {
            data: this.countrieschartData,
            backgroundColor: [
              '#195CC0',
              '#003C6B',
              '#FFAA00',
              '#EA4335',
              '#9747FF',
              '#00FFB2',
              '#EA4335',
              '#C8C353',
              '#4B47FF',
              '#9C27B0',
              '#9E9E9E',
              '#FFEB3B',
              '#000000',
            ],
          },
        ],
      },
      options: {
        layout: {
          padding: 0,
        },
        // legend: {
        //   position: 'bottom',
        //   labels: {
        //     boxWidth: 30,

        //     usePointStyle: true,
        //     fontFamily: 'Product Sans',
        //     fontSize: 13,

        //     padding: screen.width > 450 ? 20 : 8,
        //   },
        // },
        plugins: {
          legend: {
            maxWidth: 250,
            onHover: handleHover,
            onLeave: handleLeave,
            labels: {
              padding: screen.width > 450 ? 30 : 15,
              boxWidth: 30,
              boxHeight: 30,
              color: '#000',
              font: {
                family: 'Product Sans',
                size: 13,
              },
              textAlign: 'left',
              usePointStyle: true,
              pointStyle: 'rectRounded',
            },
          },
        },
      },
    });
  }

  drawbarChart() {
    this.zone.run(() => {
      const barChart = document.getElementById('BarChart') as HTMLCanvasElement;
      if (barChart) {
        var context = barChart.getContext('2d');
        let chartStatus = Chart.getChart('BarChart');
        if (chartStatus != undefined) {
          chartStatus.destroy();
        }
        context.beginPath();

        this.barchart = new Chart(barChart, {
          type: 'bar',
          data: {
            labels: this.orderdatechartLabels,
            datasets: [
              {
                label: 'Number of Orders',
                data: this.ordercountdate,
                backgroundColor: [],

                barThickness: screen.width > 500 ? 20 : 10,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              // stepSize: 4,
              // ticks: {
              //   maxRotation: 0,
              //   minRotation: 0,
              // },
              // yAxes: [
              //   {
              //     ticks: {
              //       beginAtZero: true,
              //       stepSize: 2,
              //     },
              //   },
              // ],
              // xAxes: [
              //   {
              //     ticks: {
              //       autoSkip: true,
              //       maxRotation: 90,
              //       minRotation: this.calculateMinRotation(),
              //       maxTicksLimit: 6,
              //     },
              //   },
              // ],
            },
          },
        });

        let bars = this.barchart.data.datasets[0];
        for (let i = 0; i < bars.data.length; i++) {
          const bar = bars.data[i];
          let barColor = bars.backgroundColor;

          if (bar <= 4) {
            barColor.push('#FFAA00');
          } else if (bar <= 8) {
            barColor.push('#003C6B');
          } else if (bar <= 16) barColor.push('#195CC0');
        }
        this.barchart.update();
      }
    });
  }
  calculateMinRotation(): number {
    const screenThreshold = 768; // Adjust the threshold as needed for different screen sizes
    const smallScreenMinRotation = 45; // Adjust the minRotation for smaller screens
    const largeScreenMinRotation = 0; // Adjust the minRotation for larger screens

    return screen.width < screenThreshold
      ? smallScreenMinRotation
      : largeScreenMinRotation;
  }
  updateChartLabels() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      'yyyy-MM-dd'
    );
    const formattedEndDate = this.datePipe.transform(
      this.endDate,
      'yyyy-MM-dd'
    );

    if (this.startDate && this.endDate) {
      this.getordersDate(formattedStartDate, formattedEndDate);
    }
    // else if(this.startDate){
    //   this.beginningDate=formattedStartDate;
    //   this.getordersDate(formattedStartDate, formattedEndDate,this.beginningDate);
    // }
  }

  getExecutedOrders() {
    const formattedStartDate = this.datePipe.transform(
      this.startDate,
      'yyyy-MM-dd'
    );
    const formattedEndDate = this.datePipe.transform(
      this.endDate,
      'yyyy-MM-dd'
    );
    const formattedBeginningDate = this.datePipe.transform(
      this.beginningDate,
      'yyyy-MM-dd'
    );

    this.getordersDate(
      formattedStartDate,
      formattedEndDate,
      formattedBeginningDate
    );
  }

  async getLogs() {
    const resp = await this.http
      .get<any>(Endpoint.API_URL + Endpoint.ACTIVITY_LOGS_FILE_NAME)
      .toPromise();
    if (resp) {
      this.logs = resp.map((log) => ({
        ...log,
        created_at: this.datePipe.transform(
          log.created_at,
          ' hh:mm a yyyy-MM-dd '
        ),
      }));
    }
  }

  viewCustomer(customer: DomainModel) {
    // const customerString = JSON.stringify(customer);
    // alert(customerString);
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(customer));
    this.router.navigate(['customers/customer-services/subscriptions'], {
      skipLocationChange: true,
    });
  }

  async viewActivityOrder(edit: boolean, id) {
    var prs = new HttpParams().set('order_id', id);
    const resp = await this.http
      .get<any>(Endpoint.API_URL + '' + Endpoint.GET_ORDER_BY_ID + id, {
        params: prs,
      })
      .toPromise();
    if (resp) {
      this.order_in_activty = resp.order;
    }
    this.router.navigate(['orders/order-view'], {
      state: { edit: edit, order_model: JSON.stringify(this.order_in_activty) },
      skipLocationChange: false,
    });
  }

  async viewActivityCustomer(id) {
    var prs = new HttpParams().set('customer_id', id);
    const resp = await this.http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.GET_DOMAIN_BY_ID + '/' + id,
        {
          params: prs,
        }
      )
      .toPromise();
    if (resp) {
      this.customer_in_activty = resp;
      this.localStorage.save(
        Unicode.CURRENT_CUSTOMER,
        JSON.stringify(this.customer_in_activty)
      );
    }
    this.router.navigate(['customers/customer-services/subscriptions'], {
      skipLocationChange: true,
    });
  }

  viewActivityOpportunity(id) {
    this.router.navigate(['opportunities/opportunity-details'], {
      state: { id: id },
    });
  }

  viewOrder(edit: boolean, id) {
    var order = this.orders.find((order) => order._id === id);
    this.router.navigate(['orders/order-view'], {
      state: { edit: edit, order_model: JSON.stringify(order) },
      skipLocationChange: false,
    });
  }
  viewPendingOrder(edit: boolean, id) {
    var order = this.pending_orders.find((x) => x._id == id);
    this.router.navigate(['orders/order-view'], {
      state: { edit: edit, order_model: JSON.stringify(order) },
      skipLocationChange: false,
    });
  }

  async ViewSale(id) {
    console.log(this.all_users);
    var user = this.all_users.find((x) => x._id == id);
    if (!user) user = this.all_users.find((x) => x._id == id);
    this.router.navigate(['/users/user-details'], {
      state: { model: JSON.stringify(user), formdisable: true },
    });
  }

  ViewLogDetails(target_type, target_id) {
    if (target_type == 'user') {
      this.ViewSale(target_id);
    } else if (target_type == 'order') {
      this.viewActivityOrder(true, target_id);
    } else if (target_type == 'customer') {
      this.viewActivityCustomer(target_id);
    } else if (target_type == 'opportunity') {
      this.viewActivityOpportunity(target_id);
    }
  }

  async getPendingOrders() {
    var prs = new HttpParams()
      .set(Unicode.ORDER_STATUS, OrderStatus.Pending)
      .set(Unicode.LIMIT, this.pageSize + '')
      .set(Unicode.PAGE, 0 + '');
    prs = prs.set(Unicode.VIEW_ALL, this.currentUser.canViewAll);

    await this.http
      .get<any>(Endpoint.API_URL + '' + Endpoint.ORDER_LIST, {
        params: prs,
      })
      .toPromise()
      .then((resp) => {
        if (resp) {
          this.pending_orders = resp.orders ?? [];
        }
      });
  }

  section1() {
    var arrow1 = document.getElementById('arrow_section1');
    var arrow2 = document.getElementById('arrow_section2');
    var section1 = document.getElementById('main_section1');
    var section2 = document.getElementById('main_section2');
    if (arrow1.getAttribute('src') == this.imgSrcDoubleArrowLeft) {
      arrow1.setAttribute('src', this.imgSrcDoubleArrowRight);
      arrow2.setAttribute('src', this.imgSrcArrowRight);
      section1.setAttribute('class', 'py-2 px-2 col-2');
      this.section1Closed = true;
      this.section1Mid = false;
      this.section1Opened = false;
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.add('col-12');
      //   });
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.remove('col-6');
      //   });
      //SECTION2
      section2.setAttribute('class', 'py-2 px-2 col-10');
    } else if (arrow1.getAttribute('src') == this.imgSrcDoubleArrowRight) {
      arrow1.setAttribute('src', this.imgSrcArrowLeft);
      arrow2.setAttribute('src', this.imgSrcDoubleArrowLeft);
      section1.setAttribute('class', 'py-2 px-2 col-10');
      this.section1Opened = true;
      this.section1Mid = false;
      this.section1Closed = false;
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.add('col-6');
      //   });
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.remove('col-12');
      //   });
      //SECTION2
      section2.setAttribute('class', 'py-2 px-2 col-2');
    } else if (arrow1.getAttribute('src') == this.imgSrcArrowLeft) {
      arrow1.setAttribute('src', this.imgSrcDoubleArrowLeft);
      arrow2.setAttribute('src', this.imgSrcDoubleArrowRight);
      section1.setAttribute('class', 'py-2 px-2 col-lg-6 col-md-12 col-12');
      this.section1Mid = true;
      this.section1Closed = false;
      this.section1Opened = false;
      //SECTION2
      section2.setAttribute('class', 'py-2 px-2 col-lg-6 col-md-12 col-12');
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.add('col-6');
      //   });
      // document
      //   .querySelectorAll('.div_pending_order_domain')
      //   .forEach(function (divElt) {
      //     divElt.classList.remove('col-12');
      //   });
    }
  }
  section2() {
    var arrow2 = document.getElementById('arrow_section2');
    var arrow1 = document.getElementById('arrow_section1');
    var section1 = document.getElementById('main_section1');
    var section2 = document.getElementById('main_section2');
    if (arrow2.getAttribute('src') == this.imgSrcDoubleArrowRight) {
      arrow1.setAttribute('src', this.imgSrcArrowLeft);
      arrow2.setAttribute('src', this.imgSrcDoubleArrowLeft);
      section2.setAttribute('class', 'py-2 px-2 col-2');
      this.section2Closed = true;
      this.section1Closed = false;
      this.section1Mid = false;
      this.section1Opened = true;
      //SECTION2
      section1.setAttribute('class', 'py-2 px-2 col-10');
    } else if (arrow2.getAttribute('src') == this.imgSrcDoubleArrowLeft) {
      arrow1.setAttribute('src', this.imgSrcDoubleArrowRight);
      arrow2.setAttribute('src', this.imgSrcArrowRight);
      section2.setAttribute('class', 'py-2 px-2 col-10');
      this.section2Opened = true;
      this.section1Closed = true;
      this.section1Mid = false;
      this.section1Opened = false;
      //SECTION2
      section1.setAttribute('class', 'py-2 px-2 col-2');
    } else if (arrow2.getAttribute('src') == this.imgSrcArrowRight) {
      arrow1.setAttribute('src', this.imgSrcDoubleArrowLeft);
      arrow2.setAttribute('src', this.imgSrcDoubleArrowRight);
      section2.setAttribute('class', 'py-2 px-2 col-lg-6 col-md-12 col-12');
      this.section2Mid = true;
      this.section1Closed = false;
      this.section1Mid = true;
      this.section1Opened = false;
      //SECTION2
      section1.setAttribute('class', 'py-2 px-2 col-lg-6 col-md-12 col-12');
    }
  }
}
