<div
  class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5 w-100"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="goBack()"
          alt="back icon" /></a
      >Proof of Concepts
    </h1>
    <div class="d-flex justify-content-end">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-between align-items-start pt-3">
    <div
      class="d-flex flex-wrap align-items-center col-12 col-lg-6 mt-3 mt-sm-0 p-0"
    >
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="fromSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (from)"
          class="form-control rounded-pill py-0"
          id="deal-date-from"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
      <div class="col-6 col-md-6 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <input
          type="date"
          [(ngModel)]="toSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (to)"
          class="form-control rounded-pill py-0"
          id="deal-date-to"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
    </div>
    <div
      class="d-flex flex-wrap align-items-center col-12 col-lg-6 mt-3 mt-sm-0 p-0"
    >
      <div
        class="position-relative col-6 col-sm-6 col-md-4 col-lg-4 me-2 mb-2 mb-md-0 p-0 small-text"
      >
        <img
          class="position-absolute pt-2 px-2 end-0"
          src="./assets/img/search-blue-icon.svg"
          alt="search icon"
        />
        <input
          id="txt-order-global-search"
          type="search"
          [(ngModel)]="customerSearch"
          (keyup.enter)="search()"
          class="form-control rounded-pill h-36"
          placeholder="Search by Domain"
        />
      </div>

      <div class="col-6 col-sm-6 col-md-4 col-lg-4 me-2 mb-2 mb-md-0 p-0">
        <ng-select
          placeholder="Select Engineer"
          bindLabel="sale.first_name"
          appendTo="body"
          [searchable]="true"
          class="col-12"
          [clearable]="true"
          style="padding: 0 !important; border: none !important"
          [(ngModel)]="selectedSales"
          (change)="selectSale()"
        >
          <ng-option [value]="sale._id" *ngFor="let sale of assignsales">
            {{ sale.first_name }} {{ sale.last_name }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-12 col-sm-6 col-md-2 col-lg-2 me-2 mb-2 mb-md-0 p-0">
        <img
          src="./assets/img/refresh-icon.png"
          alt="search icon"
          (click)="refreshPage()"
        />
      </div>
    </div>
  </div>

  <div class="table-responsive fluid-section w-100">
    <table
      class="table table-hover align-middle text-center small-text mt-3 min-h-200"
    >
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-2 col-1 col-md-2 border-top-0"
          >
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Opportunity ID
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            <div class="btn-group me-3" id="dal_type_db">
              <div class="btn-group" ngbDropdown role="group" aria-label="type">
                <button
                  type="button"
                  class="btn border-0 shadow-none"
                  ngbDropdownToggle
                  id="dropdownBasicdeal"
                >
                  Product
                </button>
                <div
                  class="dropdown-menu bg-light"
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasicdeal"
                >
                  <button
                    ngbDropdownItem
                    *ngFor="let type of types"
                    (click)="applyFilter(type)"
                  >
                    {{ type }}
                  </button>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Creation Date
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            PreSales
          </th>
          <th scope="col" class="p-1 p-md-2 col-3 col-md-3 border-top-0">
            Status
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr
          *ngFor="
            let poc of GetData
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  }
          "
        >
          <td class="p-1 p-md-2 col-1 col-md-2 text-start">
            {{ poc.domain?.domain }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ poc.opportunity_id }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            <div *ngIf="poc.is_gcp">GCP</div>
            <div *ngIf="!poc.is_gcp">GWS</div>
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ poc.created_at | date : "dd-MMM-yyyy" }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            <img
              class="px-0 cursor-pointer img-user"
              src="{{ poc.user_poc?.created_user_img }}"
              alt="{{ poc.user_poc?.created_user_name }}"
              title=" {{ poc.user_poc?.created_user_name }}"
            />
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ poc.poc_status }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            <div class="col-md-12 d-flex">
              <div (click)="viewPOC(poc)">
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/edit-icon.svg"
                  alt="edit icon"
                />
              </div>
              <div
                *ngIf="
                   currentUser?._id == poc?.user_poc.created_by_rfid ||
                    currentUser?._id == poc?.user_poc.manager_user_id
                "
                (click)="deletePoc(poc._id)"
              >
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/delete-icon.svg"
                  alt="delete icon"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
