import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  HostBinding,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GCPProjectModel } from 'src/models/mongo/gcp_project.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { LinkProjectPopupComponent } from 'src/shared-components/link-project-popup/link-project-popup.component';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { GcpService } from '../gcp.service';
import { OpportunityService } from 'projects/opportunity/src/public-api';

@Component({
  selector: 'lib-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  @HostBinding('class') class = '';
  allprojects = [];
  projectsData: GCPProjectModel[] = [];
  pageSize: number = 10;
  pagination: number = 1;
  total: number = 200;
  selectedProject = { name: '', number: '', _id: '', id: '' };
  selectedBillingAccount;
  billingAccountList = [];
  @ViewChild('linkProjectModal')
  linkProjectModal: LinkProjectPopupComponent;
  currentUser: UserModel;
  nextPageToken: string = '';
  searchFor: string;
  @ViewChild('editProjectModal') editProjectModal: ModalDirective;

  opportunityService = inject(OpportunityService);

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private gcpService: GcpService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    localStorage.remove(Unicode.CURRENT_CUSTOMER);
    this.opportunityService.opportunity.set(null);
  }

  async ngOnInit(): Promise<void> {
    await this.getProjects();
  }

  async getProjects() {
    var params = new HttpParams()
      .set(Unicode.LIMIT, this.pageSize)
      .set(Unicode.NEXT_TOKEN, this.nextPageToken)
      .set(Unicode.WITH_DETAILS, true);
    if (!Utilities.isNullOrEmpty(this.searchFor))
      params = params.set(Unicode.VALUE, this.searchFor);
    await this.http
      .get(Endpoint.API_URL + Endpoint.LIST_OF_PROJECTS, { params: params })
      .toPromise()
      .then(async (resp: any) => {
        if (resp?.list) {
          this.projectsData = resp.list ?? [];
          this.projectsData.forEach(
            (x) => (x.organizationName = Endpoint.ORGANISATION_NAME)
          );
          this.nextPageToken = resp.nextPageToken;
        }
      });
    // this.allprojects = this.allprojects.sort((a, b) => {
    //   return <any>new Date(b.createTime) - <any>new Date(a.createTime);
    // });
    //this.total = this.allprojects.length;
    // this.getProjectsDetails(0);
  }

  async renderPage(event: number) {
    this.pagination = event;
    // this.getProjectsDetails((this.pagination - 1) * this.pageSize, null);
    await this.getProjects();
  }

  async searchProject(event) {
    var that = this;
    var value = event?.target?.value;
    this.searchFor = value;
    this.nextPageToken = null;
    await this.getProjects();
    //  if (!Utilities.isNullOrEmpty(value)) {
    //   var projects = this.allprojects.filter(
    //     (x) => x.name && x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
    //   );
    //   this.getProjectsDetails(0, projects);
    // } else this.getProjectsDetails(0, null);
  }

  async linkProjectPopup(projectNumber: string, projectName: string) {
    var params = new HttpParams().set('nb', projectNumber);
    await this.http
      .get(Endpoint.API_URL + Endpoint.SELECT_CONSOLE_PROJECT, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp?.rfid) {
          this.selectedProject._id = resp.rfid;
          this.selectedProject.id = resp.id;
          this.selectedProject.number = projectNumber;
          this.selectedProject.name = projectName;
          this.selectedBillingAccount = null;
          this.linkProjectModal.show();
        } else {
          alert(resp.message);
        }
      });
  }

  linkingDone(data) {
    if (data) {
      var project = this.projectsData.find(
        (x) => x.project_id == this.selectedProject.id
      );
      if (project) project.billingAccountName = data.link?.billing_account_name;
      this.linkProjectModal.hide();
      alert('successfully Linked');
    }
  }

  editProjectPopup(projectNumber: string, projectName: string) {
    this.selectedProject.number = projectNumber;
    this.selectedProject.name = projectName;
    this.editProjectModal.show();
  }

  editProject() {
    if (Utilities.isNullOrEmpty(this.selectedProject.name)) {
      alert('Add the Name');
      return;
    }
    this.gcpService
      .editProject(this.selectedProject.number, this.selectedProject.name)
      .then((resp: any) => {
        if (resp?.success) {
          alert(resp.message);
          var project = this.projectsData.find(
            (x) => x.number == this.selectedProject.number
          );
          if (project) project.name = this.selectedProject.name;
          this.editProjectModal.hide();
        }
      });
  }

  deleteProject(projectNumber, projectName: string) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Do you want to delete ' + projectName,
      null,
      'confirm',
      function () {
        that.gcpService.deleteProject(projectNumber).then((resp: any) => {
          if (resp?.success) {
            alert(resp.message);
            var index = that.projectsData.findIndex(
              (x) => x.number == projectNumber
            );
            if (index >= 0) that.projectsData.splice(index, 1);
            that.editProjectModal.hide();
          }
        });
      },
      function () {}
    );
  }
  unlinkProject(
    projectId,
    projectName: string,
    billingAccountId: string,
    billingAccountName: string
  ) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Do you want to unlink ' + projectName + ' and ' + billingAccountName,
      null,
      'confirm',
      function () {
        that.gcpService
          .unlinkProject({ id: billingAccountId, pId: projectId })
          .then((resp: any) => {
            if (resp?.success) {
              alert(resp.message);
              var project = that.projectsData.find(
                (x) => x.project_id == projectId
              );
              if (project) project.billingAccountName = null;
              that.editProjectModal.hide();
            }
          });
      },
      function () {}
    );
  }
}
