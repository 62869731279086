<form
  [formGroup]="budgetForm"
  role="form"
  #formDir="ngForm"
  id="add-budget-form"
  novalidate
  class="max-h-70 w-100 fluid-section d-flex flex-column flex-xl-row justify-content-xl-between"
>
  <div class="col-12 d-grid">
    <input
      formControlName="name"
      class="form-control rounded-pill form-control-sm"
      id="budget_name"
      [ngClass]="{
        'is-invalid': formDir.submitted && name.invalid
      }"
      placeholder="Budget Name"
    />
    <input
      formControlName="amount"
      type="number"
      min="0"
      class="form-control rounded-pill form-control-sm mt-3"
      id="budget_amount"
      [ngClass]="{
        'is-invalid': formDir.submitted && amount.invalid
      }"
      placeholder="Target Amount"
    />
    <ng-select
      bindLabel="period"
      appendTo="body"
      [searchable]="true"
      class="col-12 p-0 flex-fill form-select rounded-pill mt-3"
      [clearable]="true"
      [ngClass]="{
        'is-invalid': formDir.submitted && period.invalid
      }"
      formControlName="period"
      placeholder="Budget Type"
    >
      <ng-option [value]="period" *ngFor="let period of periods">
        {{ period }}
      </ng-option>
    </ng-select>
    <label class="mt-3">Alerts</label>
    <ng-conatiner formArrayName="alerts">
      <div
        class="row smaller-text flex-nowrap mx-0 mt-1"
        *ngFor="let alert of alerts.controls; let index = index"
      >
        <div
          class="form-check p-0 d-flex align-items-center pe-1 col-5"
          [formGroupName]="index"
        >
          <input
            formControlName="percentage"
            (change)="updateAlertPercentage(alert, $event)"
            class="form-control rounded-pill form-control-sm"
            placeholder="Percentage %"
          />
        </div>
        <div
          class="form-check p-0 d-flex align-items-center pe-1 col-5"
          [formGroupName]="index"
        >
          <input
            formControlName="amount"
            (change)="updateAlertAmount(alert, $event)"
            class="form-control rounded-pill form-control-sm"
            placeholder="Amount $"
            type="number"
            min="0"
          />
        </div>
        <img
          *ngIf="index != 0"
          (click)="alerts.removeAt(index)"
          class="px-1 px-md-2 cursor-pointer btn_sku_delete col-1"
          src="./assets/img/delete-icon.svg"
          alt="delete icon"
        />
      </div>
      <button class="btn_add_row" (click)="addAlertRow()">
        <img src="./assets/img/plus-icon.svg" alt="plus-icon" />
        <span>Add Alert</span>
      </button>
    </ng-conatiner>
    <div
      class="d-flex align-items-start justify-content-center justify-content-xl-end mt-5 mt-xl-2 pt-xl-4 pb-2 product-sans-light"
    >
      <input
        type="submit"
        class="btn btn-primary small-text btn-color rounded-pill col-lg-2 col-md-3 col-sm-5 col-xl-2 p-1 p-xl-2 ms-1 ms-xl-4 fs-6 align-self-end"
        value="Save Budget"
        (click)="saveBudget()"
      />
    </div>
  </div>
</form>
