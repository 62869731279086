import { SettingsService } from 'src/servcies/backend-services/settings.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint } from 'src/shared/Endpoint';
import { Router } from '@angular/router';
import { CurrencyModel } from 'src/models/mongo/currency.model';
import { VwExchangeRate } from 'src/models/vw_exchange_rate';

@Component({
  selector: 'settings-exchange-rate',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss'],
})
export class ExchangeRateComponent implements OnInit {
  exchForm: UntypedFormGroup;
  currencies: CurrencyModel[] = [];
  exchanges: any[] = [];
  initialRates: VwExchangeRate[] = [];
  rates: VwExchangeRate[] = [];
  exchangeRateId: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService
  ) {
    this.exchForm = fb.group({
      currencyFromRfid: ['', Validators.required],
      currencyToRfid: ['', Validators.required],
      rate: ['', Validators.required],
      inverseRate: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    var resp: any = await this.http
      .get(Endpoint.API_URL + Endpoint.GET_CURRENCIES)
      .toPromise();
    if (resp) {
      this.currencies = resp.data;
    }

    this.getAllExchanges();
  }

  async getAllExchanges(): Promise<void> {
    await this.settingsService.getAllExchanges().subscribe((response: any) => {
      this.exchanges = response.data;
      console.log('Exchanges:', this.exchanges);
    });
  }

  addNew() {
    var exchangeData = this.exchForm.value;
    this.settingsService
      .createExchangeRate(exchangeData)
      .subscribe((response: any) => {
        console.log('Exchange rate created successfully:', response);
        this.exchanges.push(response.model); // Add the newly created exchange rate to the array
      });
  }

  changeRate(event) {
    var value = event.target.value;
    this.inverseRate.setValue(value);
  }

  deleteConfirmation() {
    this.settingsService
      .deleteExchangeRate(this.exchangeRateId)
      .subscribe((response: any) => {
        console.log('Exchange rate successfully deleted');
      });
  }

  updateExchangeRate(): void {
    const updatedRate = this.exchForm.value;

    this.settingsService
      .updateExchangeRate(this.exchangeRateId, updatedRate)
      .subscribe((response) => {
        console.log('Currency updated successfully:', response);
      });
  }

  getRowIdToDelete(rowId) {
    this.exchangeRateId = rowId;
  }

  edit(rowId) {
    this.exchangeRateId = rowId;
    this.settingsService
      .getExchangeRateById(this.exchangeRateId)
      .subscribe((response: any) => {
        // patch the form
        this.exchForm.patchValue(response.data);
      });
  }

  get currencyFromRfid() {
    return this.exchForm.get('currencyFromRfid');
  }
  get currencyToRfid() {
    return this.exchForm.get('currencyToRfid');
  }
  get rate() {
    return this.exchForm.get('rate');
  }
  get inverseRate() {
    return this.exchForm.get('inverseRate');
  }
}
