import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HealthyRoutingModule } from './healthy-routing.module';
import { HealthyComponent } from './healthy.component';


@NgModule({
  declarations: [HealthyComponent],
  imports: [
    CommonModule,
    HealthyRoutingModule
  ]
})
export class HealthyModule { }
