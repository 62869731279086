import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderRenewalListRoutingModule } from './order-renewal-list-routing.module';
import { OrderRenewalListComponent } from './order-renewal-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [OrderRenewalListComponent],
  imports: [
    CommonModule,
    NgSelectModule,FormsModule,ReactiveFormsModule,
    OrderRenewalListRoutingModule
  ]
})
export class OrderRenewalListModule { }
