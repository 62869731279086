import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sales-sales',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  ngOnInit(): void {}
}
