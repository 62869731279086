import { NgModule } from '@angular/core';
import { CustomerRequirementsComponent } from './customer-requirements.component';
import { CustomerRequirementsRoutingModule } from './customer-requirement.routing.module';

@NgModule({
  declarations: [CustomerRequirementsComponent],
  imports: [
    CustomerRequirementsRoutingModule,
  ],
  exports: [CustomerRequirementsComponent],
})
export class CustomerRequirementsModule {}
