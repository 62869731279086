import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DealFormRoutingModule } from './deal-form-routing.module';
import { DealFormComponent } from './deal-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';



@NgModule({
  declarations: [DealFormComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule


    //!note its routing module not exist
  ],
  exports: [DealFormComponent],
})
export class DealFormModule {}
