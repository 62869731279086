import { NgModule } from '@angular/core';
import { ProposalComponent } from './proposal.component';
import { ProposalRoutingModule } from './proposal.routing.module';

@NgModule({
  declarations: [ProposalComponent],
  imports: [ProposalRoutingModule],
  exports: [ProposalComponent],
})
export class ProposalModule {}
