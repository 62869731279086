import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerRequirementsListComponent } from './customer-requirements-list.component';
import { CustomerRequirementsDetailsModule } from './customer-requirements-details/customer-requirements-details.module';

const routes: Routes = [
  {
    path: '',
    component: CustomerRequirementsListComponent,
    children: [
      {
        path: 'customer-requirements-details',
        loadChildren: () => CustomerRequirementsDetailsModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRequirementsListRoutingModule {}
