import { BaseModel } from '../mongo/base.model';

export class CurrencyModel extends BaseModel{
   
    _id:string;
    name:string;
    code:string;

    constructor(){
        super();
    }
}