import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PocVrmApprovalPopupRoutingModule } from './poc-vrm-approval-popup-routing.module';
import { PocVrmApprovalPopupComponent } from './poc-vrm-approval-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [PocVrmApprovalPopupComponent],
  imports: [CommonModule, ModalModule.forRoot()],
  exports: [PocVrmApprovalPopupComponent],
})
export class PocVrmApprovalPopupModule {}
