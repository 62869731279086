import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddDealGCPComponent } from './add-deal-gcp.component';
import { AddDealGCPRoutingModule } from './add-deal-gcp-routing.module';
import { DealFormComponent } from 'src/shared-components/deal-form/deal-form.component';
import { DealFormModule } from 'src/shared-components/deal-form/deal-form.module';

@NgModule({
  declarations: [AddDealGCPComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    DealFormModule,
    AddDealGCPRoutingModule,
  ],
  exports: [],
})
export class AddDealGCPModule {}
