import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SOWGoogleDocCardComponent } from './sow-google-doc-card.component';
import { CustomerInfoPopupModule } from '../customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from '../rejection-popup/rejection-popup.module';

@NgModule({
  declarations: [SOWGoogleDocCardComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    NgSelectModule,
    FormsModule,
    CustomerInfoPopupModule,
    RejectionPopupModule,
  ],
  exports: [SOWGoogleDocCardComponent],
})
export class SOWGoogleDocCardModule {}
