import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './../navigation/navigation.component';
import { LayoutsComponent } from './layouts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { NewProjectModalModule } from 'src/shared-components/new-project-modal/new-project-modal.module';
import { GwsGcpPopupModule } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [LayoutsComponent, NavigationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    ModalModule.forRoot(),
    NewProjectModalModule,
    LayoutsRoutingModule,
    GwsGcpPopupModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
})
export class LayoutsModule {}
