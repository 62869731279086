import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MspPopupComponent } from './msp-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MspPopupComponent],
  imports: [CommonModule, ModalModule.forRoot(), FormsModule, NgbModule],
  exports: [MspPopupComponent],
})
export class MspPopupModule {}
