import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissionListRoutingModule } from './permission-list-routing.module';
import { PermissionListComponent } from './permission-list.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PermissionListComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    FormsModule,ReactiveFormsModule,
    PermissionListRoutingModule
  ]
})
export class PermissionListModule { }
