import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SowModule } from './sow.module';
import { SowListModule } from './sow-list/sow-list.module';
import { SowDetailsModule } from './sow-details/sow-details.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sow-list',
    pathMatch: 'full',
  },
  { path: 'sow-list', loadChildren: () => SowListModule },
  { path: 'sow-details', loadChildren: () => SowDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SowRoutingModule {}
