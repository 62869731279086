import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lib-orders',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  constructor(http: HttpClient) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {}
}
