import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SkuStatusModalComponent } from './sku-status-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SkuStatusModalComponent],
  imports: [CommonModule, ModalModule.forRoot(), NgbModule],
  exports: [SkuStatusModalComponent],
})
export class SkuStatusModalModule {}
