<div class="container-fluid">
  <div class="bg-white box-shadow mobile-section row rounded-5 p-0 m-0">
    <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 mt-4">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="cancel()"
          alt="back icon" /></a
      >{{ title }}
    </h1>
    <div class="d-flex flex-row ms-3">
      <h2 class="h5 mt-2 mb-3 col-10 p-0">Deal details</h2>
    </div>

    <div *ngIf="addDeal || model.id">
      <app-deal-form
        [addDeal]="addDeal"
        [onlyView]="onlyView"
        [model]="model"
        [isGCP]="false"
      ></app-deal-form>
    </div>
  </div>
</div>
