import { OrderModel } from './../models/mongo/order.model';
import {
  DealType,
  Group,
  OrderAction,
  Permissions,
  PlanValues,
} from './Endpoint';
import { Endpoint, OrderStatus, Status, Unicode } from 'src/shared/Endpoint';
import { Injectable } from '@angular/core';
import { KJUR } from 'jsrsasign';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { RequestResultModel } from 'src/models/request_result.model';
import { DealModel } from 'src/models/mongo/deal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { SubscriptionService } from 'src/servcies/api/subscription.service';
import * as CryptoJS from 'crypto-js';
import { CurrencyModel } from 'src/models/mongo/currency.model';

@Injectable()
export class Utilities {
  static BUDGET_PERIOD = ['MONTH', 'QUARTER', 'YEAR']; //CALENDAR_PERIOD_UNSPECIFIED
  constructor() {}

  public static isNullOrEmpty(value): boolean {
    return (
      !value ||
      value == undefined ||
      value == 'undefined' ||
      value == null ||
      value == 'null' ||
      value == 'Null' ||
      value == 'NULL' ||
      value == ''
    ); // or tolowerCase()
  }

  public static isTrue(value) {
    if (value == undefined) return false;
    else if (typeof value == 'boolean') return value == true;
    else if (typeof value == 'string') return value == 'true';
    else {
      return Number.parseInt(value + '') == 1;
    }
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static getToken(
    http: HttpClient,
    email: string,
    scopes: string,
    privateKey,
    clientEmail
  ) {
    return new Promise((resolve, reject) => {
      var pClaim = { aud: '', scope: '', iss: '', exp: 0, iat: 0, sub: '' };
      pClaim.aud = 'https://www.googleapis.com/oauth2/v3/token';
      pClaim.scope = scopes;
      pClaim.iss = clientEmail;
      pClaim.exp = KJUR.jws.IntDate.get('now + 1hour');
      pClaim.iat = KJUR.jws.IntDate.get('now');
      pClaim.sub = email.trim();

      var sClaim = JSON.stringify(pClaim);
      var sJWS = KJUR.jws.JWS.sign(
        null,
        Endpoint.KJUR_P_HEDAER,
        sClaim,
        privateKey
      );

      var urlEncodedData = '';
      var urlEncodedDataPairs = [];

      urlEncodedDataPairs.push(
        encodeURIComponent('grant_type') +
          '=' +
          encodeURIComponent('urn:ietf:params:oauth:grant-type:jwt-bearer')
      );
      urlEncodedDataPairs.push(
        encodeURIComponent('assertion') + '=' + encodeURIComponent(sJWS)
      );
      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      var headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      http
        .post('https://www.googleapis.com/oauth2/v3/token', urlEncodedData, {
          headers: headers,
        })
        .toPromise()
        .then((resp: any) => {
          resolve(resp); // expires_in
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  public static generatePassword(passwordLength) {
    var numberChars = '0123456789';
    var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return Utilities.shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
    ).join('');
  }

  private static shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  public static setOrder(order: OrderModel, products, plans, localStorag?) {
    order.checked = order.checked == true;
    order.order_status =
      order.order_status == OrderStatus.Draft
        ? OrderStatus.Submitted
        : order.order_status;
    if (!products) {
      products = JSON.parse(localStorag.get(Unicode.GOOGLE_PRODUCTS));
    }
    if (!plans) {
      plans = JSON.parse(localStorag.get(Unicode.PLANS));
    }
    // if (Number.parseInt(order.is_new_customer) == 1) {
    //   order.action = 'New Customer';
    //   let tab = order.new_subscription_values.split(';');
    //   if (tab.length == 3) {
    //     order.product_name =
    //       products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
    //   }
    // } else if (Number.parseInt(order.is_new_customer) == 3) {
    //   order.action = 'Transfer Customer';
    //   let tab = order.new_subscription_values.split(';');
    //   if (tab.length == 3) {
    //     order.product_name =
    //       products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
    //   }
    // } else if (
    //   order.subscription_is_new == 1 &&
    //   order.new_subscription_values
    // ) {
    //   order.action = 'New Subscription';
    //   let tab = order.new_subscription_values.split(';');
    //   if (tab.length == 3) {
    //     order.product_name =
    //       products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
    //   }
    // } else {
    //   if (!Utilities.isNullOrEmpty(order.old_sku_id)) {
    //     order.product_name = products.find(
    //       (x) => x.sku_id == order.old_sku_id
    //     )?.sku_name;
    //   }
    //   let action = '';
    //   order.licences = order.old_licence_number + '';
    //   let oldplan = plans.find((x) => x.id == order.old_plan_id);
    //   order.plan_code = oldplan?.code;
    //   if (Number.parseInt(order.plan_is_updated + '') == 1) {
    //     let newplan = plans.find((x) => x.id == order.new_plan_rfid);
    //     action =
    //       action + 'Plan updated:' + oldplan?.name + '->' + newplan?.name + ';';
    //   }
    //   if (Number.parseInt(order.licences_is_updated + '') == 1) {
    //     let diff = order.new_licence_number - order.old_licence_number;
    //     if (diff <= 0) {
    //       action =
    //         action +
    //         'licenses updated: old licenses:' +
    //         order.old_licence_number +
    //         ';';
    //     } else {
    //       action =
    //         action +
    //         'licenses updated: old licenses:' +
    //         order.old_licence_number +
    //         '   additional licenses:' +
    //         diff +
    //         ';';
    //     }
    //   }
    //   if (Number.parseInt(order.subscription_status_is_updated + '') == 1) {
    //     if (order.new_subscription_status == Status.Active) {
    //       action = action + 'Activate subscription;';
    //     } else {
    //       action = action + 'Suspend subscription;';
    //     }
    //   }
    //   if (
    //     !Utilities.isNullOrEmpty(order.new_sku_id) &&
    //     order.new_sku_id !== '0' &&
    //     order.old_sku_id !== order.new_sku_id
    //   ) {
    //     let tab = order.new_sku_id.split(';');
    //     let old_sku = products.find((x) => x.sku_id == order.old_sku_id);
    //     let txt = '';
    //     if (old_sku && old_sku != null && old_sku != undefined) {
    //       txt = '(old sku=' + old_sku.sku_name + ')';
    //     }
    //     if (tab.length == 2) {
    //       let new_sku_name = products.find((x) => x.sku_id == tab[0])?.sku_name;
    //       if (tab[1] == '1') {
    //         action =
    //           'Subscription updated to ' + new_sku_name + ' ' + txt + ';';
    //       } else {
    //         action =
    //           'Subscription updated to ' + new_sku_name + ' ' + txt + ';';
    //       }
    //     }
    //   }
    //   if (Number.parseInt(order.start_paid_service + '') == 1) {
    //     action = action + 'Start paid Service;';
    //   }
    //   if (Number.parseInt(order.transfer_to_direct + '') == 1) {
    //     action = action + 'Transfer to Google directly;';
    //   }
    //   if (Number.parseInt(order.update_renewel_settings + '') == 1) {
    //     action = action + 'Update Renewal settings;';
    //   }
    //   if (Number.parseInt(order.is_renewal_order + '') == 1) {
    //     action = 'Renewal Order;';
    //   }
    //
    //   order.action = action.slice(0, action.length - 1);
    // }
  }

  public static setOrderEmailSubjectAction(order): string {
    var action = 'New Order- ';
    if (this.isTrue(order.is_renewal_order)) {
      action += 'Renewal';
    } else {
      if (Utilities.isNullOrEmpty(order.subscription_rfid)) {
        if (Number(order.is_new_customer) == 1) {
          action += 'New Customer';
        } else {
          action += 'New Service';
        }
      } else {
        if (
          !Utilities.isNullOrEmpty(order.old_sku_id) &&
          !Utilities.isNullOrEmpty(order.new_sku_id) &&
          order.old_sku_id.toLowerCase() !== order.new_sku_id.toLowerCase()
        ) {
          var tab = order.new_sku_id.split(';');
          if (tab && tab.length == 2 && !Utilities.isNullOrEmpty(tab[1])) {
            if (tab[1] == '1') {
              action += 'Downgrade';
            } else if (tab[1] == '0') {
              action += 'Upgrade';
            }
          } else {
            action += 'Upgrade/Downgrade';
          }
        } else {
          if (this.isTrue(order.subscription_status_is_updated)) {
            if (order.new_subscription_status == Status.Active)
              action += 'Activate subscription';
            else action += 'Suspend subscription';
          } else {
            var licensesUpdated = this.isOrderToUpdateLicensesOnly(
              order.licences_is_updated,
              order.plan_is_updated,
              order.old_sku_id,
              order.new_sku_id,
              order.new_subscription_values
            );
            if (licensesUpdated) {
              action += 'Update licenses';
            } else {
              var planIsUpdated = this.isTrue(order.plan_is_updated);
              if (planIsUpdated) action += 'Update plan';
            }
          }
        }
      }
    }
    return action;
  }

  public static createMimeMessage_(files: any[], from, to, subject, bodyText) {
    var nl = '\n';
    var boundary = '__ctrlq_dot_org__';

    var mimeBody = [
      'MIME-Version: 1.0',
      'To: ' + to,
      'From: ' + from,
      'Subject: ' + subject,

      'Content-Type: multipart/mixed; boundary=' + boundary + nl,
      '--' + boundary,

      'Content-Type: text/html; charset=UTF-8',
      'Content-Transfer-Encoding: base64' + nl,
      bodyText + nl,
    ];

    for (var i = 0; i < files.length; i++) {
      var attachment = [
        '--' + boundary,
        'Content-Type: ' +
          files[i].mimeType +
          '; name="' +
          files[i].fileName +
          '"',
        'Content-Disposition: attachment; filename="' + files[i].fileName + '"',
        'Content-Transfer-Encoding: base64' + nl,
        files[i].bytes,
      ];
      mimeBody.push(attachment.join(nl));
    }

    mimeBody.push('--' + boundary + '--');

    return mimeBody.join(nl);
  }

  public static setDatePickerDate(datePipe, date) {
    if (date) {
      var dt = datePipe.transform(date, 'yyyy-MM-dd');
      var tab = dt.split('-');
      return {
        year: Number(tab[0]),
        month: Number(tab[1]),
        day: Number(tab[2]),
      };
    } else return null;
  }

  public static setDBDate(datePipe, datePickerDate) {
    return datePipe.transform(
      new Date(
        datePickerDate.year,
        datePickerDate.month - 1,
        datePickerDate.day
      ),
      'yyyy-MM-dd hh:mm'
    );
  }

  public static calculateDiffDays(start, end) {
    return Math.floor(
      (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  public static calculateDiffMonths(start, end) {
    return Math.floor(
      (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
        (1000 * 60 * 60 * 24 * 30)
    );
  }

  public static async getCustomerByIdAPI(
    http: HttpClient,
    customerKey: string /** ID OR DOMAIN **/
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var params = new HttpParams().set(Unicode.CUSTOMER_ID, customerKey);
      await http
        .get(Endpoint.API_URL + Endpoint.GET_CUSTOMER_BY_ID_API, {
          params: params,
        })
        .toPromise()
        .then((result: any) => {
          if (
            result?.success == true &&
            !Utilities.isNullOrEmpty(result.customer)
          ) {
            resolve(JSON.parse(result.customer));
          } else {
            reject('Not Found in the Console');
          }
        })
        .catch((error: any) => {
          var msg = error?.error?.message ?? 'Not Found in the Console';
          reject(msg);
        });
    });
  }

  public static orderApprovalPhases(
    localStrg: LocalStorageService,
    order: OrderModel,
    loginUsersData: any
  ) {
    var skipManagerApproval = false; //MAYBE IT BE UPDATED BASED ON SOME CONDITIONS - BUT FOR NOW WE DONT HAVE THIS CASE
    var result = {
      canApprove: false,
      approveText: 'Approve',
      canReject: false,
      status: '',
      receiverEmails: order?.approval_phases?.map((x) => x.email) ?? [],
    };
    result.receiverEmails.push(order.user.created_user_email);
    var user: any = localStrg.getLoggedinUserModel();
    var cost =
      order?.sku && order.sku.length > 0 ? order.sku[0]?.cost_price : null;
    //GPM
    if (user.isGPM) {
      
      //Skip Ahmad Chaaban Approval
      if (order.team?.manager?.user_name === 'Ahmad Chaaban') {
        skipManagerApproval = true;
      }

      if (
        order.order_status?.toLowerCase() ==
          OrderStatus.Approved_by_GPM.toLowerCase() ||
        order.order_status?.toLowerCase() == OrderStatus.Executed.toLowerCase()
      ) {
        result.canApprove = false;
        result.canReject = false;
      } else if (
        order.order_status?.toLowerCase() ==
          OrderStatus.Pending.toLowerCase() &&
        (skipManagerApproval ||
          order.created_by_rfid === order.team?.manager?.id)
      ) {
        result.canApprove = true;
        result.canReject = true;
      } else if (
        order.order_status?.toLowerCase() ==
          OrderStatus.Approved.toLowerCase() &&
        order.approval_phases.find(
          (x) => x.role.toLowerCase() == Group.Sale_Manager
        )?.date
      ) {
        result.canApprove = true;
        result.canReject = true;
      }
      result.receiverEmails.push(loginUsersData?.grp_executors_email);
      result.status = OrderStatus.Approved_by_GPM;
    }
    //EXECUTOR
    else if (user.isFinanceTeam && user.isExecutor == true) {
      if (order.is_gcp) {
        if (
          order.order_status?.toLowerCase() ==
          OrderStatus.Approved_by_GPM.toLowerCase()
        ) {
          result.canApprove = true;
          result.canReject = true;
        }
      } else {
        // skip Ahmad chaaban approval
        if(order.team?.manager?.user_name ==='Ahmad Chaaban'){
          skipManagerApproval = true;
        }
        if (Utilities.isNullOrEmpty(order.order_status)) {
          //FINANCE CREATE THE ORDER
          result.approveText = 'Save';
          result.canApprove = true;
        } else if (
          order.order_status?.toLowerCase() ==
          OrderStatus.Approved_by_GPM.toLowerCase()
        ) {
          result.canApprove = true;
          result.canReject = true;
        } else if (
          order.order_status?.toLowerCase() ==
          OrderStatus.Approved.toLowerCase()
        ) {
          if (Utilities.isOrderToUpdateLicensesOrStatus(order.action, cost)) {
            result.canApprove = true;
            result.canReject = true;
          } else {
            result.canApprove = false;
            result.canReject = false;
          }
        } else if (
          order.order_status?.toLowerCase() == OrderStatus.Pending.toLowerCase()
        ) {
          if (
            Utilities.isOrderToUpdateLicensesOrStatus(order.action, cost) &&
            (skipManagerApproval ||
              order.created_by_rfid === order.team?.manager?.id)
          ) {
            result.canApprove = true;
            result.canReject = true;
          }
        }
      }
      result.status = OrderStatus.Executed;
      result.receiverEmails.push(loginUsersData.after_execution_grp_email);
      result.receiverEmails.push(loginUsersData.gpm_group_email);
      result.approveText = 'Execute';
    } else if (
      order.order_status?.toLowerCase() == OrderStatus.Pending.toLowerCase()
    ) {
      if (
        user?.email?.toLowerCase() ===
          order?.team?.manager?.email?.toLowerCase() &&
        order.user?.created_user_email?.toLowerCase() !==
          user?.email?.toLowerCase()
      ) {
        result.canApprove = true;
        result.canReject = true;
        result.status = OrderStatus.Approved;
        result.receiverEmails.push(loginUsersData.gpm_group_email);
        if (Utilities.isOrderToUpdateLicensesOrStatus(order.action, cost))
          result.receiverEmails.push(loginUsersData?.grp_executors_email);
      }
    } else if (
      order.order_status?.toLowerCase() == OrderStatus.Approved.toLowerCase()
    ) {
      if (
        user?.email?.toLowerCase() ===
        order?.team?.manager?.email?.toLowerCase()
      ) {
        result.canApprove = false;
        result.canReject = false;
      }
    } else if (Utilities.isNullOrEmpty(order.order_status)) {
      result.approveText = 'Save';
      result.canApprove = true;
      result.status = OrderStatus.Pending;
      if (!skipManagerApproval && user.email !== order.team?.manager?.email) {
        result.receiverEmails.push(order.team?.manager?.email);
      } else {
        result.receiverEmails.push(loginUsersData.gpm_group_email);
        if (Utilities.isOrderToUpdateLicensesOrStatus(order.action, cost))
          result.receiverEmails.push(loginUsersData?.grp_executors_email);
      }
    }
    //
    if (
      order.order_status?.toLowerCase() ===
        OrderStatus.Rejected.toLowerCase() &&
      order.rejecter_email === user?.email
    ) {
      result.canApprove = true;
    }
    return result;
  }

  public static dealApprovalPhases(
    localStrg: LocalStorageService,
    order: DealModel,
    loginUsersData
  ) {
    var result = {
      canApprove: false,
      approveText: 'Approve',
      canReject: false,
      status: OrderStatus.Submitted,
      receiverEmails: order?.approval_phases?.map((x) => x.email) ?? [],
    };
    // result.receiverEmails.push(order.created_user_email);
    var user: any = JSON.parse(localStrg.getLoggedinUser());
    //GPM
    if (user.isGPM && user.isGPM == true) {
      result.status = OrderStatus.Approved_by_GPM;
      result.canApprove = true;
      result.canReject = true;
      result.receiverEmails.push(order.user_deal.created_user_email);
      result.receiverEmails.push(order.user_deal.manager_user_email);
      result.receiverEmails.push(loginUsersData?.grp_executors_email);
    } else if (Utilities.isNullOrEmpty(order.deal_status)) {
      result.approveText = 'Save';
      result.canApprove = true;
      result.receiverEmails.push(loginUsersData.gpm_group_email);
      result.receiverEmails.push(order.user_deal.manager_user_email);
    }
    return result;
  }

  public static getSubscriptionByCustomerId(
    subscriptionService: SubscriptionService,
    cusomerId: string
  ) {
    return new Promise(async (resolve, reject) => {
      await subscriptionService
        .requestSubscriptions(cusomerId)
        .then((resp: any) => {
          if (resp && resp.subscriptions) {
            resolve(resp.subscriptions);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject();
        });
    });
  }

  public static async getGPMemails(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<string[]> {
    var gpmEmails = [];
    var gp = localStorage.get(Unicode.GPM_EMAILS);
    if (!Utilities.isNullOrEmpty(gp)) {
      gpmEmails = JSON.parse(gp);
    } else {
      var resp: any = await http
        .get(Endpoint.API_URL + Endpoint.GLOBAL_DATA_LIST)
        .toPromise();
      if (resp && !resp.msg) {
        localStorage.save(Unicode.COUNTRIES, JSON.stringify(resp.countries));
        localStorage.save(
          Unicode.GOOGLE_PRODUCTS,
          JSON.stringify(resp.products)
        );
        localStorage.save(Unicode.PLANS, JSON.stringify(resp.plans));
        localStorage.save(
          Unicode.GROUP_PERMISSIONS,
          JSON.stringify(resp.groups_permissions)
        );
        localStorage.save(
          Unicode.DEFAULT_CURRENCY_ID,
          resp.defaultCurrencyId ?? '1'
        );
        if (Utilities.isNullOrEmpty(resp.gpm_emails))
          localStorage.save(Unicode.GPM_EMAILS, JSON.stringify([]));
        else
          localStorage.save(
            Unicode.GPM_EMAILS,
            JSON.stringify(resp.gpm_emails.map((x) => x.email))
          );
        gp = localStorage.get(Unicode.GPM_EMAILS);
        gpmEmails = JSON.parse(gp);
      }
    }
    return gpmEmails;
  }

  public static async getPrices(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<CurrencyModel[]> {
    var prices = [];
    var pricesJson = localStorage.get(Unicode.PRICES);
    if (!Utilities.isNullOrEmpty(pricesJson)) {
      prices = JSON.parse(pricesJson);
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.API_URL + '' + Endpoint.SKU_PRICE_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.prices) {
            prices = resp.prices;
            localStorage.save(Unicode.PRICES, JSON.stringify(prices));
          }
        });
    }
    return prices;
  }

  public static async globalData(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var data = [];
    var dataJson = localStorage.get(Unicode.GLOBAL_DATA_LIST);
    if (!Utilities.isNullOrEmpty(dataJson)) {
      data = JSON.parse(dataJson);
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.API_URL + '' + Endpoint.GLOBAL_DATA_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp) {
            data = resp;
            localStorage.save(Unicode.GLOBAL_DATA_LIST, JSON.stringify(resp));
          }
        });
    }
    return data;
  }

  public static getGlobalDataValues(data: any[]) {
    var resp = {
      opportunitySources: [],
      opportunityStatus: [],
      engagements: [],
      rejectionReasons: [],
      companySections: [],
      countries: [],
      customerSector: [],
      industries: [],
      custRejectionReasons: [],
      dealTypes: [],
      needs: [],
      timelines: [],
      authorities: [],
      budgets: [],
      decisionPhases: [],
      sources: [],
      opporunityStages: [],
    };
    if (data?.length > 0) {
      var opportunitySources =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_SOURCE.toLowerCase()
        )?.values ?? [];
      var opportunityStatus =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY__OPPORTUNITY_STATUS.toLowerCase()
        )?.values ?? [];
      var engagements =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_ENGAGEMENT.toLowerCase()
        )?.values ?? [];
      var rejectionReasons =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY_REJECTION_REASON.toLowerCase()
        )?.values ?? [];
      var companySections =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() ==
              Unicode.GLOBAL_KEY_COMPANY_SECTIONS.toLowerCase()
        )?.values ?? [];
      var countries =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_COUNTRY.toLowerCase()
        )?.values ?? [];
      var customerSector =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_SECTORS.toLowerCase()
        )?.values ?? [];
      var industries =
        data.find(
          (x) =>
            x.key &&
            x.key.toLowerCase() == Unicode.GLOBAL_KEY_INDISTRY.toLowerCase()
        )?.values ?? [];
      var custRejectionReasons =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_CUSTOMER_DELETION_REASONS
            )
        )?.values ?? [];
      var dealTypes =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_DEAL_TYPE
            )
        )?.values ?? [];
      var needs =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_NEED
            )
        )?.values ?? [];
      var timelines =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_TIMELINE
            )
        )?.values ?? [];
      var authorities =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_AUTHORITY
            )
        )?.values ?? [];
      var budgets =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_BUDGET
            )
        )?.values ?? [];
      var decisionPhases =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_DESCISION
            )
        )?.values ?? [];
      var sources =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_SOURCE
            )
        )?.values ?? [];
      var opporunityStages =
        data.find(
          (x) =>
            x.key &&
            Utilities.areStringsEqualIgnoringCase(
              x.key,
              Unicode.GLOBAL_KEY_OPPORTUNITY_STAGE
            )
        )?.values ?? [];
      resp.opportunitySources = opportunitySources;
      resp.opportunityStatus = opportunityStatus;
      resp.engagements = engagements;
      resp.rejectionReasons = rejectionReasons;
      resp.companySections = companySections;
      resp.countries = countries;
      resp.customerSector = customerSector;
      resp.industries = industries;
      resp.custRejectionReasons = custRejectionReasons;
      resp.dealTypes = dealTypes;
      resp.needs = needs;
      resp.timelines = timelines;
      resp.authorities = authorities;
      resp.budgets = budgets;
      resp.decisionPhases = decisionPhases;
      resp.opporunityStages = opporunityStages;
      resp.sources = sources;
    }
    return resp;
  }

  public static async googleProductList(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var data = [];
    var dataJson = localStorage.get(Unicode.GOOGLE_PRODUCTS);
    if (!Utilities.isNullOrEmpty(dataJson) && JSON.parse(dataJson).length > 0) {
      data = JSON.parse(dataJson);
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.API_URL + '' + Endpoint.GOOGLE_PRODUCTS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp) {
            data = resp;
            localStorage.save(Unicode.GOOGLE_PRODUCTS, JSON.stringify(resp));
          }
        });
    }
    return data;
  }

  public static async planList(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var data = [];
    var dataJson = localStorage.get(Unicode.PLANS);
    if (!Utilities.isNullOrEmpty(dataJson)) {
      data = JSON.parse(dataJson);
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.API_URL + '' + Endpoint.PLAN_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp) {
            data = resp;
            localStorage.save(Unicode.PLANS, JSON.stringify(resp));
          }
        });
    }
    return data;
  }

  public static groupByCustomer(array) {
    return array.reduce((r, a) => {
      r[a.customerDomain] = r[a.customerDomain] || [];
      r[a.customerDomain].push(a);
      return r;
    }, Object.create(null));
  }

  public static getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public static executorsGrp(localStorage: LocalStorageService): string[] {
    //notused
    var gpPerms: any = localStorage.get(Unicode.GROUP_PERMISSIONS);
    gpPerms = gpPerms ? JSON.parse(gpPerms) : [];
    var result = [];
    result = gpPerms
      .filter(
        (x) =>
          !Utilities.isNullOrEmpty(x.permission_code) &&
          x.permission_code.toLowerCase().trim() ==
            Permissions.FINAL_EXECUTION.toLowerCase().trim()
      )
      .map((y) => y.user_email);
    result.push(
      ...gpPerms
        .filter(
          (x) =>
            !Utilities.isNullOrEmpty(x.permission_code) &&
            x.permission_code.toLowerCase().trim() ==
              Permissions.FINAL_EXECUTION.toLowerCase().trim()
        )
        .map((y) => y.group_email)
    );
    return result.filter((x) => !this.isNullOrEmpty(x));
  }

  public static isOrderToUpdateLicensesOnly(
    licences_is_updated,
    plan_is_updated,
    old_sku_id,
    new_sku_id,
    new_subscription_values
  ): boolean {
    return (
      this.isTrue(licences_is_updated) &&
      !this.isTrue(plan_is_updated) &&
      old_sku_id + '' == new_sku_id &&
      new_subscription_values.indexOf(';') < 0
    );
  }
  public static isOrderToUpdateLicensesOrStatus(
    //NOT NEED GPM APPROVAL
    action: string,
    cost = null
  ): boolean {
    if (
      action?.toLowerCase() == OrderAction.ACTIVATE.toLowerCase() ||
      action?.toLowerCase() == OrderAction.SUSPEND.toLowerCase()
    ) {
      return true;
    } else if (
      action?.toLowerCase() == OrderAction.UPDATE_LICENSE.toLowerCase()
    ) {
      if (Utilities.isNullOrEmpty(cost)) return true;
      else {
        try {
          var cst = Number.parseFloat(cost);
          if (cst > 10000) return false;
          else return true;
        } catch (e) {
          return true;
        }
      }
    } else return false;
  }
  public static isOrderToUpdateStatus(
    //NOT NEED GPM APPROVAL
    action: string
  ): boolean {
    return (
      action?.toLowerCase() == OrderAction.ACTIVATE.toLowerCase() ||
      action?.toLowerCase() == OrderAction.SUSPEND.toLowerCase()
    );
  }

  public static async getPermissions(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var permissions = [];
    if (localStorage.get(Unicode.PERMISSIONS)) {
      permissions = JSON.parse(localStorage.get(Unicode.PERMISSIONS)) ?? [];
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.API_URL + '' + Endpoint.PERMS_FILE_NAME, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.permissions) {
            permissions = resp.permissions ?? [];
            localStorage.save(Unicode.PERMISSIONS, JSON.stringify(permissions));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return permissions;
  }

  public static async updateUsersGroupsPermissions(
    http: HttpClient,
    localStorage: LocalStorageService
  ) {
    var resp: any = await http
      .get(Endpoint.API_URL + Endpoint.GROUPS_PERMISSIONS_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg && resp.groups_permissions) {
      localStorage.save(
        Unicode.GROUP_PERMISSIONS,
        JSON.stringify(resp.groups_permissions)
      );
    }
  }

  public static getLicensesByPlan(subsc): number {
    if (subsc.plan?.planName?.toLowerCase().trim().indexOf('annual') >= 0)
      return subsc.seats.numberOfSeats;
    else return subsc.seats.maximumNumberOfSeats;
  }

  public static updateSkuDates(apiResponse: RequestResultModel, sku) {
    if (apiResponse.data && apiResponse.data.trialSettings?.trialEndTime) {
      sku.trial_expiry_date = apiResponse.data.trialSettings.trialEndTime;
    }
    if (
      apiResponse.data &&
      apiResponse.data.plan &&
      apiResponse.data.plan.commitmentInterval?.endTime
    ) {
      sku.end_api_date = apiResponse.data.plan.commitmentInterval.endTime;
    }
  }

  static fromMongoDateToStringDateInput(
    datePipe,
    date,
    format: string = 'yyyy-MM-dd'
  ) {
    if (date && date.$date) {
      try {
        return datePipe.transform(
          new Date(Number.parseInt(date.$date?.$numberLong)),
          format
        );
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  static toStringDateFormat(datePipe, date: any, format: string) {
    var stringDate = date;
    if (date && date.$date?.$numberLong) {
      var d = new Date(Number.parseInt(date.$date?.$numberLong));

      var userTimezoneOffset = d.getTimezoneOffset() * 60000; //todo to be checked
      d = new Date(d.getTime() + userTimezoneOffset);

      stringDate = datePipe.transform(d, format);
    } else if (date && typeof date !== 'string')
      stringDate = datePipe.transform(date, format);
    return stringDate;
  }

  static async checkIfCustomerAlreadyExists(
    http,
    httpRequestService,
    domain: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        await Utilities.getCustomerByIdAPI(http, domain)
          .then((respAPI) => {
            if (respAPI && !respAPI.postalAddress && !respAPI.phoneNumber)
              resolve(true);
            else resolve(false);
          })
          .catch((err) => {
            return resolve(false);
          });
      } catch (err) {
        return resolve(false);
      }
    });
  }

  static displayCheck(value): string {
    if (value == undefined) return '';
    else return value;
  }
  static setReceivers(currentUser: UserModel, loginUsersData): string[] {
    var receivers = [];
    if (currentUser.isExecutor) {
      receivers.push(loginUsersData.gpm_group_email);
      receivers.push(loginUsersData.after_execution_grp_email);
    } else if (currentUser.manager_id)
      receivers.push(currentUser.manager_email);
    else receivers.push(loginUsersData.gpm_group_email);
    return receivers;
  }

  public static getSubscriptionsByCustomer(
    http: HttpClient,
    customerKey: string,
    transferToken: string,
    withoutLoader: string = '0'
  ) {
    return new Promise(async (resolve, reject) => {
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_API_ID, customerKey)
        .set(Unicode.WITHOUT_LOADER, withoutLoader);
      if (!Utilities.isNullOrEmpty(transferToken))
        params = params.set(Unicode.TRANSFER_TOKEN, transferToken);
      await http
        .get(
          Endpoint.API_URL +
            '' +
            Endpoint.GET_SUBSCRIPTIONS_BY_CUSTOMER_ID_API,
          { params: params }
        )
        .toPromise()
        .then((result: any) => {
          if (
            result &&
            result.success == true &&
            !Utilities.isNullOrEmpty(result.services)
          ) {
            var services = JSON.parse(result.services);
            if (services) resolve(services);
            else resolve(null);
          } else {
            resolve(null);
          }
        })
        .catch((error: any) => {
          resolve(null);
        });
    });
  }

  public static async usersByGroup(
    groupName: string,
    http: HttpClient,
    localStorage,
    onlyManager: boolean = false
  ): Promise<any[]> {
    //todo save b storage
    var data = [];
    var params = new HttpParams()
      .set(Unicode.GROUP, groupName)
      .set(Unicode.ONLY_MANAGER, onlyManager);
    await http
      .get(Endpoint.API_URL + '' + Endpoint.GET_USERS_BY_GROUP, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp) {
          data = resp;
          localStorage.save(Unicode.SALES_USERS_LIST, JSON.stringify(resp));
        }
      });
    return data;
  }

  public static async offerList(
    http: HttpClient,
    localStorage
  ): Promise<any[]> {
    var data = [];
    var offerJson = localStorage.get(Unicode.GCP_OFFERS);
    if (!this.isNullOrEmpty(offerJson) && JSON.parse(offerJson).length > 0) {
      data = JSON.parse(offerJson);
    } else {
      await http
        .get(Endpoint.API_URL + '' + Endpoint.GET_OFFERS)
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.offers) {
            var offers = resp.offers;
            localStorage.save(Unicode.GCP_OFFERS, JSON.stringify(offers));
            data = offers;
          }
        });
    }
    return data;
  }

  public static getLoginUserInfo(localStorage: LocalStorageService) {
    var usersInfojson = localStorage.get(Unicode.USERS_INFO);
    var usersInfo = usersInfojson ? JSON.parse(usersInfojson) : {};
    return usersInfo;
  }

  public static async listOfFolders(
    http: HttpClient,
    is_org: boolean,
    localStorage,
    id: string = '00'
  ): Promise<any[]> {
    var data = [];
    var json = localStorage.get(Unicode.FOLDERS);
    if (!this.isNullOrEmpty(json) && JSON.parse(json).length > 0) {
      data = JSON.parse(json);
    } else {
      localStorage.save(Unicode.FOLDERS, JSON.stringify(['temp']));
      var params = new HttpParams()
        .set(Unicode.IS_ORG, is_org)
        .set(Unicode.ID, id);
      await http
        .get(Endpoint.API_URL + Endpoint.GET_FOLDERS, { params: params })
        .toPromise()
        .then(async (resp: any) => {
          if (resp?.list) {
            data = JSON.parse(resp.list).folders;
            localStorage.save(Unicode.FOLDERS, JSON.stringify(data));
          }
        })
        .catch((e) => {
          data = [];
          localStorage.save(Unicode.FOLDERS, JSON.stringify(data));
        });
    }
    return data;
  }

  public static decryptTest() {
    var key = '';
    var z = 'value';
    var encrypted: any = atob(z);
    encrypted = JSON.parse(encrypted);
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const value = encrypted.value;
    var key = CryptoJS.enc.Base64.parse(key);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });
    console.log(decrypted.toString(CryptoJS.enc.Utf8));
  }

  public static setOrderCostMargin(action: string) {
    var margin = 0.8;
    if (action) {
      switch (action.toLowerCase()) {
        case OrderAction.NEW_CUSTOMER.toLowerCase():
          margin = 0.8;
          break;
        case OrderAction.NEW_SERVICES.toLowerCase():
          margin = 0.8;
          break;
        case OrderAction.UPGRADE.toLowerCase():
          margin = 0.8;
          break;
        case OrderAction.UPDATE_PLAN.toLowerCase():
          margin = 0.88;
          break;
        case OrderAction.RENEW.toLowerCase():
          margin = 0.88;
          break;
        case OrderAction.UPDATE_LICENSE.toLowerCase():
          margin = 0.88;
          break;
        case OrderAction.TRANSFER.toLowerCase():
          margin = 0.88;
          break;
      }
    }
    return margin;
  }

  public static setDealCostMargin(dealType: string) {
    var margin = 0.8;
    if (dealType) {
      switch (dealType.toLowerCase()) {
        case DealType.New.toLowerCase():
          margin = 0.8;
          break;
        case DealType.New_SERVICE.toLowerCase():
          margin = 0.8;
          break;
        case DealType.UPGRADE.toLowerCase():
          margin = 0.8;
          break;
        case DealType.TRANSFER.toLowerCase():
          margin = 0.88;
          break;
        case DealType.TRANSFER_UPGRADE.toLowerCase():
          margin = 0.88;
          break;
      }
    }
    return margin;
  }

  public static setOrderActionFromDeal(dealType: string) {
    var action;
    if (dealType) {
      switch (dealType.toLowerCase()) {
        case DealType.New.toLowerCase():
          action = OrderAction.NEW_CUSTOMER;
          break;
        case DealType.New_SERVICE.toLowerCase():
          action = OrderAction.NEW_SERVICES;
          break;
        case DealType.UPGRADE.toLowerCase():
          action = OrderAction.UPGRADE;
          break;
        case DealType.TRANSFER.toLowerCase():
          action = OrderAction.TRANSFER;
          break;
        case DealType.TRANSFER_UPGRADE.toLowerCase():
          action = OrderAction.TRANSFER;
          break;
      }
    }
    return action;
  }

  public static areStringsEqualIgnoringCase(
    str1: string,
    str2: string
  ): boolean {
    return str1?.toLowerCase() === str2?.toLowerCase();
  }

  public static setSKUFields(element, plans, googleProducts) {
    delete element.expanded;
    if (element.plan_id) {
      var plan = plans.find((x) => x._id && x._id == element.plan_id);
      element.plan_code = plan?.code;
      element.plan_name = plan?.name;
    }
    var is_commitment =
      plan?.code &&
      plan?.code.toLowerCase().indexOf(PlanValues.ANNUAL.toLowerCase()) >= 0;
    element.is_commitment = is_commitment;
    var newSku = googleProducts.find(
      (x) => x.sku_id.toLowerCase() == element.sku_id?.toLowerCase()
    );
    element.sku_name = newSku?.sku_name;
    if (newSku?.gcp_sku_id) element.gcp_sku_id = newSku.gcp_sku_id;
  }

  public static setOrderAction(model: OrderModel) {
    var orderAction = '';
    if (model.action) {
      orderAction = model.action.split('_').join(' ') + ':';
      if (model.sku && model.sku.length == 1) {
        var sku1 = model.sku[0];
        if (!Utilities.isNullOrEmpty(sku1.old_sku_name))
          orderAction += ' old sku=' + sku1.old_sku_name + ';';
        if (!Utilities.isNullOrEmpty(sku1.old_plan_name))
          orderAction += ' old plan=' + sku1.old_plan_name + ';';
        if (sku1.old_license != undefined)
          orderAction += ' old license=' + sku1.old_license + ';';
        if (
          model.action?.toLowerCase() == OrderAction.UPDATE_RNEWAL_SETTNGS &&
          !Utilities.isNullOrEmpty(sku1.renewel_setting_type)
        )
          orderAction += ' to ' + sku1.renewel_setting_type;
      }
      if (orderAction.indexOf(':') === orderAction.length - 1)
        orderAction = orderAction.slice(0, orderAction.length - 1);
    }
    return orderAction;
  }
}
