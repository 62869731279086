import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddDealRoutingModule } from './add-deal-routing.module';
import { AddDealComponent } from './add-deal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { DealFormModule } from 'src/shared-components/deal-form/deal-form.module';

@NgModule({
  declarations: [AddDealComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    DealFormModule,
    AddDealRoutingModule,
  ],
})
export class AddDealModule {}
