import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerRequirementsDetailsComponent } from './customer-requirements-details.component';
import { CustomerRequirementsGoogleDocCardModule } from 'src/shared-components/customer-requirements-google-doc-card/customer-requirements-google-doc-card.module';
import { CustomerRequirementsDetailsRoutingModule } from './customer-requirements-details-routing.module';

@NgModule({
  declarations: [CustomerRequirementsDetailsComponent],
  imports: [
    CommonModule,
    CustomerRequirementsDetailsRoutingModule,
    CustomerRequirementsGoogleDocCardModule,
  ],
})
export class CustomerRequirementsDetailsModule {}
