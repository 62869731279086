import { CustomerRequirementsGoogleDocCardModule } from './../../../../../../src/shared-components/customer-requirements-google-doc-card/customer-requirements-google-doc-card.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunitySolutionBuildingPhaseComponent } from './opportunity-solution-building-phase.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomerInfoPopupModule } from 'src/shared-components/customer-info-popup/customer-info-popup.module';
import { RejectionPopupModule } from 'src/shared-components/rejection-popup/rejection-popup.module';
import { SOWGoogleDocCardModule } from 'src/shared-components/sow-google-doc-card/sow-google-doc-card.module';
import { FormsModule } from '@angular/forms';
import { EngineeringRecommendationsGoogleDocCardModule } from 'src/shared-components/engineering_recommendations-google-doc-card/engineering_recommendations-google-doc-card.module';
import {OpportunityConsumedTimePipe} from '../../../../../../src/app/pipes/opportunity-consumed-time.pipe';

@NgModule({
  declarations: [OpportunitySolutionBuildingPhaseComponent,OpportunityConsumedTimePipe],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ModalModule.forRoot(),
    CustomerInfoPopupModule,
    RejectionPopupModule,
    CustomerRequirementsGoogleDocCardModule,
    EngineeringRecommendationsGoogleDocCardModule,
    SOWGoogleDocCardModule,
  ],
  exports: [OpportunitySolutionBuildingPhaseComponent, OpportunityConsumedTimePipe],
})
export class OpportunitySolutionBuildingPhaseModule {}
