<div
  class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5 w-100"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Billings
    </h1>
  </div>
  <div class="d-flex justify-content-between align-items-start pt-3">
    <div
      class="position-relative col-4 small-text p-0"
    >
      <img
        class="position-absolute pt-2 px-2 end-0"
        src="./assets/img/search-blue-icon.svg"
        alt="search icon"
      />
      <input
        id="txt-order-global-search"
        type="search"
        [(ngModel)]="searchValue"
        (keyup.enter)="search()"
        class="form-control rounded-pill h-36"
        placeholder="Search .."
      />
    </div>
  </div>

  <!-- <div
    class="table-responsive fluid-section w-100"
    (scrolled)="onScrollLoadData($event)"
    infiniteScroll
    [immediateCheck]="true"
    style="height: 70%; overflow-y: auto"
  > -->
  <div class="table-responsive fluid-section w-100">
    <table class="table table-hover align-middle text-center small-text mt-3">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Name
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">ID</th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Parent
          </th>
          <!-- <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 position-relative border-top-0"
          >
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th> -->
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr *ngFor="let billing of billingAccounts">
          <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
            {{ billing.name }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ billing.id }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ billing.parent }}
          </td>
          <!-- <td
            class="p-1 p-md-2 text-end text-nowrap col-1 col-md-3 col-lg-2 text-md-end p-0 p-md-2"
          >
            <img
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/view-icon.svg"
              alt="view icon"
              (click)="viewBilling(billing)"
            />
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
