import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  Group,
  OrderAction,
  OrderStatus,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { Location } from '@angular/common';
import { DomainModel } from 'src/models/mongo/domain.model';
import { TeamModel } from 'src/models/mongo/team';

@Component({
  selector: 'app-add-order-form',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-order-form.component.html',
  styleUrls: ['./add-order-form.component.scss'],
})
export class AddOrderFormComponent implements OnInit {
  @HostBinding('class') class = 'p-0';
  orderForm: UntypedFormGroup;
  @Input() model: OrderModel = new OrderModel();
  opportunitySources = [];
  opportunityStatus = [];
  globalData = [];
  googleProducts = [];
  plans = [];
  currentUser: UserModel;
  orderPhasesInfo;
  @Input() fromCustomer: boolean = true;
  searchResultDomains = [];
  prices = [];
  saveText = 'Save & Send for approval';
  @Input() isGWS: boolean;
  engagements = [];
  @Input() dealSKUs;
  @Input() dealType: string;
  //ATCIONS
  @Input() actions = {
    isActionOrder: false,
    toUpdatePlan: false,
    toUprade: false,
    toDowngrade: false,
    toRenew: false,
  };
  totalNbrOfUsers = 0;
  companySections = [];
  usersInfo: any;
  teams: TeamModel[] = [];
  @Output() addOrderEmitter = new EventEmitter<any>();
  @Input() opporunityID: string;
  currentDomain: DomainModel;

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private location: Location
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    var custjson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    this.currentDomain = custjson ? JSON.parse(custjson) : null;
    if (this.currentDomain) {
      this.model.customer_domain = this.currentDomain.domain;
      this.model.customer_api_id = this.currentDomain.customer_api_id;
      this.model.is_customer_transfer = this.currentDomain.is_transfer;
      this.model.customer_organisation = this.currentDomain.organisation_name;
    }
    this.orderForm = fb.group({
      customer_domain: [this.currentDomain?.domain ?? '', Validators.required],
      customer_organisation: [
        this.currentDomain?.organisation_name ?? '',
        Validators.required,
      ],
      description: [null],
      po_id: [null, Validators.required],
      order_sale_invoice_nbr: [null, Validators.required],
      opportunity_source: [null, Validators.required],
      opportunity_nbr: [{ value: null, disabled: !this.currentUser.isGPM }],
      opportunity_status: [null, Validators.required],
      google_rep: [''],
      company_section: [null, Validators.required],
      team: [null, Validators.required],
      project_number: ['', Validators.required],
      project_name: ['', Validators.required],
      billing_name: ['', Validators.required],
      engagement: ['', Validators.required],
      sku: this.fb.array([]),
      support: new FormGroup({
        available: new FormControl(false, [Validators.required]),
        price: new FormControl(0, []),
        start_date: new FormControl('', []),
        end_date: new FormControl('', []),
      }),
    });
  }

  async ngOnInit(): Promise<void> {
    var that = this;
    if (this.currentUser.isExecutor) this.saveText = 'Execute';
    this.usersInfo = this.localStorage.getUserInfo();
    this.orderPhasesInfo = Utilities.orderApprovalPhases(
      this.localStorage,
      this.model,
      this.usersInfo
    );
    this.teams = this.usersInfo?.teams ?? [];
    //DROPDOWNS DATA
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.globalData = await Utilities.globalData(this.http, this.localStorage);
    var gData = Utilities.getGlobalDataValues(this.globalData);
    if (gData) {
      this.opportunitySources = gData.opportunitySources;
      this.opportunityStatus = gData.opportunityStatus;
      this.engagements = gData.engagements;
      this.companySections = gData.companySections;
    }
    //
    this.orderForm.patchValue(this.model);
    this.orderForm.updateValueAndValidity();
    //
    if (this.model && Utilities.isNullOrEmpty(this.currentDomain?.domain)) {
      this.currentDomain = new DomainModel();
      this.currentDomain._id = this.model.domain_rfid;
      this.currentDomain.domain = this.model.customer_domain;
      this.currentDomain.organisation_name = this.model.customer_organisation;
      this.currentDomain.customer_api_id = this.model.customer_api_id;
      this.currentDomain.is_transfer = this.model.is_customer_transfer;
    }
    //
    if (this.isGWS) {
      this.orderForm.removeControl('project_number');
      this.orderForm.removeControl('project_name');
      this.orderForm.removeControl('billing_name');
      this.orderForm.removeControl('engagement');

      this.googleProducts = this.googleProducts.filter(
        (x) => x.gcp == undefined || x.gcp == false
      );
      if (this.actions.isActionOrder) {
        var subscription_api_id =
          this.model.paramsData.subscription_api_id_action;
        if (this.actions.toUpdatePlan == true) {
          var skuId = this.model.paramsData.skuId;
          var planName = this.model.paramsData.planName;
          var planId = this.model.paramsData.planId;
          var license = this.model.paramsData.license;
          this.model.action = OrderAction.UPDATE_PLAN;
          var isFlexible = planName?.toLowerCase().indexOf('flexible') >= 0;
          if (skuId) {
            this.sku.push(
              this.fb.group({
                sku_id: [skuId, [Validators.required]],
                plan_id: [null, Validators.required],
                old_plan_name: [planName],
                old_plan_id: [planId],
                license: [license, Validators.required],
                old_license: [license],
                start_date: [null, Validators.required],
                annual_months: [null],
                is_flexible: [isFlexible],
                end_date: [null, Validators.required],
                with_google_offer: [false],
                google_offer: [0],
                with_customer_offer: [false],
                customer_offer: [0],
                cost_price: [0],
                selling_price: [0],
                subscription_api_id: subscription_api_id,
                expanded: [true],
              })
            );
          }
        } else if (this.actions.toUprade) {
          var skuName = this.model.paramsData.skuName;
          var skuId = this.model.paramsData.skuId;
          var planId = this.model.paramsData.planId;
          var license = this.model.paramsData.license;
          var availableSKUIDs = this.model.paramsData.availableSKUIDs;
          this.model.action = OrderAction.UPGRADE;
          this.googleProducts = this.googleProducts.filter((x) =>
            availableSKUIDs.split(';').includes(x.sku_id)
          );
          var planName = this.plans.find((x) => x._id == planId)?.name;
          var isFlexible = planName?.toLowerCase()?.indexOf('flexible') >= 0;
          this.sku.push(
            this.fb.group({
              sku_id: [null, [Validators.required]],
              plan_id: [planId, Validators.required],
              old_plan_id: [planId],
              old_plan_name: [planName],
              old_sku_name: [skuName],
              old_sku_id: [skuId],
              license: [license, Validators.required],
              old_license: [license],
              start_date: [null, Validators.required],
              annual_months: [null],
              is_flexible: [isFlexible],
              end_date: [null, Validators.required],
              with_google_offer: [false],
              google_offer: [0],
              with_customer_offer: [false],
              customer_offer: [0],
              cost_price: [0],
              selling_price: [0],
              subscription_api_id: subscription_api_id,
              expanded: [true],
            })
          );
        } else if (this.actions.toDowngrade) {
          var skuName = this.model.paramsData.skuName;
          var planId = this.model.paramsData.planId;
          var skuId = this.model.paramsData.skuId;
          var license = this.model.paramsData.license;
          var availableSKUIDs = this.model.paramsData.availableSKUIDs;
          this.model.action = OrderAction.DOWNGRADE;
          this.googleProducts = this.googleProducts.filter((x) =>
            availableSKUIDs.split(';').includes(x.sku_id)
          );
          var planName = this.plans.find((x) => x._id == planId)?.name;
          var isFlexible = planName?.toLowerCase()?.indexOf('flexible') >= 0;
          this.sku.push(
            this.fb.group({
              sku_id: [null, [Validators.required]],
              plan_id: [planId, Validators.required],
              old_plan_id: [planId],
              old_plan_name: [planName],
              old_sku_name: [skuName],
              old_sku_id: [skuId],
              license: [license, Validators.required],
              old_license: [license],
              start_date: [null, Validators.required],
              annual_months: [null],
              is_flexible: [isFlexible],
              end_date: [null, Validators.required],
              with_google_offer: [false],
              google_offer: [0],
              with_customer_offer: [false],
              customer_offer: [0],
              cost_price: [0],
              selling_price: [0],
              subscription_api_id: subscription_api_id,
              expanded: [true],
            })
          );
        } else if (this.actions.toRenew == true) {
          var skuId = this.model.paramsData.skuId;
          var planId = this.model.paramsData.planId;
          var skuName = this.model.paramsData.skuName;
          var planName = this.model.paramsData.planName;
          var license = this.model.paramsData.license;
          var endApiDate = this.model.paramsData.endApiDate;
          this.model.action = OrderAction.RENEW;
          var isFlexible = planName?.toLowerCase()?.indexOf('flexible') >= 0;
          var endAPITime = null;
          if (!Utilities.isNullOrEmpty(endApiDate)) {
            endAPITime = endApiDate;
            endApiDate = this.datePipe.transform(
              new Date(Number.parseInt(endApiDate)),
              'yyyy-MM-dd'
            );
          }
          if (skuId) {
            this.sku.push(
              this.fb.group({
                sku_id: [skuId, [Validators.required]],
                plan_id: [planId, Validators.required],
                old_plan_id: [planId],
                old_plan_name: [planName],
                old_sku_name: [skuName],
                old_sku_id: [skuId],
                license: [license, Validators.required],
                old_license: [license],
                start_date: [endApiDate, Validators.required],
                annual_months: [null],
                is_flexible: [isFlexible],
                end_date: [null, Validators.required],
                end_api_date: endAPITime,
                with_google_offer: [false],
                google_offer: [0],
                with_customer_offer: [false],
                customer_offer: [0],
                cost_price: [0],
                subscription_api_id: subscription_api_id,
                selling_price: [0],
                expanded: [true],
              })
            );
          }
        }
      } else {
        if (this.dealSKUs && this.dealSKUs.length > 0) {
          if (!Utilities.isNullOrEmpty(this.dealType))
            //  this.model.action = Utilities.setOrderActionFromDeal(this.dealType);
            //MAKE ORDER FROM DEAL CASE
            this.dealSKUs.forEach((element) => {
              this.sku.push(
                this.fb.group({
                  sku_id: [element.sku_id, [Validators.required]],
                  plan_id: [null, Validators.required],
                  license: [element.license, Validators.required],
                  start_date: [null, Validators.required],
                  annual_months: [null],
                  end_date: [null, Validators.required],
                  is_flexible: [false],
                  with_google_offer: [false],
                  google_offer: [0],
                  with_customer_offer: [false],
                  customer_offer: [0],
                  cost_price: [element.cost_price],
                  selling_price: [0],
                  expanded: [true],
                })
              );
            });
        } else {
          this.sku.push(
            this.fb.group({
              sku_id: [null, [Validators.required]],
              plan_id: [null, Validators.required],
              license: [1, Validators.required],
              start_date: [null, Validators.required],
              annual_months: [null],
              is_flexible: [false],
              end_date: [null, Validators.required],
              with_google_offer: [false],
              google_offer: [0],
              with_customer_offer: [false],
              customer_offer: [0],
              cost_price: [0],
              selling_price: [0],
              expanded: [true],
            })
          );
        }
      }
    } else {
      // this.orderForm.patchValue({
      //   project_name: '',
      //   project_number: '',
      //   billing_name: '',
      // });
      this.googleProducts = this.googleProducts.filter((x) => x.gcp == true);
      if (this.dealSKUs && this.dealSKUs.length > 0) {
        for (var i = 0; i < this.dealSKUs.length; i++) {
          var element = this.dealSKUs[i];
          this.sku.push(
            this.fb.group({
              sku_id: [element.sku_id, [Validators.required]],
              sku_name: [null],
              amount: [element.amount],
              expanded: [true],
            })
          );
        }
      } else {
        this.sku.push(
          this.fb.group({
            sku_id: [null, [Validators.required]],
            sku_name: [null],
            amount: [0],
            expanded: [true],
          })
        );
      }
    }
    //TOTAL USERS
    this.sku.controls.forEach((cont) => {
      var users = (<UntypedFormGroup>cont).controls['license'];
      if (!Utilities.isNullOrEmpty(users)) {
        this.totalNbrOfUsers += users.value;
      }
    });
    //

    //ACTION
    var x = this.currentDomain.domain;
    if (!Utilities.isNullOrEmpty(this.currentDomain.domain))
      this.setModelAction(this.model);
    if (
      this.isGWS &&
      !Utilities.isNullOrEmpty(this.currentDomain?.domain) &&
      OrderAction.NEW_CUSTOMER === this.model.action
    ) {
      if (!new DomainModel(this.currentDomain).isGWSDomainValid()) {
        alert('Check customer required fields for GWS order');
        this.router.navigate(['customers/customer-create'], {
          state: { customerRfid: this.currentDomain.customer_rfid },
          queryParams: {
            isCreationOrTransfer: true,
          },
        });
      }
    }

    console.log(this.model);
  }

  private setModelAction(model: OrderModel) {
    if (Utilities.isNullOrEmpty(this.model.action)) {
      if (!Utilities.isNullOrEmpty(this.model.customer_api_id))
        model.action = OrderAction.NEW_SERVICES;
      else if (this.currentDomain.is_transfer) {
        model.action = OrderAction.TRANSFER;
      } else model.action = OrderAction.NEW_CUSTOMER;
    } else model.action = this.model.action;
  }

  async saveOrder(draft: boolean = false) {
    var that = this;
    if (
      draft ||
      (this.orderForm.valid && !this.orderForm.value.support.available) ||
      (this.orderForm.valid &&
        this.orderForm.value.support.available &&
        this.orderForm.value.support.price !== null &&
        this.orderForm.value.support.start_date &&
        this.orderForm.value.support.end_date)
    ) {
      var skus = this.orderForm.value.sku;

      if (!draft && (!skus || this.sku.length == 0)) {
        alert('You must add at least one SKU');
        return;
      } else if (!draft && !this.isGWS) {
        //ON GCP CASE< SPLIT SELECTED SKUS FROM DROPDWON MULTI-SELECT
        // var drpSkus = this.orderForm.value.sku[0].sku_id ?? [];
        // var newSKUS = [];
        // drpSkus.forEach((element) => {
        //   newSKUS.push({ sku_id: element });
        // });
        // this.orderForm.value.sku = newSKUS;
      }
      if (
        !draft &&
        this.isGWS == true &&
        skus.find(
          (x) => !x.plan_id || !x.sku_id || !x.license || x.license == 0
        ) != null
      ) {
        alert('You must select SKU,plan and add license for each service');
        return;
      }
      if (
        !draft &&
        this.isGWS == true &&
        skus.find(
          (x) =>
            (x.with_google_offer == true &&
              (!x.google_offer || x.google_offer == 0)) ||
            (x.with_customer_offer == true &&
              (!x.customer_offer || x.customer_offer == 0))
        )
      ) {
        alert('you must add Offer percentage');
        return;
      }
      this.model.draft = draft;
      if (this.currentUser.isExecutor && this.isGWS == true) {
        var orderAPI = this.orderForm.value;
        orderAPI.customer_api_id = this.model.customer_api_id;
        orderAPI.action = this.model.action ?? OrderAction.NEW_SERVICES;
        await this.save(true).then(async (respDB) => {
          if (respDB == true) {
          }
        });
      } else {
        await this.save(false).then(async (addedToDB) => {
          if (addedToDB == true) {
          }
        });
      }
    } else {
      alert('Add Required Fields');
      var skus: any = this.sku.controls;
      if (skus) {
        skus.forEach((sku) => {
          (<UntypedFormGroup>sku).controls['expanded'].setValue(true);
        });
      }
    }
  }

  async save(toExecute: boolean): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var that = this;
      var order: OrderModel = Object.assign(
        new OrderModel(),
        this.orderForm.value
      );
      order.domain_rfid = this.currentDomain._id;
      order.customer_api_id = this.model.customer_api_id;
      order.created_by_rfid = this.model.created_by_rfid;
      order.draft = this.model.draft;
      // order.opportunity_rfid = this.opporunityID;
      order.sku.forEach((element) => {
        Utilities.setSKUFields(element, this.plans, this.googleProducts);
      });
      //ACTION
      this.setModelAction(order);
      //
      order.order_status = this.orderPhasesInfo.status ?? OrderStatus.Pending;
      order.is_gcp = !this.isGWS;
      if (
        order.action == OrderAction.RENEW &&
        Utilities.isNullOrEmpty(order.sku[0]?.end_api_date)
      ) {
        order.sku[0].end_api_date = new Date(
          order.sku[0]?.start_date
        ).getTime();
      }
      order.initialiseApprovalPhases(this.teams, this.currentUser);
      var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
      var receivers = this.orderPhasesInfo.receiverEmails ?? [];
      params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
      order.team_rfid = order.team?.id;
      await this.http
        .post(Endpoint.API_URL + Endpoint.ADD_ORDER, order, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.success == true && !Utilities.isNullOrEmpty(resp.id)) {
            this.model._id = resp.id;
            var textAlert = 'Successfully Executed';
            if (!toExecute && !this.model.draft) {
              textAlert = 'your order has been submitted for approval ';
              var isTeamManager = this.teams.find(
                (x) => x.manager?.id === this.currentUser._id
              );
              if (!isTeamManager && order.team?.manager)
                textAlert += 'to ' + order.team.manager.email;
            }
            if (
              this.addOrderEmitter &&
              this.addOrderEmitter.observers.length > 0
            ) {
              var ord = JSON.parse(JSON.stringify(order));
              ord._id = resp.id;
              ord.consumed_time = resp.consumed_time;
              ord.created_user_email = this.currentUser.email;
              ord.created_at = new Date();
              ord.created_date_string = Utilities.toStringDateFormat(
                this.datePipe,
                new Date(),
                'dd MMM,yyyy-hh:mma'
              );
              ord.user_order = {
                id: this.currentUser._id,
                created_user_email: this.currentUser.email,
                created_user_name: this.currentUser.user_name,
                manager_user_id: this.currentUser.manager_id,
              };
              this.setOrderPhases(ord);
              this.addOrderEmitter.emit(ord);
            } else {
              alert(textAlert);
            }
            resolve(true);
          }
        })
        .catch((err) => {
          resolve(false);
          throw err;
        });
    });
  }

  async onSearchDomainEnter(e) {
    var value = e.term;
    if (value && value.length > 2) {
      if (!Utilities.isNullOrEmpty(value)) this.searchResultDomains = [];
      var params = new HttpParams()
        .set(Unicode.CUSTOMER_FILTER, value)
        .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
      await this.http
        .get(Endpoint.API_URL + '' + Endpoint.SEARCH_FOR_CUSTOMERS_LIST, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp?.domains?.length > 0) {
            this.searchResultDomains = resp.domains ?? [];
          }
        });
    }
  }

  selectDomain() {
    if (this.orderForm.value.customer_domain) {
      this.currentDomain = this.orderForm.value.customer_domain;
      this.model.customer_domain = this.currentDomain.domain;
      this.model.customer_organisation = this.currentDomain.organisation_name;
      this.model.customer_api_id = this.currentDomain.customer_api_id;
      this.model.is_customer_transfer = this.currentDomain.is_transfer;
      this.orderForm.patchValue({
        customer_organisation: this.currentDomain.organisation_name,
        customer_domain: this.currentDomain.domain,
      });
    }
    this.setModelAction(this.model);
    if (
      this.isGWS &&
      !Utilities.isNullOrEmpty(this.currentDomain?.domain) &&
      OrderAction.NEW_CUSTOMER === this.model.action
    ) {
      if (!new DomainModel(this.currentDomain).isGWSDomainValid()) {
        alert('Check customer required fields for GWS order');
        this.router.navigate(['customers/customer-create'], {
          state: { customerRfid: this.currentDomain.customer_rfid },
          queryParams: {
            isCreationOrTransfer: true,
          },
        });
      }
    }
  }

  createNewCustomer() {
    this.localStorage.remove(Unicode.CURRENT_CUSTOMER);
    this.router.navigate(['customers/customer-create'], {
      queryParams: { isCreationOrTransfer: true },
      skipLocationChange: true,
    });
  }

  addAnotherSKU() {
    this.sku.controls.forEach((cont) => {
      (<UntypedFormGroup>cont).controls['expanded'].setValue(false);
    });
    this.sku.push(
      this.fb.group({
        sku_id: [null, Validators.required],
        plan_id: [null, Validators.required],
        license: [0, Validators.required],
        start_date: [null, Validators.required],
        annual_months: [null],
        is_flexible: [false],
        end_date: [null, Validators.required],
        with_google_offer: [false],
        google_offer: [0],
        with_customer_offer: [false],
        customer_offer: [0],
        cost_price: [0],
        selling_price: [0],
        expanded: [true],
      })
    );
  }

  deleteSKU(skuID) {
    var index = this.sku.controls.findIndex(
      (x: any) => x.value.sku_id == skuID
    );
    if (index >= 0) this.sku.removeAt(index);
  }
  expandSku(skuID) {
    var sku = this.sku.controls.find((x: any) => x.value.sku_id == skuID);
    if (sku) {
      var expanded = (<UntypedFormGroup>sku).controls['expanded'].value;
      (<UntypedFormGroup>sku).controls['expanded'].setValue(!expanded);
    }
  }

  calculatePrices(sku) {
    //TOTAL USERS
    this.totalNbrOfUsers = 0;
    this.sku.controls.forEach((cont) => {
      var users = (<UntypedFormGroup>cont).controls['license'];
      if (!Utilities.isNullOrEmpty(users)) {
        this.totalNbrOfUsers += users.value;
      }
    });
    //EXPANED
    if (sku) sku.controls['expanded'].setValue(true);
    //
    var planId = sku.value.plan_id;
    if (!Utilities.isNullOrEmpty(planId)) {
      // sku.value.end_date = null;
      // sku.controls['end_date'].setValue(null);
      var planName = this.plans.find((x) => x._id == planId)?.name;
      var isFlexible = planName?.toLowerCase()?.indexOf('flexible') >= 0;
      sku.controls['is_flexible'].setValue(isFlexible);
      sku.value.is_flexible = isFlexible;
      // sku.value.annual_months = 0;
      // sku.controls['annual_months'].setValue(0);
    }
    //
    if (
      !Utilities.isNullOrEmpty(sku.value.start_date) &&
      !Utilities.isNullOrEmpty(sku.value.annual_months) &&
      sku.value.annual_months > 0
    ) {
      var start = new Date(sku.value.start_date);
      var end_date: any = new Date(
        start.setMonth(start.getMonth() + sku.value.annual_months)
      );
      end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd');
      sku.value.end_date = end_date;
      sku.controls['end_date'].setValue(end_date);
    }
    if (
      Utilities.isNullOrEmpty(sku.value.start_date) ||
      Utilities.isNullOrEmpty(sku.value.end_date)
    ) {
      return;
    }
    var from_ = new Date(sku.value.start_date);
    var to_ = new Date(sku.value.end_date);
    var months = Utilities.calculateDiffMonths(from_, to_);
    var days = months * 30;

    var skuObjectId = this.googleProducts.find(
      (x) =>
        sku.value.sku_id &&
        x.sku_id.toLowerCase() == sku.value.sku_id.toLowerCase()
    )?._id;
    var priceObj = this.prices.find(
      (x) =>
        skuObjectId &&
        sku.value.plan_id &&
        x.sku_rfid == skuObjectId &&
        x.plan_rfid == sku.value.plan_id
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costMargin = Utilities.setOrderCostMargin(this.model.action);
      var costPrice: number = price * costMargin * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        sku.value.with_google_offer &&
        sku.value.google_offer &&
        sku.value.google_offer != 0
      ) {
        var offer = (100 - sku.value.google_offer) / 100;
        costPrice *= offer;
      }
      if (
        sku.value.with_customer_offer &&
        sku.value.customer_offer &&
        sku.value.customer_offer != 0
      ) {
        var offer = (100 - sku.value.customer_offer) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = sku.value.license;
      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        sku.controls['selling_price'].setValue(
          Number.parseFloat(sellingPrice.toFixed(2))
        );
        sku.controls['cost_price'].setValue(
          Number.parseFloat(costPrice.toFixed(2))
        );
      }
    }
  }

  cancelOrder() {
    // this.router.navigate(['/orders']);
    this.location.back();
  }

  get customer_domain() {
    return this.orderForm.get('customer_domain');
  }
  get customer_organisation() {
    return this.orderForm.get('customer_organisation');
  }
  get description() {
    return this.orderForm.get('description');
  }
  get po_id() {
    return this.orderForm.get('po_id');
  }
  get order_sale_invoice_nbr() {
    return this.orderForm.get('order_sale_invoice_nbr');
  }
  get opportunity_source() {
    return this.orderForm.get('opportunity_source');
  }
  get opportunity_nbr() {
    return this.orderForm.get('opportunity_nbr');
  }
  get opportunity_status() {
    return this.orderForm.get('opportunity_status');
  }
  get google_rep() {
    return this.orderForm.get('google_rep');
  }
  get project_number() {
    return this.orderForm.get('project_number');
  }
  get project_name() {
    return this.orderForm.get('project_name');
  }
  get billing_name() {
    return this.orderForm.get('billing_name');
  }
  get engagement() {
    return this.orderForm.get('engagement');
  }
  get sku(): UntypedFormArray {
    return this.orderForm.get('sku') as UntypedFormArray;
  }
  get support(): FormGroup {
    return this.orderForm.get('support') as FormGroup;
  }
  get company_section() {
    return this.orderForm.get('company_section');
  }
  get team() {
    return this.orderForm.get('team');
  }

  setOrderPhases(order) {
    order.managerApprovalPart = order.approval_phases
      .slice()
      .reverse()
      .find(
        (x) =>
          x.role && x.role.toLowerCase() == Group.Sale_Manager.toLowerCase()
      );
    order.gpmApprovalPart = order.approval_phases
      .slice()
      .reverse()
      .find((x) => x.role && x.role.toLowerCase() == Group.GPM.toLowerCase());
    order.financePart = order.approval_phases
      .slice()
      .reverse()
      .find(
        (x) => x.role && x.role.toLowerCase() == Group.Finance.toLowerCase()
      );
  }
  selectOrderTeam() {
    this.model.team = this.orderForm.value.team;
    this.orderPhasesInfo = Utilities.orderApprovalPhases(
      this.localStorage,
      this.model,
      this.usersInfo
    );
  }
}
