<section class="container-fluid box-shadow bg-white rounded-5 p-2 p-md-4 mx-0">
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a routerLink="/dashboard"
        ><img
          src="./assets/img/back-icon.svg"
          (click)="goBack()"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Users
    </h1>
    <div class="d-flex justify-content-center w-50">
      <input
        id="txt-order-global-search"
        type="search"
        (keyup.enter)="searchUser($event)"
        class="form-control rounded-pill h-36 w-100"
        placeholder="Search "
      />
    </div>
    <div class="d-flex justify-content-end">
      <span>
        {{ minUserIndex }} - {{ maxUserIndex }} of {{ totalItems }}
        <img
          src="./assets/img/back-icon.svg"
          class="px-1 px-md-2 mx-0 mx-md-2 cursor-pointer"
          [ngClass]="{ disabled: currentPage === 1 }"
          alt="back icon"
          (click)="currentPage == 1 ? false : onPageChanged(currentPage - 1)"
        />
        <img
          src="./assets/img/next-icon.svg"
          class="pe-1 pe-md-2 cursor-pointer"
          alt="next icon"
          (click)="
            maxUserIndex === 0 || currentPage === totalPages
              ? false
              : onPageChanged(currentPage + 1)
          "
        />
      </span>
    </div>
  </div>
  <div class="table-responsive fluid-section bg-white max-h-70">
    <table class="table table-hover align-middle text-center smaller-text">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Name
          </th>
          <th scope="col" class="col-1 col-md-2 border-top-0">Email</th>
          <th scope="col" class="col-1 col-md-2 border-top-0">Image</th>
          <th scope="col" class="col-1 col-md-2 border-top-0">Country</th>
          <th scope="col" class="col-1 col-md-2 border-top-0">Group</th>
          <th scope="col" class="col-1 col-md-2 position-relative border-top-0">
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr *ngFor="let user of pagedUsers">
          <td class="text-md-start ps-md-5 col-1 col-md-2 py-3">
            {{ user.user_name }}
          </td>
          <td class="col-1 col-md-2">{{ user.email }}</td>
          <td class="col-1 col-md-2" *ngIf="user.image_url; else no_image_div">
            <img class="rounded-pill usr_img" src="{{ user.image_url }}" />
          </td>
          <ng-template #no_image_div>
            <td class="col-1 col-md-2">
              <img src="./assets/img/default_user_icon.svg" />
            </td>
          </ng-template>
          <td class="col-1 col-md-2">{{ user.country }}</td>
          <td class="col-1 col-md-2">
            {{ user.group_names }}
          </td>
          <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">
            <img
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/view-icon.svg"
              alt="view icon"
              (click)="ViewSale(user._id)"
            />
            <img
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/edit-icon.svg"
              (click)="editSale(user._id)"
              alt="edit icon"
            />
            <img
              class="ps-1 ps-md-2 cursor-pointer"
              src="./assets/img/delete-icon.svg"
              alt="delete icon"
              (click)="deleteSale(user._id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
