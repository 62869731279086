import { Router } from '@angular/router';
import { Utilities } from 'src/shared/utilities';
import { GroupModel } from 'src/models/mongo/group.model';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'settings-group-members',
  templateUrl: './group-members.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./group-members.component.scss'],
})
export class GroupMembersComponent implements OnInit {
  currentGrp: GroupModel;
  members = [];
  @ViewChild('AddMmbModal')
  AddMmbModal: ModalDirective;
  users: any[];
  usersEmail: any[];
  userSelected: string = '';

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    var usersJ = this.localStorage.get(Unicode.USERS);
    this.users = usersJ ? JSON.parse(usersJ) : [];
    this.usersEmail = Array.from(new Set(this.users.map((u) => u.user_email)));
    var grpjson = this.localStorage.get(Unicode.CURRENT_GRP);
    if (grpjson) {
      this.currentGrp = JSON.parse(grpjson);
      var params = new HttpParams().set(Unicode.GROUP_ID, this.currentGrp.id);
      this.http
        .get(Endpoint.API_URL + '' + Endpoint.USR_GRP_FILE_NAME, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.groupMembers) {
            this.members = resp.groupMembers;
          }
        });
    }
  }

  AddNewMember() {
    this.AddMmbModal.show();
  }

  deleteMember(userGroupId: number, userName) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Are you sure you want to remove ' + userName + ' from this group?',
      null,
      'confirm',
      async function () {
        var params = new HttpParams().set(Unicode.USR_GRP_ID, userGroupId + '');
        that.http
          .delete(Endpoint.API_URL + '' + Endpoint.USR_GRP_FILE_NAME, {
            params: params,
          })
          .toPromise()
          .then((resp: any) => {
            if (resp && !resp.msg) {
              var index = that.members.findIndex(
                (x) => x.user_group_id == userGroupId
              );
              that.members.splice(index, 1);
              alert('Deleted');
            } else {
              alert(resp.msg ?? '');
            }
          });
      },
      function () {}
    );
  }

  selectedStatic(result) {
    this.userSelected = result;
  }

  saveNewMember() {
    this.AddMmbModal.hide();
    var user = this.users.find(
      (x) =>
        x.user_email.toLowerCase().trim() ==
        this.userSelected.toLowerCase().trim()
    );
    if (user && !Utilities.isNullOrEmpty(this.currentGrp.id)) {
      var params = new HttpParams()
        .set(Unicode.USER_ID, user.usr_id + '')
        .set(Unicode.GROUP_ID, this.currentGrp.id);
      this.http
        .post(Endpoint.API_URL + '' + Endpoint.USR_GRP_FILE_NAME, null, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && !resp.msg && resp.userGroupId) {
            this.members.push({
              user_group_id: resp.userGroupId,
              created_by_rfid: user.usr_id,
              user_name: user.user_name,
              user_email: user.user_email,
            });
            alert('Added successfully');
          } else {
            alert(resp.msg ?? '');
          }
        });
    }
  }
  back() {
    this.router.navigate(['settings/groups']);
  }
}
