import { Component } from '@angular/core';

@Component({
  selector: 'lib-poc',
  template: `
    <p>
      poc works!
    </p>
  `,
  styles: [
  ]
})
export class PocComponent {

}
