import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SowDetailsRoutingModule } from './sow-details-routing.module';
import { SowDetailsComponent } from './sow-details.component';
import { SOWGoogleDocCardModule } from 'src/shared-components/sow-google-doc-card/sow-google-doc-card.module';

@NgModule({
  declarations: [SowDetailsComponent],
  imports: [CommonModule, SOWGoogleDocCardModule, SowDetailsRoutingModule],
})
export class SowDetailsModule {}
