import { LocalStorageService } from '../localstorage-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoint, Unicode } from 'src/shared/Endpoint';

@Injectable()
export class EntitlementService {
  private cache$: Map<string, Observable<Array<any>>> = new Map<
    string,
    Observable<Array<any>>
  >();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getCustomerEntitlementsByName(name: string) {
    var params = new HttpParams().set(Unicode.NAME, name);
    return this.http
      .get(Endpoint.API_URL + Endpoint.GET_CUSTOMER_ENTITLEMENTS, {
        params: params,
      })
      .toPromise();
  }
  sendApprovalRequest(body: any) {
    return this.http
      .post(Endpoint.API_URL + Endpoint.SEND_APPROVAL_REQUEST, body)
      .toPromise();
  }
  activateSuspendEntitlement(id: string, name: string, activate: boolean) {
    return this.http
      .put(Endpoint.API_URL + Endpoint.ACTIVATE_SUSPEND_ENTITLEMENT, {
        name: name,
        id: id,
        activate: activate,
      })
      .toPromise();
  }
  cancelEntitlement(id: string, name: string) {
    return this.http
      .put(Endpoint.API_URL + Endpoint.CANCEL_ENTITLEMENT, {
        name: name,
        id: id,
      })
      .toPromise();
  }
}
