import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EngineerRecommendationsListRoutingModule } from './engineer-recommendations-list-routing.module';
import { EngineerRecommendationsListComponent } from './engineer-recommendations-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    EngineerRecommendationsListComponent
  ],
  imports: [
    CommonModule,
    EngineerRecommendationsListRoutingModule,
    NgxPaginationModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule,
  ]
})
export class EngineerRecommendationsListModule { }
