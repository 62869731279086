import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-layouts',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss'],
})
export class LayoutsComponent implements OnInit {
  content;
  newArray;

  constructor() {}

  ngOnInit(): void {}
}
