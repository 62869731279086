import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'lib-billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrls: ['./billing-accounts.component.css'],
})
export class BillingAccountsComponent {
  @HostBinding('class') class = ' ';
}
