import {HttpClient, HttpParams} from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { SettingsService } from '../../../../../src/servcies/backend-services/settings.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {Endpoint, Unicode} from '../../../../../src/shared/Endpoint';

@Component({
  selector: 'settings-crm-token',
  templateUrl: './crm-token.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./crm-token.component.scss'],
})
export class ApiAccessTokenComponent implements OnInit {
  @ViewChild('AddModal')
  AddModal: ModalDirective;
  tokenForm: UntypedFormGroup;
  tokens: any[] = [];
  tokenId: string;
   pagination: number = 1;
   pageSize: number = 10;
   total: number = 1;

inputStarted:boolean;


  constructor(
    private router:Router,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private settingsService: SettingsService,
  ) {
    this.tokenForm = fb.group({
        name: ['', [Validators.required]]
      });
  }

  async ngOnInit(): Promise<void> {
    this.pagination = 1;
    this.getAllTokens();
  }

  async getAllTokens(): Promise<void> {
    var prs = new HttpParams().set(
      Unicode.PAGE,
      (this.pagination - 1) * this.pageSize + ''
    )
      .set(Unicode.LIMIT, this.pageSize + '');

    await this.http.get<any>(Endpoint.API_URL + '' + Endpoint.GET_API_ACCESS_TOKENS, {
        params: prs})
      .subscribe(
        (response:any) => {
          this.tokens = response.tokens;
          this.total = <number>response['total'];
          console.log('Tokens:', this.tokens);
        }
      );

   }
  onInputChange() {
    this.inputStarted = true;
  }
  AddNewToken() {
    this.settingsService.createToken(this.tokenForm.value).subscribe(
      (response: any) => {
        console.log('Token created successfully:', response);
        // this.permissions.push(PermissionData);
        this.tokens.push(response.token);
        this.getAllTokens();
      }
    );
  }

  deleteConfirmation(){
    this.settingsService.deleteToken(this.tokenId).subscribe(
      (response:any) => {
        console.log(response.message);
        this.getAllTokens();
      });
  }



  getRowIdToDelete(rowId){
    this.tokenId = rowId;
  }



  get name() { return this.tokenForm.get('name'); }
  renderPage(event: number) {
    this.pagination = event;
    this.getAllTokens();
  }
  get GetPagination() {
    return this.pagination;
  }
  get GetPageSize() {
    return this.pageSize;
  }
  get GetTotalCount() {
    return this.total;
  }
}
