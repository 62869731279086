import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'daf-request-decision-popup',
  templateUrl: './daf-request-decision-popup.component.html',
  styleUrls: ['./daf-request-decision-popup.component.scss'],
})
export class DafRequestDecisionPopupComponent implements OnInit {
  @ViewChild('vrmDecisionModal') vrmDecisionModal: ModalDirective;
  @Output() takeDecisionListener = new EventEmitter<boolean>();
  @Input() user_name;
  constructor() {}

  ngOnInit(): void {}

  show() {
    this.vrmDecisionModal.show();
  }
  hide() {
    this.vrmDecisionModal.hide();
  }
  takeDecision(required: boolean) {
    this.takeDecisionListener.emit(required);
  }
}
