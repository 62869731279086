import { Component } from '@angular/core';

@Component({
  selector: 'lib-proposal',
  template: `
    <p>
      proposal works!
    </p>
  `,
  styles: [
  ]
})
export class ProposalComponent {

}
