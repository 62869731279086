import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  private subject = new Subject<any>();

  confirmThis(
    message: string,
    title: string,
    type: string,
    yesFn: () => void,
    noFn: () => void
  ): any {
    this.setConfirmation(message, title, type, yesFn, noFn);
  }

  setConfirmation(
    message: string,
    title: string,
    type: string,
    yesFn: () => void,
    noFn: () => void
  ): any {
    const that = this;
    this.subject.next({
      type: type,
      text: message,
      title: title,
      yesFn(): any {
        that.subject.next(); // This will close the modal
        yesFn();
      },
      noFn(): any {
        that.subject.next();
        noFn();
      },
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  hide() {
    this.subject.next();
  }
}
