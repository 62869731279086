import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'settings-group-list',
  templateUrl: './group-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  constructor() {}

  async ngOnInit(): Promise<void> {}
}
