import { Component, ViewEncapsulation } from '@angular/core';
import { UserModel } from '../../../../../src/models/mongo/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoint, Group, Unicode } from '../../../../../src/shared/Endpoint';
import { UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from '../../../../../src/servcies/localstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Utilities } from '../../../../../src/shared/utilities';

@Component({
  selector: 'lib-engineer-recommendations-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './engineer-recommendations-list.component.html',
  styleUrls: ['./engineer-recommendations-list.component.scss'],
})
export class EngineerRecommendationsListComponent {
  plans = [];
  currentUser: UserModel;
  static _http: HttpClient;
  customerSearch: string;
  static pageSize: number = Unicode.ITEMS_PER_PAGE;
  static opportunities = [];
  static engineerRecommendations = [];
  static pagination: number = 1;
  static total: number = 1;
  static gcp: boolean;
  selectedFilterType: string = 'ALL';
  types = ['ALL', 'GWS', 'GCP'];
  customerRfId;
  initial_userIds = [];
  add: boolean = true;
  users = [];
  selectedUserId;
  selectedSales: string;
  engineerRecommendationsForm: UntypedFormGroup;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    EngineerRecommendationsListComponent._http = http;
    EngineerRecommendationsListComponent.gcp = false;
  }

  async ngOnInit(): Promise<void> {
    this.users = await Utilities.usersByGroup(
      Group.ENGINEER,
      this.http,
      this.localStorage
    );
    this.currentUser = this.localStorage.getLoggedinUserModel();
    EngineerRecommendationsListComponent.pagination = 1;
    this.getengineerRecommendations(null);
  }

  async getengineerRecommendations(pagination) {
    if (pagination == null)
      pagination = EngineerRecommendationsListComponent.pagination;
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        (pagination - 1) * EngineerRecommendationsListComponent.pageSize + ''
      )
      .set(Unicode.LIMIT, EngineerRecommendationsListComponent.pageSize + '')
      .set(Unicode.ENGINEER_RECOMMENDATIONS_TYPE, this.selectedFilterType)
      .set(Unicode.VIEW_ALL, this.currentUser.canViewAll);
    if (!Utilities.isNullOrEmpty(this.customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, this.customerSearch);
    if (!Utilities.isNullOrEmpty(this.selectedSales))
      prs = prs.set(Unicode.SALES_MANAGER, this.selectedSales);
    await EngineerRecommendationsListComponent._http
      .get<any>(
        Endpoint.API_URL + '' + Endpoint.GET_ALL_ENGINEER_RECOMMENDATIONS,
        {
          params: prs,
        }
      )
      .toPromise()
      .then((resp) => {
        if (resp) {
          EngineerRecommendationsListComponent.total = <number>resp['total'];
          EngineerRecommendationsListComponent.engineerRecommendations =
            resp.engineer_recommendations ?? [];
        }
      });
  }

  renderPage(event: number) {
    EngineerRecommendationsListComponent.pagination = event;
    this.getengineerRecommendations(null);
  }

  search() {
    EngineerRecommendationsListComponent.pagination = 1;
    this.getengineerRecommendations(null);
  }
  selectDate() {
    EngineerRecommendationsListComponent.pagination = 1;
    this.getengineerRecommendations(null);
  }
  selectSale() {
    EngineerRecommendationsListComponent.pagination = 1;
    this.getengineerRecommendations(null);
  }
  selectStatus() {
    EngineerRecommendationsListComponent.pagination = 1;
    this.getengineerRecommendations(null);
  }

  switchType(e) {
    e.target.classList.toggle('toggled');
    EngineerRecommendationsListComponent.pagination = 1;
    EngineerRecommendationsListComponent.gcp =
      !EngineerRecommendationsListComponent.gcp;
    this.getengineerRecommendations(null);
  }

  applyFilter(type: string): void {
    if (type != this.selectedFilterType) {
      EngineerRecommendationsListComponent.pagination = 1;
      this.selectedFilterType = type;
      this.getengineerRecommendations(null);
    }
  }

  get GetData() {
    return EngineerRecommendationsListComponent.engineerRecommendations;
  }
  get GetTotalCount() {
    return EngineerRecommendationsListComponent.total;
  }
  get GetPagination() {
    return EngineerRecommendationsListComponent.pagination;
  }
  get GetPageSize() {
    return EngineerRecommendationsListComponent.pageSize;
  }

  refreshPage() {
    this.getengineerRecommendations(null);
  }
  goBack(): void {
    this.location.back();
  }
  viewEngineerRecommendations(engineerRecommendations) {
    this.router.navigate(['engineer-recommendations/details'], {
      state: { erModel: engineerRecommendations },
    });
  }
  deleteEngineerRecommendations(id: string) {
    if (confirm('Are you sure to delete this engineer recommendation doc?')) {
      this.http
        .put(
          Endpoint.API_URL +
            '' +
            Endpoint.DELETE_ENGINEER_RECOMMENDATIONS +
            id,
          {}
        )
        .subscribe((response: any) => {
          this.getengineerRecommendations(null);
          alert(response.msg);
        });
    }
  }
}
