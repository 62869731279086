<div class="main-bg py-4 d-flex flex-row overflow-hidden">
  <nav
    class="sidebar-narrowed d-flex flex-column justify-content-between"
    id="sidebar"
  >
    <div class="d-flex flex-column">
      <button
        class="btn py-3 w-100 hamburger-btn rounded-0 d-none d-md-flex align-items-center justify-content-between"
        type="button"
        id="hamburger-btn"
      >
        <img src="./assets/img/reseller-icon.png" alt="hamburger icon" /><span
          class="h5 mb-0 text-white hidden-text col-9 px-0 text-start text-nowrap d-none d-sm-inline"
          >Reseller App</span
        >
      </button>
      <a
        *ngFor="let menu of menus"
        class="btn p-1 ps-md-3 py-3 w-100 menu-items-btn border-0 d-flex align-items-center justify-content-between"
        type="button"
        routerLink="{{ menu.link }}"
        id="{{ menu.name }}-menu"
        routerLinkActive="active-link"
      >
        <img
          data-bs-toggle="tooltip"
          title="{{ menu.name }}"
          src="./assets/img/{{ menu.img }}"
          alt="{{ menu.name }} icon"
        /><span
          class="text-white hidden-text col-9 px-0 text-start d-none d-sm-inline f-14"
          >{{ menu.name }}</span
        >
      </a>
    </div>
    <div class="d-flex flex-column">
      <a
        class="btn p-1 p-md-3 py-3 w-100 menu-items-btn border-0 d-flex align-items-center justify-content-between help-btn"
        type="button"
        routerLink="/help"
        id="help-menu"
      >
        <img src="./assets/img/help-icon.svg" alt="help icon" /><span
          class="px-0 text-white hidden-text col-9 text-start d-none d-sm-inline text-nowrap"
          >Help & Guidance</span
        >
      </a>
      <div class="btn-group dropend d-block">
        <!-- Modal -->
        <div
          class="modal fade"
          id="createCustomerModal"
          tabindex="-1"
          aria-labelledby="createCustomerModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <button
                type="button"
                class="btn-close p-3 ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div class="modal-header justify-content-center border-0">
                <h1 class="modal-title fs-5 product-sans text-center">
                  Create a New Customer or Transfer a Customer?
                </h1>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-evenly">
                  <a
                    data-bs-dismiss="modal"
                    class="text-black text-decoration-none cursor-pointer"
                    (click)="createCustomer()"
                  >
                    <div
                      src="./assets/img/new-customer-icon.png"
                      class="new-customer-icon"
                    ></div>
                    <p class="text-center mt-4 product-sans">New Customer</p>
                  </a>
                  <a
                    data-bs-dismiss="modal"
                    (click)="transferCustomer()"
                    class="text-black text-decoration-none cursor-pointer"
                  >
                    <div
                      src="./assets/img/transfer-customer-icon.png"
                      class="transfer-customer-icon"
                    ></div>
                    <p class="text-center mt-4 product-sans">
                      Transfer Customer
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        *ngIf="currentUser?.isAdmin == true"
        routerLink="/settings"
        class="btn p-1 p-md-3 py-3 w-100 menu-items-btn border-0 d-flex align-items-center justify-content-between"
        type="button"
        routerLinkActive="active-link"
      >
        <img src="./assets/img/settings-icon.svg" alt="settings icon" /><span
          class="text-white hidden-text col-9 px-0 text-start d-none d-sm-inline"
          >Settings</span
        >
      </a>

      <div>
        <button class="mt-3 p-0" mat-button [matMenuTriggerFor]="afterMenu">
          <img
            src="{{ currentUser.imageUrl }}"
            alt="{{ currentUser.first_name }} {{ currentUser.last_name }}"
            class="rounded-5 user-icon"
          /><span
            [ngStyle]="{
              visibility: count > 0 ? 'visible' : 'hidden'
            }"
            class="notification-marker"
            >{{ count }}</span
          >
        </button>

        <mat-menu
          #afterMenu="matMenu"
          yPosition="above"
          xPosition="after"
          class="nav-left-menu"
        >
          <button mat-menu-item id="mat_menu_nav_content">
            <div
              class="d-flex flex-column align-items-center text-white logout-menu-item p-2"
            >
              <img
                class="mb-1 rounded-circle w-50 h-80"
                src="{{ currentUser.imageUrl }}"
                alt="{{ currentUser.first_name }} {{ currentUser.last_name }}"
                width="50px"
                height="80px"
              />
              <p class="product-sans-light mb-1 fs-5">
                {{ currentUser.first_name }} {{ currentUser.last_name }}
              </p>
              <p class="mb-1 small-text product-sans">
                {{ currentUser.groups[0]?.name }}
              </p>
              <div>
                <p
                  *ngIf="isLoggedIn == true"
                  class="mb-1 small-text product-sans border-top border-bottom border-2 border-white p-10"
                  type="button"
                  routerLink="/notifications"
                  routerLinkActive="active-link"
                  id="notifications-menu"
                >
                  <i class="fa fa-bell me-2"></i>
                  Notifications
                  <span
                    *ngIf="count > 0"
                    class="notification-marker-dropdown"
                    >{{ count }}</span
                  >
                </p>
              </div>
              <button
                (click)="logout()"
                class="btn bg-white blue-text rounded-pill py-1 lh-1"
              >
                Logout
              </button>
            </div>
          </button>
        </mat-menu>
      </div>
    </div>
  </nav>

  <div class="px-md-4 w-100">
    <router-outlet></router-outlet>
  </div>
</div>

<!--- FAB BUTTONS -->
<div class="inner-fabs" *ngIf="!currentUser.isViewerOnly">
  <div
    #fab_item
    class="fab round"
    id="fab18"
    *ngIf="canCreateGCPModules"
    data-tooltip="Create Engineer Recommendations"
    (click)="createEngReco()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/engineer-recommendations-icon.png"
        alt="eng recom icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab17"
    *ngIf="canCreateGCPModules"
    data-tooltip="Create Customer Requirements"
    (click)="createCustReq()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/customer-requirements-icon.png"
        alt="cus req icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab16"
    *ngIf="canCreateGCPModules"
    data-tooltip="Create SOW"
    (click)="createSOW()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/sow-icon.png"
        alt="sow icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab15"
    *ngIf="canCreateGCPModules"
    data-tooltip="Create POC"
    (click)="createPOC()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/poc-icon.png"
        alt="poc icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab14"
    *ngIf="canCreateGCPModules"
    data-tooltip="Create Proposal"
    (click)="createProposal()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/proposal-icon.png"
        alt="proposal icon"
    /></i>
  </div>
  <!-- <div
    class="fab round"
    id="fab13"
    *ngIf="canStartOpportunity"
    data-tooltip="Create Opportunity"
    (click)="createOpportunity()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/opportunity-icon.png"
        alt="opportunities icon"
    /></i>
  </div> -->
  <div
    #fab_item
    class="fab round"
    id="fab12"
    data-tooltip="Create an Order"
    *ngIf="canCreateGWSModules"
    (click)="createOrder()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/orders-icon.svg"
        alt="orders icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab11"
    *ngIf="canCreateGWSModules"
    data-tooltip="Create a Deal"
    (click)="createDeal()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/deals-icon.svg"
        alt="deals icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab10"
    data-tooltip="Create a Customer"
    data-bs-toggle="modal"
    *ngIf="canCreateGWSModules"
    data-bs-target="#createCustomerModal"
  >
    <i class="material-icons w-30-px"
      ><img
        class="material-icons"
        src="./assets/img/customers-icon.svg"
        alt="customers icon"
    /></i>
  </div>
  <div
    #fab_item
    class="fab round"
    id="fab9"
    *ngIf="currentUser?.isAdmin"
    data-tooltip="Create a User"
    (click)="AddNewSale()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/users-icon.svg"
        alt="customers icon"
    /></i>
  </div>
  <div
    #fab_item
    *ngIf="
      (currentUser?.isEngineer && currentUser?.is_gcp) || currentUser?.isAdmin
    "
    class="fab round"
    id="fab8"
    data-tooltip="Create a Project"
    (click)="createProject()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/project-icon.svg"
        alt="customers icon"
    /></i>
  </div>
  <div
    #fab_item
    *ngIf="currentUser?.isFinanceTeam || currentUser?.isAdmin"
    class="fab round"
    id="fab7"
    data-tooltip="Create a Billing"
    (click)="createBillingAccount()"
  >
    <i class="material-icons"
      ><img
        class="material-icons"
        src="./assets/img/billing-icon.svg"
        alt="customers icon"
    /></i>
  </div>
</div>
<div
  class="fab round"
  id="fab1"
  (click)="onMouseEnter()"
  *ngIf="!currentUser.isViewerOnly"
>
  <i class="material-icons" id="fabIcon"
    ><img
      class="material-icons"
      id="fabIcon"
      src="./assets/img/fab-icon.svg"
      alt="floating button action icon"
  /></i>
</div>
<!--- FAB BUTTONS -->

<div *ngIf="createProjectClicked == true">
  <new-project-modal #new_project_modal></new-project-modal>
</div>

<gws-gcp-popup #gwsGcpDealModal (saveOption)="goToPage($event)"></gws-gcp-popup>
