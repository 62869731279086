<div
  id="msp_box"
  [ngClass]="{
    msp_box_checked: msp?.is_msp,
    msp_box_normal: !msp?.is_msp
  }"
>
  <div class="row">
    <input
      type="checkbox"
      class="msp_checkbox col-1"
      (change)="mspChecked()"
      [ngClass]="{
        my_disabled:
          !canEdit || (!currentUser.isSales && !currentUser.isFinanceTeam)
      }"
      [(ngModel)]="msp.is_msp"
    />
    <label class="col-5 mt-4 product-sans-medium"
      >Provide MSP to this customer</label
    >
    <div class="col-6 text-end p-0">
      <img src="./assets/img/google_msp.svg" class="img_msp" />
    </div>
  </div>

  <div class="row mt-4 col-12" *ngIf="msp?.is_msp">
    <label class="form-label text-wrap col-4 p-0">
      Email Attachment Proof
    </label>
    <div
      class="col-6"
      [ngClass]="{ attach_name_link: msp.attachment_proof_drive_id }"
    >
      <input
        *ngIf="!msp.attachment_proof_drive_id"
        type="text"
        class="form-control rounded-pill m-0 h-36"
        [value]="msp.attachment_proof_name ?? ''"
        readonly
      />
      <a
        *ngIf="msp.attachment_proof_drive_id"
        target="_blank"
        href="{{ msp.attachment_proof_drive_id }}"
        >{{ msp.attachment_proof_name }}</a
      >
    </div>
    <div
      *ngIf="canEdit"
      class="col-2 text-end"
      [ngClass]="{
        my_disabled: !currentUser.isSales && !currentUser.isFinanceTeam
      }"
    >
      <label for="opp-msp-upload-profile"
        ><div
          type="button"
          class="btn rounded-pill btn-color d-flex align-items-center"
        >
          <span class="small pe-1">Attach</span>
          <img src="./assets/img/ic_attach_white.svg" alt="attach icon" /></div
      ></label>
      <input
        (change)="onAttachMSpFile($event)"
        type="file"
        id="opp-msp-upload-profile"
        hidden
      />
    </div>
    <!-- <ng-template #oppMspFilExists>
    <div class="col-8 p-0">
      <a target="_blank" href="{{ msp.attachment_proof_drive_id }}">{{
        msp.attachment_proof_name
      }}</a>
    </div>
  </ng-template> -->
  </div>
</div>
