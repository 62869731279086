import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomainModel } from 'src/models/mongo/domain.model';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { EntitlementService } from 'src/servcies/api/entitlement.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { OrderAction, OrderStatus, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';

@Component({
  selector: 'lib-customer-entitlements',
  templateUrl: './customer-entitlements.component.html',
  styleUrls: ['./customer-entitlements.component.scss'],
})
export class CustomerEntitlementsComponent implements OnInit {
  currentUser: UserModel;
  entitlements = [];
  currentDomain: DomainModel = new DomainModel();
  googleProducts = [];

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private entitlementService: EntitlementService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.currentUser = this.localStorage.getLoggedinUserModel();
    var paramsJson = this.localStorage.get(Unicode.CURRENT_CUSTOMER);
    if (paramsJson) {
      this.currentDomain = JSON.parse(paramsJson);
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.currentDomain.channel_name_gcp) {
      await this.entitlementService
        .getCustomerEntitlementsByName(this.currentDomain.channel_name_gcp)
        .then((resp) => {
          if (resp['data']) this.entitlements = resp['data'];
        });
    }
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
  }

  async activateEntitlement(id: string, activate: boolean) {
    if (id && this.currentDomain.channel_name_gcp) {
      var ent = this.entitlements.find((x) => x.id === id);
      var order = new OrderModel();
      order.action = activate ? OrderAction.ACTIVATE : OrderAction.SUSPEND;
      order.is_gcp = true;
      order.created_by_rfid = this.currentUser._id;
      order.domain_rfid = this.currentDomain._id;
      order.customer_domain = this.currentDomain.domain;
      order.order_status = OrderStatus.Pending;
      order.sku = [
        {
          entitlement_id: id,
          sku_name: ent.sku_name,
          sku_id: this.googleProducts.find(
            (x) =>
              x.gcp && x.sku_name?.toLowerCase() === ent.sku_name.toLowerCase()
          )?.sku_id,
          plan_name: ent.plan_name,
        },
      ];
      await this.entitlementService
        .sendApprovalRequest(order)
        .then((resp: any) => {
          if (resp?.success) {
            var that = this;
            var action = activate ? 'Activation' : 'Suspension';
            var actionVerb = activate ? 'activate' : 'suspend';
            var actionAdj = activate ? 'active' : 'suspended';
            that.confirmDialogService.confirmThis(
              'your request to ' +
                actionVerb +
                ' ' +
                ent.sku_name +
                ' for ' +
                that.currentDomain.domain +
                ' has been sent to the CEO. They will not get ' +
                actionAdj +
                ' until it is approved by the CEQ himself.',
              action + ' request sent',
              'info',
              function () {},
              function () {}
            );
          }
        });
    }
  }
  async cancelEntitlement(id: string) {
    // if (this.entitlements.find((x) => x.status == 'active')) {
    //   alert("You Can't cancel if there is an active Service");
    //   return;
    // }
    if (id && this.currentDomain.channel_name_gcp) {
      var ent = this.entitlements.find((x) => x.id === id);
      var order = new OrderModel();
      order.action = OrderAction.CANCEL;
      order.is_gcp = true;
      order.created_by_rfid = this.currentUser._id;
      order.domain_rfid = this.currentDomain._id;
      order.customer_domain = this.currentDomain.domain;
      order.order_status = OrderStatus.Pending;
      order.sku = [
        {
          entitlement_id: id,
          sku_name: ent.sku_name,
          sku_id: this.googleProducts.find(
            (x) =>
              x.gcp && x.sku_name?.toLowerCase() === ent.sku_name.toLowerCase()
          )?.sku_id,
          plan_name: ent.plan_name,
        },
      ];
      await this.entitlementService
        .sendApprovalRequest(order)
        .then((resp: any) => {
          if (resp?.success) {
            var that = this;
            that.confirmDialogService.confirmThis(
              'your request to cancel ' +
                ent.sku_name +
                ' for ' +
                that.currentDomain.domain +
                ' has been sent to the CEO. They will not get canceled until it is approved by the CEQ himself.',
              'Cancel request sent',
              'info',
              function () {},
              function () {}
            );
          }
        });
    }
  }
}
