<div class="" id="orders-renewal">
  <div class="d-flex justify-content-between align-items-center py-1">
    <p class="text-primary mb-0">
      The clients that have renewals during this month
    </p>
    <button
      (click)="exportRenewalList()"
      class="btn btn-primary small-text btn-color rounded-pill col-2 col-xl-1 p-1 p-xl-2 fs-6 align-self-end"
      type="button"
    >
      Export
    </button>
  </div>

  <div class="table-responsive fluid-section bg-white mt-2 mt-md-0 w-100">
    <table class="table table-hover align-middle text-center smaller-text mt-3">
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 border-top-0"
          >
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            SKU
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Plan
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Expiry Date
          </th>
          <th
            scope="col"
            class="p-1 p-md-2 ps-md-5 col-1 col-md-2 text-start border-top-0"
          >
            Sales Person
          </th>
          <th
            scope="col"
            class="p-1 p-md-2 col-1 col-md-2 position-relative border-top-0"
          >
            Created
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr *ngFor="let sub of GetRenewal">
          <td class="p-1 p-md-2 text-md-start ps-md-5 col-1 col-md-2 py-3">
            {{ sub.customerDomain }}
          </td>
          <td
            class="p-1 p-md-2 col-1 col-md-2"
            [ngClass]="{
              color_red: !sub.status.toLowerCase().includes('active'),
              color_green: sub.status.toLowerCase().includes('active')
            }"
          >
            {{ sub.skuName }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">{{ sub.plan?.planName }}</td>
          <td
            class="p-1 p-md-2 col-1 col-md-2"
            *ngIf="
              sub.plan &&
              sub.plan.commitmentInterval &&
              sub.plan.commitmentInterval.endTime
            "
          >
            {{ sub.plan.commitmentInterval.endTime | date : "longDate" }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-3">
            <ng-select
              *ngIf="sub.sales && sub.sales.length > 0"
              appendTo="body"
              id="assigned-salesperson"
              [searchable]="true"
              [clearable]="false"
              [(ngModel)]="sub.sales[0].first_name"
              class="col-12 rounded-pill smaller-text pe-4"
              aria-label="Assigned Salesperson"
            >
              <ng-option [value]="sale._id" *ngFor="let sale of sub.sales">
                {{ sale.first_name }} {{ sale.last_name }}
              </ng-option>
            </ng-select>
          </td>
          <td
            class="p-1 p-md-2 text-nowrap col-1 col-md-3 col-lg-2 p-0 p-md-2"
            *ngIf="!sub.created"
          >
            <img src="./assets/img/x-icon.svg" alt="x icon" />
          </td>
          <td
            *ngIf="sub.created"
            class="p-1 p-md-2 text-nowrap col-1 col-md-3 col-lg-2 p-0 p-md-2"
          >
            <img src="./assets/img/tick-icon.svg" alt="tick icon" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
