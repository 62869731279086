import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {IntegrationsRoutingModule} from './integrations-routing.module';
import {IntegrationsComponent} from './integrations.component';
import {CurrencyRoutingModule} from '../currency/currency-routing.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


@NgModule({
  declarations: [IntegrationsComponent],
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule, NgbModule,
        ModalModule.forRoot(),
        IntegrationsRoutingModule, MatSlideToggleModule
    ]
})
export class IntegrationsModule { }
