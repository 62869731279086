import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectListRoutingModule } from './project-list-routing.module';
import { ProjectListComponent } from './project-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { LinkProjectPopupModule } from 'src/shared-components/link-project-popup/link-project-popup.module';

@NgModule({
  declarations: [ProjectListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    NgxPaginationModule,
    ProjectListRoutingModule,
    LinkProjectPopupModule,
  ],
})
export class ProjectListModule {}
