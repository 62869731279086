import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { GcpService } from '../../gcp.service';
import { Unicode } from 'src/shared/Endpoint';
import { BillingAccountModel } from 'src/models/mongo/billing-account';

@Component({
  selector: 'lib-billing-account-list',
  templateUrl: './billing-account-list.component.html',
  styleUrls: ['./billing-account-list.component.scss'],
})
export class BillingAccountListComponent implements OnInit {
  // @HostBinding('class') class = ' ';

  currentUser: UserModel;
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  billingAccounts: BillingAccountModel[] = [];
  total: number = 1;
  searchValue;
  nextPageToken = null;
  previousPageToken = null;
  infiniteScrollThrottleValue = 50;

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private gcpService: GcpService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
  }
  async ngOnInit(): Promise<void> {
    await this.getBillingAccounts();
  }

  async getBillingAccounts() {
    await this.gcpService
      .getListOfBillingAccount(
        false,
        null,
        this.searchValue,
        this.nextPageToken,
        this.pageSize
      )
      .then((resp: any) => {
        this.billingAccounts = resp?.data ?? [];
        this.billingAccounts = this.billingAccounts.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.name === obj.name)
        );
        this.previousPageToken = this.nextPageToken;
        this.nextPageToken = resp?.nextPageToken;
      });
  }

  search() {
    this.getBillingAccounts();
  }

  viewBilling(billing: BillingAccountModel) {
    this.router.navigate(['gcp/billing-account/details'], {
      state: { billing: billing },
    });
  }

  private previousScrollPosition = 0;
  async onScrollLoadData(event) {
    // const currentScrollPosition = (event.target as HTMLElement).scrollTop;
    //   if (
    //   currentScrollPosition > this.previousScrollPosition &&
    //   !Utilities.isNullOrEmpty(this.nextPageToken) &&
    //  this.nextPageToken !== this.previousPageToken
    // ) {
    await this.getBillingAccounts();
    // }
    // this.previousScrollPosition = currentScrollPosition;
  }
}
