import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'poc-vrm-approval-popup',
  templateUrl: './poc-vrm-approval-popup.component.html',
  styleUrls: ['./poc-vrm-approval-popup.component.scss']
})
export class PocVrmApprovalPopupComponent implements OnInit {
  @ViewChild('vrmApprovalModal') vrmApprovalModal: ModalDirective;
  @Output() sendListener = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  show() {
    this.vrmApprovalModal.show();
  }
  hide() {
    this.vrmApprovalModal.hide();
  }

  send() {
    this.sendListener.emit("");
  }
}
