<button
  type="button"
  class="btn btn-color text-white rounded-pill d-block ms-auto product-sans-light"
  data-bs-toggle="modal"
  data-bs-target="#addGroupModal"
>
  <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
  <span>Add New Group</span>
</button>
<div class="table-responsive fluid-section bg-white groups w-100">
  <table class="table table-hover align-middle text-center">
    <thead class="position-sticky top-0 bg-white">
      <tr class="product-sans-medium">
        <th
          scope="col"
          class="text-md-start pe-0 ps-md-5 col-1 col-md-2 border-dark border-top-0"
        >
          Name
        </th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">
          Email
        </th>
        <th
          scope="col"
          class="col-1 col-md-2 position-relative border-dark border-top-0"
        >
          Edit
          <div class="w-100 h-100 bg-white position-absolute top-0"></div>
        </th>
      </tr>
    </thead>
    <tbody class="product-sans-light">
      <tr *ngFor="let row of groups">
        <td class="text-md-start ps-md-5 col-1 col-md-2 py-3">
          {{ row.name }}
        </td>
        <td class="col-1 col-md-2">{{ row.email }}</td>
        <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">
          <img
            class="px-1 px-md-2 cursor-pointer"
            (click)="openMemberPage()"
            src="./assets/img/manage-members-icon.svg"
            alt="manage members icon"
          />
          <a (click)="edit(row._id)">
            <img
              class="px-1 px-md-2 cursor-pointer"
              src="./assets/img/edit-icon.svg"
              data-bs-toggle="modal"
              data-bs-target="#editGroupModal"
              alt="edit icon"
            />
          </a>
          <img
            class="ps-1 ps-md-2 cursor-pointer"
            src="./assets/img/delete-icon.svg"
            alt="delete icon"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
            (click)="getRowIdToDelete(row._id)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- ADD NEW GROUP MODAL -->
<div
  class="modal fade"
  role="alert"
  id="addGroupModal"
  tabindex="-1"
  aria-labelledby="addGroupModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-5">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addGroupModalLabel"
        >
          Add New Group
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
        role="form"
        [formGroup]="groupForm"
        role="form"
        #formDir="ngForm"
        id="add-exchange-form"
        novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="name"
              class="form-label text-nowrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
            >
              Name
            </label>

            <input
              formControlName="name"
              [ngClass]="{
                'is-invalid': formDir.submitted && name.invalid
              }"
              type="text"
              class="form-control rounded-pill"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="email"
              class="form-label text-nowrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
              >Email</label
            >
            <input
              formControlName="email"
              [ngClass]="{
                'is-invalid': formDir.submitted && email.invalid
              }"
              type="email"
              class="form-control rounded-pill"
              id="email"
              placeholder="Email"
              required
            />
          </div>
          <div class="mb-3 d-flex align-items-center">
            <label
              class="form-label text-nowrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
            >
              Main Group
            </label>
            <input
              class="form-check-input"
              formControlName="main"
              type="checkbox"
            />
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="group-permissions"
              class="form-label text-wrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
              >Add Permissions to this group</label
            >
            <ng-select
              id="group-permissions"
              aria-label="Select Permission"
              bindLabel="permission.name"
              appendTo="body"
              [searchable]="true"
              [multiple]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="permission"
              [ngClass]="{
                'is-invalid': formDir.submitted && permission.invalid
              }"
              placeholder="choose permission"
            >
              <ng-option
                [value]="permission._id"
                *ngFor="let permission of permissions"
              >
                {{ permission.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="modal-footer">
          <input
            class="btn btn-primary btn-color rounded-pill col-2"
            value="Save"
            type="submit"
            data-bs-dismiss="modal"
            (click)="addNew()"
          />
        </div>
      </form>
    </div>
  </div>
</div>
<!-- EDIT GROUP MODAL -->
<div
  class="modal fade"
  role="alert"
  id="editGroupModal"
  tabindex="-1"
  aria-labelledby="editGroupModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-5">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addGroupModalLabel"
        >
          Edit Group
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
        role="form"
        [formGroup]="groupForm"
        role="form"
        #formDir="ngForm"
        id="add-exchange-form"
        novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="name"
              class="form-label text-nowrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
            >
              Name
            </label>

            <input
              formControlName="name"
              [ngClass]="{
                'is-invalid': formDir.submitted && name.invalid
              }"
              type="text"
              class="form-control rounded-pill"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="email"
              class="form-label text-nowrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
              >Email</label
            >
            <input
              formControlName="email"
              [ngClass]="{
                'is-invalid': formDir.submitted && email.invalid
              }"
              type="email"
              class="form-control rounded-pill"
              id="email"
              placeholder="Email"
              required
            />
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="group-permissions"
              class="form-label text-wrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
              >Add Permissions to this group</label
            >
            <ng-select
              id="group-permissions"
              aria-label="Select Permission"
              bindLabel="permission.name"
              appendTo="body"
              [searchable]="true"
              [multiple]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="permission"
              [ngClass]="{
                'is-invalid': formDir.submitted && permission.invalid
              }"
              placeholder="choose permission"
            >
              <ng-option
                [value]="permission._id"
                *ngFor="let permission of permissions"
              >
                {{ permission.name }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="modal-footer">
          <input
            class="btn btn-primary btn-color rounded-pill col-2"
            value="Save"
            type="submit"
            data-bs-dismiss="modal"
            (click)="updateGroup()"
          />
        </div>
      </form>
    </div>
  </div>
</div>
<!-- DELETE CONFIRMATION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="deleteModalLabel"
        >
          Delete Confirmation
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h3 text-center">Are you sure you want to delete?</h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-toggle="modal"
          data-bs-target="#rejectionReasonModal"
          (click)="deleteConfirmation()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
