import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GwsGcpPopupRoutingModule } from './gws-gcp-popup-routing.module';
import { GwsGcpPopupComponent } from './gws-gcp-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [GwsGcpPopupComponent],
  imports: [CommonModule, NgbModule, ModalModule.forRoot()],
  exports: [GwsGcpPopupComponent],
})
export class GwsGcpPopupModule {}
