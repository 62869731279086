import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DealsComponent } from './deals.component';
import { AllDealsModule } from './all-deals/all-deals.module';
import { AddDealModule } from './add-deal/add-deal.module';
import { AddDealGCPModule } from './add-deal-gcp/add-deal-gcp.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'all-deal',
    pathMatch: 'full',
  },
  { path: 'all-deal', loadChildren: () => AllDealsModule },
  { path: 'add-deal', loadChildren: () => AddDealModule },
  { path: 'add-deal-gcp', loadChildren: () => AddDealGCPModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DealsRoutingModule {}
