<!-- START PAID SERVICE CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #startPaidServiceModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="startPaidServiceModalLabel"
        >
          Start Paid Service`
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="startPaidServiceModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h5 text-center">
          Are you sure you want to start paid service?
        </h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="startPaidService()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="startPaidServiceModal.hide()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
