<div class="orders px-4 my-2 fluid-section w-100">
  <div
    class="row border-bottom product-sans-medium border-opacity-50 border-dark position-sticky top-0 bg-white py-3"
  >
    <p class="col-2 mb-0 ps-0 text-center">SKU</p>
    <p class="col-2 mb-0 ps-0">Plan</p>
    <p class="col-2 mb-0 ps-0">Date</p>
    <p class="col-1 mb-0 ps-0">Status</p>
    <p class="col-2 mb-0 ps-0">Billing Account Name</p>
    <p class="col-3 mb-0 ps-0 position-relative"></p>
  </div>

  <div
    class="row pt-2 border-bottom border-opacity-10 border-dark"
    *ngFor="let ent of entitlements"
  >
    <p class="col-2 mb-0 ps-0 py-3">{{ ent.sku_name }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ ent.plan_name }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ ent.string_date }}</p>
    <p class="col-1 mb-0 ps-0 py-3 text-capitalize">{{ ent.status }}</p>
    <p class="col-2 mb-0 ps-0 py-3">{{ ent.billing_account_name }}</p>
    <p class="col-1 mb-0 py-2 text-end" *ngIf="currentUser.isFinanceTeam">
      <img
        *ngIf="ent.status.toLowerCase() === 'active'"
        class="p-1 cursor-pointer me-1"
        src="./assets/img/suspend-icon.svg"
        data-toggle="tooltip"
        title="suspend"
        (click)="activateEntitlement(ent.id, false)"
        alt="suspend icon"
      />
      <img
        *ngIf="ent.status.toLowerCase() === 'suspended'"
        class="p-1 cursor-pointer"
        data-toggle="tooltip"
        title="activate"
        src="./assets/img/activate-icon.svg"
        (click)="activateEntitlement(ent.id, true)"
        alt="activate icon"
      />
      <img
        class="cursor-pointer"
        data-toggle="tooltip"
        title="cancel"
        src="./assets/img/cancel-icon.svg"
        (click)="cancelEntitlement(ent.id)"
        alt="cancel icon"
      />
    </p>
  </div>
</div>
