import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';

@Component({
  selector: 'app-help',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  constructor(private http: HttpClient) {}

  async ngOnInit(): Promise<void> {}
}
