import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupMembersRoutingModule } from './group-members-routing.module';
import { GroupMembersComponent } from './group-members.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [GroupMembersComponent],
  imports: [
    CommonModule,
    NgbModule,
    ModalModule.forRoot(),
    FormsModule,
    GroupMembersRoutingModule,
  ],
})
export class GroupMembersModule {}
