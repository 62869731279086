import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ApiAccessTokenComponent} from './crm-token.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApiAccessTokenRoutingModule} from './crm-token-routing.module';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [ApiAccessTokenComponent],
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        ApiAccessTokenRoutingModule, NgxPaginationModule
    ]
})
export class ApiAccessTokenModule { }
