import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PocListModule } from './poc-list/poc-list.module';
import { PocDetailsModule } from './poc-details/poc-details.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'poc-list',
    pathMatch: 'full',
  },
  { path: 'poc-list', loadChildren: () => PocListModule },
  { path: 'poc-details', loadChildren: () => PocDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class POCRoutingModule {}
