import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class MessageService {
  private dropdownValue: Subject<number> = new Subject();

  public getDropdownValue(): Observable<any> {
    return this.dropdownValue.asObservable();
  }

  public setDropdownValue(value: number): void {
    this.dropdownValue.next(value);
  }
}
