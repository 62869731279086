<div class="row col-12 page_content_main rounded-5" id="opp_details_page">
  <div
    class="bg-white box-shadow mobile-section rounded-5 p-0 m-0 pb-2"
    style="height: fit-content !important"
  >
    <div class="row">
      <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 col-6">
        <a
          ><img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            alt="back icon" /></a
        >New Opporunity
      </h1>
      <div
        class="col-3 mt-4 text-center"
        [ngClass]="{ 'hidden-text': !opportunity?.dependency_on }"
      >
        <label class="">Dependency on</label>
        <label class="mx-1">{{ opportunity.dependency_on }}</label>
      </div>
      <div
        class="col-2 pe-4 text-end mt-3 divider_label"
        *ngIf="opportunity.opportunity_status"
      >
        <label>Status&nbsp;&nbsp;</label>
        <label
          class="rounded-5"
          [ngClass]="{
            opp_status_inprogress:
              opportunity.opportunity_status.toLowerCase() == 'in progress'
          }"
          >{{ opportunity.opportunity_status
          }}<span
            *ngIf="
              opportunity.opportunity_status.toLowerCase() == 'in progress'
            "
            >...</span
          ></label
        >
      </div>
      <div
        class="col-1 pe-4 text-start mt-3 d-flex align-items-center"
        *ngIf="
          currentUser.isEngineer ||
          this.opportunity?.deal?.psf ||
          this.opportunity?.deal?.daf
        "
      >
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon> more_vert </mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            id="mat_menu_opp_content1"
            (click)="pickOpporunity('engineer')"
            *ngIf="
              currentUser.isEngineer && !ispicked && !currentUser.is_manager
            "
          >
            <span>Pick the opportunity</span>
          </button>
          <button
            id="mat_menu_opp_content2"
            *ngIf="currentUser.is_manager && currentUser.isEngineer"
            mat-menu-item
            data-bs-toggle="modal"
            data-bs-target="#PickUsersModal"
            (click)="getEngineers()"
          >
            <span>Pick Presales</span>
          </button>
          <button
            id="mat_menu_opp_content3"
            mat-menu-item
            (click)="showEditSaleAmount()"
            [disabled]="currentUser.isEngineer"
            *ngIf="this.opportunity?.deal?.psf || this.opportunity?.deal?.daf"
          >
            <span>Edit PSF/DAF Amount</span>
          </button>
          <button
            id="mat_menu_opp_msp_content"
            mat-menu-item
            (click)="showMspPopup()"
          >
            <span>Providing Managed Services </span>
          </button>

          <button
            id="mat_menu_opp_content4"
            mat-menu-item
            *ngIf="opportunity.proposal?.sendingToCustomerPart?.approval_date"
            (click)="showServicesDeliveredCheck()"
          >
            <span>Fully Delivered Services</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="d-flex flex-row ms-3">
      <h2 class="h5 mt-2 mb-3 col-10 p-0">Opportunity details</h2>
    </div>

    <div id="opp_info" class="ms-3 mb-3">
      <div class="mt-1 w-100 row col-10" *ngIf="opportunity.opportunity_id">
        <div class="col-lg-6 col-md-7 col-xl-7">
          <div class="row col-9">
            <label class="col-lg-4 col-md-2 col-4 text-wrap"
              >Opportunity ID</label
            ><label class="col-lg-6 divider_label">{{
              opportunity.opportunity_id
            }}</label>
          </div>
        </div>

        <!--CONSUMPTION BARS-->
        <div
          class="col-lg-6 col-md-5 col-xl-5"
          *ngIf="
            opportunity?.poc_phase?.poc &&
            opportunity?.poc_phase?.link_project_billing?.linking_date &&
            pocBillingCostInfo.project_id
          "
        >
          <span class="product-sans-bold">POC Budget & Alert</span>

          <div class="d-inline-flex gap-2 w-100 align-items-center mt-2">
            <span class="col-2 text-center product-sans-bold">Cost</span>
            <span class="col-2"
              >USD{{ pocBillingCostInfo.total_budget_amount }}</span
            >
            <div class="col-8">
              <ngb-progressbar
                type="primary"
                [value]="pocBillingCostInfo.total_cost_usage"
                [max]="pocBillingCostInfo.total_budget_amount"
                ><span class="progress-text"
                  >{{ pocBillingCostInfo.cost_usage_percentage }}%(USD
                  {{ pocBillingCostInfo.total_cost_usage }})</span
                >
                <div
                  *ngFor="
                    let alert of this.opportunity.poc_phase.billing_budget
                      .alerts;
                    let i = index
                  "
                  class="cost-alert"
                  #tooltip="matTooltip"
                  matTooltip="Alert {{ i }}
                {{ alert * 100 }}%
                USD {{ pocBillingCostInfo.total_budget_amount * alert }}"
                  matTooltipClass="custom-tooltip"
                  [style.left.%]="alert * 100"
                ></div>
              </ngb-progressbar>
            </div>
          </div>

          <div class="d-inline-flex gap-2 w-100 align-items-center mt-1">
            <span class="col-2 text-center product-sans-bold">Credit</span>
            <span class="col-2">USD{{ pocBillingCostInfo.total_credits }}</span>

            <div class="col-8">
              <ngb-progressbar
                type="success"
                [value]="pocBillingCostInfo.total_credits_usage"
                [max]="pocBillingCostInfo.total_credits"
                ><span class="progress-text"
                  >{{ pocBillingCostInfo.credit_usage_percentage }}%(USD
                  {{ pocBillingCostInfo.total_credits_usage }})</span
                ></ngb-progressbar
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 w-100 row" *ngIf="domain?.domain; else oppcustdrop">
        <div class="col-lg-6 col-md-7 col-xl-7 d-inline-flex">
          <div class="col-lg-9">
            <div class="row col-12">
              <label class="col-lg-4 col-md-2 col-4 text-wrap">Domain</label
              ><label class="col-lg-6 divider_label">{{ domain.domain }}</label>
            </div>
            <div class="row mt-3 col-12">
              <label class="col-lg-4 col-md-2 col-4 text-wrap"
                >Company Name</label
              ><label class="col-lg-6 divider_label">{{
                domain.organisation_name
              }}</label>
            </div>
          </div>
          <div
            class="col-lg-3 col-md-3 col-5 divider_label justify-content-end"
          >
            <input
              (click)="viewCustomer()"
              class="btn btn-primary small-text btn-color rounded-pill p-1 w-100 f-14 align-self-center btn_view_customer mt-4"
              value="View Customer"
            />
          </div>
        </div>

        <div
          class="col-lg-6 col-md-5 col-xl-5 mt-2"
          *ngIf="
            orderBillingCostInfo.project_id &&
            opportunity.order_phase?.link_project_billing?.linking_date
          "
        >
          <span class="product-sans-bold">Order Budget & Alert</span>

          <div class="d-inline-flex gap-2 w-100 align-items-center mt-2">
            <span class="col-2 text-center product-sans-bold">Cost</span>
            <span class="col-2"
              >USD{{ orderBillingCostInfo.total_budget_amount }}</span
            >
            <div class="col-8">
              <ngb-progressbar
                type="primary"
                [value]="orderBillingCostInfo.total_cost_usage"
                [max]="orderBillingCostInfo.total_budget_amount"
                ><span class="progress-text"
                  >{{ orderBillingCostInfo.cost_usage_percentage }}%(USD
                  {{ orderBillingCostInfo.total_cost_usage }})</span
                >
                <div
                  *ngFor="
                    let alert of this.opportunity.order_phase.billing_budget
                      ?.alerts;
                    let i = index
                  "
                  class="cost-alert"
                  #tooltip="matTooltip"
                  matTooltip="Alert {{ i }}
                {{ alert * 100 }}%
                USD {{ orderBillingCostInfo.total_budget_amount * alert }}"
                  matTooltipClass="custom-tooltip"
                  [style.left.%]="alert * 100"
                ></div>
              </ngb-progressbar>
            </div>
          </div>

          <div class="d-inline-flex gap-2 w-100 align-items-center mt-1">
            <span class="col-2 text-center product-sans-bold">Credit</span>
            <span class="col-2"
              >USD{{ orderBillingCostInfo.total_credits }}</span
            >

            <div class="col-8">
              <ngb-progressbar
                type="success"
                [value]="orderBillingCostInfo.total_credits_usage"
                [max]="orderBillingCostInfo.total_credits"
                ><span class="progress-text"
                  >{{ orderBillingCostInfo.credit_usage_percentage }}%(USD
                  {{ orderBillingCostInfo.total_credits_usage }})</span
                ></ngb-progressbar
              >
            </div>
          </div>
        </div>
      </div>
      <ng-template #oppcustdrop>
        <div class="mt-3 row">
          <ng-select
            bindLabel="domain.domain"
            appendTo="body"
            id="domain"
            (search)="onSearchDomainEnter($event)"
            (change)="selectDomain()"
            [(ngModel)]="domainSelected"
            [searchable]="true"
            class="col-7"
            [clearable]="true"
            placeholder="Domain"
          >
            <ng-option
              [value]="domain"
              *ngFor="let domain of searchResultCustomers"
            >
              {{ domain.domain }}
            </ng-option>
          </ng-select>
        </div>
      </ng-template>
      <div class="f-14 mt-4" *ngIf="opportunity?._id">
        <input
          type="checkbox"
          (change)="engEngNotReqChecked($event)"
          [(ngModel)]="opportunity.engineer_engagement_not_required"
        />
        Engineer Engagement not Required
      </div>
    </div>

    <div id="div_setps" class="row">
      <!--- DEAL -->
      <opporunity-deal-phase
        *ngIf="opportunity.deal"
        class="opp_step m-0 p-0"
        [domain]="domain.domain"
        [dealPhaseData]="opportunity.deal"
        [isGWS]="isGWS"
        (backToMainOpp)="saveDeal_back($event)"
        (startDeal)="startDealCreation($event)"
      ></opporunity-deal-phase>
      <!--- SOLUTION BUILDING -->
      <opportunity-solution-building-phase
        *ngIf="opportunity"
        class="opp_step m-0 p-0"
        [solutionPhaseData]="opportunity.solution_building"
        [skip]="opportunity?.skip_steps?.solution_building"
        [disabled]="opportunity?.disabled?.solution_building"
        [isGWS]="isGWS"
        [opporunityID]="opportunity.id"
        (skipEmitter)="skipSolution($event)"
      ></opportunity-solution-building-phase>
      <!--- POC -->
      <opportunity-poc-phase
        class="opp_step m-0 p-0"
        *ngIf="opportunity"
        [pocPhaseData]="opportunity.poc_phase"
        [skip]="opportunity?.skip_steps?.poc"
        [disabled]="opportunity?.disabled?.poc"
        [isGWS]="isGWS"
        [opporunityID]="opportunity.id"
        (skipEmitter)="skipPoc($event)"
      ></opportunity-poc-phase>
      <opporunity-proposal-phase
        class="opp_step m-0 p-0"
        *ngIf="opportunity"
        [proposalPhaseData]="opportunity.proposal"
        [skip]="opportunity?.skip_steps?.proposal"
        [disabled]="opportunity?.disabled?.proposal"
        [isGWS]="isGWS"
        [opporunityID]="opportunity.id"
        [projectName]="opportunity?.poc_phase?.gcp_project?.name"
        (skipEmitter)="skipProposal($event)"
      ></opporunity-proposal-phase>
      <opportunity-order-phase
        class="opp_step m-0 p-0"
        *ngIf="opportunity"
        [orderPhaseData]="opportunity.order_phase"
        [skip]="opportunity?.skip_steps?.order"
        [disabled]="opportunity?.disabled?.order"
        [isGWS]="isGWS"
        [dealType]="opportunity?.deal?.type"
        [opporunityID]="opportunity.id"
        (skipEmitter)="skipOrder($event)"
      ></opportunity-order-phase>
    </div>
  </div>
</div>

<!-- Picked Opportunity POPUP -->
<div
  class="modal fade"
  bsModal
  #OpportunityPickedModal="bs-modal"
  tabindex="-1"
  id="OpportunityPickedModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="OpportunityPickedModalLabel"
        >
          Opportunity has been picked
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="OpportunityPickedModal.hide()"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="container">
          <div class="row">
            <div class="col-4 d-flex justify-content-center">
              <div class="picked p-2">
                <img
                  *ngIf="currentUser.imageUrl"
                  class="img-fluid rounded-circle"
                  [src]="currentUser.imageUrl"
                  [alt]="currentUser.first_name"
                />
                <div class="text-center">
                  {{ currentUser.first_name + " " + currentUser.last_name }}
                </div>
              </div>
            </div>
            <div class="col-6 mt-4 p-2">
              <div class="text-center">
                You have picked this opportunity successfully,you can now start
                adding the customer requirements
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
          (click)="OpportunityPickedModal.hide()"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Pick Users POPUP -->
<div
  class="modal fade"
  bsModal
  #PickUsersModal="bs-modal"
  tabindex="-1"
  id="PickUsersModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="PickUsersModalLabel"
        >
          Pick Presales
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          (click)="PickUsersModal.hide()"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="d-flex justify-content-center">
          <h2 class="h5">
            Pick the presales persons from your team for this opportunity.
          </h2>
        </div>
        <div class="row pt-4 justify-content-center">
          <div
            *ngFor="let user of engineers"
            class="col-md-3 col-sm-6 p-2 d-flex justify-content-center cursor-pointer"
          >
            <div
              [ngClass]="checkPickedUser(user._id) ? 'picked p-4' : 'p-4'"
              (click)="pickEngineer(user._id)"
            >
              <img
                class="img-fluid rounded-circle img_picked_engineer"
                [src]="
                  user.imageUrl
                    ? user.imageUrl
                    : './assets/img/default_user_icon.svg'
                "
                [alt]="user.first_name"
              />
              <div class="text-center">
                {{ user.first_name + " " + user.last_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
          (click)="pickOpporunity('manager')"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Edit DAF/PSF POPUP -->
<div
  class="modal fade"
  bsModal
  #EditSaleAmountModal="bs-modal"
  tabindex="-1"
  id="EditSaleAmountModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content custom-modal">
      <div class="modal-header custom-bg-blue text-white">
        <h5 class="modal-title w-100 text-center" id="EditSaleAmountModalLabel">
          PSF/DAF Amount
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="EditSaleAmountModal.hide()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="d-flex flex-column">
            <!---PSF -->
            <div class="d-flex flex-column gap-2 label_grey_border_bottom">
              <div class="custom-text-color">
                Please edit the PSF Amount here
              </div>
              <div class="row mb-1" *ngIf="opportunity.deal?.psf">
                <div class="col-7">
                  <div class="input-group">
                    <input
                      type="number"
                      placeholder="PSF Amount"
                      class="rounded-pill form-control"
                      [(ngModel)]="opportunity.deal.psf.amount"
                      [disabled]="
                        (!currentUser.isSales && !currentUser.isGPM) ||
                        opportunity.deal?.psf?.google?.approved
                      "
                    />
                  </div>
                </div>
                <div class="col-5">
                  <button
                    *ngIf="
                      opportunity.deal?.psf?.amount &&
                      !opportunity.deal?.psf?.google?.approved &&
                      currentUser.isGPM
                    "
                    class="btn btn-primary custom-btn"
                    (click)="markAsGoogleApproved(false)"
                  >
                    Mark as Approved by Google
                  </button>
                  <img
                    *ngIf="opportunity.deal?.psf?.google?.approved"
                    src="assets/img/google-approve.png"
                    alt="Approved"
                    class="approved-img"
                    title="Approved by Google"
                  />
                </div>
              </div>

              <div class="row mb-2" *ngIf="opportunity.deal?.psf?.amount">
                <label
                  class="form-label text-wrap col-4 mb-0 d-none d-md-inline"
                >
                  Email Attachment Proof
                </label>
                <div
                  *ngIf="
                    opportunity.deal.psf?.attachment_proof_drive_id;
                    then oppPsfFilExists;
                    else oppPsfFilNotExists
                  "
                ></div>
                <ng-template #oppPsfFilNotExists>
                  <div class="col-5">
                    <input
                      type="text"
                      class="form-control rounded-pill m-0 h-36"
                      [value]="opportunity.deal.psf.attachment_proof_name ?? ''"
                      readonly
                    />
                  </div>
                  <div
                    class="col-3 text-end"
                    [ngClass]="{
                      my_disabled:
                        (!currentUser.isSales && !currentUser.isGPM) ||
                        opportunity.deal?.psf?.google?.approved
                    }"
                  >
                    <label for="opp-daf-upload-profile"
                      ><div
                        type="button"
                        class="btn rounded-pill border-primary d-flex align-items-center"
                      >
                        <span class="small pe-1 blue_1">Attach</span>
                        <img
                          src="./assets/img/ic_attach.svg"
                          alt="attach icon"
                        /></div
                    ></label>
                    <input
                      (change)="onAttachDafPsfFile(false, $event)"
                      type="file"
                      id="opp-daf-upload-profile"
                      hidden
                    />
                  </div>
                </ng-template>
                <ng-template #oppPsfFilExists>
                  <div class="col-8 p-0">
                    <a
                      target="_blank"
                      href="{{
                        opportunity.deal.psf.attachment_proof_drive_id
                      }}"
                      >{{ opportunity.deal.psf.attachment_proof_name }}</a
                    >
                  </div>
                </ng-template>
              </div>
            </div>

            <!---DAF -->
            <div
              class="d-flex flex-column gap-2 mb-2 mt-3"
              *ngIf="opportunity.deal?.daf"
            >
              <div class="custom-text-color">
                Please edit the DAF Amount here
              </div>
              <div class="row">
                <div class="col-7">
                  <div class="input-group">
                    <input
                      type="number"
                      [disabled]="
                        (!currentUser.isSales && !currentUser.isGPM) ||
                        opportunity.deal?.daf?.google?.approved
                      "
                      placeholder="DAF Amount"
                      class="rounded-pill form-control"
                      [(ngModel)]="opportunity.deal.daf.amount"
                    />
                  </div>
                </div>
                <div class="col-5">
                  <button
                    *ngIf="
                      opportunity.deal?.daf?.amount &&
                      !opportunity.deal?.daf?.google?.approved &&
                      currentUser.isGPM
                    "
                    class="btn btn-primary custom-btn"
                    (click)="markAsGoogleApproved(true)"
                  >
                    Mark as Approved by Google
                  </button>
                  <img
                    *ngIf="opportunity.deal?.daf?.google?.approved"
                    src="assets/img/google-approve.png"
                    alt="Approved"
                    class="approved-img"
                    title="Approved by Google"
                  />
                </div>
              </div>

              <div class="row mb-2" *ngIf="opportunity.deal?.daf?.amount">
                <label
                  class="form-label text-wrap col-4 mb-0 d-none d-md-inline"
                >
                  Email Attachment Proof
                </label>
                <div
                  *ngIf="
                    opportunity.deal.daf?.attachment_proof_drive_id;
                    then oppDafFilExists;
                    else oppDafFilNotExists
                  "
                ></div>
                <ng-template #oppDafFilNotExists>
                  <div class="col-5">
                    <input
                      type="text"
                      class="form-control rounded-pill m-0 h-36"
                      [value]="opportunity.deal.daf.attachment_proof_name ?? ''"
                      readonly
                    />
                  </div>
                  <div
                    class="col-3 text-end"
                    [ngClass]="{
                      my_disabled:
                        (!currentUser.isSales && !currentUser.isGPM) ||
                        opportunity.deal?.daf?.google?.approved
                    }"
                  >
                    <label for="opp-daf-upload-profile"
                      ><div
                        type="button"
                        class="btn rounded-pill border-primary d-flex align-items-center"
                      >
                        <span class="small pe-1 blue_1">Attach</span>
                        <img
                          src="./assets/img/ic_attach.svg"
                          alt="attach icon"
                        /></div
                    ></label>
                    <input
                      (change)="onAttachDafPsfFile(false, $event)"
                      type="file"
                      id="opp-daf-upload-profile"
                      hidden
                    />
                  </div>
                </ng-template>
                <ng-template #oppDafFilExists>
                  <div class="col-8 p-0">
                    <a
                      target="_blank"
                      href="{{
                        opportunity.deal.daf.attachment_proof_drive_id
                      }}"
                      >{{ opportunity.deal.daf.attachment_proof_name }}</a
                    >
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer custom-bg-white border-top border-black">
          <button
            type="button"
            [disabled]="
              !currentUser.isSales &&
              !currentUser.isGPM &&
              !currentUser.isEngineer
            "
            class="btn btn-primary btn-lg"
            data-bs-dismiss="modal"
            (click)="editDafPsf()"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  bsModal
  #mspModal="bs-modal"
  tabindex="-1"
  id="mspModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content custom-modal">
      <div class="modal-header custom-bg-blue text-white">
        <h5 class="modal-title w-100 text-center">
          Providing Managed Services
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white ms-0"
          (click)="mspModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body pt-0">
        <div class="mt-3 product-sans-medium">
          Please mark the below check box if you will provide MSP qualifying
          services to this customer. And add an email attachment as a proof.
        </div>
        <msp-popup
          [opportunityRfId]="opportunity.id"
          [canEdit]="true"
        ></msp-popup>
        <div class="modal-footer">
          <input
            [ngClass]="{
              my_disabled:
                (!currentUser.isSales && !currentUser.isFinanceTeam) ||
                !opportunity._id
            }"
            class="btn btn-primary small-text btn-color rounded-pill col-2 p-1 p-xl-2 fs-6 align-self-end product-sans-light"
            value="Save"
            (click)="saveMSP()"
            type="button"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!--Delivered Services Modal-->
<div
  class="modal fade"
  bsModal
  #FullyDeliveredServicesModal="bs-modal"
  tabindex="-1"
  id="FullyDeliveredServicesModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content custom-modal">
      <div class="modal-header custom-bg-blue text-white">
        <h5 class="modal-title w-100 text-center" id="EditSaleAmountModalLabel">
          Fully Delivered Services
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="FullyDeliveredServicesModal.hide()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="d-flex flex-column">
            <div class="d-flex flex-column gap-2 label_grey_border_bottom">
              <div class="custom-text-color">
                Please mark the below check box if all services have been
                successfully delivered to the customer and add the delivery date
              </div>

              <div
                class="row mb-1 services-row pt-3 align-items-center"
                [ngClass]="servicesDelivered ? 'services-delivered' : ''"
              >
                <div class="col-7">
                  <div class="input-group">
                    <mat-checkbox
                      class="example-margin"
                      [checked]="servicesDelivered"
                      (change)="servicesDeliveredChecked($event.checked)"
                      [disabled]="servicesAlreadyDelivered"
                    >
                      All services have been delivered to the customer
                    </mat-checkbox>
                  </div>
                </div>
                <div class="col-5 text-end">
                  <img
                    src="./assets/img/services-delivered.png"
                    alt="Approved"
                    class="approved-img"
                    title="Approved by Google"
                  />
                </div>

                <div
                  class="mb-2 d-flex pt-3 align-items-center justify-content-between"
                >
                  <label
                    class="form-label text-wrap col-2 mb-0 d-none d-md-inline"
                    >Delivery Date</label
                  >
                  <div class="col-10">
                    <input
                      type="date"
                      [(ngModel)]="delivery_date"
                      [disabled]="servicesAlreadyDelivered"
                      class="form-control rounded-pill"
                      placeholder="Delivery Date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer custom-bg-white border-top border-black">
          <button
            type="button"
            [disabled]="servicesAlreadyDelivered || !currentUser.isEngineer"
            class="btn btn-primary btn-lg"
            data-bs-dismiss="modal"
            (click)="servicesDeliveredSave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
