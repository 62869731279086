import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderRenewalListComponent } from './order-renewal-list.component';

const routes: Routes = [{ path: '', component: OrderRenewalListComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRenewalListRoutingModule {}
