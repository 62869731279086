<div
  class="row flex-xl-nowrap gx-2 align-items-center col-12 mt-3 mt-sm-0 mb-3 product-sans-light"
>
  <div class="col-7 col-lg-2 mb-2 mb-lg-0 pe-2 ps-0">
    <input
      id="txt-order-global-search"
      type="search"
      class="form-control rounded-pill h-36 w-100"
      [ngModel]="filtersearch"
      (keyup.enter)="searchOrder($event)"
      placeholder="Search "
    />
  </div>
  <div class="form-floating my-2 my-md-0 col-7 col-lg-3 ps-0 pe-0">
    <div class="date-range-picker-container row">
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            matInput
            id="floating-date-from"
            placeholder="Start date"
            [(ngModel)]="default_date_from"
          />
          <input
            matEndDate
            matInput
            id="floating-date-to"
            placeholder="End date"
            [(ngModel)]="default_date_to"
            (dateChange)="chooseDateOrders()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-7 col-lg-2 mt-2 mt-lg-0 px-2">
    <select
      id="salesperson"
      class="form-select rounded-pill p-2"
      #SalesPerson
      aria-label="Select Sales Person"
      required
      (change)="SalesPersonSelect($event)"
    >
      <option value="" selected disabled hidden>Account Manager</option>
      <option value="clear"></option>
      <option *ngFor="let sale of sales" value="{{ sale.id }}">
        {{ sale.first_name }}{{ sale.last_name }}
      </option>
    </select>
  </div>
  <div class="col-7 col-lg-2 mt-2 mt-lg-0 px-2">
    <select
      id="order-status"
      class="form-select rounded-pill p-2"
      aria-label="Select Order Status"
      #orderStatusSelect
      required
      (change)="OrderStatusSelect($event)"
    >
      <option value="" selected disabled hidden>Order Status</option>
      <option value="clear"></option>
      <option *ngFor="let orderstatus of orderStatus" value="{{ orderstatus }}">
        {{ orderstatus }}
      </option>
    </select>
  </div>
  <div class="form-check col-lg-2 mt-3 mt-xl-0">
    <input
      class="form-check-input"
      #notCheckedOrdersCheckbox
      type="checkbox"
      (change)="onlyNotCheckedHandle()"
    />
    <label class="form-check-label text-wrap" for="not-checked-orders">
      Unchecked only
    </label>
  </div>
  <div class="form-check col-lg-1 mt-3 mt-xl-0">
    <button
      class="clear-filters-button px-3 py-2 mr-3"
      (click)="resetFilters()"
    >
      Reset
    </button>
  </div>
</div>
<div class="d-block text-end">
  <button
    class="btn btn-primary product-sans-light small-text btn-color rounded-pill col-2 col-lg-1 p-1 p-xl-2 me-2"
    type="button"
    (click)="export()"
  >
    Export
  </button>
  {{ minUserIndex }} - {{ maxUserIndex }} of {{ totalItems }}
  <img
    src="./assets/img/back-icon.svg"
    class="px-1 px-md-2 mx-0 mx-md-2 cursor-pointer"
    [ngClass]="{ disabled: currentPage === 1 }"
    alt="back icon"
    (click)="currentPage === 1 ? false : onPageChanged(currentPage - 1)"
  />
  <img
    src="./assets/img/next-icon.svg"
    class="pe-1 pe-md-2 cursor-pointer"
    [ngClass]="{ disabled: maxUserIndex === 0 || currentPage === totalPages }"
    alt="next icon"
    (click)="
      maxUserIndex === 0 || currentPage === totalPages
        ? false
        : onPageChanged(currentPage + 1)
    "
  />
</div>
<div class="table-responsive fluid-section bg-white mt-3 mt-md-3 w-100">
  <table class="table table-hover align-middle text-center small-text">
    <thead class="position-sticky top-0 bg-white">
      <tr class="product-sans-medium">
        <th
          scope="col"
          class="p-1 p-md-2 text-md-start ps-md-5 border-dark border-top-0"
        >
          ID
        </th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Product</th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Status</th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">
          Order Date
        </th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Domain</th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">
          Customer
        </th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">
          Account Manager
        </th>

        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Action</th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Checked</th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">
          Verified by Finance
        </th>
      </tr>
    </thead>
    <tbody class="product-sans-light">
      <tr *ngFor="let order of orders">
        <td
          class="p-1 p-md-2 py-md-3 text-md-start ps-md-5 text-primary cursor-pointer"
          (click)="viewOrder(false, order._id)"
        >
          {{ order.id }}
        </td>
        <td class="p-1 p-md-2 py-md-3">{{ order.sku_names }}</td>
        <td class="p-1 p-md-2 py-md-3">{{ order.order_status }}</td>
        <td class="p-1 p-md-2 py-md-3">
          {{ order.created_at | date : "dd-MMM-yyyy" }}
        </td>
        <td class="p-1 p-md-2 py-md-3">{{ order.domain?.domain }}</td>
        <td class="p-1 p-md-2 py-md-3">
          {{ order.domain?.organisation_name }}
        </td>
        <td class="p-1 p-md-2 py-md-3 cursor-pointer">
          <div [ngbPopover]="popoverContent" triggers="mouseenter:mouseleave">
            {{ order.sale_name }}
          </div>
        </td>
        <ng-template #popoverContent>
          <div>{{ order.sale_email }}</div>
        </ng-template>

        <td class="p-1 p-md-2 py-md-3">{{ order.action }}</td>
        <td class="p-1 p-md-2 py-md-3">
          <input
            class="cursor-pointer border-0"
            type="checkbox"
            [checked]="order.checked ? true : null"
            (change)="onCheck($event, order.id)"
            [disabled]="order.checked"
          />
        </td>
        <td class="p-1 p-md-2 py-md-3">
          <input
            class="cursor-pointer border-0"
            type="checkbox"
            [checked]="order.verified ? true : null"
            (change)="onVerify($event, order.id)"
            [disabled]="!order.checked || order.verified"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #popoverContent let-order="order">
  <!-- Your popover content here -->
  <!-- For example: -->
  <div>{{ order.sale_email }}</div>
</ng-template>
