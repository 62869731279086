import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntegrationModel } from 'src/models/mongo/integration.model';
import { Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  // Currency
  createCurrency(CurrencyData: any) {
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_CURRENCY,
      CurrencyData
    );
  }

  updateCurrency(currencyId: string, currencyData: any) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.EDIT_CURRENCY + '/' + currencyId,
      currencyData
    );
  }

  getAllCurrencies() {
    return this.http.get(Endpoint.API_URL + '' + Endpoint.GET_CURRENCIES);
  }

  getCurrencyById(CurrencyData: any) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_CURRENCY + '/' + CurrencyData
    );
  }

  deleteCurrency(CurrencyData: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_CURRENCY + '/' + CurrencyData
    );
  }

  // Exchange Rate
  createExchangeRate(ExchangeData: any) {
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_EXCHANGE,
      ExchangeData
    );
  }

  getAllExchanges() {
    return this.http.get(Endpoint.API_URL + '' + Endpoint.GET_EXCHANGES);
  }

  deleteExchangeRate(Data: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_EXCHANGE + '/' + Data
    );
  }

  getExchangeRateById(Data: any) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_EXCHANGE + '/' + Data
    );
  }

  updateExchangeRate(Id: string, Data: any) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.EDIT_EXCHANGE + '/' + Id,
      Data
    );
  }

  // Group
  createGroup(GroupData: any) {
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_GROUP,
      GroupData
    );
  }

  getAllGroups(params = null) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.ALL_GROUPS_FILE_NAME,
      { params: params }
    );
  }

  deleteGroup(Data: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_GROUP + '/' + Data
    );
  }

  getGroupById(Data: any) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_GROUP + '/' + Data
    );
  }

  updateGroup(Id: string, Data: any) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.EDIT_GROUP + '/' + Id,
      Data
    );
  }

  // SKUs
  createSku(Sku: any) {
    return this.http.post(Endpoint.API_URL + '' + Endpoint.ADD_SKU, Sku);
  }

  getAllSkus() {
    return this.http.get(Endpoint.API_URL + '' + Endpoint.GET_SKUS);
  }

  deleteSku(Data: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_SKU + '/' + Data
    );
  }

  getSkuById(Data: any) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_SKU + '/' + Data
    );
  }

  updateSku(Id: string, Data: any) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.EDIT_SKU + '/' + Id,
      Data
    );
  }

  // Permission
  createPermission(Permission: any) {
    if (Utilities.isNullOrEmpty(Permission?.code)) {
      Permission.code = Permission.name?.toUpperCase()?.replace(' ', '_');
    }
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_PERMITION,
      Permission
    );
  }

  getAllPermissions() {
    return this.http.get(Endpoint.API_URL + '' + Endpoint.GET_PERMISSIONS);
  }

  deletePermission(Data: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_PERMISSION + '/' + Data
    );
  }

  getPermissionById(Data: any) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_PERMISSION + '/' + Data
    );
  }

  updatePermission(Id: string, Data: any) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.EDIT_PERMITION + '/' + Id,
      Data
    );
  }

  //API Access Token
  createToken(Token: any) {
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_API_ACCESS_TOKEN,
      Token
    );
  }

  getAllTokens() {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_API_ACCESS_TOKENS
    );
  }

  deleteToken(Data: any) {
    return this.http.delete(
      Endpoint.API_URL + '' + Endpoint.DELETE_API_ACCESS_TOKEN + '/' + Data
    );
  }
  getAllIntegrations() {
    return this.http.get(Endpoint.API_URL + '' + Endpoint.GET_INTEGRATIONS);
  }
  updateIntegration(model: IntegrationModel) {
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.UPDATE_INTEGRATION,
      model
    );
  }
  getIntegrationById(id: string) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_INTEGRATION_BY_ID + '/' + id
    );
  }
  getIntegrationByCode(code: string) {
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.GET_INTEGRATION_BY_CODE,
      { params: { code: code } }
    );
  }
}
