import { Component } from '@angular/core';

@Component({
  selector: 'lib-gcp',
  template: `
    <p>
      gcp works!
    </p>
  `,
  styles: [
  ]
})
export class GcpComponent {

}
