<div
  class="modal fade"
  bsModal
  #vrmApprovalModal="bs-modal"
  tabindex="-1"
  id="vrmApprovalModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_app_header">
        <h1 class="modal-title fs-5 product-sans col-11">
          VRM Approval Needed
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="vrmApprovalModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="d-flex align-items-center justify-content-between">
          You are heading to create a POC without a DAF request. If you want to
          proceed with this step, first you need to get the approval of the VRM.
          If not approved by the VRM, a DAF request has to be sent to Google
          before being able to create the POC.
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="send()"
          class="btn btn-primary btn-color rounded-pill col-lg-3 col-md-4 col-5"
          data-bs-dismiss="modal"
        >
          Send Request to VRM
        </button>
      </div>
    </div>
  </div>
</div>
