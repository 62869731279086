import { Injectable } from '@angular/core';
import { OrderSupportModel } from 'src/models/mongo/order.model';
import { Utilities } from 'src/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor() {}

  public isOrderSupportValid(support: OrderSupportModel): boolean {
    return (
      !support.available ||
      (support.available &&
        !Utilities.isNullOrEmpty(support.start_date) &&
        !Utilities.isNullOrEmpty(support.end_date) &&
        !Utilities.isNullOrEmpty(support.price))
    );
  }
}
