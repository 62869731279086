import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EngineerRecommendationsRoutingModule } from './engineer-recommendations-routing.module';
import {EngineerRecommendationsComponent} from './engineer-recommendations.component';

@NgModule({
  declarations: [EngineerRecommendationsComponent],
  imports: [
    CommonModule,
    EngineerRecommendationsRoutingModule
  ],
  exports: [EngineerRecommendationsComponent],
})
export class EngineerRecommendationsModule { }
