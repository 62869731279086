import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DafRequestDecisionPopupRoutingModule } from './daf-request-decision-popup-routing.module';
import { DafRequestDecisionPopupComponent } from './daf-request-decision-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [DafRequestDecisionPopupComponent],
  imports: [CommonModule, ModalModule.forRoot()],
  exports: [DafRequestDecisionPopupComponent],
})
export class DafRequestDecisionPopupModule {}
