import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Endpoint, Unicode } from 'src/shared/Endpoint';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private http: HttpClient) {}

  createCustomer(customerData: any, toExecute: boolean) {
    if (customerData?.domain) customerData.domain = customerData.domain.trim();
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    return this.http.post(
      Endpoint.API_URL + '' + Endpoint.ADD_CUST,
      customerData,
      { params: params }
    );
  }

  updateCustomer(
    customerData: any,
    toExecute: boolean,
    withoutLog: string = '0'
  ) {
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    if (withoutLog && withoutLog == '1')
      params = params.set(Unicode.WITHOUT_LOG, withoutLog);
    return this.http.put(
      Endpoint.API_URL + '' + Endpoint.UP_CUST + '/' + customerData._id,
      customerData,
      { params: params }
    );
  }

  checkCustomerExistenceDbAPI(domain: string, withoutLog: boolean = true) {
    var params = new HttpParams().set(Unicode.DOMAIN, domain);
    params = params.set(Unicode.WITHOUT_LOG, withoutLog);
    return this.http.get(
      Endpoint.API_URL + '' + Endpoint.CHECK_CUSTOMER_EXISTENCE_API_DB,
      { params: params }
    );
  }
}
