<!-- RENEWAL SETTINGS CONFIRMATION POPUP -->
<div
  class="modal fade"
  bsModal
  #renewalSettingsModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="renewalSettingsModalLabel"
        >
          Update Renewal Settings
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="renewalSettingsModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark px-5">
        <div class="row my-3">
          <div
            (click)="newRenewalSettingsType = 'AUTO_RENEW_YEARLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Auto Renew Yearly Pay</h2>
            <img
              src="./assets/img/auto-renew-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval,
                automatically renew the subscription's plan as a yearly payment
                with the same number of seats.
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'AUTO_RENEW_MONTHLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Auto Renew Monthly Pay</h2>
            <img
              src="./assets/img/auto-renew-icons-monthly.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval,
                automatically renew the subscription's plan as monthly with the
                same number of seats.
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'RENEW_CURRENT_USERS_YEARLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Renew Current Users Yearly Plan</h2>
            <img
              src="./assets/img/auto-renew-current-monthly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, renew the
                subscription plan as a yearly payment with the total number of
                currently active user license seats. This is the default setting
                for active annual commitment plans (paid yearly).
              </p>
            </div>
          </div>
          <div
            (click)="newRenewalSettingsType = 'SWITCH_TO_PAY_AS_YOU_GO'"
            class="d-flex flex-column align-items-center justify-content-between col-3 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Switch to Pay As You Go</h2>
            <img
              src="./assets/img/pay-as-you-go-icon.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, change the
                annual commitment plan to a flexible plan.
              </p>
            </div>
          </div>
        </div>
        <div class="row mx-5 px-5">
          <div
            (click)="newRenewalSettingsType = 'RENEW_CURRENT_USERS_MONTHLY_PAY'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Renew Current Users Monthly Plan</h2>
            <img
              src="./assets/img/auto-renew-current-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan's interval, renew the
                subscription plan as a monthly payment with the total number of
                currently active user license seats. This is the default setting
                for active annual commitment plans (paid monthly).
              </p>
            </div>
          </div>

          <div
            (click)="newRenewalSettingsType = 'CANCEL'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">Cancel</h2>
            <img
              src="./assets/img/cancel-icon.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of an annual commitment plan interval, the
                subscription is suspended.
              </p>
            </div>
          </div>

          <div
            (click)="newRenewalSettingsType = 'RENEW_ON_PROPOSED_OFFER'"
            class="d-flex flex-column align-items-center justify-content-between col-4 p-3 cursor-pointer hoverable-div"
          >
            <h2 class="h6 blue-text">RENEW ON PROPOSED OFFER</h2>
            <img
              src="./assets/img/auto-renew-current-yearly-icons.svg"
              alt="auto renew yearly icon"
            />
            <div class="info-box ms-auto">
              <img
                src="./assets/img/info-icon.svg"
                alt="suspend icon"
                class="cursor-pointer"
              />
              <p class="text-center small pt-4 hoverable-text">
                At the end of the current commitment plan's interval, renew on
                the latest renewal proposal with the number of currently active
                user licenses or proposed offer commitment whichever is higher.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-dark border-opacity-50">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          (click)="updateRenewalSettings()"
          type="submit"
          value="Save"
        />
      </div>
    </div>
  </div>
</div>
