import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProposalModel } from 'src/models/mongo/proposal.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DomainModel } from '../../../../../src/models/mongo/domain.model';
import { ProposalService } from '../proposal.service';

@Component({
  selector: 'proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrls: ['./proposal-details.component.scss'],
})
export class ProposalDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding('class') class = '';
  currentUser: UserModel;
  proposal: ProposalModel = new ProposalModel();
  title: string = 'Create Proposal';
  googleDocData: any;
  currentDomain: DomainModel = new DomainModel();
  loginUsersData: any;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private proposalService: ProposalService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.loginUsersData = Utilities.getLoginUserInfo(localStorage);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(async (routeParams) => {
      const state = history.state;
      if (state?.proposal) this.proposal = state.proposal;
      else if (routeParams['Id']) {
        //EMAIL LINK CASE
        await this.http
          .get(Endpoint.API_URL + Endpoint.GET_POC_BY_ID + routeParams['Id'])
          .toPromise()
          .then((resp: any) => {
            if (resp) {
              this.proposal = resp;
            }
          });
      }
      this.proposal = Object.assign(new ProposalModel(), this.proposal);
      if (this.proposal?._id) {
        this.title = 'Proposal';
        this.googleDocData = {
          module: 'proposal',
          customer_domain: this.proposal.domain.domain,
          customer_org: this.proposal.domain.organisation_name,
          googleDocUrl: this.proposal.googleDocUrl,
          createdUser: {
            name: this.proposal.user_proposal.created_user_name,
            role: this.proposal.user_proposal.created_user_role,
            date: this.proposal.created_at,
            text: 'Created Proposal',
            img: this.proposal.user_proposal.created_user_img,
          },
          approval_phases: this.proposal?.approval_phases,
        };
      } else if (this.proposal) {
        this.googleDocData = {
          module: 'proposal',
          customer_domain: null,
          customer_org: null,
        };
      }
      this.googleDocData.googleDocUrl =
        this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/document/d/' +
            this.proposal.google_doc_id +
            '/edit?usp=sharing&embed=true'
        );
    });
  }

  ngOnInit(): void {}

  selectCustomer(dm: DomainModel) {
    this.currentDomain = dm;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.currentDomain)
    );
  }

  createproposal(proposal: ProposalModel) {
    this.router.navigate(['proposal']);
  }

  approveproposal(proposal: ProposalModel) {
    this.router.navigate(['proposal']);
  }

  sendDraftproposalForApproval(proposal: ProposalModel) {
    this.router.navigate(['proposal']);
  }

  async sendproposalToCustomer(customerInfo) {
    var that = this;
    await this.proposalService
      .sendProposalToCustpmer(
        this.proposal,
        customerInfo,
        this.currentUser,
        this.proposal.opportunity_rfid
      )
      .then((result) => {
        if (result) {
          this.router.navigate(['poc']);
        }
      });
  }

  cancel() {
    this.router.navigate(['proposal']);
  }
}
