<div
  class="modal fade"
  bsModal
  #rejectionModal="bs-modal"
  tabindex="-1"
  id="rejectionModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header justify-content-center border-0">
        <h1 class="modal-title fs-5 product-sans text-center col-11">
          Please specify why it has been rejected.
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="rejectionModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mt-4 d-flex align-items-center justify-content-between">
          <textarea
            rows="6"
            #sowRejectionReason
            placeholder="Rejection reason"
            class="rounded-4 deal_description_area col-12 p-2 h-100"
          ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="reject(sowRejectionReason.value)"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</div>
