<div
  class="modal fade"
  id="setBillingAccountModal"
  bsModal
  #setBillingAccountModal="bs-modal"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-5 px-2">
      <div class="modal-header justify-content-between border-0">
        <h1 class="modal-title fs-5 product-sans text-start col-11">
          Set Billing Account
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="setBillingAccountModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div
          class="mb-3 d-flex align-items-center justify-content-between form-floating position-relative"
        >
          <input
            type="text"
            class="rounded-pill form-control"
            id="nv_b_project_name"
            placeholder="Project Name"
            required
            disabled
          />
          <label
            for="nv_b_project_name"
            class="form-label text-wrap col-3 me-3 mb-0 d-none d-md-inline"
            >{{ projectName }}</label
          >
        </div>
        <div class="form-floating">
          <ng-select
            bindLabel="billingAccount.name"
            appendTo="body"
            [searchable]="true"
            [clearable]="false"
            class="col-12 p-0"
            [(ngModel)]="billingAccountSelected"
            placeholder="Billing Account"
          >
            <ng-option
              [value]="billingAccount"
              *ngFor="let billingAccount of billingAccountList"
            >
              {{ billingAccount.name }}
            </ng-option>
          </ng-select>
        </div>
        <p class="text-secondary mb-2 small">
          Any charges for this project will be billed to the account you select
          here.
        </p>
        <div class="d-flex gap-2 justify-content-end">
          <input
            class="btn btn-color-outline small-text rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Close"
            type="button"
            (click)="setBillingAccountModal.hide()"
          />
          <input
            class="btn btn-primary small-text btn-color rounded-pill p-1 p-xl-2 mt-3 fs-6 align-self-end product-sans-light"
            value="Set Account"
            type="button"
            (click)="linkBillingAccount()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
