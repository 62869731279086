<div class="container-fluid">
  <div class="bg-white box-shadow mobile-section row rounded-5 p-0 m-0">
    <div class="row">
      <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 col">
        <a
          ><img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            (click)="cancelOrder()"
            alt="back icon" /></a
        >Create an Order
      </h1>
      <div class="d-flex flex-row ms-3">
        <h2 class="h5 mt-2 mb-3 col-10 p-0">Order details</h2>
      </div>
    </div>

    <app-add-order-form
      [actions]="actions"
      [isGWS]="isGWS"
      [dealSKUs]="dealSKUs"
      [dealType]="dealType"
      [fromCustomer]="fromCustomer"
      [model]="model"
    ></app-add-order-form>
  </div>
</div>
