import { BaseModel } from './mongo/base.model';

export class RequestResultModel extends BaseModel {
  success: boolean;
  data;
  errorMessage: string;

  constructor(_sucess, msg, data = null) {
    super();
    this.success = _sucess;
    this.errorMessage = msg;
    this.data = data;
  }
}
