import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerGcpProjectsComponent } from './customer-gcp-projects.component';

const routes: Routes = [{ path: '', component: CustomerGcpProjectsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerGcpProjectsRoutingModule { }
