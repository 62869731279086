import { HttpClient, HttpParams } from '@angular/common/http';
import { Input } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OrderModel } from 'src/models/mongo/order.model';
import { UserModel } from 'src/models/mongo/user.model';
import { RequestResultModel } from 'src/models/request_result.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  OrderAction,
  OrderStatus,
  Endpoint,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-renewal-settings-modal',
  templateUrl: './renewal-settings-modal.component.html',
  styleUrls: ['./renewal-settings-modal.component.scss'],
})
export class RenewalSettingsModalComponent implements OnInit {
  @ViewChild('renewalSettingsModal')
  public renewalSettingsModal: ModalDirective;
  @Input() renewalSettingsInfo;
  newRenewalSettingsType: string = '';
  googleProducts = [];
  plans = [];

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private router: Router
  ) {}
  async ngOnInit(): Promise<void> {
    this.plans = await Utilities.planList(this.http, this.localStorage);
    this.googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
  }

  show() {
    this.renewalSettingsModal.show();
  }
  hide() {
    this.renewalSettingsModal.hide();
  }

  async updateRenewalSettings() {
    if (Utilities.isNullOrEmpty(this.newRenewalSettingsType)) {
      alert('Select Renewal type');
      return;
    }
    var currentUser: UserModel = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    var plans = await Utilities.planList(this.http, this.localStorage);
    var googleProducts = await Utilities.googleProductList(
      this.http,
      this.localStorage
    );
    var order = new OrderModel();
    order.action = OrderAction.UPDATE_RNEWAL_SETTNGS;
    order.created_by_rfid = currentUser._id;
    order.domain_rfid = this.renewalSettingsInfo.customerRfid;
    order.customer_api_id = this.renewalSettingsInfo.customerId;
    order.is_gcp = false;
    order.order_status = currentUser.isExecutor
      ? OrderStatus.Executed
      : OrderStatus.Pending;
    var seatsNbr = 0;
    if (
      this.renewalSettingsInfo.plan.planName == Unicode.FLEXIBLE_PLAN ||
      this.renewalSettingsInfo.plan.planName == Unicode.TRIAL_PLAN
    ) {
      seatsNbr = this.renewalSettingsInfo.seats?.maximumNumberOfSeats ?? 0;
    } else {
      seatsNbr = this.renewalSettingsInfo.seats?.numberOfSeats ?? 0;
    }
    order.sku = [
      {
        sku_id: this.renewalSettingsInfo.skuId,
        sku_name: this.renewalSettingsInfo.skuName,
        plan_id: plans.find(
          (x) => x.code == this.renewalSettingsInfo.plan?.planName
        )._id,
        license: seatsNbr,
        subscription_api_id: this.renewalSettingsInfo.subscriptionId,
        cost_price: 0,
        selling_price: 0,
        with_customer_offer: false,
        with_google_offer: false,
        renewel_setting_type: this.newRenewalSettingsType,
      },
    ];
    var toExecute = currentUser.isExecutor ? '1' : '0';
    var params = new HttpParams().set(Unicode.TO_EXECUTE, toExecute);
    var receivers = this.renewalSettingsInfo.receivers;
    params = params.set(Unicode.EMAIL_RECEIVERS, JSON.stringify(receivers));
    await this.http
      .post(Endpoint.API_URL + '' + Endpoint.ADD_ORDER, order, {
        params: params,
      })
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        if (resp && resp.success == true && !Utilities.isNullOrEmpty(resp.id)) {
          order._id = resp.id;
          this.renewalSettingsModal.hide();
          if (this.newRenewalSettingsType == 'RENEW_ON_PROPOSED_OFFER') {
            this.router.navigate(['deals/add-deal'], {
              queryParams: {},
              skipLocationChange: true,
            });
          }
        } else {
          var msg = resp.message ?? 'Internal Server Error';
          try {
            msg = JSON.parse(resp.message).error.message;
          } catch (e) {}
          alert(msg);
        }
      });
  }
}
