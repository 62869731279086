<div class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 rounded-5 w-100">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a><img src="./assets/img/back-icon.svg" class="back-icon cursor-pointer" (click)="goBack()" alt="back icon" /></a>
      Notifications
    </h1>
    <div class="d-flex justify-content-end w-100 w-md-auto mt-2 mt-md-0">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-start pt-3 flex-wrap">
    <div class="col-12 col-md-6 d-flex flex-wrap flex-md-nowrap align-items-center mt-3 mt-md-0 p-0">
      <!-- Additional content can go here if needed -->
    </div>
    <div class="col-12 col-md-6 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-end mt-3 mt-md-0 p-0">
      <div class="btn-group">
        <button
          data-bs-toggle="modal"
          data-bs-target="#customerCreateOrderModal"
          class="btn btn-primary rounded-pill btn-color me-2"
          (click)="markAsRead()"
        >
          Mark as Read
        </button>
        <button
          data-bs-toggle="modal"
          data-bs-target="#customerCreateOrderModal"
          class="btn btn-primary rounded-pill btn-color"
          (click)="markAllAsRead()"
        >
          Mark all as Read
        </button>
      </div>
    </div>
  </div>

  <div class="table-responsive fluid-section w-100 mt-3">
    <table class="table table-hover align-middle text-center small-text min-h-200" id="myTable">
      <tbody class="product-sans-light">
      <tr
        *ngFor="
            let notification of GetData
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  }
          "
      >
        <td class="text-start p-1 p-md-2 position-relative">
          <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center w-100">
            <div class="d-flex align-items-center me-2">
              <div class="form-check me-2">
                <input
                  class="form-check-input checkbox"
                  type="checkbox"
                  (change)="clickElt($event, notification._id)"
                />
              </div>
              <img *ngIf="isCustomerAction(notification.non_translated_action)"
                class="cursor-pointer img-user"
                src="./assets/img/user-profile.png"
                alt="{{ notification.customer_name }}"
                title="{{ notification.customer_name }}"
              />
              <img *ngIf="!isCustomerAction(notification.non_translated_action)"
                   class="cursor-pointer img-user"
                   src="{{ notification.user_img }}"
                   alt="{{ notification.user_fullname }}"
                   title="{{ notification.user_fullname }}"
              />
            </div>
            <div class="d-flex flex-column mt-2 mt-md-0 ms-md-2 w-100">
              <div>
                <span class="fw-bold"
                      *ngIf="!isCustomerAction(notification.non_translated_action)">
                  {{ notification.user_fullname }}</span>
                <span class="fw-bold" *ngIf="isCustomerAction(notification.non_translated_action)">
                  {{ notification.customer_name }}</span>
                <span>{{ notification.action }}</span>
                <span *ngIf="notification.next_action">and {{ notification.next_action }}</span>.
                <a [href]="getOpportunityDetailsUrl(notification)" target="_blank" title="View Details">
<!--                  <span class="fw-bold"> Phase ID: {{notification.phase_rfid}}</span></a>-->
                <span class="fw-bold"> View Details </span></a>
              </div>
              <div class="d-flex align-items-center justify-content-between mt-1">
                <span>{{ notification.time }}</span>
              </div>
            </div>
          </div>
          <div
            *ngIf="!notification.read"
            class="notification-dot color{{ notification._id }} colors"
          ></div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
