<div class="d-flex justify-content-between align-items-center py-1">
  <input
    id="txt-cust-orders-search"
    type="search"
    (keyup.enter)="search()"
    class="col-3 form-control h-36 rounded-pill w-auto"
    placeholder="Search..."
  />
  <div class="d-flex">
    <button
      (click)="exportReport()"
      class="btn btn-color btn-primary fs-6 rounded-pill small-text"
      type="button"
    >
      Export
    </button>
    <pagination-controls
      [responsive]="true"
      previousLabel=""
      nextLabel=""
      class=""
      (pageChange)="renderPage($event)"
    ></pagination-controls>
  </div>
</div>
<div class="table-responsive fluid-section bg-white mt-3 mt-md-3 w-100">
  <table class="table table-hover align-middle text-center small-text">
    <thead class="position-sticky top-0 bg-white">
      <tr class="border-dark product-sans-medium">
        <th
          scope="col"
          class="p-1 p-md-2 text-md-start ps-md-5 border-dark border-top-0"
        >
          Domain
        </th>
        <th
          scope="col"
          class="p-1 p-md-2 text-md-start ps-md-5 border-dark border-top-0"
        >
          Customer Name
        </th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">
          Total users
        </th>
        <th scope="col" class="p-1 p-md-2 border-dark border-top-0">Details</th>
      </tr>
    </thead>
    <tbody class="product-sans-light">
      <tr
        *ngFor="
          let customer of GetData
            | paginate
              : {
                  itemsPerPage: GetPageSize,
                  currentPage: GetPagination,
                  totalItems: GetTotalCount
                }
        "
      >
        <td class="p-1 p-md-2 py-md-3 text-md-start ps-md-5">
          {{ customer.domain }}
        </td>
        <td class="p-1 p-md-2 py-md-3 text-md-start ps-md-5">
          {{ customer.organisation_name }}
        </td>
        <td class="p-1 p-md-2 py-md-3">{{ customer.totalNbrOfUsers }}</td>
        <td class="p-1 p-md-2 py-md-3" [innerHTML]="customer.details"></td>
      </tr>
    </tbody>
  </table>
</div>
