import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-reports',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  active = '1';
  tabs = [
    { id: '1', name: 'Orders', link: 'orders-report' },
    { id: '2', name: 'Customers', link: 'customers-report' },
  ];

  constructor(private router: Router,  private location: Location) {}
  ngOnInit(): void {}

  @HostBinding('class') classAttribute: string =
    'container-fluid w-100 d-flex flex-column justify-content-between fluid-section pt-2';

  static searchGlobal(x, y, z, t, a, d, b) {}
  goBack(): void {
    this.location.back();
  }
}
