import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProposalListRoutingModule } from './proposal-list-routing.module';
import { ProposalListComponent } from './proposal-list.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgSelectModule} from '@ng-select/ng-select';
import {PocListRoutingModule} from '../../../../poc/src/lib/poc-list/poc-list-routing.module';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    ProposalListComponent
  ],
  imports: [
    CommonModule,
    ProposalListRoutingModule,
    NgxPaginationModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule
  ]
})
export class ProposalListModule { }
