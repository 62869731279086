import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { OpportunityService } from 'projects/opportunity/src/public-api';
import { OpportunityModel } from 'src/models/mongo/opportunity.model';
import { UserModel } from 'src/models/mongo/user.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'msp-popup',
  templateUrl: './msp-popup.component.html',
  styleUrls: ['./msp-popup.component.scss'],
})
export class MspPopupComponent {
  @Input() opportunityRfId: string;
  @Input() canEdit: boolean = false;
  msp: any = {};
  currentUser: UserModel;
  opportunity: OpportunityModel;
  @Output() mspDataEmitter = new EventEmitter<any>(); //BILLING OUTSIDE OPPORTUNITY CASE

  oppService = inject(OpportunityService);

  constructor(private localStorageService: LocalStorageService) {
    effect(() => {
      this.opportunity = this.oppService.opportunity();
      if (this.opportunity && !this.opportunity.msp) this.opportunity.msp = {};
      this.msp = this.opportunity?.msp ?? {};
    });
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getLoggedinUserModel();
    if (this.opportunity && !this.opportunity.msp) this.opportunity.msp = {};
  }

  mspChecked() {
    if (this.opportunity) {
      this.opportunity.msp.is_msp = this.msp.is_msp;
      this.oppService.opportunity.set(this.opportunity);
    } else if (
      this.mspDataEmitter &&
      this.mspDataEmitter.observers.length > 0
    ) {
      this.mspDataEmitter.emit({ is_msp: this.msp.is_msp });
    }
  }

  onAttachMSpFile(event) {
    const file = event.target.files[0];
    if (file) {
      this.msp.attachment_proof_name = file.name;
      this.msp.attachment_proof_drive_id = null;
      this.msp.file = file;

      if (this.opportunity) {
        this.opportunity.msp.attachment_proof_name =
          this.msp.attachment_proof_name;
        this.opportunity.msp.file = this.msp.file;
        this.oppService.opportunity.set(this.opportunity);
      } else if (
        this.mspDataEmitter &&
        this.mspDataEmitter.observers.length > 0
      ) {
        this.mspDataEmitter.emit({
          file: this.msp.file,
          attachment_proof_name: file.name,
        });
      }
    }
  }
}
