import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gws-gcp-popup',
  templateUrl: './gws-gcp-popup.component.html',
  styleUrls: ['./gws-gcp-popup.component.scss'],
})
export class GwsGcpPopupComponent implements OnInit {
  @Output() saveOption = new EventEmitter<boolean>();
  @ViewChild('gwsGcpOppModal') gwsGcpOppModal: ModalDirective;

  constructor() {}

  ngOnInit(): void {}

  showPopup() {
    this.gwsGcpOppModal.show();
  }

  save(gws: boolean) {
    this.saveOption.emit(gws);
    this.gwsGcpOppModal.hide();
  }
}
