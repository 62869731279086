<div class="d-flex justify-content-between">
  <a
    href="javascript:void(0)"
    (click)="back()"
    class="text-color text-decoration-none"
    ><img src="./assets/img/back-icon.svg" alt="back icon" /> Back to Groups</a
  >
  <button
    type="button"
    class="btn btn-color text-white rounded-pill d-block ms-auto product-sans-light"
    data-bs-toggle="modal"
    data-bs-target="#addMemberGroup"
  >
    <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
    <span>Add New Member</span>
  </button>
</div>
<div class="table-responsive fluid-section bg-white groups w-100 mt-3">
  <table class="table table-hover align-middle text-center">
    <thead class="position-sticky top-0 bg-white z-9">
      <tr class="product-sans-medium">
        <th
          scope="col"
          class="text-md-start pe-0 ps-md-5 col-1 col-md-2 border-dark border-top-0"
        >
          Member Name
        </th>
        <th scope="col" class="col-1 col-md-2 border-dark border-top-0">
          Member Email
        </th>
        <th
          scope="col"
          class="col-1 col-md-2 position-relative border-dark border-top-0"
        >
          Edit
          <div class="w-100 h-100 bg-white position-absolute top-0"></div>
        </th>
      </tr>
    </thead>
    <tbody class="product-sans-light">
      <tr>
        <td class="text-md-start ps-md-5 col-1 col-md-2 py-3">Name</td>
        <td class="col-1 col-md-2">Email</td>
        <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">
          <img
            class="px-1 px-md-2 cursor-pointer"
            src="./assets/img/delete-icon.svg"
            alt="delete icon"
            data-bs-toggle="modal"
            data-bs-target="#deleteModal"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- ADD NEW MEMBER TO GROUP MODAL -->
<div
  class="modal fade"
  role="alert"
  id="addMemberGroup"
  tabindex="-1"
  aria-labelledby="addMemberGroupLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addMemberGroupLabel"
        >
          Add New Member to Group
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <label
            for="user"
            class="form-label text-wrap col-2 pe-3 pe-xl-1 mb-0 d-none d-md-inline"
            >Choose User</label
          >
          <select
            id="user"
            class="form-select rounded-pill"
            aria-label="Select User"
            required
          >
            <option value="" selected disabled hidden>Choose User</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          value="Save"
          type="submit"
          required
        />
      </div>
    </div>
  </div>
</div>

<!-- DELETE CONFIRMATION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="deleteModalLabel"
        >
          Remove from Group
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h4 text-center">
          Are you sure you want to remove "Name" from this group?
        </h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-toggle="modal"
          data-bs-target="#rejectionReasonModal"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
