import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomerCreateRoutingModule } from './customer-create-routing.module';
import { CustomerCreateComponent } from './customer-create.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddBillingAccountModule } from 'src/shared-components/add-billing-account/add-billing-account.module';
import { BillingAccountCreatedPopupComponent } from 'src/shared-components/billing-account-created-popup/billing-account-created-popup.component';
import { GwsGcpPopupModule } from 'src/shared-components/gws-gcp-popup/gws-gcp-popup.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

@NgModule({
  declarations: [CustomerCreateComponent, BillingAccountCreatedPopupComponent],
    imports: [
        CommonModule,
        CustomerCreateRoutingModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        ModalModule.forRoot(),
        ReactiveFormsModule,
        AddBillingAccountModule,
        GwsGcpPopupModule,
        MatSlideToggleModule,
        NgxIntlTelInputModule,

    ],
})
export class CustomerCreateModule {
}
