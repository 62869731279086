import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllDealsRoutingModule } from './all-deals-routing.module';
import { AllDealsComponent } from './all-deals.component';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [AllDealsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgbDropdownModule,
    AllDealsRoutingModule,
  ],
})
export class AllDealsModule {}
