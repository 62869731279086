import { CustomersComponent } from './customers.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransferableSubscriptionsModule } from './transferable-subscriptions/transferable-subscriptions.module';
import { CustomerCreateModule } from './customer-create/customer-create.module';
import { CustomerServicesModule } from './customer-services/customer-services.module';

const routes: Routes = [
  {
    path: '',
    component: CustomersComponent,
  },
  {
    path: 'transferable-subscriptions',
    loadChildren: () => TransferableSubscriptionsModule,
  },
  { path: 'customer-create', loadChildren: () => CustomerCreateModule },
  // { path: 'customer-edit', loadChildren: () => CustomerEditModule },
  { path: 'customer-services', loadChildren: () => CustomerServicesModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule {}
