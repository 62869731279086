<button
  type="button"
  class="btn btn-color text-white rounded-pill d-block ms-auto product-sans-light"
  data-bs-toggle="modal"
  data-bs-target="#addSkuModal"
>
  <img src="./assets/img/plus-white-icon.svg" alt="plus icon" class="pe-2" />
  <span>Add New SKU</span>
</button>
<div class="table-responsive fluid-section bg-white permissions w-100">
  <table class="table table-hover align-middle text-center">
    <thead class="position-sticky top-0 bg-white z-9">
        <tr class="product-sans-medium">
          <th scope="col" class="text-md-start pe-0 ps-md-4 col-3 col-md-3 border-dark border-top-0 sku-column">
            SKU
          </th>
          <th scope="col" class="col-3 col-md-3 border-dark border-top-0">
            Plan Name
          </th>
          <th scope="col" class="col-3 col-md-3 border-dark border-top-0">
            Price
          </th>
          <th scope="col" class="col-3 col-md-3 position-relative border-dark border-top-0">
            Edit
            <div class="w-100 h-100 bg-white position-absolute top-0"></div>
          </th>
        </tr>
    </thead>
    <tbody class="product-sans-light">

      <tr
      *ngFor="
        let row of SKUs
      "
      >
      <td class="text-md-start ps-md-3 col-1 col-md-2 py-3 text-nowrap">
        <img src="{{ row.sku_img }}" alt="gws icon" class="col-2 pe-3" />
            {{ row.sku_name }} </td>
        <td class="col-1 col-md-2"> {{ row.plan_name }} </td>
        <td class="col-1 col-md-2"> {{ row.price }} </td>

        <td class="col-2 col-md-3 col-lg-2 text-md-end p-0 p-md-2">

          <a (click)="edit(row.id)">
            <img
              class="px-1 px-md-2 cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#editSkuModal"            
              src="./assets/img/edit-icon.svg"
              alt="edit icon"
            />
          </a>
          <img
            class="ps-1 ps-md-2 cursor-pointer"
            src="./assets/img/delete-icon.svg"
            data-bs-toggle="modal"
            data-bs-target="#deleteSkuModal"            
            alt="delete icon"
            (click)="getRowIdToDelete(row.id)"
          />

        </td>
      </tr>
    </tbody>
  </table>
</div>




<!-- ADD NEW SKU MODAL -->
<div
  class="modal fade"
  role="alert"
  id="addSkuModal"
  tabindex="-1"
  aria-labelledby="addSkuModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addExchangeModalLabel"
        >
          Add New SKU
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
      role="form"
      [formGroup]="skuForm"
      role="form"
      #formDir="ngForm"
      id="add-sku-form"
      novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="sku_rfid"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >sku</label
              >
              <ng-select
              bindLabel="googleProducts.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="skuRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && skuRfid.invalid
              }"
              placeholder="sku"
              aria-label="Select google product"
              >

                <ng-option [value]="product._id" *ngFor="let product of googleProducts">
                  {{ product.sku_name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="plan"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Plan</label
              >
              <ng-select
              bindLabel="plan.name"
              appendTo="body"
              [searchable]="true"
              class="col p-0"
              [clearable]="true"
              formControlName="planRfid"
              [ngClass]="{
                'is-invalid': formDir.submitted && planRfid.invalid
              }"
              placeholder="plan"
              aria-label="Select Plan"
              >

                <ng-option [value]="plan._id" *ngFor="let plan of plans">
                  {{ plan.name }}
                </ng-option>

              </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
              <label
                for="rate"
                class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
                >Price</label
              >
              <input
              formControlName="price"
              [ngClass]="{
                'is-invalid': formDir.submitted && price.invalid
              }"

                type="text"
                class="form-control rounded-pill"
                id="price"
                placeholder="Price"
                required
              />
          </div>

        </div>
        <div class="modal-footer">
        <input
          class="btn btn-primary btn-color rounded-pill col-2"
          value="Save"
          type="submit"
          data-bs-dismiss="modal"
          (click)="AddNewSku()"
      />
        </div>
      </form>
    </div>
  </div>
</div>


<!-- DELETE CONFIRMATION MODAL -->
<div
  class="modal fade"
  role="alert"
  id="deleteSkuModal"
  tabindex="-1"
  aria-labelledby="deleteSkuLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="deleteSkuLabel"
        >
          Delete Confirmation
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <h2 class="h3 text-center">Are you sure you want to delete?</h2>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-toggle="modal"
          data-bs-target="#rejectionReasonModal"
          (click)="deleteConfirmation()"
        >
          Yes
        </button>
        <button
          type="button"
          class="btn btn-primary btn-color rounded-pill col-2"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>


<!-- EDIT NEW SKU MODAL -->
<div class="modal fade" role="alert" id="editSkuModal" tabindex="-1" aria-labelledby="editSkuModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1 class="modal-title fs-5 text-white product-sans-light" id="addExchangeModalLabel">
          Edit SKU Price
        </h1>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form role="form" [formGroup]="skuForm" role="form" #formDir="ngForm" id="add-sku-form" novalidate>
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label for="sku_rfid" class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline">SKU</label>
            <ng-select bindLabel="googleProducts.name" appendTo="body" [searchable]="true" class="col p-0" [clearable]="true"
              formControlName="skuRfid" [ngClass]="{'is-invalid': formDir.submitted && skuRfid.invalid}" placeholder="SKU"
              aria-label="Select google product" [disabled]="true">
              <ng-option [value]="product._id" *ngFor="let product of googleProducts" [disabled]="true">
                {{ product.sku_name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label for="plan" class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline">Plan</label>
            <ng-select bindLabel="plan.name" appendTo="body" [searchable]="true" class="col p-0" [clearable]="true"
              formControlName="planRfid" [ngClass]="{'is-invalid': formDir.submitted && planRfid.invalid}" placeholder="Plan"
              aria-label="Select Plan" [disabled]="true">
              <ng-option [value]="plan._id" *ngFor="let plan of plans" [disabled]="true">
                {{ plan.name }}
              </ng-option>
            </ng-select>
          </div>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label for="rate" class="form-label text-wrap px-0 col-2 me-3 me-xl-1 mb-0 d-none d-md-inline">Price</label>
            <input formControlName="price" [ngClass]="{'is-invalid': formDir.submitted && price.invalid}" type="text"
              class="form-control rounded-pill" id="price" placeholder="Price" required />
          </div>
        </div>
        <div class="modal-footer">
          <input class="btn btn-primary btn-color rounded-pill col-2" value="Save" type="submit" data-bs-dismiss="modal"
            (click)="updateSku()" />
        </div>
      </form>
    </div>
  </div>
</div>

