<div class="container-fluid p-0">
  <div class="bg-white box-shadow mobile-section row rounded-5 p-0 m-0">
    <div class="row align-items-center">
      <h1 class="h4 p-2 ps-3 d-flex align-items-center mb-0 col">
        <a (click)="cancelOrder()"
          ><img
            src="./assets/img/back-icon.svg"
            class="back-icon cursor-pointer"
            alt="back icon" /></a
        >Order
      </h1>
      <div
        class="col-auto d-flex flex-row align-content-center"
        *ngIf="isExecuted == true"
      >
        <p class="mb-0 px-2 py-1">Status</p>
        <div
          class="text-uppercase text-white product-sans-light px-2 py-1 rounded-pill executed-div"
        >
          {{ orderStatus }}
          <img src="./assets/img/white-tick-icon.svg" alt="white tick icon" />
        </div>
      </div>
      <div
        class="col-auto d-flex flex-row align-content-center"
        *ngIf="!isExecuted"
      >
        <p class="mb-0 px-2 py-1">Status</p>
        <div
          class="text-uppercase text-white product-sans-light px-2 py-1 rounded-pill pending-div"
        >
          {{ orderStatus }}
        </div>
      </div>
    </div>

    <view-order-form
      *ngIf="model"
      [isGWS]="!model.is_gcp"
      [model]="model"
    ></view-order-form>
  </div>
</div>
