import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllOrdersModule } from './all-orders/all-orders.module';
import { OrderListComponent } from './order-list.component';
import { OrderRenewalListModule } from './order-renewal-list/order-renewal-list.module';
import { FlexCustomersModule } from './flex-customers/flex-customers.module';

const routes: Routes = [
  {
    path: '',
    component: OrderListComponent,
    children: [
      {
        path: '',
        redirectTo: 'all-orders/true',
        pathMatch: 'full',
      },
      { path: 'all-orders/:all', loadChildren: () => AllOrdersModule},
      { path: 'pending-orders/:all', loadChildren: () => AllOrdersModule },
      {
        path: 'order-renewal-list',
        loadChildren: () => OrderRenewalListModule,
      },
      { path: 'flex-customers', loadChildren: () => FlexCustomersModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderListRoutingModule {}
