import { Utilities } from 'src/shared/utilities';
import { RequestResultModel } from './../models/request_result.model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './localstorage-service';
import { UserModel } from 'src/models/mongo/user.model';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  currentUser: UserModel;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    var usr = this.localStorage.getLoggedinUser();
    if (!Utilities.isNullOrEmpty(usr)) this.currentUser = JSON.parse(usr);
  }

  public async get(
    url: string,
    headers: HttpHeaders,
    params: HttpParams = null
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel(false, '');
    if (!params) params = new HttpParams();
    await this.http
      .get(url, { headers: headers, params: params })
      .toPromise()
      .then((resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message ?? '';
        // try{
        // await Utilities.writeLogEntry(this.http, { error: "reseller api/get request", url: url, body: { request: Array.from(params.keys()), response: err } }, "Error");
        // }catch(e){
        //     JSON.stringify({ error: "reseller api/get request", url: url, body: { request: Array.from(params.keys()), response: err }}),'');
        // }
      });
    return result;
  }

  public async post(
    url: string,
    body,
    headers: HttpHeaders = null
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel(false, '');
    await this.http
      .post(url, !Utilities.isNullOrEmpty(body) ? JSON.stringify(body) : null, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
      });
    return result;
  }

  public async put(
    url: string,
    body,
    headers: HttpHeaders
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel(false, '');
    await this.http
      .put(url, !Utilities.isNullOrEmpty(body) ? JSON.stringify(body) : null, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
      });
    return result;
  }

  public async delete(
    url: string,
    headers: HttpHeaders,
    params: HttpParams
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel(false, '');
    await this.http
      .delete(url, { headers: headers, params: params })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
      });
    return result;
  }

  public async batch(
    url: string,
    body,
    headers: HttpHeaders
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel(false, '');
    await this.http
      .post(url, body, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.data = err;
        result.errorMessage = '';
      });
    return result;
  }
}
