<div
  class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5 w-100"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="back()"
          alt="back icon" /></a
      >Billing Details
    </h1>
  </div>

  <div class="row mt-2 billing_section_name">{{ billing.name }}</div>

  <div class="row mt-4 fluid-section w-100">
    <label class="billing_section_title col-lg-1 col-md-1 col-2">Budgets</label>
    <button class="btn_add_row col-lg-1 col-md-1 col-2" (click)="addBudget()">
      <img src="./assets/img/plus-icon.svg" alt="plus-icon" />
      <span>Add Budget</span>
    </button>
    <div *ngFor="let budget of budgets" class="mt-3 label_grey_border_bottom">
      <div class="row mb-2 col-12">
        <label class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
          >Budget Name</label
        >
        <div class="col-5">
          <input
            type="text"
            [(ngModel)]="budget.name"
            class="rounded-4 flex-fill form-control"
            [ngClass]="{ my_disabled_input: budget.id }"
          />
        </div>
      </div>
      <div class="row mb-2 col-12">
        <label class="form-label text-wrap col-2 me-0 mb-0 d-none d-md-inline"
          >Amount</label
        >
        <div class="col-5">
          <input
            type="text"
            [(ngModel)]="budget.amount"
            [ngClass]="{ my_disabled_input: budget.id }"
            class="rounded-4 flex-fill form-control"
          />
        </div>
      </div>

      <label
        class="form-label text-wrap col-lg-1 col-md-1 col-2 me-0 mb-0 d-none d-md-inline"
        >Alerts</label
      >
      <button
        class="btn_add_row col-lg-1 col-md-1 col-2"
        (click)="addAlertRow(budget)"
      >
        <img src="./assets/img/plus-icon.svg" alt="plus-icon" />
        <span>Add Alert</span>
      </button>
      <div
        class="row smaller-text flex-nowrap mx-0 mt-1 mb-2"
        *ngFor="let alert of budget.alerts; let index = index"
      >
        <div class="form-check p-0 d-flex align-items-center pe-1 col-5">
          <input
            [(ngModel)]="alert.percentage"
            (change)="updateAlertPercentage(budget, alert, $event)"
            class="form-control rounded-pill form-control-sm"
            placeholder="Percentage %"
          />
        </div>
        <div class="form-check p-0 d-flex align-items-center pe-1 col-5">
          <input
            [(ngModel)]="alert.amount"
            (change)="updateAlertAmount(budget, alert, $event)"
            class="form-control rounded-pill form-control-sm"
            placeholder="Amount"
          />
        </div>
        <img
          *ngIf="budget.id || index != 0"
          (click)="deleteAlert(index, budget)"
          class="px-1 px-md-2 cursor-pointer btn_sku_delete col-1"
          src="./assets/img/delete-icon.svg"
          alt="delete icon"
        />
      </div>
    </div>
    <input
      *ngIf="budgets?.length > 0"
      class="btn btn-primary small-text btn-color rounded-pill col-5 col-xl-2 p-1 p-xl-2 fs-6 align-self-end"
      value="Save"
      (click)="saveBudgetsChanges()"
    />

    <div class="mt-4" *ngIf="projects?.length > 0">
      <label class="billing_section_title">Projects</label>
      <div *ngFor="let project of projects" class="mt-3 row">
        <label class="col-lg-3 col-md-4 col-5">{{ project.id }}</label>
        <label
          class="col-lg-2 col-md-3 col-5"
          [ngClass]="{
            color_green: project.enabled,
            color_red: !project.enabled
          }"
          >{{ project.enabled ? "Enabled" : "Disabled" }}</label
        >
        <button
          *ngIf="project.enabled"
          (click)="unlinkProject(project)"
          class="btn btn-primary d-md-inline rounded-pill btn-color smaller-text col-2"
        >
          Unlink
        </button>
      </div>
    </div>
  </div>
</div>
