import {
  Component,
  HostBinding,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'lib-settings',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  constructor(  private location: Location) {}

  @HostBinding('class') classAttribute: string =
    'container-fluid px-2 d-flex flex-column justify-content-between';

  ngOnInit(): void {}
  goBack(): void {
    this.location.back();
  }
}
