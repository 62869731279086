import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class PocService {
  constructor(private http: HttpClient) {}

  async sendPOCToCustpmer(
    poc,
    custInfo,
    currentUser,
    opporunityID
  ): Promise<any> {
    var that = this;
    var phases = poc.approval_phases;
    var custPhase = phases.find(
      (x) => x.role?.toLowerCase() == Group.CUSTOMER.toLowerCase()
    );
    if (custPhase) {
      custPhase.name = currentUser.user_name;
      custPhase.email = currentUser.email;
      custPhase.date = new Date();
      custPhase.id = currentUser._id;
      custPhase.customer_email = custInfo.email;
      custPhase.customer_name = custInfo.name;
    } else {
      phases.push({
        name: currentUser.user_name,
        email: currentUser.email,
        date: new Date(),
        id: currentUser._id,
        customer_email: custInfo.email,
        customer_name: custInfo.name,
      });
    }
    poc.approval_phases = phases;
    poc.approval_phases.forEach((phase) => {
      if (phase?.date && phase.date.$date?.$numberLong)
        phase.date = new Date(Number.parseInt(phase.date.$date?.$numberLong));
    });
    await this.http
      .put(Endpoint.API_URL + Endpoint.SEND_POC_TO_CUSTOMER + poc._id, {
        approval_phases: poc.approval_phases,
        customer_emails: custInfo?.email,
        opportunity_rfid: opporunityID,
        dependency_on: !Utilities.isNullOrEmpty(opporunityID)
          ? 'Customer'
          : null,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp.approval_phases) {
          alert(resp.message);
          if (poc?.approval_phases) {
            poc.approval_phases = resp.approval_phases;
            poc.sendingToCustomerPart = poc.approval_phases.find(
              (x) =>
                x.role && x.role.toLowerCase() == Group.CUSTOMER.toLowerCase()
            );
          }
        }
      });
    return poc;
  }
}
