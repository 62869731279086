import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerDealsComponent } from './customer-deals.component';

const routes: Routes = [{ path: '', component: CustomerDealsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerDealsRoutingModule {}
