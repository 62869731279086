import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddOrderRoutingModule } from './add-order-routing.module';
import { AddOrderComponent } from './add-order.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddOrderFormModule } from 'src/shared-components/add-order-form/add-order-form.module';

@NgModule({
  declarations: [AddOrderComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AddOrderFormModule,
    AddOrderRoutingModule,
  ],
})
export class AddOrderModule {}
