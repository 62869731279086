<section class="box-shadow bg-white rounded-5 p-2 p-md-4 mx-0 h-95">
  <h1 class="h5 py-1 py-sm-3 ps-2 ps-sm-0 d-flex align-items-center mb-0">
    <a
      ><img
      (click)="goBack()"
        src="./assets/img/back-icon.svg"
        class="back-icon cursor-pointer"
        alt="back icon" /></a
    >Settings
  </h1>
  <ul class="d-flex flex-row pe-3 pe-lg-0 small-text gap-1 ps-0" role="tablist">
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0 rounded-start list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="/settings/currency"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Currency</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="/settings/exchange_rate"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Exchange Rate</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="/settings/groups/list"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Groups</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0"
    >
      <a
        class="nav-link"
        routerLink="/settings/sku-pricing"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
        >SKU Pricing</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0 rounded-end"
    >
      <a
        class="nav-link"
        routerLink="/settings/permissions/list"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
        >Permissions</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0 rounded-end"
    >
      <a
        class="nav-link"
        routerLink="/settings/api-access-token"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
      >Api Token</a
      >
    </li>
    <li
      class="col text-center p-1 p-sm-2 settings-tabs cursor-pointer list-group-item border-0 rounded-end"
    >
      <a
        class="nav-link"
        routerLink="/settings/integrations"
        [routerLinkActive]="['custom-active-link']"
        [routerLinkActiveOptions]="{ exact: true }"
      >Integrations</a
      >
    </li>
  </ul>
  <router-outlet></router-outlet>
</section>
