import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LicenseUpdateModalComponent } from './license-update-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [LicenseUpdateModalComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [LicenseUpdateModalComponent],
})
export class LicenseUpdateModalModule {}
