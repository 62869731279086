import { NgModule } from '@angular/core';
import { DealsComponent } from './deals.component';
import { DealsRoutingModule } from './deals-routing.module';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [DealsComponent],
  imports: [
    DealsRoutingModule,
    CommonModule,
  ],
  exports: [DealsComponent]
})
export class DealsModule { }
