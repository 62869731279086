import { SkuPriceComponent } from './sku-price.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SkuPriceRoutingModule } from './sku-price-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionsRoutingModule } from '../permissions/permissions-routing.module';


@NgModule({
  declarations: [SkuPriceComponent],
  imports: [
    CommonModule,
    SkuPriceRoutingModule,
    NgSelectModule,
    FormsModule,ReactiveFormsModule,
    PermissionsRoutingModule

  ]
})
export class SkuPriceModule { }
