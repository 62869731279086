import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpporunityDetailsModule } from './opporunity-details/opporunity-details.module';
import { OpportunityListModule } from './opportunity-list/opportunity-list.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'opportunity-list',
    pathMatch: 'full',
  },
  { path: 'opportunity-list', loadChildren: () => OpportunityListModule },
  { path: 'opportunity-details', loadChildren: () => OpporunityDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpportunityRoutingModule {}
