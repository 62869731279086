<div
  class="fluid-section mt-2 mt-md-0 bg-white box-shadow p-2 p-md-4 px-2 px-md-4 rounded-5 w-100"
>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="h5 p-1 ps-2 d-flex align-items-center mb-0">
      <a
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          (click)="goBack()"
          alt="back icon" /></a
      >Opportunities
    </h1>
    <div class="d-flex justify-content-end">
      <pagination-controls
        [responsive]="true"
        previousLabel=""
        nextLabel=""
        (pageChange)="renderPage($event)"
      ></pagination-controls>
    </div>
  </div>
  <div class="d-flex flex-wrap justify-content-between align-items-start pt-3">
    <div
      class="d-flex flex-wrap flex-md-nowrap align-items-center col-12 col-lg-5 mt-3 mt-sm-0 product-sans-light p-0"
    >
      <div class="col-6 me-2 col-md-4 p-0">
        <input
          type="date"
          [(ngModel)]="fromSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (from)"
          class="form-control rounded-pill py-0"
          id="deal-date-from"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
      <div class="my-2 my-md-0 col-6 me-2 col-md-4 mx-md-2 p-0">
        <input
          type="date"
          [(ngModel)]="toSelectedDate"
          (ngModelChange)="selectDate()"
          placeholder="Date (to)"
          class="form-control rounded-pill py-0"
          id="deal-date-to"
          required
        />
        <label
          for="floatingDate"
          class="customized-floating-label py-2 px-3"
        ></label>
      </div>
    </div>
    <div
      class="d-flex flex-wrap flex-md-nowrap align-items-center col-12 col-lg-7 mt-3 mt-sm-0 product-sans-light p-0"
    >
      <div class="col-6 me-2 col-md-3 col-sm-8 p-0 p-t-5">
        <!--      <img-->
        <!--        class="position-absolute pt-2 px-2 end-0"-->
        <!--        src="./assets/img/search-blue-icon.svg"-->
        <!--        alt="search icon"-->
        <!--      />-->
        <input
          id="txt-order-global-search"
          type="search"
          [(ngModel)]="customerSearch"
          (keyup.enter)="search()"
          class="form-control rounded-pill h-36"
          placeholder="Search by Domain"
        />
      </div>
      <div class="col-6 me-2 col-md-3 col-sm-8 p-0 p-t-5">
        <ng-select
          placeholder="Select Dependency"
          appendTo="body"
          [searchable]="true"
          class="col-12"
          [clearable]="true"
          style="padding: 0 !important; border: none !important"
          [(ngModel)]="selectedDependency"
          (change)="selectDependency()"
        >
          <ng-option
            [value]="dependency"
            *ngFor="let dependency of DependencyOptions"
          >
            {{ dependency }}
          </ng-option>
        </ng-select>
      </div>

      <div class="col-6 me-2 col-md-2 col-sm-8 p-0 p-t-5">
        <ng-select
          placeholder="Select Status"
          appendTo="body"
          [searchable]="true"
          class="col-12"
          [clearable]="true"
          style="padding: 0 !important; border: none !important"
          [(ngModel)]="selectedStatus"
          (change)="selectStatus()"
        >
          <ng-option [value]="status" *ngFor="let status of statusOptions">
            {{ status }}
          </ng-option>
        </ng-select>
      </div>

      <div class="col-6 me-2 col-md-3 col-sm-8 p-0 p-t-5">
        <ng-select
          placeholder="Select Engineer"
          bindLabel="sale.first_name"
          appendTo="body"
          [searchable]="true"
          class="col-12"
          [clearable]="true"
          style="padding: 0 !important; border: none !important"
          [(ngModel)]="selectedSales"
          (change)="selectSale()"
        >
          <ng-option [value]="sale._id" *ngFor="let sale of users">
            {{ sale.first_name }} {{ sale.last_name }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-6 me-2 col-md-2 p-0 p-t-5">
        <img
          src="./assets/img/refresh-icon.png"
          alt="search icon"
          (click)="refreshPage()"
        />
      </div>
    </div>
  </div>

  <div class="table-responsive fluid-section w-100">
    <table
      class="table table-hover align-middle text-center small-text mt-3 min-h-200"
    >
      <thead class="position-sticky top-0 bg-white z-9">
        <tr class="border-dark product-sans-medium">
          <th
            scope="col"
            class="p-1 p-md-2 text-md-start ps-md-2 col-1 col-md-2 border-top-0"
          >
            Opportunity ID
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Domain
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            <div class="btn-group me-3" id="dal_type_db">
              <div class="btn-group" ngbDropdown role="group" aria-label="type">
                <button
                  type="button"
                  class="btn border-0 shadow-none"
                  ngbDropdownToggle
                  id="dropdownBasicdeal"
                >
                  Product
                </button>
                <div
                  class="dropdown-menu bg-light"
                  ngbDropdownMenu
                  aria-labelledby="dropdownBasicdeal"
                >
                  <button
                    ngbDropdownItem
                    *ngFor="let typeItem of productTypes"
                    (click)="applyFilter(typeItem)"
                  >
                    {{ typeItem }}
                  </button>
                </div>
              </div>
            </div>
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Creation Date
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Current Phase
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Account Manager
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-3 border-top-0">
            Dependency on
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-3 border-top-0">
            Status
          </th>
          <th scope="col" class="p-1 p-md-2 col-1 col-md-2 border-top-0">
            Actions
          </th>
        </tr>
      </thead>
      <tbody class="product-sans-light">
        <tr
          *ngFor="
            let opportunityItem of GetData
              | paginate
                : {
                    itemsPerPage: GetPageSize,
                    currentPage: GetPagination,
                    totalItems: GetTotalCount
                  }
          "
        >
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ opportunityItem.id }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ opportunityItem.customer_domain }}
          </td>

          <td class="p-1 p-md-2 col-1 col-md-2">
            <div *ngIf="opportunityItem.is_gcp">GCP</div>
            <div *ngIf="!opportunityItem.is_gcp">GWS</div>
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ opportunityItem.created_at | date : "dd-MMM-yyyy" }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            {{ opportunityItem.current_phase }}
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            <img
              class="px-0 cursor-pointer img-user"
              src="{{ opportunityItem.user_image }}"
              alt="{{ opportunityItem.created_user_name }}"
              title=" {{ opportunityItem.created_user_name }}"
            />
          </td>
          <td class="p-1 p-md-2 col-1 col-md-2">
            <div
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                *ngIf="opportunityItem.dependency_on"
                [ngClass]="{
                  'bg-sales':
                    opportunityItem.dependency_on.toLowerCase() === 'sales',
                  'bg-vrm':
                    opportunityItem.dependency_on.toLowerCase() === 'vrm' ||
                    opportunityItem.dependency_on.toLowerCase() === 'gpm',
                  'bg-engineer':
                    opportunityItem.dependency_on.toLowerCase() ===
                      'engineer' ||
                    opportunityItem.dependency_on.toLowerCase() === 'presales',
                  'bg-finance':
                    opportunityItem.dependency_on.toLowerCase() === 'finance',
                  'bg-google':
                    opportunityItem.dependency_on.toLowerCase() === 'google',
                  'bg-customer':
                    opportunityItem.dependency_on.toLowerCase() === 'customer'
                }"
                class="badge-custom d-inline-flex justify-content-center align-items-center"
              >
                <div class="badge-text">
                  {{ opportunityItem.dependency_on }}
                </div>
              </div>
            </div>
          </td>

          <td class="p-1 p-md-2 col-1 col-md-2">
            <div
              *ngIf="opportunityItem.opportunity_status === 'In Progress'"
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                class="badge-custom bg-primary text-white d-inline-flex align-items-center"
              >
                <div class="badge-text">In Progress</div>
                <div class="dots">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
            </div>

            <div
              *ngIf="opportunityItem.opportunity_status === 'Lost'"
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                class="badge-custom bg-danger text-white d-inline-flex align-items-center"
              >
                <div class="badge-text">Lost</div>
                <i class="fa fa-times text-white ms-2"></i>
              </div>
            </div>

            <div
              *ngIf="opportunityItem.opportunity_status === 'Won'"
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                class="badge-custom bg-success text-white d-inline-flex align-items-center"
              >
                <div class="badge-text">Won</div>
                <div class="ms-2">✔</div>
              </div>
            </div>

            <div
              *ngIf="opportunityItem.opportunity_status === 'On Hold'"
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                class="badge-custom bg-warning text-white d-inline-flex align-items-center"
              >
                <div class="badge-text">On Hold</div>
                <div class="status-indicator"></div>
              </div>
            </div>
          </td>

          <td class="p-1 p-md-2 col-1 col-md-2">
            <div class="col-md-12 d-flex">
              <div>
                <img
                  (click)="viewopportunity(opportunityItem._id)"
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/edit-icon.svg"
                  alt="edit icon"
                />
              </div>
              <div
                *ngIf="currentUser?._id == opportunityItem?.created_by_rfid"
                (click)="deleteOpportunity(opportunityItem._id)"
              >
                <img
                  class="px-1 px-md-2 cursor-pointer"
                  src="./assets/img/delete-icon.svg"
                  alt="delete icon"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
