import { ExchangeRateComponent } from './exchange-rate.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExchangeRateRoutingModule } from './exchange-rate-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [ExchangeRateComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule, 
    NgSelectModule,
    ExchangeRateRoutingModule
  ]
})
export class ExchangeRateModule { }
