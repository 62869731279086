import { NgModule } from '@angular/core';
import { GcpComponent } from './gcp.component';
import { GCPRoutingModule } from './gcp-routing.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GcpComponent],
  imports: [GCPRoutingModule, CommonModule],
  exports: [GcpComponent],
})
export class GcpModule {}
