import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationListRoutingModule } from './notification-list-routing.module';
import { NotificationListComponent } from './notification-list.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
  declarations: [
    NotificationListComponent
  ],
    imports: [
        CommonModule,
        NotificationListRoutingModule,
        NgxPaginationModule,
        FormsModule,
        NgSelectModule,
        NgbDropdownModule
    ]
})
export class NotificationListModule { }
