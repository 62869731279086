<main class="px-2 px-md-4 w-100 d-flex flex-column justify-content-between">
  <section
    class="bg-white box-shadow container-fluid fluid-section mx-0 px-5 rounded-5 w-100 max-h-93"
  >
    <h1 class="align-items-center d-flex h5 mb-0 mt-4 p-1">
      <a href="./dashboard.html"
        ><img
          src="./assets/img/back-icon.svg"
          class="back-icon cursor-pointer"
          alt="back icon" /></a
      >Help & Guidance
    </h1>
    <p>To create new order follow the below steps:</p>
    <div class="d-flex flex-column flex-lg-row g-0">
      <div class="col border rounded-4 p-3 me-lg-2">
        <p>Order Type - (New Customer-Without Offer)</p>
        <ol>
          <li>Create customer</li>
          <li>
            Create the order with all requested details (don't tick the offer
            checkmark)
          </li>
          <li>Submit for approval</li>
        </ol>
      </div>
      <div class="col border rounded-4 p-3 ms-lg-2 mt-3 mt-lg-0">
        <p>Order Type - (New Customer-With Offer)</p>
        <ol>
          <li>Create customer</li>
          <li>
            Create the order with all requested details (tick the offer
            checkmark)
          </li>
          <li>Mention the offer details in the description section</li>
          <li>Submit for approval</li>
        </ol>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row g-0 mt-3">
      <div class="col border rounded-4 p-3 me-lg-2">
        <p>Order Type - (Transfer Order)</p>
        <ol>
          <li>
            Create customer(from Transfer Customer section , on customer
            creation page)
          </li>
          <li>
            Create the order with all requested details (Also mention the
            transfer Token)der with all requested details (don't tick the offer
            checkmark)
          </li>
          <li>
            Tick the offer checkbox and put the offer details in the remark
            section , if the order is with Offer
          </li>
          <li>Submit for approval</li>
        </ol>
      </div>
      <div class="col border rounded-4 p-3 ms-lg-2 mt-3 mt-lg-0">
        <p>Order Type - (Upgrade/Downgrade)</p>
        <ol>
          <li>Select the customer</li>
          <li>
            Click on the upgrade/downgrade icon to create the order (Tick the
            offer checkmark if the order is with Offer)
          </li>
          <li>
            Add all order details(mention the offer details in the remark
            section,if it is with Offer)
          </li>
          <li>Submit for approval</li>
        </ol>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row g-0 mt-3">
      <div class="col border rounded-4 p-3 me-lg-2">
        <p>Order Type - (Renewal Order)</p>
        <ol>
          <li>
            Renew it before the date(still on annual plan)
            <ol type="a">
              <li>Click the renew icon ,next to the expiry date</li>
              <li>
                Create the order with all requested details(tick the offer
                checkbox if it’s with offer)
              </li>
              <li>Mention the offer details in the remark section</li>
              <li>Submit for approval</li>
              <li>
                The order will be executed automatically at time,after manager’s
                confirmation
              </li>
            </ol>
          </li>
          <li>
            Renew it after expiration(plan switched from annual to flex based on
            the renewal setting)
            <ol type="a">
              <li>Click update plan icon</li>
              <li>Mention the offer details in the remark section</li>
              <li>Submit for approval</li>
              <li>Will be renewed directly after execution</li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="col border rounded-4 p-3 ms-lg-2 mt-3 mt-lg-0">
        <p>Order Type - (Update licenses)</p>
        <ol>
          <li>Select the customer</li>
          <li>Click update license icon</li>
          <li>
            Add additional licenses if the plan is annual, and cap licenses(new
            Nbr.) if flexible(for example ,request to update the users from 40
            to 50.Annual plan : add the additional only : 10Flexible plan : add
            the new : 50 )
          </li>
          <li>Enter the details</li>
          <li>Submit for approval</li>
        </ol>
      </div>
    </div>
    <div class="d-flex flex-column flex-lg-row g-0 mt-3">
      <div class="col border rounded-4 p-3 me-lg-2">
        <p>Order Type - (New Subscription)</p>
        <ol>
          <li>Select the customer</li>
          <li>Click "create new order", to add new service to this customer</li>
          <li>
            Select the SKU(should not be in his subscriptions list),the plan,
            and add the details
          </li>
          <li>
            Tick the offer checkbox and put the offer details in the remark
            section , if the order is with Offer
          </li>
          <li>Submit for approval</li>
        </ol>
      </div>
      <div class="col border rounded-4 p-3 ms-lg-2 mt-3 mt-lg-0 text-danger">
        <p>Notes</p>
        <ul class="help-list-note">
          <li>
            Add the customer email if you want to send email, automatically,
            after execution. Else keep it empty.
          </li>
          <li>
            The involved persons will receive notification email at every stage.
          </li>
        </ul>
      </div>
    </div>
  </section>
</main>
