import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerGcpProjectsRoutingModule } from './customer-gcp-projects-routing.module';
import { CustomerGcpProjectsComponent } from './customer-gcp-projects.component';


@NgModule({
  declarations: [CustomerGcpProjectsComponent],
  imports: [
    CommonModule,
    CustomerGcpProjectsRoutingModule
  ]
})
export class CustomerGcpProjectsModule { }
