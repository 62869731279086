import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'opportunityConsumedTime'
})
export class OpportunityConsumedTimePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {

      let new_time=value.split(' ');

      if (new_time[0].startsWith('0h')){
        return new_time[1];
      }
      return new_time[0];


  }


}
