<div id="opp_poc_parent_step" class="container d-flex flex-column">
  <div
    class="opp_poc_step_new h-100"
    [ngClass]="{ step_skipped: skip == true }"
    *ngIf="pocPhaseData?.poc?.user_poc == null; else opp_poc_step"
  >
    <div class="step_count">
      <span class="notification-icon--fixed">
        <small class="notification-badge f-20">3</small>
      </span>
    </div>
    <div class="text-center mt-2">
      <img
        src="./assets/img/ic_step_poc.svg"
        class="back-icon icon_step"
        alt="poc building icon"
      />
    </div>
    <div class="text-center txt_deal_phase mt-2">
      <span class="col-1">POC Phase</span>
    </div>
    <div class="row">
      <button
        (click)="createPOCGoogleDoc()"
        [ngClass]="{
          phases_disabled_btn: !currentUser.isEngineer || disabled == true,
          cursor_none: skip == true
        }"
        class="col-10 mt-2 offset-1 btn d-none d-md-inline rounded-pill btn-bg-white smaller-text"
      >
        Create a POC
      </button>
    </div>
    <div class="new-div mt-auto mb-3 offset-3 div_skip">
      <div
        class="product-sans-light f-12"
        [ngClass]="{
          f_black: skip == true,
          'product-sans-medium': skip == true
        }"
      >
        <label class="switch mb-1">
          <input
            type="checkbox"
            [(ngModel)]="skip"
            (change)="skipStep($event)"
          />
          <span class="slider"></span>
        </label>
        Skip this step
      </div>
    </div>
  </div>

  <ng-template #opp_poc_step>
    <div class="opp_poc_step h-100">
      <div class="poc_step_header d-flex flex-row">
        <span class="flex-column col-1 f-20">3</span>
        <label class="text-nowrap mt-1 f-15 flex_auto">POC</label>
        <div class="flex-column pe-2 text-end col-4">
          <span class="f-12 d-grid"
            >time <span>{{ pocPhaseData?.poc?.poc_time }}</span></span
          >
        </div>
        <img
          src="./assets/img/ic_time_expand.svg"
          class="ic_time col-2 text-end"
        />
      </div>
      <div class="poc_step_content product-sans">
        <!-- POC DOC -->
        <div
          class="btn_collapse_parent mt-2"
          id="poc_creation"
          *ngIf="
            !pocPhaseData?.poc?.draft ||
            currentUser._id == pocPhaseData.poc.created_by_rfid
          "
        >
          <div class="row opp_col_content align-items-center">
            <div class="col-9 phase-content p-0">
              <button
                type="button"
                class="btn_collapse_header w-100 d-inline-flex"
                (click)="isCreatedPOCDocCollapsed = !isCreatedPOCDocCollapsed"
                [attr.aria-expanded]="!isCreatedPOCDocCollapsed"
                aria-controls="collapseCustReq"
                [ngClass]="{
                  'col-10': pocPhaseData.poc,
                  'col-12': !pocPhaseData.poc
                }"
              >
                <span
                  class="fa p-1"
                  [ngClass]="{
                'fa-angle-down': isCreatedPOCDocCollapsed,
                'fa-angle-up': !isCreatedPOCDocCollapsed,
              }"
                ></span>
                <label
                  title="PreSales Created POC Doc"
                  class="label-ellipsis"
                  *ngIf="pocPhaseData.poc"
                  >PreSales Created POC Doc</label
                >
                <label
                  title="Pending POC Doc Creation by PreSales"
                  class="label-ellipsis"
                  *ngIf="!pocPhaseData.poc"
                  >Pending POC Doc Creation by PreSales</label
                >
              </button>
            </div>

            <div class="col-3 time-content d-inline-flex align-items-center">
              <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                {{
                  pocPhaseData.poc.consumed_time
                    ? (pocPhaseData.poc.consumed_time | opportunityConsumedTime)
                    : ""
                }}
              </span>

              <span
                *ngIf="pocPhaseData.poc"
                class="check_green col-2 fa fa-check fa-thin float-end mt-1"
              ></span>
            </div>
          </div>
          <div
            #collapse="ngbCollapse"
            [(ngbCollapse)]="isCreatedPOCDocCollapsed"
          >
            <div class="btn_collapse_content">
              <div class="d-grid p-1">
                <ng-template [ngIf]="pocPhaseData.poc?.user_poc">
                  <label class="phase_details_title">PreSales Name</label>
                  <label class="phase_details_data_txt">{{
                    pocPhaseData.poc.user_poc.created_user_name
                  }}</label>
                  <label class="phase_details_title mt-2"
                    >Date & Time of Creation</label
                  >
                  <label class="phase_details_data_txt">{{
                    pocPhaseData.poc.created_at | date : "dd MMM,yyyy - hh:mma"
                  }}</label>
                  <label class="phase_details_title mt-2">Consumed Time</label>
                  <label class="phase_details_data_txt">{{
                    pocPhaseData.poc.consumed_time
                  }}</label>
                  <!-- <input
                    *ngIf="currentUser._id == pocPhaseData.poc.created_by_rfid"
                    (click)="editPOC()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Edit POC Doc"
                  /> -->
                  <input
                    (click)="reviewPOC()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Review POC Doc"
                  />
                </ng-template>
                <ng-template [ngIf]="!pocPhaseData.poc">
                  <input
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isEngineer
                    }"
                    (click)="createPOCGoogleDoc()"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    value="Create POC Doc"
                  />
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- POC DOC APPROVAL PROCESS -->
        <ng-template
          [ngIf]="
            pocPhaseData?.poc?.approval_phases && !pocPhaseData?.poc?.draft
          "
        >
          <!-- PRESALES MANAGER APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="poc_manager_approval"
            *ngIf="pocPhaseData.poc.managerApprovalPart"
          >
            <div class="row opp_col_content align-items-center">
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.poc.managerApprovalPart.date,
                    'col-12': !pocPhaseData.poc.managerApprovalPart.date
                  }"
                  (click)="
                    isManagerApprovedpocCollapsed =
                      !isManagerApprovedpocCollapsed
                  "
                  [attr.aria-expanded]="!isManagerApprovedpocCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isManagerApprovedpocCollapsed,
                      'fa-angle-up': !isManagerApprovedpocCollapsed
                    }"
                  ></span>
                  <label
                    title="Customer POC
                {{
                      pocPhaseData.poc.managerApprovalPart.approved
                        ? 'Approved'
                        : 'rejected'
                    }}
                by PreSales Manager"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.poc.managerApprovalPart.date"
                    >Customer POC
                    {{
                      pocPhaseData.poc.managerApprovalPart.approved
                        ? "Approved"
                        : "rejected"
                    }}
                    by PreSales Manager</label
                  >
                  <label
                    title="Pending Customer POC Approved by PreSales Manager"
                    class="label-ellipsis"
                    *ngIf="!pocPhaseData.poc.managerApprovalPart.date"
                    >Pending Customer POC Approved by PreSales Manager</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex time-content align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    pocPhaseData.poc.managerApprovalPart.consumed_time
                      ? (pocPhaseData.poc.managerApprovalPart.consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="pocPhaseData.poc.managerApprovalPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isManagerApprovedpocCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title"
                    >Engineer Manager Name</label
                  >
                  <label class="phase_details_data_txt">{{
                    pocPhaseData.poc.managerApprovalPart.name
                  }}</label>
                  <ng-template
                    [ngIf]="pocPhaseData.poc.managerApprovalPart.date"
                  >
                    <label class="phase_details_title mt-2"
                      >Date & Time of
                      {{
                        pocPhaseData.poc.managerApprovalPart.approved
                          ? "Approval"
                          : "Rejection"
                      }}</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.managerApprovalPart.date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.managerApprovalPart.consumed_time
                    }}</label>
                    <div
                      class="d-grid mt-2"
                      *ngIf="
                        pocPhaseData.poc.managerApprovalPart.rejection_reason &&
                        pocPhaseData.poc.managerApprovalPart.rejection_reason !=
                          ''
                      "
                    >
                      <label class="phase_details_title"
                        >Rejection Reason</label
                      >
                      <label class="phase_details_data_txt">{{
                        pocPhaseData.poc.managerApprovalPart.rejection_reason
                      }}</label>
                    </div>
                  </ng-template>
                  <input
                    *ngIf="
                      currentUser._id ==
                      pocPhaseData?.poc?.managerApprovalPart?.id
                    "
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="reviewPOC(true)"
                    value="Review Customer POC"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- SENDING TO CUSTOMER PART -->
          <div
            class="btn_collapse_parent mt-2"
            id="poc_customer1"
            *ngIf="pocPhaseData.poc.sendingToCustomerPart"
          >
            <div class="row opp_col_content align-items-center">
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.poc.sendingToCustomerPart.date,
                    'col-12': !pocPhaseData.poc.sendingToCustomerPart.date
                  }"
                  (click)="
                    isSendingCustpocCollapsed = !isSendingCustpocCollapsed
                  "
                  [attr.aria-expanded]="!isSendingCustpocCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isSendingCustpocCollapsed,
                      'fa-angle-up': !isSendingCustpocCollapsed
                    }"
                  ></span>
                  <label
                    title="PreSales sent POC Doc to Customer"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.poc.sendingToCustomerPart.date"
                    >PreSales sent POC Doc to Customer
                  </label>
                  <label
                    title="Pending Sending POC Doc to Customer by PreSales"
                    class="label-ellipsis"
                    *ngIf="!pocPhaseData.poc.sendingToCustomerPart.date"
                    >Pending Sending POC Doc to Customer by PreSales</label
                  >
                </button>
              </div>

              <div class="col-3 time-content align-items-center d-inline-flex">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    pocPhaseData.poc.sendingToCustomerPart.consumed_time
                      ? (pocPhaseData.poc.sendingToCustomerPart.consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="pocPhaseData.poc.sendingToCustomerPart.date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isSendingCustpocCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template
                    [ngIf]="pocPhaseData.poc.sendingToCustomerPart.date"
                  >
                    <label class="phase_details_title">PreSales Name</label>
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.sendingToCustomerPart.name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Sending</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.sendingToCustomerPart.date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.sendingToCustomerPart.consumed_time
                    }}</label>
                  </ng-template>
                  <input
                    *ngIf="!pocPhaseData.poc.sendingToCustomerPart.date"
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isEngineer
                    }"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="sendpocToCustPopup()"
                    value="Send poc to Customer"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--CUSTOMER APPROVAL -->
          <div
            class="btn_collapse_parent mt-2"
            id="poc_customer2"
            *ngIf="pocPhaseData.poc.sendingToCustomerPart?.date"
          >
            <div class="row opp_col_content align-items-center">
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.poc.sendingToCustomerPart.date,
                    'col-12': !pocPhaseData.poc.sendingToCustomerPart.date
                  }"
                  (click)="
                    isCustApprovedpocCollapsed = !isCustApprovedpocCollapsed
                  "
                  [attr.aria-expanded]="!isCustApprovedpocCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isCustApprovedpocCollapsed,
                      'fa-angle-up': !isCustApprovedpocCollapsed
                    }"
                  ></span>
                  <label
                    title="Customer
                {{
                      pocPhaseData.poc.sendingToCustomerPart.approved
                        ? 'Approved'
                        : 'rejected'
                    }}
                POC Doc"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.poc.sendingToCustomerPart.approval_date"
                    >Customer
                    {{
                      pocPhaseData.poc.sendingToCustomerPart.approved
                        ? "Approved"
                        : "rejected"
                    }}
                    POC Doc
                  </label>
                  <label
                    title="Pending Customer Approval on POC Doc"
                    class="label-ellipsis"
                    *ngIf="
                      !pocPhaseData.poc.sendingToCustomerPart.approval_date
                    "
                    >Pending Customer Approval on POC Doc</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex time-content align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    pocPhaseData.poc.sendingToCustomerPart
                      .customer_approval_consumed_time
                      ? (pocPhaseData.poc.sendingToCustomerPart
                          .customer_approval_consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="pocPhaseData.poc.sendingToCustomerPart.approval_date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isCustApprovedpocCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <label class="phase_details_title">Customer Name</label>
                  <label class="phase_details_data_txt">{{
                    pocPhaseData.poc.sendingToCustomerPart.customer_name
                  }}</label>
                  <ng-template
                    [ngIf]="
                      pocPhaseData.poc.sendingToCustomerPart.approval_date
                    "
                  >
                    <label class="phase_details_title mt-2"
                      >Date & Time of
                      {{
                        pocPhaseData.poc.sendingToCustomerPart.approved
                          ? "Approval"
                          : "Rejection"
                      }}</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.sendingToCustomerPart.approval_date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.poc.sendingToCustomerPart
                        .customer_approval_consumed_time
                    }}</label>
                    <ng-template
                      [ngIf]="pocPhaseData.poc.sendingToCustomerPart.comments"
                    >
                      <label class="phase_details_title mt-2"
                        >Customer Comments</label
                      >
                      <label class="phase_details_data_txt">{{
                        pocPhaseData.poc.sendingToCustomerPart.comments
                      }}</label>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!--- POC GCP PART --->
        <ng-template
          [ngIf]="pocPhaseData.poc?.sendingToCustomerPart?.approval_date"
        >
          <!--PROJECT-->
          <div class="btn_collapse_parent mt-2" id="poc_project_creation">
            <div class="row opp_col_content align-items-center">
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.gcp_project?.created_at,
                    'col-12': !pocPhaseData.gcp_project?.created_at
                  }"
                  (click)="isGcpPrjCreCollapsed = !isGcpPrjCreCollapsed"
                  [attr.aria-expanded]="!isGcpPrjCreCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isGcpPrjCreCollapsed,
                      'fa-angle-up': !isGcpPrjCreCollapsed
                    }"
                  ></span>
                  <label
                    title="Engineer Created POC Project"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.gcp_project?.created_at"
                    >Engineer Created POC Project</label
                  >
                  <label
                    title="Pending Engineer POC Project Creation"
                    class="label-ellipsis"
                    *ngIf="!pocPhaseData.gcp_project?.created_at"
                    >Pending Engineer POC Project Creation</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    pocPhaseData.gcp_project?.consumed_time
                      ? (pocPhaseData.gcp_project.consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="pocPhaseData.gcp_project?.created_at"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isGcpPrjCreCollapsed">
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template [ngIf]="pocPhaseData.gcp_project?.created_at">
                    <label class="phase_details_title">Engineer Name</label>
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.gcp_project.created_user_name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Creation</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.gcp_project.created_at
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.gcp_project.consumed_time
                    }}</label>
                    <label class="phase_details_title mt-2">Project Name</label>
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.gcp_project.name
                    }}</label>
                  </ng-template>
                  <input
                    *ngIf="!pocPhaseData.gcp_project?.created_at"
                    [ngClass]="{ phases_disabled_btn: !currentUser.isEngineer }"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="createProjectPopup()"
                    value="Create Project"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--LINK PROJECT-->
          <div
            class="btn_collapse_parent mt-2"
            id="poc_link_project"
            *ngIf="pocPhaseData.gcp_project?.created_at"
          >
            <div class="row opp_col_content align-items-center">
              <div class="col-9 phase-content p-0">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.link_project_billing?.linking_date,
                    'col-12': !pocPhaseData.link_project_billing?.linking_date
                  }"
                  (click)="isGcpPrjLinkedCollapsed = !isGcpPrjLinkedCollapsed"
                  [attr.aria-expanded]="!isGcpPrjLinkedCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isGcpPrjLinkedCollapsed,
                      'fa-angle-up': !isGcpPrjLinkedCollapsed
                    }"
                  ></span>
                  <label
                    title="Finance linked Project to Billing"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.link_project_billing?.linking_date"
                    >Finance linked Project to Billing</label
                  >
                  <label
                    title="Pending Finance Project Linking"
                    class="label-ellipsis"
                    *ngIf="!pocPhaseData.link_project_billing?.linking_date"
                    >Pending Finance Project Linking</label
                  >
                </button>
              </div>

              <div class="col-3 d-inline-flex align-items-center time-content">
                <span class="col-1 mt-1 float-end p-0 text-end w-fit-content">
                  {{
                    pocPhaseData.link_project_billing?.consumed_time
                      ? (pocPhaseData.link_project_billing.consumed_time
                        | opportunityConsumedTime)
                      : ""
                  }}
                </span>

                <span
                  *ngIf="pocPhaseData.link_project_billing?.linking_date"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div
              #collapse="ngbCollapse"
              [(ngbCollapse)]="isGcpPrjLinkedCollapsed"
            >
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template
                    [ngIf]="
                      pocPhaseData.link_project_billing?.linking_date &&
                      pocPhaseData.link_project_billing?.link_by
                    "
                  >
                    <label class="phase_details_title">Finance Name</label>
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.link_project_billing.link_by.name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Linking</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.link_project_billing.linking_date
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Consumed Time</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.link_project_billing.consumed_time
                    }}</label>
                    <label class="phase_details_title mt-2">Link</label>
                    <label class="phase_details_data_txt"
                      >{{ pocPhaseData.link_project_billing.project_name }} to
                      {{
                        pocPhaseData.link_project_billing.billing_account_name
                      }}</label
                    >
                  </ng-template>
                  <input
                    *ngIf="!pocPhaseData.link_project_billing?.linking_date"
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isFinanceTeam
                    }"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="linkProjectPopup()"
                    value="Link Project to Billing"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- BUDGET -->
          <div
            class="btn_collapse_parent mt-2"
            id="order_budget"
            *ngIf="pocPhaseData.link_project_billing?.linking_date"
          >
            <div class="row opp_col_content align-items-center">
              <div class="col-9 p-0 phase-content">
                <button
                  type="button"
                  class="btn_collapse_header col-10 w-100 d-inline-flex"
                  [ngClass]="{
                    'col-10': pocPhaseData.billing_budget?.created_at,
                    'col-12': !pocPhaseData.billing_budget?.created_at
                  }"
                  (click)="isBudgetCreCollapsed = !isBudgetCreCollapsed"
                  [attr.aria-expanded]="!isBudgetCreCollapsed"
                  aria-controls="collapseManager"
                >
                  <span
                    class="fa p-1"
                    [ngClass]="{
                      'fa-angle-down': isBudgetCreCollapsed,
                      'fa-angle-up': !isBudgetCreCollapsed
                    }"
                  ></span>
                  <label
                    title="Finance Created Budget"
                    class="label-ellipsis"
                    *ngIf="pocPhaseData.billing_budget?.created_at"
                    >Finance Created Budget</label
                  >
                  <label
                    title="Pending Finance Budget Creation"
                    class="label-ellipsis"
                    *ngIf="!pocPhaseData.billing_budget?.created_at"
                    >Pending Finance Budget Creation</label
                  >
                </button>
              </div>

              <div class="col-3 time-content d-inline-flex align-items-center">
                <span
                  *ngIf="pocPhaseData.billing_budget?.created_at"
                  class="check_green fa fa-check fa-thin float-end mt-1 col-2"
                ></span>
              </div>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isBudgetCreCollapsed">
              <div class="btn_collapse_content">
                <div class="d-grid p-1">
                  <ng-template [ngIf]="pocPhaseData.billing_budget?.created_at">
                    <label class="phase_details_title">Finance Name</label>
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.billing_budget.created_user_name
                    }}</label>
                    <label class="phase_details_title mt-2"
                      >Date & Time of Creation</label
                    >
                    <label class="phase_details_data_txt">{{
                      pocPhaseData.billing_budget.created_at
                        | date : "dd MMM,yyyy - hh:mma"
                    }}</label>
                    <input
                      [ngClass]="{
                        phases_disabled_btn: !currentUser.isFinanceTeam
                      }"
                      class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                      (click)="createBudgetPopup()"
                      value="Update Budget"
                    />
                  </ng-template>
                  <input
                    *ngIf="!pocPhaseData.billing_budget?.created_at"
                    [ngClass]="{
                      phases_disabled_btn: !currentUser.isFinanceTeam
                    }"
                    class="btn btn-primary btn-color rounded-pill p-1 f-14 align-self-end col-12 mt-1 mb-2"
                    (click)="createBudgetPopup()"
                    value="Create Budget"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>

<!-- EMBEDED GOOGLE DOC -->
<div
  class="modal fade"
  bsModal
  #googlepocDoc="bs-modal"
  tabindex="-1"
  id="googlepocDoc"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-xl modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 product-sans col-11">
          Proof of Concept Document
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="googlepocDoc.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body border-dark">
        <div class="mx-2" *ngIf="pocPhaseData?.poc?.googleDocUrl">
          <poc-google-doc-card
            *ngIf="googleDocData"
            [cardData]="googleDocData"
            [poc]="pocPhaseData.poc"
            (createEmitter)="createPOC($event)"
            (approveEmitter)="approvePoc($event)"
            (custEmitter)="sendPOCToCustomer($event)"
            (draftEmitter)="sendDraftPocForApproval($event)"
            [opporunityID]="opporunityID"
          ></poc-google-doc-card>
        </div>
      </div>
    </div>
  </div>
</div>

<customer-info-popup
  *ngIf="customerInfoAdded"
  #poccustomerInfoPopup
  (sendListener)="sendPOCToCustomer($event)"
  [customer_emails]="customerInfoAdded?.customer_email"
  [customer_name]="customerInfoAdded?.customer_name"
></customer-info-popup>

<new-project-modal
  [pocID]="pocPhaseData?.poc?._id"
  (createProjectEmitter)="createProject($event)"
  #poc_new_project_modal
></new-project-modal>

<link-project-popup
  *ngIf="pocPhaseData?.gcp_project"
  [pocID]="pocPhaseData?.poc?._id"
  [projectRfId]="pocPhaseData.gcp_project._id"
  [projectName]="pocPhaseData.gcp_project.name"
  (linkProj)="linkingDone($event)"
  #poclinkProjectModal
></link-project-popup>

<!-- CREATION BUDGET POPUP -->
<div
  class="modal fade"
  bsModal
  #pocBudgetCreModal="bs-modal"
  tabindex="-1"
  id="pocBudgetCreModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-md modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header border-0 vrm_decision_header">
        <h1 class="modal-title fs-5 col-11 product-sans-medium">
          Create Budget
        </h1>
        <button
          type="button"
          class="btn-close ms-0"
          (click)="pocBudgetCreModal.hide()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <add-budget-form
          *ngIf="billingRfId != null"
          [billingRfId]="billingRfId"
          (createBudgetEmitter)="createBudget($event)"
        ></add-budget-form>
      </div>
    </div>
  </div>
</div>
