// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var withoutLoader =
      (!Utilities.isNullOrEmpty(req.params.get(Unicode.WITHOUT_LOADER)) &&
        req.params.get(Unicode.WITHOUT_LOADER) == '1') ||
      (req.method.toLowerCase() == 'get' &&
        (req.url.indexOf('cloudresourcemanager.googleapis.com/v3/projects') >=
          0 ||
          req.url.indexOf('cloudbilling.googleapis') >= 0)); //todo
    if (req && req.params && withoutLoader == false) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    }
    return Observable.create((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
