<div class="container p-2 w-100">
  <div class="row">
    <div
      *ngFor="let integration of integrations"
      class="col-lg-2 col-md-4 col-sm-6 py-2 text-center"
    >
      <p class="text-md">{{ integration.name }}</p>

      <img
        class="cursor-pointer integration-logo"
        src="./assets/img/{{ integration.icon }}"
        (click)="showOdooIntegPopup()"
        alt="integration system icon"
      />
      <!-- [checked]="isChecked"
        [disabled]="isDisabled" -->
      <mat-slide-toggle
        (change)="change(integration._id)"
        color="$primary-blue-dark"
        [(ngModel)]="integration.enabled"
        [checked]="integration.enabled"
      >
      </mat-slide-toggle>
    </div>
  </div>
</div>
<!-- ADD NEW Integration MODAL -->
<div
  class="modal fade"
  bsModal
  #addOdooIntegrationModal="bs-modal"
  tabindex="-1"
  id="addOdooIntegrationModal"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg modal-dialog-centered rounded-4">
    <div class="modal-content">
      <div class="modal-header btn-color">
        <h1
          class="modal-title fs-5 text-white product-sans-light"
          id="addIntegrationModalLabel"
        >
          Configuration
        </h1>
        <button
          type="button"
          class="btn-close btn-close-white"
          (click)="addOdooIntegrationModal.hide()"
          aria-label="Close"
        ></button>
      </div>
      <form
        role="form"
        [formGroup]="integrationForm"
        role="form"
        #formDir="ngForm"
        id="add-curr-form"
        novalidate
      >
        <div class="modal-body border-dark">
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <label
              for="name"
              class="form-label text-nowrap col-2 me-3 me-xl-1 mb-0 d-none d-md-inline"
              >Key</label
            >
            <input
              formControlName="key"
              [ngClass]="{
                'is-invalid': formDir.submitted && key.invalid
              }"
              type="text"
              class="form-control rounded-pill"
              id="name"
              placeholder="Key"
            />
          </div>
        </div>
        <div class="modal-footer">
          <input
            class="btn btn-primary btn-color rounded-pill col-3"
            value="Test Settings"
            data-bs-dismiss="modal"
          />
          <input
            class="btn btn-primary btn-color rounded-pill col-3"
            value="Save Configuration"
            data-bs-dismiss="modal"
          />
        </div>
      </form>
    </div>
  </div>
</div>
