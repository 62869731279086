import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EngineerRecommendationsListModule } from './engineer-recommendations-list/engineer-recommendations-list.module';
import { EngineerRecommendationsDetailsModule } from './engineer-recommendations-list/engineer-recommendations-details/engineer-recommendations-details.module';
import { EngineerRecommendationsComponent } from './engineer-recommendations.component';

const routes: Routes = [
  {
    path: '',
    component: EngineerRecommendationsComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      { path: 'list', loadChildren: () => EngineerRecommendationsListModule },
      {
        path: 'details',
        loadChildren: () => EngineerRecommendationsDetailsModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EngineerRecommendationsRoutingModule {}
